const orderBy = (array, key, order = 'low' || 'high') => {
  if (!Array.isArray(array)) return [];
  if (order === 'low') {
    return array?.sort((a, b) => {
      if (a?.[key] < b?.[key]) return -1;
      if (a?.[key] > b?.[key]) return 1;
      return 0;
    });
  }
  return array?.sort((a, b) => {
    if (a?.[key] > b?.[key]) return -1;
    if (a?.[key] < b?.[key]) return 1;
    return 0;
  });
};

export default orderBy;
