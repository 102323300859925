import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useModal from 'src/hooks/useModal';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  IconButton,
  Collapse,
  Button,
  Typography
} from '@material-ui/core';
import { KeyboardArrowDownRounded, MoreHoriz, Add } from '@material-ui/icons';
import Modal from 'src/components/Modal';
import isArray from 'src/utils/is_array';

// services
import ProductService from 'src/services/ProductService';

// utils
import orderBy from 'src/utils/arrays';
import CreateVariantForm from './components/CreateVariantForm';
import RowProduct from './components/RowProduct';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '15px',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none'
  },
  containerAvatar: {
    objectFit: 'cover',
    marginRight: theme.spacing(2),
    width: 60,
    height: 60,
    borderRadius: 10,
    overflow: 'hidden'
  },
  avatar: {
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '100%',
    minHeight: '100%'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  },
  table: {
    border: 'none',
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
    '& th,td': {
      border: 'none'
    },
    '& th': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5)
    },
    '& th p': {
      color: '#b2b3b8',
      fontWeight: 500,
      fontSize: 14
    }
  },
  head: {
    border: 'none'
  },
  nameProduct: {
    fontSize: 15,
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  descripProduct: {
    color: '#A5A5A5',
    fontSize: 12,
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  row: {
    background: '#fff',
    '& td:first-child': {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10
    },
    '& td:last-child': {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10
    }
  },
  sortableIcon: {
    color: theme.palette.primary.main,
    fontSize: '24px',
    marginRight: '10px'
  },
  buttonIcon: {
    borderRadius: '50%',
    minWidth: 10,
    padding: 5,
    color: '#fff'
  },
  modal: {
    boxShadow:
      'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;',
    borderRadius: 25
  },
  bodyModal: {
    padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
    paddingRight: theme.spacing(1.5)
  },
  dropdown: {
    color: theme.palette.primary.main,
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s ease 0s'
  },
  dropdownActive: {
    transform: 'rotate(180deg)'
  }
}));

const Results = ({
  className,
  data,
  categoryIsAvailable,
  onRefresh,
  ...rest
}) => {
  const classes = useStyles();
  const [isOpen, toggle] = useModal();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState('');
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [panel, setPanel] = useState(null);
  const [products, setProducts] = useState([]);
  const [uniqueProducts, setUniqueProducts] = useState([]);
  const [productSelected, setProductSelected] = useState(null);

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const closePanel = () => {
    setTimeout(() => {
      setPanel(null);
    }, 50);
  };

  const toggleAvailableProduct = async (productId) => {
    const response = await ProductService.toggle(productId);
    setTooltipOpen('');
    setTooltipTitle('');

    if (response?.code === 200) {
      const newProducts = products.reduce((result, item) => {
        if (item.id === productId) {
          return result.concat({ ...item, is_available: !item?.is_available });
        }
        return result.concat(item);
      }, []);

      setProducts(newProducts);
    }

    if (response?.code === 409) {
      setTooltipTitle(response?.errors[0]);
      setTooltipOpen(productId);

      setTimeout(() => {
        setTooltipOpen('');
        setTooltipTitle('');
      }, 5000);
    }
  };
  /**
   * groupVariants me agrupa las variantes de un producto atravez de su grupo.
   * @param string group: Es el grupo al que pertenece cada una de la variantes que
   * queremos agrupar en la lista
   * @return array: Me retorna un producto con un campo que almacena su lista de variantes
   *
   */
  const groupVariants = (group) => {
    // Me filtra todos los productos que esten asociados al grupo
    const productsGroup = data.filter((product) => product?.group === group);
    // En caso de que solo alla un solo producto con ese grupo, entonces no se agrupa.
    if (productsGroup?.length === 1) return productsGroup[0];
    return {
      id: productsGroup[0]?.id,
      name: productsGroup[0]?.name,
      business: productsGroup[0]?.business,
      description: productsGroup[0]?.description,
      primary_image: productsGroup[0]?.primary_image,
      sizes: productsGroup[0]?.sizes,
      showVariants: false,
      waitCollapse: false,
      variants: productsGroup
    };
  };

  /**
   * Metodo que me permite actualizar algún campo de un producto
   *
   * @param string index: posición del producto que quiero actualizar.
   * @param string field: nombre del campo que se desea editar
   * @param any value: El valor nuevo que se le desea asignar al campo.
   */
  const editProduct = (index, field, value) => {
    setProducts((prev) => {
      if (!isArray(prev)) return [];
      return prev.map((product, i) => {
        if (i === index) {
          return {
            ...product,
            [field]: value
          };
        }
        return product;
      });
    });
  };

  const toggleShowVariants = (index) => {
    let showVariants = false;
    setProducts((prev) => {
      if (!isArray(prev)) return [];
      return prev.map((product, i) => {
        if (i === index) {
          showVariants = !product?.showVariants;
          return {
            ...product,
            showVariants
          };
        }
        return product;
      });
    });

    /**
     * Me permite esperar a que se termine la animación del collapse, antes de
     * desaparecer la lista de variantes
     */
    if (!showVariants) {
      setTimeout(() => {
        editProduct(index, 'waitCollapse', false);
      }, 650);
    } else {
      editProduct(index, 'waitCollapse', true);
    }
  };

  /**
   * filterPerPage, me filtra un limite de productos, lo cuales van a ser los que se van
   * a renderizar por cada pagina de la tabla, por ejemplo si se van a mostrar 10 productos
   * por pagina, entonces este metodo me va a obtener solo los 10 productos que se encuentren
   * en esa pagina.
   */
  const filterPerPage = () => {
    const newProducts = [];
    // Se ordenan los productos por su campo numerico order
    const filterProducts = orderBy(uniqueProducts, 'order').slice(
      page * limit,
      page * limit + limit
    );
    filterProducts.forEach((item) => {
      const product = item?.group ? groupVariants(item?.group) : item;
      newProducts.push(product);
    });
    setProducts(newProducts);
  };

  /**
   * deleteCopyGroups me borra todos los productos que tengan au grupo repetido,
   * para que así solo quede un solo producto de cada grupo existente.
   */
  const deleteCopyGroups = () => {
    const uniquesGroups = data.reduce((accArr, product) => {
      if (
        accArr.findIndex((item) => item?.group === product?.group) < 0 ||
        !product?.group
      ) {
        accArr.push(product);
      }
      return accArr;
    }, []);
    setUniqueProducts(uniquesGroups);
  };

  useEffect(() => {
    if (panel) {
      document.addEventListener('click', closePanel);
    }

    return () => {
      document.removeEventListener('click', closePanel);
    };
  }, [panel]);

  useEffect(() => {
    if (Array.isArray(data)) {
      deleteCopyGroups();
    }
  }, [data]);

  useEffect(() => {
    if (uniqueProducts.length > 0) filterPerPage();
  }, [page]);

  useEffect(() => {
    if (uniqueProducts.length > 0) filterPerPage();
  }, [uniqueProducts]);

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1020}>
            <Table className={classes.table}>
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell style={{ width: 100 }}>
                    <Typography variant="body2">Orden</Typography>
                  </TableCell>
                  <TableCell style={{ width: 270 }}>
                    <Typography variant="body2">Nombre</Typography>
                  </TableCell>
                  {products?.some(
                    (product) => product?.business === 'Ropa/Calzado'
                  ) && (
                    <TableCell style={{ width: 120 }}>
                      <Typography variant="body2">Opciones</Typography>
                    </TableCell>
                  )}
                  <TableCell>
                    <Typography variant="body2">Precio</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">Disponible</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody lockAxis="y">
                {products.map((product, index) => {
                  const variantsColors = product?.variants?.filter(
                    (variant) => variant?.color
                  );
                  return (
                    <>
                      {product?.variants ? (
                        <>
                          <TableRow className={classes.row} key={product.id}>
                            <TableCell style={{ width: 10, paddingRight: 0 }}>
                              <Box width="inherit">
                                <IconButton
                                  onClick={() => toggleShowVariants(index)}
                                  size="small"
                                  aria-label="drop-down"
                                >
                                  <KeyboardArrowDownRounded
                                    className={clsx(classes.dropdown, {
                                      [classes.dropdownActive]:
                                        product?.showVariants
                                    })}
                                  />
                                </IconButton>
                              </Box>
                            </TableCell>
                            <TableCell
                              style={{
                                width: 270
                              }}
                            >
                              <Box display="flex" alignItems="center">
                                <Box className={classes.containerAvatar}>
                                  <img
                                    src={product.primary_image}
                                    className={classes.avatar}
                                    alt="product_image"
                                  />
                                </Box>
                                <Box width={270}>
                                  <Typography
                                    variant="body2"
                                    className={classes.nameProduct}
                                  >
                                    {product.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    className={classes.descripProduct}
                                  >
                                    {product.description}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell style={{ width: 120 }}>
                              <Box
                                width={120}
                                display="flex"
                                alignItems="center"
                              >
                                {variantsColors?.slice(0, 3).map((item) => (
                                  <>
                                    {item?.business === 'Ropa/Calzado' && (
                                      <Box display="flex" alignItems="center">
                                        {item?.color && (
                                          <Box
                                            mx={0.3}
                                            bgcolor={`#${item?.color?.hexadecimal}`}
                                            width={17}
                                            height={17}
                                            borderRadius="50%"
                                            border="1px solid rgba(0, 0, 0, 0.1)"
                                          />
                                        )}
                                      </Box>
                                    )}
                                  </>
                                ))}
                                {variantsColors?.length > 3 && (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    bgcolor="#e9e9f0"
                                    borderRadius="50%"
                                    mx={0.3}
                                    width={17}
                                    height={17}
                                  >
                                    <MoreHoriz
                                      color="primary"
                                      style={{ fontSize: '17px' }}
                                    />
                                  </Box>
                                )}
                                <Box ml={0.5}>
                                  <Button
                                    onClick={() => {
                                      setProductSelected(product);
                                      toggle();
                                    }}
                                    className={classes.buttonIcon}
                                    style={{
                                      padding: 2
                                    }}
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                  >
                                    <Add style={{ fontSize: '17px' }} />
                                  </Button>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                          </TableRow>
                          <TableRow
                            style={{
                              display: product?.waitCollapse ? null : 'none'
                            }}
                            className={classes.row}
                          >
                            <TableCell style={{ padding: 0 }} colSpan={10}>
                              <Collapse
                                in={product?.showVariants}
                                timeout={400}
                                mountOnEnter
                                unmountOnExit
                              >
                                <Box minWidth={1020}>
                                  <Table className={classes.table}>
                                    <TableBody lockAxis="y">
                                      {product?.variants?.map((item) => (
                                        <RowProduct
                                          products={product?.variants}
                                          product={item}
                                          setProductSelected={
                                            setProductSelected
                                          }
                                          toggle={toggle}
                                          toggleAvailableProduct={
                                            toggleAvailableProduct
                                          }
                                          tooltipOpen={tooltipOpen}
                                          tooltipTitle={tooltipTitle}
                                          categoryIsAvailable={
                                            categoryIsAvailable
                                          }
                                          index={index}
                                          onRefresh={onRefresh}
                                        />
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <RowProduct
                          products={products}
                          product={product}
                          setProductSelected={setProductSelected}
                          toggle={toggle}
                          toggleAvailableProduct={toggleAvailableProduct}
                          tooltipOpen={tooltipOpen}
                          tooltipTitle={tooltipTitle}
                          categoryIsAvailable={categoryIsAvailable}
                          showAddColor
                          onRefresh={onRefresh}
                        />
                      )}
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={uniqueProducts.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 15]}
        />
      </Card>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        title="Añadir color"
        showHeader={false}
        width={260}
        className={classes.modal}
        bodyClass={classes.bodyModal}
      >
        <CreateVariantForm
          product={productSelected}
          onClose={toggle}
          onRefresh={onRefresh}
        />
      </Modal>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  categoryIsAvailable: PropTypes.bool,
  onRefresh: PropTypes.func
};

export default Results;
