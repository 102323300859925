import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// MUI components
import {
  Box,
  FormControl,
  Typography,
  List,
  ListItem,
  makeStyles,
  Button,
  CircularProgress,
  FormHelperText
} from '@material-ui/core';

// Services
import ProductService from 'src/services/ProductService';

// Utils
import { createImages } from 'src/utils/formImages';
import Selector from 'src/components/Selector';
import ColorService from 'src/services/ColorService';

// Redux
import { selector as UserSelector } from 'src/redux/ducks/user';
import MultiImages from 'src/components/MultiImages';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  addImage: {
    marginRight: theme.spacing(1),
    borderRadius: 10,
    maxWidth: 45,
    maxHeight: 45,
    minWidth: 45,
    minHeight: 45,
    padding: 5,
    border: `2px dotted ${theme.palette.primary.main}`
  },
  containerAvatar: {
    objectFit: 'cover',
    marginBottom: theme.spacing(1),
    width: 45,
    height: 45,
    borderRadius: 10,
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`
    }
  },
  avatar: {
    opacity: 1,
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '100%',
    minHeight: '100%'
  },
  previewImage: {
    objectFit: 'cover',
    marginRight: theme.spacing(1),
    width: 150,
    height: 150,
    borderRadius: 10,
    overflow: 'hidden'
  },
  avatarLoad: {
    opacity: 0
  },
  nameProduct: {
    fontSize: 15,
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  descripProduct: {
    margin: '1px 0',
    color: '#A5A5A5',
    fontSize: 13,
    fontWeight: 400,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  scroll: {
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  },
  bodyModal: {
    paddingTop: 0,
    paddingBottom: 0
  },
  containerButtons: {
    display: 'none',
    alignItems: 'items',
    justifyContent: 'flex-end',
    margin: '6px 3px'
  },
  overlay: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 3,
    backgroundColor: 'transparent',
    '&:hover': {
      opacity: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      '& $containerButtons': {
        display: 'flex'
      }
    },
    width: '100%',
    height: '100%',
    transition: 'opacity 0.5s ease'
  },
  buttonIcon: {
    borderRadius: '50%',
    minWidth: 10,
    padding: 4,
    margin: '0 3px'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white'
    }
  },
  header: {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8
  },
  badge: {
    '& > p': {
      fontSize: 11,
      fontWeight: 300
    },
    color: '#fff',
    background: theme.palette.primary.main,
    padding: '2px 5px',
    borderRadius: 4,
    marginBottom: 9
  },
  sizeText: {
    fontSize: 13,
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  listSizes: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    padding: 0,
    '& > li': {
      paddingLeft: 0,
      paddingTop: theme.spacing(0.1),
      paddingBottom: theme.spacing(0.1),
      paddingRight: theme.spacing(1),
      width: 'fit-content'
    }
  },
  input: {
    '& .MuiInputBase-root': {
      boxSizing: 'border-box',
      borderRadius: 6,
      backgroundColor: '#F5F7FB',
      border: '1px solid rgb(216, 216, 216)',
      '&.Mui-focused': {
        border: '0.13em solid #009739'
      },
      '&.Mui-error': {
        border: '0.13em solid #f44336'
      }
    },
    '& .MuiInputBase-root::after, & .MuiInputBase-root::before': {
      display: 'none'
    },
    '& .MuiFilledInput-input': {
      paddingTop: theme.spacing(1.1),
      paddingBottom: theme.spacing(1.1),
      fontFamily: '"Poppins", sans-serif',
      fontSize: 13,
      color: 'rgba(0, 0, 0, 0.8)',
      fontWeight: 500
    },
    '& .MuiFilledInput-input:-webkit-autofill': {
      borderRadius: 7
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
      marginTop: 0
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiInputAdornment-root': {
        transition: 'color 0.3s ease',
        color: '#8c9baf',
        '& .MuiIconButton-label': {
          transition: 'color 0.3s ease',
          color: '#8c9baf'
        }
      },
      '&.Mui-focused .MuiInputAdornment-root': {
        color: '#009739',
        '& .MuiIconButton-label': {
          color: '#009739'
        }
      }
    }
  },
  label: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 13,
    fontWeight: 500,
    color: '#a8aab4'
  },
  button: {
    borderRadius: 50,
    width: 165,
    fontSize: 13,
    paddingTop: theme.spacing(0.7),
    paddingBottom: theme.spacing(0.7)
  }
}));

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10
  }
});

const selectorStyles = {
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: '#F5F7FB',
      borderColor: isFocused ? '#009739' : 'rgb(204, 204, 204)',
      boxShadow: isFocused ? '#009739 0 0 0 1px' : undefined,
      '&:hover': {
        borderColor: isFocused ? '#009739' : 'rgb(175, 175, 175)'
      },
      cursor: 'pointer'
    };
  },
  menu: (styles) => ({
    ...styles,
    zIndex: 2
  }),
  menuList: (menuListStyles) => ({
    ...menuListStyles,
    '::-webkit-scrollbar': {
      width: '4px'
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: '#009739'
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const dotStyles = data?.isColor ? dot(data.color) : {};
    let backgroundColor = '';
    let bgColorActive = '';

    if (isDisabled) backgroundColor = undefined;
    else if (isSelected) backgroundColor = '#009739';
    else if (isFocused) backgroundColor = '#97D4AE';
    else backgroundColor = undefined;

    if (!isDisabled) {
      if (isSelected) bgColorActive = '#009739';
      else bgColorActive = '#97D4AE';
    } else bgColorActive = undefined;

    return {
      ...styles,
      ...dotStyles,
      backgroundColor,
      color: isSelected ? '#fff' : '#000',
      cursor: 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor: bgColorActive
      }
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#d4edda'
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#009739'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#009739',
    ':hover': {
      backgroundColor: '#009739',
      color: '#fff'
    }
  }),
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, fontSize: 14 }),
  singleValue: (styles, { data }) => {
    const dotStyles = data?.isColor ? dot(data.color) : {};
    return {
      ...styles,
      fontSize: 14,
      ...dotStyles
    };
  }
};

const CreateVariantForm = ({ product, onClose, onRefresh }) => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [colors, setColors] = useState([]);
  const [color, setColor] = useState(null);
  const [localErrors, setLocalErrors] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [imageSelected, setImageSelected] = useState({
    index: 0,
    image: null,
    scale: '3:4'
  });
  // Estado que establece cuando esta cargando el envio de información del formulario
  const [loading, setLoading] = useState(false);

  const handlePreviewImages = (images) => {
    setPreviewImages(images);
  };

  const handleImageSelected = (image) => {
    setImageSelected(image);
  };

  const handleChangeColor = (value) => {
    setColor(value);
  };

  /*
    Esta función me reordena el array de imagenes, insertando la imagen seleccionada
    en la primera posición de la lista
  */
  const setPrimaryImage = () => {
    const newPreviewImages = previewImages?.slice();
    newPreviewImages.splice(imageSelected?.index, 1);
    newPreviewImages.splice(0, 0, {
      image: imageSelected?.image,
      scale: imageSelected?.scale
    });
    return newPreviewImages;
  };

  const uploadImages = async (id, files) => {
    // Se obtiene solo las imagenes subidas
    const images = files?.map((item) => item?.image) || [];
    // Se obtiene solo las las escalas de las imagenes subidas
    const scales = files?.map((item) => item?.scale) || [];

    const formData = createImages(id, images, scales);
    if (formData) {
      await ProductService.uploadImages(formData);
    }
    setLoading(false);
    onRefresh();
    onClose();
  };

  const getColors = async () => {
    const response = await ColorService.getByBrand(user.brand_id);
    if (response?.code === 200) {
      setColors(response?.data);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const errors = []; // Errors fields

    // Data to send
    const data = {
      id: product?.id,
      color_id: color?.value
    };

    // Validations
    if (!data?.color_id || data.color_id?.length === 0) {
      errors.push({
        field: 'color',
        error: 'Debe seleccionar un color'
      });
    }

    /*
      Se verifica si hubo algun error en uno de los campos, en caso de haber se muestran
      los errores respectivos
    */
    if (errors.length > 0) {
      setLoading(false);
      setLocalErrors(errors);
      return;
    }

    const response = await ProductService.createVariant(data);
    if (response?.code === 200 || response?.code === 201) {
      let files = [];
      if (previewImages && previewImages?.length > 0) {
        // Establece la imagen seleccionada como la principal del producto
        files = setPrimaryImage();
      }
      uploadImages(response?.data?.id, files);
    } else {
      console.error(response?.errors || []);
    }
  };

  useEffect(() => {
    getColors();
  }, []);

  return (
    <>
      <form
        className={classes.form}
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          position="relative"
        >
          <MultiImages
            previewImages={previewImages}
            onChangePreviewImages={handlePreviewImages}
            imageSelected={imageSelected}
            onChangeImage={handleImageSelected}
          />
          <Box
            pr={1}
            mt={2}
            display="flex"
            flexDirection="column"
            width="100%"
            textAlign="start"
          >
            <Box width="fit-content" className={classes.badge}>
              <Typography variant="body2">Nuevo</Typography>
            </Box>
            <Typography
              variant="body2"
              color="primary"
              className={classes.nameProduct}
            >
              {product?.name}
            </Typography>
            <Typography variant="body2" className={classes.descripProduct}>
              {product?.description}
            </Typography>
            {product?.sizes?.length > 0 && (
              <Box mt={1.5}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.sizeText}
                >
                  Tallas
                </Typography>
                <List className={classes.listSizes}>
                  {product?.sizes?.map((size) => (
                    <>
                      <ListItem>
                        <Box
                          bgcolor="#d4edda"
                          borderRadius="50%"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width={26}
                          height={26}
                          p={0.7}
                        >
                          <Typography
                            className={classes.descripProduct}
                            style={{
                              color: '#009739',
                              fontSize: 11,
                              fontWeight: 600
                            }}
                            variant="body2"
                          >
                            {size}
                          </Typography>
                        </Box>
                      </ListItem>
                    </>
                  ))}
                </List>
              </Box>
            )}
            <Box mt={1.5} display="flex" flexDirection="column">
              <Typography
                variant="body2"
                color="primary"
                className={classes.sizeText}
              >
                Color
              </Typography>
              <Box display="flex" alignItems="center" mt={0.6}>
                <Box display="flex" height="100%" width="100%">
                  <FormControl
                    required
                    className={classes.formControl}
                    error={localErrors?.some((err) => err?.field === 'color')}
                  >
                    <Selector
                      styles={selectorStyles}
                      options={colors?.map((item) => ({
                        label: item?.name,
                        value: item?.id,
                        color: `#${item?.hexadecimal}`,
                        isColor: true
                      }))}
                      value={color}
                      onChange={handleChangeColor}
                      name="color"
                      labelId="color"
                      label="Color"
                      placeholder="Seleccione el color"
                    />
                    <FormHelperText error>
                      {
                        localErrors?.find((err) => err?.field === 'color')
                          ?.error
                      }
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt={2.5}>
            <Button
              className={classes.button}
              size="small"
              variant="contained"
              color="primary"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : ''}
              type="submit"
            >
              Añadir color
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

CreateVariantForm.propTypes = {
  product: PropTypes.object,
  onClose: PropTypes.func,
  onRefresh: PropTypes.func
};

export default CreateVariantForm;
