import React, { forwardRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles(() => ({
  border: {
    borderBottom: '1px dashed #000'
  },
  font: {
    fontFamily: '"Courier New", Courier, monospace'
  },
  bold: {
    fontWeight: 'bold'
  }
}));

const TicketReport = forwardRef(({
  orders,
  branchOffice,
  status,
  date,
  totalCash,
  totalCard,
  total
}, ref) => {
  const classes = useStyles();

  return orders && branchOffice && (
    <Box px={2} py={1} ref={ref}>
      <Box py={1} display="flex" flexDirection="column" alignItems="items" textAlign="center" className={classes.border}>
        <Typography className={classes.font} style={{ fontWeight: 600 }} variant="h3">{`Reporte ${status}s`}</Typography>
        <Typography className={classes.font} variant="body2">
          {`Fecha: ${date}`}
        </Typography>
        <Typography className={classes.font} variant="body2">
          {branchOffice?.brand?.name}
        </Typography>
        <Typography className={classes.font} variant="body2">
          {branchOffice?.address?.replace(/#/g, 'N°')}
        </Typography>
        <Typography className={classes.font} variant="body2">
          {branchOffice?.phone1}
        </Typography>
      </Box>
      <Box className={classes.border}>
        <Box py={0.5} textAlign="center" className={classes.border}>
          <Typography className={clsx(classes.font, classes.bold)} variant="h6">LISTADO ORDENES</Typography>
        </Box>
        <Box py={1}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={clsx(classes.font, classes.bold)} variant="body2">Orden</Typography>
            <Typography className={clsx(classes.font, classes.bold)} variant="body2">Valor</Typography>
          </Box>
          {orders?.map((order) => (
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={0.5}>
              <Box display="flex" flexDirection="column">
                <Typography style={{ wordBreak: 'break-all' }} className={classes.font} variant="body2">
                  #
                  {order?.reference}
                </Typography>
                <Typography style={{ wordBreak: 'break-all', fontSize: '12px' }} className={classes.font} variant="body2">
                  {order?.payment_method === 'cash' ? 'Efectivo' : 'Tarjeta'}
                </Typography>
              </Box>
              <Box ml={3.5}>
                <Typography style={{ display: 'flex' }} variant="body2">
                  <span className={classes.font} style={{ marginRight: '3px' }}>$</span>
                  <CurrencyFormat
                    className={classes.font}
                    value={order?.sub_total}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box py={1} className={classes.border}>
        <Typography className={clsx(classes.font, classes.bold)} variant="h6">
          <span className={classes.font} style={{ marginRight: '3px' }}>Efectivo = $</span>
          <CurrencyFormat
            className={classes.font}
            value={totalCash}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
          />
        </Typography>
      </Box>
      <Box py={1} className={classes.border}>
        <Typography className={clsx(classes.font, classes.bold)} variant="h6">
          <span className={classes.font} style={{ marginRight: '3px' }}>Tarjeta = $</span>
          <CurrencyFormat
            className={classes.font}
            value={totalCard}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
          />
        </Typography>
      </Box>
      <Box py={1} className={classes.border}>
        <Typography className={clsx(classes.font, classes.bold)} variant="h6">
          <span className={classes.font} style={{ marginRight: '3px' }}>Total día = $</span>
          <CurrencyFormat
            className={classes.font}
            value={total}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
          />
        </Typography>
      </Box>
    </Box>
  );
});

TicketReport.propTypes = {
  orders: PropTypes.array,
  branchOffice: PropTypes.object,
  status: PropTypes.string,
  date: PropTypes.string,
  totalCash: PropTypes.number,
  totalCard: PropTypes.number,
  total: PropTypes.number,
};

export default TicketReport;
