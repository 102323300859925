import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';
import {
  Grid,
  Typography,
  makeStyles,
  TextField,
  Box,
  Switch
} from '@material-ui/core';
import {
  DeleteOutline as DeleteIcon
} from '@material-ui/icons';
import {
  Plus as PlusIcon
} from 'react-feather';
import CurrencyFormat from 'react-currency-format';

const model = {
  id: () => uuid4(),
  name: '',
  price: 0.0,
  is_default: false,
};

const useStyles = makeStyles({
  plusIcon: {
    cursor: 'pointer'
  },
  deleteIcon: {
    cursor: 'pointer',
    position: 'relative',
  },
  switch: {
    position: 'relative',
  }
});

const PricesCustomization = ({ onChange, data, edit }) => {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [prices, setPrices] = useState([{
    name: 'Precio Base',
    price: 0.0,
    is_default: true,
    description: '',
  }]);

  const addPrice = () => {
    setPrices(prices.concat({
      ...model,
      id: uuid4()
    }));
  };

  const removePrice = (uuid) => {
    if (prices.length <= 1) return;

    setPrices(prices.filter(({ id }) => id !== uuid));
  };

  const setDefault = (uuid) => {
    const newPrices = prices?.reduce((array, item) => {
      delete item.default;
      delete item.value;
      return (
        array.concat({
          ...item,
          is_default: item?.id === uuid
        })
      );
    }, []);

    setPrices(newPrices);
  };

  const handleChange = (e, uuid) => {
    const newPrices = prices?.reduce((array, item) => {
      delete item.default;
      delete item.value;
      const key = e.target?.name === 'price_description' ? 'description' : e.target.name;
      return (
        item?.id !== uuid
          ? array.concat(item)
          : array.concat({
            ...item,
            [key]: e.target.value
          })
      );
    }, []);

    setPrices(newPrices);
  };

  useEffect(() => {
    onChange(prices);
  }, [prices]);

  useEffect(() => {
    if (edit && !isLoaded && data?.length > 0) {
      setPrices(data.map((item) => ({
        ...item,
        id: item?.id || uuid4()
      })));
      setIsLoaded(true);
    }
  }, [data, edit]);

  return (
    <Box mb={2}>
      <Grid container spacing={3}>
        <Grid container item justify="space-between" alignItems="center">
          <Typography variant="h5">Precios</Typography>
          <PlusIcon className={classes?.plusIcon} onClick={addPrice} />
        </Grid>
        {
        prices.map((price, index) => (
          <Grid container item xs={12} spacing={3} key={price?.id}>
            <Grid item xs={9}>
              <TextField
                InputLabelProps={{
                  shrink: true
                }}
                label="Nombre"
                fullWidth
                variant="outlined"
                name="name"
                value={price?.name}
                onChange={(e) => handleChange(e, price?.id)}
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyFormat
                thousandSeparator="."
                decimalSeparator=","
                customInput={TextField}
                InputLabelProps={{
                  shrink: true
                }}
                label="Precio"
                fullWidth
                variant="outlined"
                name="price"
                value={price?.price}
                onChange={(e) => handleChange(e, price?.id)}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                InputLabelProps={{
                  shrink: true
                }}
                label="Descripción"
                fullWidth
                variant="outlined"
                name="price_description"
                value={price?.description}
                onChange={(e) => handleChange(e, price?.id)}
              />
            </Grid>
            <Grid container item xs={3} justify="center" alignItems="center">
              <Switch
                color="primary"
                checked={price?.is_default}
                className={classes?.switch}
                onChange={() => setDefault(price?.id)}
              />
              {
              index > 0 && (
              <DeleteIcon
                className={classes?.deleteIcon}
                onClick={() => removePrice(price?.id)}
              />
              )
            }
            </Grid>
          </Grid>
        ))
      }
      </Grid>
    </Box>
  );
};

PricesCustomization.propTypes = {
  onChange: PropTypes.func,
  edit: PropTypes.bool,
  data: PropTypes.array,
};

PricesCustomization.defaultProps = {
  onChange: () => null,
  edit: false,
  data: []
};

export default PricesCustomization;
