import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import ProductService from 'src/services/ProductService';
import Swal from 'sweetalert2';

const useStyles = makeStyles(() => ({
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    color: '#000',
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  }
}));

const Panel = ({ primaryImage, productId, onRefresh }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const deletePrimaryImage = async (id) => {
    const response = await ProductService.deleteImage(id);
    if (response?.code === 201 || response?.code === 200) {
      await Swal.fire({
        text: 'La foto del producto fue eliminada exitosamente',
        icon: 'success'
      });
      onRefresh();
    }
  };

  const alertDeletePrimaryImage = async (id) => {
    await Swal.fire({
      title: 'Remover foto',
      text: '¿Está seguro que desea remover la foto del producto?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#05B40DBF',
      cancelButtonColor: '#b7b4b4',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar'
    }).then(async (answer) => {
      if (answer.isConfirmed) {
        await deletePrimaryImage(id);
      }
    });
  };

  const handleOpenSchedule = (id) => {
    navigate(`/app/productos/${id}/horario`, { replace: true });
  };

  return (
    <div className={classes?.statusPanel}>
      {primaryImage.includes('apps_images') ? (
        <ul className={classes?.statusPanelList}>
          <li className={classes?.statusPanelItem}>
            <a onClick={() => handleOpenSchedule(productId)}>Ver horarios</a>
          </li>
        </ul>
      ) : (
        <ul className={classes?.statusPanelList}>
          <li className={classes?.statusPanelItem}>
            <a onClick={() => alertDeletePrimaryImage(productId)}>
              Borrar foto
            </a>
          </li>
          <li className={classes?.statusPanelItem}>
            <a onClick={() => handleOpenSchedule(productId)}>Ver horarios</a>
          </li>
        </ul>
      )}
    </div>
  );
};

Panel.propTypes = {
  primaryImage: PropTypes.string,
  productId: PropTypes.string,
  onRefresh: PropTypes.func
};

export default Panel;
