import HttpRequest from './HttpRequest';

export default class PolygonService extends HttpRequest {
  static endpoint = 'city-polygons';

  static async getByCityId(id) {
    this.setMicroservice('polygons');
    this.endpoint = 'city-polygons/by-city-id';
    const response = await this.get(id);
    return response;
  }
}
