import HttpRequest from './HttpRequest';

export default class ReportService extends HttpRequest {
  static endpoint = 'reports';

  // product reports
  static async getProductSaleById(id) {
    this.setMicroservice('reports');
    this.endpoint = 'get-sale-product';
    const response = await this.get(id);
    return response;
  }

  // partner reports
  static async getSales(id) {
    this.setMicroservice('reports');
    this.endpoint = 'get-sales-partner';
    const response = await this.get(id);
    return response;
  }
}
