import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FcHome } from 'react-icons/fc';
import {
  Card,
  Box,
  Grid,
  Typography,
  makeStyles,
  FormControl,
  InputLabel,
  TextField,
  Avatar,
  FormHelperText,
  CircularProgress,
  Button,
} from '@material-ui/core';
import {
  Edit,
  Room,
  SwapHoriz,
  Delete
} from '@material-ui/icons';
import AddressModal from 'src/components/AddressModal';
import BranchOfficeService from 'src/services/BranchOfficeService';
import CityService from 'src/services/CityService';
import Selector from 'src/components/Selector';

const useStyles = makeStyles((theme) => ({
  cardAddress: {
    overflow: 'unset',
    display: 'flex',
    boxShadow: 'none',
    height: '100%',
    borderRadius: 10,
    border: '1px solid #009739'
  },
  borderB: {
    border: '1px solid #e0336d'
  },
  text: {
    fontSize: 12,
    fontWeight: 500,
    color: '#fff'
  },
  title: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.primary.main
  },
  helperText: {
    marginLeft: 0,
    color: theme.palette.primary.main,
  },
  colorB: {
    color: '#e0336d'
  },
  formControl: {
    width: '100%',
  },
  select: {
    fontSize: 14
  },
  textField: {
    '& .MuiInputBase-root': {
      height: 40
    }
  },
  avatar: {
    backgroundColor: '#D9E8F1',
    width: 40,
    height: 40,
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 13
  },
  textAddr: {
    fontWeight: 500,
    fontSize: 12,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  buttonIcon: {
    borderRadius: '50%',
    minWidth: 10,
    padding: 5,
    color: '#fff',
  },
  buttonB: {
    backgroundColor: '#e0336d',
    '&:hover': {
      backgroundColor: '#bd285a'
    }
  },
  iconSwap: {
    fontSize: 15,
    color: '#fff'
  }
}));

const selectorStyles = {
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      borderColor: isFocused ? '#009739' : 'rgb(204, 204, 204)',
      boxShadow: isFocused ? '#009739 0 0 0 1px' : undefined,
      '&:hover': {
        borderColor: isFocused ? '#009739' : 'rgb(175, 175, 175)'
      },
      cursor: 'pointer'
    };
  },
  option: (styles, {
    isDisabled, isFocused, isSelected
  }) => {
    let backgroundColor = '';
    let bgColorActive = '';

    if (isDisabled) backgroundColor = undefined;
    else if (isSelected) backgroundColor = '#009739';
    else if (isFocused) backgroundColor = '#97D4AE';
    else backgroundColor = undefined;

    if (!isDisabled) {
      if (isSelected) bgColorActive = '#009739';
      else bgColorActive = '#97D4AE';
    } else bgColorActive = undefined;

    return {
      ...styles,
      backgroundColor,
      color: isSelected ? '#fff' : '#000',
      cursor: 'pointer',

      ':active': {
        ...styles[':active'],
        backgroundColor: bgColorActive
      },
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, fontSize: 11 }),
  singleValue: (styles) => ({ ...styles, fontSize: 14 }),
};

const Address = forwardRef(({
  className,
  isBranchOffice,
  edit,
  showInfo,
  toggleEdit,
  waitAddress,
  setAddressA,
  branchOffice,
  setAddressB,
  addressB,
  checkAddressContain,
  toggleWarning,
  isRoundtrip,
  setRoundtrip
}, ref) => {
  const classes = useStyles();
  const [branchOffices, setBranchOffices] = useState([]);
  const [loadingCity, setLoadingCity] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);

  const toggleAddressModal = () => {
    setShowAddressModal((prev) => !prev);
  };

  const handleAddress = (selectedAddress, latLng) => {
    if (!checkAddressContain(latLng)) {
      toggleWarning();
      return;
    }
    setAddressB({
      ...addressB,
      address1: selectedAddress,
      latitude: latLng?.lat,
      longitude: latLng?.lng
    });
    toggleAddressModal();
  };

  const handleChangeB = (e) => {
    setAddressB({
      ...addressB,
      [e.target.name]: e.target.value
    });
  };

  const getCity = async (id) => {
    setLoadingCity(true);
    const response = await CityService.getById(id);
    if (response?.code === 200) {
      setAddressB({
        ...addressB,
        city_id: response?.data?.id,
        city: {
          id: response?.data?.id,
          name: response?.data?.name,
          longitude: response?.data?.longitude,
          latitude: response?.data?.latitude,
          is_available: response?.data?.is_available,
          support_google_maps: response?.data?.support_google_maps,
          is_coming_soon: response?.data?.is_coming_soon,
          state: response?.data?.state
        }
      });
      setLoadingCity(false);
    }
  };

  const handleChangeBranchOffice = (value) => {
    const branchOfficeSelected = branchOffices.find((item) => item?.id === value?.value);
    setAddressA(branchOfficeSelected);
    getCity(branchOfficeSelected?.city_id);
  };

  const getBranchOffices = async () => {
    const response = await BranchOfficeService.getAll();
    if (response?.code === 200) setBranchOffices(response?.data);
  };

  useEffect(() => {
    if (isBranchOffice && edit) getBranchOffices();
  }, []);

  return (
    <Card
      ref={ref}
      className={clsx(
        className,
        classes.cardAddress,
        {
          [classes.borderB]: !isBranchOffice
        }
      )}
    >
      <Box
        width="100%"
        height="fit-content"
        pb={3}
        p={2}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="50%"
          border={`3px solid ${isBranchOffice ? '#97D4AE' : '#ed88aa'}`}
          width={26}
          height={26}
          bgcolor={isBranchOffice ? '#009739' : '#e0336d'}
        >
          {isRoundtrip ? (
            <SwapHoriz
              className={classes.iconSwap}
            />
          ) : (
            <Typography
              className={classes.text}
              variant="body2"
            >
              {isBranchOffice ? 'A' : 'B'}
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          pt={2}
          alignItems={waitAddress ? 'center' : 'start'}
          justifyContent={waitAddress ? 'center' : 'start'}
          width="100%"
        >
          <Avatar
            className={classes.avatar}
            src={isBranchOffice ? branchOffices[0]?.brand?.logo || branchOffice?.brand?.logo : null}
          >
            {!isBranchOffice && <FcHome size={23} />}
          </Avatar>
          {!waitAddress && edit && (
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              ml={2}
            >
              <Box
                display="flex"
                alignItems="center"
                width="100%"
              >
                <Box
                  width="40%"
                >
                  <Typography
                    className={clsx(
                      classes.title,
                      {
                        [classes.colorB]: !isBranchOffice
                      }
                    )}
                    variant="body2"
                  >
                    {isBranchOffice ? 'Dirección A' : 'Dirección B'}
                  </Typography>
                </Box>
                {!isBranchOffice && (
                  <Box
                    display="flex"
                    width="60%"
                    justifyContent="end"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ cursor: 'pointer' }}
                      onClick={() => toggleAddressModal()}
                    >
                      <Room
                        className={classes.colorB}
                        style={{
                          fontSize: 21
                        }}
                      />
                      <Typography
                        variant="body2"
                        className={clsx(
                          classes.textAddr,
                          classes.colorB
                        )}
                      >
                        Seleccionar una dirección
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box mt={1.5}>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    {isBranchOffice ? (
                      <FormControl
                        required
                        className={classes.formControl}
                      >
                        <InputLabel
                          className={classes.inputLabel}
                          style={{ fontSize: 14 }}
                          id="sucursal"
                        >
                          Sucursal
                        </InputLabel>
                        <Selector
                          options={branchOffices?.map((item) => ({
                            value: item?.id,
                            label: item?.name
                          }))}
                          className={classes.select}
                          value={Object.keys(branchOffice).length === 0 ? '' : {
                            value: branchOffice?.id,
                            label: branchOffice?.name
                          }}
                          onChange={handleChangeBranchOffice}
                          name="branch_office_id"
                          labelId="branch_office"
                          label="Sucursal"
                          placeholder="Selecciona una sucursal"
                          styles={selectorStyles}
                        />
                        <FormHelperText className={classes.helperText}>
                          *Debe seleccionar una sucursal
                        </FormHelperText>
                      </FormControl>
                    ) : (
                      <TextField
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            fontSize: 14
                          }
                        }}
                        inputProps={{
                          style: {
                            fontSize: 14
                          }
                        }}
                        value={addressB?.city?.name}
                        className={classes.textField}
                        placeholder="Ciudad"
                        label="Ciudad"
                        name="city"
                        required
                        size="small"
                        variant="outlined"
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      disabled
                      FormHelperTextProps={{
                        className: clsx(
                          classes.helperText,
                          classes.colorB
                        )
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          fontSize: 14
                        }
                      }}
                      inputProps={{
                        style: {
                          fontSize: 14
                        }
                      }}
                      className={classes.textField}
                      placeholder="Dirección"
                      label="Dirección"
                      name="address"
                      value={isBranchOffice ? branchOffice?.address : addressB?.address1}
                      required
                      size="small"
                      variant="outlined"
                    />
                    {!isBranchOffice && (
                      <Typography
                        className={clsx(
                          classes.helperText,
                          classes.colorB
                        )}
                        style={{
                          fontSize: 12,
                          marginTop: 3
                        }}
                        variant="body2"
                      >
                        *Debe ingresar una dirección
                      </Typography>
                    )}
                  </Grid>
                  {!isBranchOffice && (
                    <>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              fontSize: 14
                            }
                          }}
                          FormHelperTextProps={{
                            className: clsx(
                              classes.colorB,
                              classes.helperText,
                            )
                          }}
                          inputProps={{
                            style: {
                              fontSize: 14
                            }
                          }}
                          value={addressB?.complement}
                          onChange={handleChangeB}
                          helperText="(Opcional)"
                          className={classes.textField}
                          placeholder="Torre, apto, oficina"
                          label="Torre, apto, oficina"
                          name="complement"
                          size="small"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              fontSize: 14
                            }
                          }}
                          FormHelperTextProps={{
                            className: clsx(
                              classes.helperText,
                              classes.colorB
                            )
                          }}
                          inputProps={{
                            style: {
                              fontSize: 14
                            }
                          }}
                          value={addressB?.reference}
                          onChange={handleChangeB}
                          helperText="(Opcional)"
                          className={classes.textField}
                          placeholder="Ej: Al frente del CAI"
                          label="Inidicaciones de referencia"
                          name="reference"
                          size="small"
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Box>
          )}
          {waitAddress && edit && (
            <Box ml={1.5}>
              <Typography
                style={{ fontSize: 16, color: '#e0336d' }}
                variant="body2"
              >
                Selecciona una sucursal de la dirección A
              </Typography>
            </Box>
          )}
          {!edit && (
            <Box
              ml={2}
              display="flex"
              width="78%"
              flexDirection="column"
            >
              <Box
                display="flex"
                alignItems="center"
                width="fit-content"
              >
                <Box
                  bgcolor={isBranchOffice ? '#009739' : '#e0336d'}
                  borderRadius={5}
                  width={150}
                  textAlign="center"
                  py={0.8}
                  px={1.5}
                >
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 600,
                      color: '#fff',
                      fontSize: 13
                    }}
                  >
                    {isRoundtrip ? (
                      <>
                        Ida y vuelta
                      </>
                    ) : (
                      <>
                        {isBranchOffice ? 'Dirección A' : 'Dirección B'}
                      </>
                    )}
                  </Typography>
                </Box>
                <Box
                  bgcolor="#D9E8F1"
                  borderRadius={5}
                  textAlign="center"
                  py={0.8}
                  px={1.5}
                  ml={1}
                >
                  {loadingCity ? (
                    <CircularProgress size={15} />
                  ) : (
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: 13,
                        color: isBranchOffice ? '#009739' : '#e0336d'
                      }}
                    >
                      {addressB?.city?.name}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                mt={1}
                display="flex"
                flexDirection="column"
                width="100%"
              >
                {isBranchOffice && (
                  <Box
                    width="100%"
                  >
                    <Typography
                      variant="body2"
                      color="primary"
                      className={classes.name}
                    >
                      {branchOffice?.brand?.name}
                      {' '}
                      {branchOffice?.name}
                    </Typography>
                  </Box>
                )}
                <Box
                  width="100%"
                  mt={0.3}
                >
                  <Typography
                    variant="body2"
                    color="primary"
                    className={clsx(
                      classes.name,
                      {
                        [classes.colorB]: !isBranchOffice
                      }
                    )}
                  >
                    {isBranchOffice ? branchOffice?.address : addressB?.address1}
                  </Typography>
                </Box>
              </Box>
              {!isBranchOffice && (
                <Box
                  mt={0.5}
                  display="flex"
                  alignItems="center"
                  width="fit-content"
                >
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: 12,
                      color: '#A5A5A5'
                    }}
                  >
                    {addressB?.complement}
                  </Typography>
                  <Box
                    ml={1.5}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: 12,
                        color: '#A5A5A5'
                      }}
                    >
                      {addressB?.reference}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {!edit && !showInfo && (
            <Box
              ml={2}
              alignSelf="center"
            >
              <Button
                className={clsx(
                  classes.buttonIcon,
                  {
                    [classes.buttonB]: !isBranchOffice
                  }
                )}
                color="primary"
                variant="contained"
                size="small"
                onClick={() => {
                  if (isRoundtrip) {
                    setRoundtrip(false);
                    return;
                  }
                  if (isBranchOffice) toggleEdit('A');
                  else toggleEdit('B');
                }}
              >
                {isRoundtrip ? (
                  <Delete style={{ fontSize: '16px' }} />
                ) : (
                  <Edit style={{ fontSize: '16px' }} />
                )}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {showAddressModal && (
        <AddressModal
          toggleAddressModal={toggleAddressModal}
          confirmAddress={() => {}}
          address={addressB?.address1}
          positionAddress={() => {}}
          handleAddress={handleAddress}
        />
      )}
    </Card>
  );
});

Address.propTypes = {
  className: PropTypes.string,
  isBranchOffice: PropTypes.bool,
  edit: PropTypes.bool,
  showInfo: PropTypes.bool,
  toggleEdit: PropTypes.func,
  waitAddress: PropTypes.bool,
  setAddressA: PropTypes.func,
  branchOffice: PropTypes.object,
  setAddressB: PropTypes.func,
  addressB: PropTypes.object,
  checkAddressContain: PropTypes.func,
  toggleWarning: PropTypes.func,
  isRoundtrip: PropTypes.bool,
  setRoundtrip: PropTypes.func
};

Address.defaultProps = {
  className: '',
  isBranchOffice: false,
  edit: false,
  showInfo: false,
  toggleEdit: () => {},
  waitAddress: false,
  setAddressA: () => {},
  branchOffice: {},
  setAddressB: () => {},
  addressB: {},
  checkAddressContain: () => {},
  toggleWarning: () => {},
  isRoundtrip: false,
  setRoundtrip: () => {},
};

export default Address;
