import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import useErrors from '../../../hooks/useErrors';
import DriverService from '../../../services/DriverService';
import BranchOfficeService from '../../../services/BranchOfficeService';
import CountryService from '../../../services/CountryService';
import CityService from '../../../services/CityService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateDriverForm = forwardRef(({
  className, driver, edit, onChange, setLoading, hasImage, ...rest
}, ref) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const [countrySelected, setCountrySelected] = useState('');
  const [countries, setCountries] = useState([]);
  const [citySelected, setCitySelected] = useState('');
  const [cities, setCities] = useState([]);
  const [branchOfficeSelected, setBranchOfficeSelected] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);

  const handleChange = (event) => {
    onChange({
      [event?.target?.name]: event?.target?.value
    });
  };

  const handleCountry = (event) => {
    setCountrySelected(event?.target?.value);
    handleChange(event);
  };

  const handleCity = (event) => {
    setCitySelected(event?.target?.value);
    handleChange(event);
  };

  const handleBranchOffice = (event) => {
    setBranchOfficeSelected(event?.target?.value);
    handleChange(event);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const file = ref.current.files[0];
    const data = {
      first_name: e.target?.first_name?.value,
      last_name: e.target?.last_name?.value,
      avatar: e.target?.avatar?.value,
      email: e.target?.email.value,
      phone: Number(e.target?.phone?.value),
      branch_offices: branchOfficeSelected || e.target?.branch_offices?.value,
      country_id: e.target?.country_id?.value,
      city_id: e.target?.city_id?.value,
      whatsapp: e.target?.whatsapp?.value,
      is_coordinator: false,
      coordinator_id: null,
      password: null,
      birthdate: e.target?.birthdate?.value,
      has_dataphone: e.target?.has_dataphone?.value
    };

    if (data?.first_name?.length === 0) {
      errors?.push('El nombre del repartidor es obligatorio');
    }
    if (data?.last_name?.length === 0) {
      errors?.push('Los apellidos del repartidor son obligatorios');
    }
    if (data?.phone?.length === 0) {
      errors?.push('El teléfono del repartidor es obligatorio');
    }
    if (data?.email?.length === 0) {
      errors?.push('El email del repartidor es obligatorio');
    }
    if (data?.city_id?.length === 0) {
      errors?.push('La ciudad es obligatoria');
    }
    if (data?.whatsapp?.length === 0) {
      errors.push('El número de whatsapp es obligatorio');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    setLoading(true);

    const response = await DriverService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      if (response?.data?.avatar === null && file !== undefined) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await DriverService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/repartidores');
          }, 500);
        }
      }
      navigate('/app/repartidores');
    } else {
      response?.errors?.push('Por favor comunícate con Adomi');
      setErrors(response?.errors);
    }
  };

  const fetchAllCountries = async () => {
    const response = await CountryService.getAll();

    if (response?.code !== 200) {
      setErrors(['Ocurrió un error al intentar mostrar los países']);
      return;
    }

    setCountries(response?.data);
  };

  const fetchAllCities = async () => {
    const response = await CityService.getAll();

    if (response?.code !== 200) {
      setErrors(['Ocurrió un error al intentar mostrar las ciudades']);
      return;
    }

    setCities(response?.data.filter((city) => city?.state?.country?.id === countrySelected));
  };

  const fetchAllBranchOffices = async () => {
    const response = await BranchOfficeService.getAll();

    if (response?.code !== 200) {
      setErrors(['Ocurrió un error al intentar mostrar las sucursales']);
      return;
    }

    setBranchOffices(response.data.filter((branch) => branch?.city_id === citySelected));
  };

  useEffect(() => {
    (async () => {
      fetchAllCountries();
    })();
  }, []);

  useEffect(() => {
    fetchAllCities();
  }, [countrySelected]);

  useEffect(() => {
    fetchAllBranchOffices();
  }, [citySelected]);

  useEffect(() => {
    if (edit) {
      setCountrySelected(driver?.country_id);
      setCitySelected(driver?.city_id);
      setBranchOfficeSelected(
        Array.isArray(driver?.branch_offices) ? driver?.branch_offices : []
      );
    }
  }, [edit, driver]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información no puede ser editada"
          title="Repartidor propio"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="pais">Seleccione un país</InputLabel>
                <Select
                  required
                  name="country_id"
                  labelId="pais"
                  label="Selecciona un país"
                  value={countrySelected}
                  onChange={handleCountry}
                >
                  <MenuItem value="0" />
                  {
                    countries.map((country) => (
                      <MenuItem key={country?.id} value={country?.id}>
                        { country?.name }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="ciudad">Seleccione una ciudad</InputLabel>
                <Select
                  name="city_id"
                  labelId="ciudad"
                  label="Selecciona una ciudad"
                  value={driver?.city_id}
                  onChange={handleCity}
                >
                  <MenuItem value="" />
                  {
                    cities.map((city) => (
                      <MenuItem key={city?.id} value={city?.id}>
                        { city?.name }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="sucursal">Seleccione una sucursal</InputLabel>
                <Select
                  multiple
                  name="branch_offices"
                  labelId="sucursal"
                  label="Selecciona una sucursal"
                  value={branchOfficeSelected}
                  onChange={handleBranchOffice}
                >
                  {
                    branchOffices.map((branchOffice) => (
                      branchOffice?.join_name ? (
                        <MenuItem key={branchOffice?.id} value={branchOffice?.id}>
                          { `${branchOffice?.brand?.name} ${branchOffice?.name}`}
                        </MenuItem>
                      ) : (
                        <MenuItem key={branchOffice?.id} value={branchOffice?.id}>
                          { branchOffice?.brand?.name }
                        </MenuItem>
                      )
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el nombre"
                label="Nombre"
                name="first_name"
                onChange={handleChange}
                required
                value={driver?.first_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba los apellidos"
                label="Apellidos"
                name="last_name"
                onChange={handleChange}
                required
                value={driver?.last_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                label="Fecha de nacimiento"
                name="birthdate"
                onChange={handleChange}
                required
                value={driver?.birthdate}
                variant="outlined"
                type="date"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el teléfono"
                label="Teléfono"
                name="phone"
                onChange={handleChange}
                required
                value={driver?.phone}
                variant="outlined"
                type="tel"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el número de whatsapp"
                label="Número de whatsapp"
                name="whatsapp"
                onChange={handleChange}
                required
                value={driver?.whatsapp}
                variant="outlined"
                type="whatsapp"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el correo electrónico"
                label="Correo electrónico"
                name="email"
                onChange={handleChange}
                required
                value={driver?.email}
                variant="outlined"
                type="email"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="has_dataphone">¿Tiene datáfono?</InputLabel>
                <Select
                  name="has_dataphone"
                  labelId="has_dataphone"
                  label="¿Tiene datáfono?"
                  value={driver?.has_dataphone?.toString() || 'false'}
                  onChange={handleChange}
                >
                  {
                    [true, false].map((item) => (
                      <MenuItem key={item?.toString()} value={item?.toString()}>
                        { item?.toString() === 'true' ? 'Si' : 'No' }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              hasImage && (
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Perfil"
                    name="avatar"
                    onChange={handleChange}
                    required
                    value={driver?.avatar}
                    variant="outlined"
                  />
                </Grid>
              )
            }
            <input name="perfil[]" type="file" ref={ref} style={{ display: 'none' }} />
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            Guardar
          </Button>
        </Box>
      </Card>
    </form>
  );
});

CreateDriverForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  onChange: PropTypes.func,
  setLoading: PropTypes.func,
  driver: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
    email: PropTypes.string,
    country_id: PropTypes.string,
    branch_offices: PropTypes.array,
    phone: PropTypes.number,
    password: PropTypes.string,
    birthdate: PropTypes.string,
    city_id: PropTypes.string,
    whatsapp: PropTypes.string,
    coordinator_id: PropTypes.string,
    is_coordinator: PropTypes.bool,
    has_dataphone: PropTypes.bool
  })
};

CreateDriverForm.defaultProps = {
  className: '',
  edit: false,
  hasImage: false,
  onChange: () => {},
  setLoading: () => {},
  driver: {
    id: null,
    first_name: '',
    last_name: '',
    avatar: '',
    email: '',
    phone: '',
    country_id: '',
    branch_offices: [],
    password: null,
    birthdate: '',
    city_id: '',
    whatsapp: '',
    coordinator_id: null,
    is_coordinator: false,
    has_dataphone: false
  },
};

export default CreateDriverForm;
