import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cargandoGif from 'src/assets/image/cargando.gif';
import {
  Box,
  Typography,
  Card,
  Button,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import {
  ArrowBackIosRounded,
  Print,
  Restaurant,
  Block
} from '@material-ui/icons';
import {
  MdDeliveryDining,
  MdRoomService,
  MdDinnerDining
} from 'react-icons/md';
import { GiCookingPot } from 'react-icons/gi';
import { getDate, getHour } from 'src/utils/formatDate';
import CurrencyFormat from 'react-currency-format';
import ButtonStatus from './ButtonStatus';

const OrderModal = ({
  toggleOrderModal,
  togglePrintModal,
  order,
  statusTranslate,
  haveToppings,
  detailProduct,
  getDetailPrice,
  handleStatus,
  updateStatus,
  handleOrder,
  getTotalProducts,
  getCouponValue
}) => {
  const matches = useMediaQuery('screen and (min-width: 769px)');
  const [products, setProducts] = useState([]);
  const useStyles = makeStyles((theme) => ({
    modal: {
      border: '1px solid #e6ebf1',
      width: !matches ? '100%' : '100%',
      height: !matches ? '100%' : '100%',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.grey[100],
      bottom: '0',
      animation: 'showPopUp 0.5s',
      position: !matches ? 'absolute' : 'relative'
    },
    CreateCustomizationModal: {
      backgroundColor: 'rgba(46, 44, 54, 0.4)',
      position: 'fixed',
      top: 0,
      right: 0,
      height: '100%',
      width: '100%',
      zIndex: '3',
      display: matches ? 'flex' : null,
      justifyContent: matches ? 'center' : null,
      alignItems: matches ? 'center' : null
    },
    pane: {
      height: '100%',
      width: '100%',
      position: 'fixed'
    },
    body: {
      margin: 'auto',
      maxWidth: '1000px',
      width: '70%',
      minWidth: '400px',
      height: '550px',
      maxHeight: '85vh',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '70px',
      paddingBottom: '70px',
      justifyContent: 'center',
      alignContent: 'center',
      backgroundColor: '#fff',
      borderRadius: '18px',
      boxShadow:
        'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px'
    },
    btn: {
      width: '125px',
      fontSize: '13px'
    },
    pending: {
      backgroundColor: '#FBC02D',
      color: 'white'
    },
    rejected: {
      backgroundColor: 'red',
      color: 'white'
    },
    accepted: {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    },
    finished: {
      backgroundColor: '#1565C0',
      color: 'white'
    },
    sent: {
      color: 'white',
      backgroundColor: '#7B1FA2'
    },
    title: {
      width: '30px',
      // zIndex: 10,
      position: 'absolute',
    },
    containerOrders: {
      paddingBottom: '10px',
      maxHeight: '90vh',
      width: '70%',
      height: '100%',
      backgroundColor: '#fff',
      boxShadow:
        'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
      padding: theme.spacing(1.5),
      paddingRight: theme.spacing(2.5),
      paddingLeft: theme.spacing(2.5),
      borderRadius: '18px',
      '&::-webkit-scrollbar': {
        width: '7px'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '20px',
        backgroundColor: theme.palette.grey[400]
      }
    },
    card: {
      overflow: 'unset',
      backgroundColor: '#fff',
      marginBottom: theme.spacing(2.5),
      '&:last-child': {
        marginBottom: 0
      },
      padding: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    },
    buttonIcon: {
      borderRadius: '50%',
      minWidth: 10,
      padding: 6,
      margin: '0 7px'
    },
    ButtonStatus: {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      marginTop: theme.spacing(3)
    },
    iconFood: {
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
      fontSize: '17px'
    },
    button: {
      width:
        order?.status === 'pending_to_be_sent' ||
        order?.status === 'sent' ||
        order?.status === 'sent_local'
          ? 160
          : 100
    },
    gif: {
      width: '100%',
      height: '100%'
    },
    iconStatus: {
      fontSize: 28,
      color: '#009739'
    },
    container: {
      maxHeight: '90vh',
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      backgroundColor: '#fff',
      width: '90%',
      margin: 'auto',
      height: '70%'
    },
    containerInf: {
      maxHeight: '90vh',
      height: '100%',
      marginLeft: '10px',
      width: '50%',
      backgroundColor: '#fff',
      borderRadius: '18px',
      boxShadow:
        'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
      padding: theme.spacing(1.5),
      paddingRight: theme.spacing(2.5),
      paddingLeft: theme.spacing(2.5)
    },
    back: {
      width: '60px',
      height: '60px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    containerBody: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }));
  const classes = useStyles();

  const optionTranslate = (type) => {
    switch (type) {
      case 'radio':
        return '(Opción unica)';
      case 'checkbox':
        return '(Si/No)';
      case 'quantity':
        return '(Opción multiple)';
      default:
        return '';
    }
  };

  const setOrder = () => {
    handleOrder(order);
  };

  const getProducts = () => {
    return order?.order_products?.map((product) => {
      let sections = [];
      if (product?.customize?.sections?.length > 0) {
        sections = product?.customize?.sections?.map((section) => {
          let items = [];
          if (section?.type === 'radio') {
            items = section?.items.filter((item) => item.value);
          } else if (section?.type === 'checkbox') {
            items = section?.items.filter((item) => !item.value);
          } else {
            items = section?.items.filter((item) => item.value > 0);
          }

          if (items?.length === 0) return {};
          return {
            ...section,
            items
          };
        });
      }
      return {
        ...product,
        customize: {
          sections
        }
      };
    });
  };

  useEffect(() => {
    setProducts(getProducts());
  }, []);

  return (
    order && (
      <div className={classes.CreateCustomizationModal}>
        <div className={classes.pane} onClick={() => toggleOrderModal()} />
        <div className={classes.modal}>
          <div className={classes.title}>
            <Box
              m={3}
              display="flex"
              alignItems="center"
              className={classes.back}
              onClick={() => toggleOrderModal()}
            >
              <ArrowBackIosRounded
                style={{
                  cursor: 'pointer',
                  fontSize: '20px',
                  color: '#252525'
                }}
              />
            </Box>
          </div>
          <div className={classes.containerBody}>
            <div className={classes.body} display="flex">
              <Box
                display="flex"
                flexDirection="row"
                ml={4}
                justifyContent="space-between"
                width="100%"
              >
                <Box ml={2}>
                  <Typography style={{ fontWeight: 600 }} variant="h4">
                    Pedido #{order?.reference}
                  </Typography>
                  <Box display="flex">
                    <Typography
                      style={{
                        color: '#A5A5A5',
                        fontWeight: 700,
                        marginRight: '5px'
                      }}
                      variant="body2"
                    >
                      {getDate(order?.created_at)}
                    </Typography>
                    <Typography
                      style={{ color: '#A5A5A5', fontWeight: 700 }}
                      variant="body2"
                    >
                      {getHour(order?.created_at)}
                    </Typography>
                  </Box>
                  <Typography
                    style={{
                      color: '#999',
                      fontSize: '12px',
                      marginTop: '2px',
                      fontWeight: 700,
                      wordBreak: 'break-all'
                    }}
                    variant="body2"
                  >
                    {`${order?.data_client?.first_name} ${order?.data_client?.last_name}`}
                  </Typography>
                </Box>
                <Box
                  mr={11}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    position="relative"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={1}
                    width={
                      order?.status === 'created' ||
                      order?.status === 'in_progress' ||
                      order?.status === 'pending_to_be_sent'
                        ? 50
                        : 'fit-content'
                    }
                    height={
                      order?.status === 'created' ||
                      order?.status === 'in_progress' ||
                      order?.status === 'pending_to_be_sent'
                        ? 50
                        : 'fit-content'
                    }
                  >
                    {(order?.status === 'created' ||
                      order?.status === 'in_progress' ||
                      order?.status === 'pending_to_be_sent') && (
                      <Box position="absolute" top={0}>
                        <img
                          className={classes.gif}
                          src={cargandoGif}
                          alt="cargando"
                        />
                      </Box>
                    )}
                    {order?.status === 'in_progress' && (
                      <GiCookingPot className={classes.iconStatus} />
                    )}
                    {order?.status === 'pending_to_be_sent' && (
                      <MdDeliveryDining className={classes.iconStatus} />
                    )}
                    {(order?.status === 'sent' ||
                      order?.status === 'sent_local') && (
                      <MdRoomService className={classes.iconStatus} />
                    )}
                    {order?.status === 'finished' && (
                      <MdDinnerDining className={classes.iconStatus} />
                    )}
                    {(order?.status === 'cancelled' ||
                      order?.status === 'rejected') && (
                      <Block className={classes.iconStatus} />
                    )}
                  </Box>
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: '13px',
                      textAlign: 'center'
                    }}
                  >
                    {statusTranslate(
                      order?.status?.toString(),
                      order?.ship_type
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                width="90%"
                height="40px"
                mb={-1}
                mt={1.5}
              >
                <Box ml={5.8}>
                  <Typography
                    style={{ fontWeight: 600, fontSize: '18px' }}
                    variant="h5"
                  >
                    RESUMEN DETALLADO
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="end" mr={-6.5} mb={1.5}>
                  <Button
                    onClick={() => {
                      handleOrder(order);
                      togglePrintModal();
                    }}
                    className={classes.buttonIcon}
                    color="primary"
                    variant="contained"
                    size="small"
                  >
                    <Print style={{ fontSize: '18px' }} />
                  </Button>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                width="100%"
                className={classes.container}
              >
                <Box
                  className={classes.containerOrders}
                  maxHeight="263px"
                  display="flex"
                  flexDirection="column"
                  overflow="auto"
                >
                  <Box mt={2} mb={1.5}>
                    <Typography
                      style={{ fontWeight: 600, fontSize: '16px' }}
                      variant="body2"
                    >
                      Resumen productos
                    </Typography>
                  </Box>
                  {products.map((product) => {
                    const detailPrice = getDetailPrice(product?.prices);
                    return (
                      <Card className={classes.card}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          py={1.5}
                          borderBottom="2px dashed #B5B5B5"
                        >
                          <Box display="flex">
                            <Restaurant className={classes.iconFood} />
                            <Box display="flex" flexDirection="column">
                              <Typography
                                style={{ fontWeight: 600 }}
                                variant="body2"
                              >
                                {product?.amount}
                                {' x '}
                                {haveToppings(product?.customize?.sections)
                                  ? '(S) '
                                  : '(M) '}
                                {product?.name}
                              </Typography>
                              <Typography
                                style={{ fontSize: '12px', marginTop: '2px' }}
                                variant="body2"
                              >
                                <span style={{ fontWeight: 600 }}>
                                  {detailPrice?.name}
                                </span>
                                {detailPrice?.description?.length > 0 && (
                                  <>{` (${detailPrice?.description})`}</>
                                )}
                              </Typography>
                            </Box>
                          </Box>
                          <Typography
                            style={{ fontWeight: 600 }}
                            variant="body2"
                          >
                            {'$ '}
                            <CurrencyFormat
                              value={
                                product?.has_discount
                                  ? product?.discount?.discount_price
                                  : product?.price
                              }
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                            />
                          </Typography>
                        </Box>
                        {detailProduct(product) && (
                          <Box borderBottom="2px dashed #B5B5B5">
                            {!haveToppings(product?.customize?.sections) && (
                              <Box py={1.5}>
                                <Typography
                                  style={{ fontWeight: 700 }}
                                  variant="h6"
                                >
                                  Modificaciones y adicionales:
                                </Typography>
                                {product?.customize?.sections?.map(
                                  (section) => (
                                    <>
                                      {Object.keys(section)?.length > 0 && (
                                        <Box pl={1} pt={1}>
                                          <Typography
                                            style={{ fontWeight: 600 }}
                                            variant="h6"
                                          >
                                            {section.title}{' '}
                                            {optionTranslate(section?.type)}
                                          </Typography>
                                          {section?.items?.map((item) => (
                                            <Box
                                              pl={1}
                                              display="flex"
                                              alignItems="center"
                                            >
                                              <Typography variant="body2">
                                                {'* '}
                                                {section?.type ===
                                                  'quantity' && (
                                                  <span
                                                    style={{ fontWeight: 600 }}
                                                  >
                                                    {item?.value}
                                                    {' x '}
                                                  </span>
                                                )}
                                                {item?.name}
                                                {section?.type ===
                                                  'checkbox' && (
                                                  <span
                                                    style={{ fontWeight: 600 }}
                                                  >
                                                    {' (NO) '}
                                                  </span>
                                                )}
                                              </Typography>
                                              {item?.price > 0 && (
                                                <Box ml={1.5}>
                                                  <Typography variant="body2">
                                                    + $
                                                    <CurrencyFormat
                                                      value={item?.price}
                                                      displayType="text"
                                                      thousandSeparator="."
                                                      decimalSeparator=","
                                                    />
                                                  </Typography>
                                                </Box>
                                              )}
                                            </Box>
                                          ))}
                                        </Box>
                                      )}
                                    </>
                                  )
                                )}
                              </Box>
                            )}
                            {product?.comment?.length > 0 && (
                              <Box pb={1.5}>
                                <Typography
                                  style={{ fontWeight: 700 }}
                                  variant="h6"
                                >
                                  Comentarios
                                </Typography>
                                <Box pl={1}>
                                  <Typography variant="body2">
                                    {product?.comment}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        )}
                        <Box textAlign="right" pt={1.5} pb={0.5}>
                          <Typography style={{ fontWeight: 600 }} variant="h5">
                            {'Subtotal: '}
                            $
                            <CurrencyFormat
                              value={product?.total}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                            />
                          </Typography>
                        </Box>
                      </Card>
                    );
                  })}
                </Box>
                <Box pt={2} pb={0} px={2.5} className={classes.containerInf}>
                  <Box mt={1.5} mb={1.5}>
                    <Typography
                      style={{ fontWeight: 600, fontSize: '16px' }}
                      variant="body2"
                    >
                      Resumen Orden
                    </Typography>
                  </Box>
                  <Card className={classes.card}>
                    <Box
                      mt={0.3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="body2">Total productos:</Typography>
                      <Typography variant="body2">
                        $
                        <CurrencyFormat
                          value={getTotalProducts(products)}
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                        />
                      </Typography>
                    </Box>
                    <Box
                      mt={0.3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="body2">
                        Cupon
                        {order?.coupon_data !== null && (
                          <>
                            {order?.coupon_data?.type === 'percentage' && (
                              <span
                                style={{ marginLeft: '5px' }}
                              >
                                {`(${order?.coupon_value}%)`}
                              </span>
                            )}
                          </>
                        )}
                        :
                      </Typography>
                      <Typography variant="body2">
                        {order?.coupon_data !== null && (
                          <>
                            {order?.coupon_data?.type !== 'delivery' && (
                              <span style={{ marginRight: '2px' }}>
                                -
                              </span>
                            )}
                          </>
                        )}
                        {order?.coupon_data?.type === 'delivery' && (
                          <>Domicilio gratis</>
                        )}
                        {order?.coupon_data?.type !== 'delivery' && (
                          <>
                            $
                            <CurrencyFormat
                              value={getCouponValue(
                                getTotalProducts(order?.order_products),
                                order?.coupon_data
                              )}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                            />
                          </>
                        )}
                      </Typography>
                    </Box>
                    <Box
                      mt={0.3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="body2">Descuento Adomi:</Typography>
                      <Typography variant="body2">
                        {'- '}
                        $
                        <CurrencyFormat
                          value={order?.adomi_bonus}
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                        />
                      </Typography>
                    </Box>
                    <Box
                      mt={0.5}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        style={{ fontWeight: 600, fontSize: '16px' }}
                        variant="h5"
                      >
                        Total:
                      </Typography>
                      <Typography
                        style={{ fontWeight: 600, fontSize: '16px' }}
                        variant="h5"
                      >
                        $
                        <CurrencyFormat
                          value={order?.sub_total}
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                        />
                      </Typography>
                    </Box>
                  </Card>
                </Box>
              </Box>
              <Box display="flex" margin="auto" mb={0}>
                {(order?.status === 'created' ||
                  order?.status === 'in_progress' ||
                  (order?.status === 'pending_to_be_sent' &&
                    order?.ship_type === 'local') ||
                  order?.status === 'sent' ||
                  order?.status === 'sent_local') && (
                  <ButtonStatus
                    className={classes.ButtonStatus}
                    stylesButtons={classes.button}
                    status={order?.status}
                    setOrder={setOrder}
                    setStatus={handleStatus}
                    updateStatus={updateStatus}
                    idOrder={order?.id}
                    deliveryType={order?.ship_type}
                  />
                )}
              </Box>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

OrderModal.propTypes = {
  toggleOrderModal: PropTypes.func,
  togglePrintModal: PropTypes.func,
  order: PropTypes.object,
  statusTranslate: PropTypes.func,
  haveToppings: PropTypes.func,
  detailProduct: PropTypes.func,
  getDetailPrice: PropTypes.func,
  handleOrder: PropTypes.func,
  handleStatus: PropTypes.func,
  updateStatus: PropTypes.func,
  getTotalProducts: PropTypes.func,
  getCouponValue: PropTypes.func
};

export default OrderModal;
