import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  // Chip,
  // Table,
  // TableRow,
  // TableCell,
  // TableBody,
  // TableHead,
  makeStyles,
  useMediaQuery,
  useTheme,
  Button
} from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import { CancelRounded } from '@material-ui/icons';
import Comanda from '../assets/image/tickedComanda.png';
import Orden from '../assets/image/ticketOrden.png';
import TicketComanda from './TicketComanda';
import TicketOrder from './TicketOrder';

const PrintModal = ({
  togglePrintModal,
  order,
  haveToppings,
  getDetailPrice,
  getTotalProducts,
  getCouponValue,
  getPaymentMethod
}) => {
  const matches = useMediaQuery('screen and (min-width: 769px)');
  const [typePrint, setTypePrint] = useState('comanda');
  const comandaRef = useRef();
  const orderRef = useRef();

  const useStyles = makeStyles((theme) => ({
    buttons: {
      display: 'inline-block',
    },
    overlay: {
      backgroundColor: 'rgba(46, 44, 54, 0.4)',
      position: 'fixed',
      top: 0,
      right: 0,
      height: '100%',
      width: '100%',
      zIndex: '3',
      display: matches ? 'flex' : null,
      justifyContent: matches ? 'center' : null,
      alignItems: matches ? 'center' : null,
    },
    modal: {
      border: '1px solid #e6ebf1',
      borderRadius: '8px',
      width: !matches ? '100%' : '450px',
      height: !matches ? '100%' : 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      bottom: '0',
      animation: 'showPopUp 0.5s',
      position: !matches ? 'absolute' : 'relative',
    },
    pane: {
      height: '100%',
      width: '100%',
      position: 'fixed'
    },
    header: {
      padding: '10px 15px',
      borderBottom: '1px solid #e6ebf1',
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > h3': {
        fontWeight: 'bold',
        fontSize: '22px'
      }
    },
    text: {
      color: '#999',
      fontSize: '13px',
      fontWeight: 500
    },
    btnConfirm: {
      textTransform: 'capitalize',
      borderRadius: 6,
      width: 175,
      fontWeight: 700,
      fontSize: '13px'
    },
    bgTicket: {
      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
      width: '100%',
      borderRadius: 10,
      backgroundColor: theme.palette.grey[200]
    },
    ticketImg: {
      height: 370,
    },
  }));

  const classes = useStyles();
  const theme = useTheme();

  const changePrint = (e) => {
    setTypePrint(e.target.value);
  };

  const reactToPrintTrigger = () => {
    return (
      <Button className={classes.btnConfirm} size="small" color="primary" variant="contained">
        {`Imprimir ${typePrint}`}
      </Button>
    );
  };

  return (
    <div className={classes?.overlay}>
      <div className={classes.pane} onClick={() => togglePrintModal()} />
      <div className={classes?.modal}>
        <div className={classes.header}>
          <div className={classes.title}>
            <h3>
              Impresión del pedido
            </h3>
            <CancelRounded style={{ cursor: 'pointer' }} color="primary" onClick={() => togglePrintModal()} />
          </div>
        </div>
        <Box p={2.5} display="flex" flexDirection="column" textAlign="center" alignItems="center">
          <Box>
            <FormControl>
              <FormLabel style={{ fontWeight: 600, color: theme.palette.primary.main }} id="radio-buttons-group-label">Imprimir:</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={typePrint}
                onChange={changePrint}
              >
                <FormControlLabel value="comanda" control={<Radio color="primary" />} label="Comanda" />
                <FormControlLabel value="orden" control={<Radio color="primary" />} label="Orden" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box mt={0.5} className={classes.bgTicket}>
            <img className={classes.ticketImg} src={typePrint === 'comanda' ? Comanda : Orden} alt="ticketComanda" />
          </Box>
          <Box mt={2.5}>
            <ReactToPrint
              trigger={() => reactToPrintTrigger()}
              content={() => {
                if (typePrint === 'comanda') return comandaRef.current;
                return orderRef.current;
              }}
            />
            <Box display="none">
              <TicketComanda
                order={order}
                ref={comandaRef}
                haveToppings={haveToppings}
                getDetailPrice={getDetailPrice}
              />
              <TicketOrder
                order={order}
                ref={orderRef}
                getDetailPrice={getDetailPrice}
                getTotalProducts={getTotalProducts}
                getCouponValue={getCouponValue}
                getPaymentMethod={getPaymentMethod}
              />
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

PrintModal.propTypes = {
  togglePrintModal: PropTypes.func,
  order: PropTypes.object,
  haveToppings: PropTypes.func,
  getDetailPrice: PropTypes.func,
  getTotalProducts: PropTypes.func,
  getCouponValue: PropTypes.func,
  getPaymentMethod: PropTypes.func
};

export default PrintModal;
