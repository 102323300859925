import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  // Box,
  // Chip,
  Box,
  Typography,
  // Button,
  // Table,
  // TableRow,
  // TableCell,
  // TableBody,
  // TableHead,
  // Typography,
  makeStyles,
  useMediaQuery,
  Button,
  FormControl,
  FormHelperText
} from '@material-ui/core';
import {
  Add,
  CancelRounded,
  Error,
  Remove
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { selector as UserSelector } from '../../redux/ducks/user';
import OrderService from '../../services/OrderService';

const StatusModalInProgress = ({
  onClose,
  updateStatus,
  order,
}) => {
  const matches = useMediaQuery('screen and (min-width: 769px)');
  const [time, setTime] = useState('15');
  const refInput = useRef(null);
  const [localErrors, setLocalErrors] = useState([]);
  const { user } = useSelector(UserSelector);

  const useStyles = makeStyles((theme) => ({
    buttons: {
      display: 'inline-block',
    },
    overlay: {
      backgroundColor: 'rgba(46, 44, 54, 0.4)',
      position: 'fixed',
      top: 0,
      right: 0,
      height: '100%',
      width: '100%',
      zIndex: '3',
      display: matches ? 'flex' : null,
      justifyContent: matches ? 'center' : null,
      alignItems: matches ? 'center' : null,
    },
    modal: {
      border: '1px solid #e6ebf1',
      borderRadius: '8px',
      width: !matches ? '100%' : '470px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      bottom: '0',
      animation: 'showPopUp 0.5s',
      position: !matches ? 'absolute' : 'relative',
    },
    pane: {
      height: '100%',
      width: '100%',
      position: 'fixed'
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '8px 10px',
    },
    title: {
      backgroundColor: '#ffe205',
      borderRadius: '10px',
      padding: '7px 12px'
    },
    inputTime: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },
    field: {
      '& > input': {
        border: 'none',
        outline: 'none',
        MozAppearance: 'textfield'
      }
    },
    input: {
      backgroundColor: 'transparent',
      outline: 'none',
      border: 'none',
      color: '#000',
      fontSize: '15px',
      fontWeight: 500,
      height: '25px',
      width: '30px',
      textAlign: 'center',
      '&[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      }
    },
    btn: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50px',
      padding: '3px',
      height: '100%',
      color: '#fff',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#2e7d32'
      },
      '&:active': {
        backgroundColor: '#3e8e41',
        transform: 'scale(0.96)'
      },
      transition: 'background 0.2s ease 0s',
    },
    btnConfirm: {
      textTransform: 'capitalize',
      borderRadius: 6,
      width: 125,
      fontWeight: 700,
      fontSize: '14px'
    },
  }));

  const classes = useStyles();

  const timeAdd = () => {
    if (Number(time) < 90) {
      const add = Number(time) + 5;
      setTime(`${add}`);
    }
  };
  const timeSubtract = () => {
    let subtract = Number(time) - 5;
    if (time === 0) {
      subtract = 0;
    }
    setTime(`${subtract}`);
  };

  const handleTime = (e) => {
    if (Number(e?.target?.value) > 90) setTime('90');
    else if (e?.target?.value === '') setTime('0');
    else if (time === '0') setTime(e?.target?.value.slice(1));
    else setTime(e?.target?.value);
  };

  const onPush = async () => {
    const errors = [];

    if (Number(time) < 15) {
      errors.push({ field: 'time', error: 'El tiempo de preparación debe ser mayor o igual a 15 minutos' });
    }

    if (errors.length > 0) {
      setLocalErrors(errors);
      return;
    }

    const data = {
      etp: Number(time),
      delivery_type: user?.branch_office?.supports_drivers === 'own' ? 'local' : user?.branch_office?.supports_drivers
    };
    const response = await OrderService.commandOrder(order?.id, data);
    if (response?.success) {
      updateStatus('in_progress', order?.id);
      onClose('in_progress', false);
    }
  };

  return (
    <div className={classes?.overlay}>
      <div className={classes.pane} onClick={() => onClose('in_progress', false)} />
      <div className={classes?.modal}>
        <div className={classes.header}>
          <CancelRounded style={{ cursor: 'pointer' }} color="primary" onClick={() => onClose('in_progress', false)} />
        </div>
        <Box pt={0.5} pb={2.5}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <div className={classes.title}>
              <h2 style={{ fontSize: '22px' }}>Tiempo de preparación</h2>
            </div>
            <FormControl component="fieldset" className={classes.inputTime} error={localErrors.some((e) => e.field === 'time')}>
              {localErrors.some((e) => e.field === 'time') && (
                <FormHelperText>
                  <Box display="flex" alignItems="center">
                    <Error size="small" style={{ fontSize: '23px', marginRight: '4px' }} />
                    <Typography style={{ fontWeight: 500, fontSize: '13px' }} variant="body2">
                      {localErrors.find((e) => e.field === 'time')?.error || ''}
                    </Typography>
                  </Box>
                </FormHelperText>
              )}
              <Box
                className={classes.field}
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={0.5}
              >
                <Box onClick={() => timeSubtract()} className={classes.btn} display={Number(time) > 15 ? 'flex' : 'none'}>
                  <Remove style={{ fontSize: '18px' }} />
                </Box>
                <Box display="flex" alignItems="center" pl={0.5} pr={1.5} style={{ cursor: 'text' }} onClick={() => refInput.current.focus()}>
                  <input
                    ref={refInput}
                    className={classes.input}
                    type="number"
                    name="quantity"
                    onChange={handleTime}
                    value={time}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <Typography variant="body2" style={{ fontSize: '15px', fontWeight: 500, pointerEvents: 'none' }}>minutos</Typography>
                </Box>
                <Box
                  onClick={() => timeAdd()}
                  className={classes.btn}
                  display={Number(time) !== 90 ? 'flex' : 'none'}
                >
                  <Add style={{ fontSize: '18px' }} />
                </Box>
              </Box>
            </FormControl>
            <Box>
              <Button className={classes.btnConfirm} size="small" variant="contained" color="primary" onClick={onPush}>Confirmar</Button>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

StatusModalInProgress.propTypes = {
  onClose: PropTypes.func,
  updateStatus: PropTypes.func,
  order: PropTypes.object,
};

export default StatusModalInProgress;
