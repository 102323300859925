import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Edit as EditIcon,
  ControlPointDuplicate as ControlPointDuplicateIcon,
} from '@material-ui/icons';

// services
import ProductComponentService from 'src/services/ProductComponentService';

// utils
import orderBy from 'src/utils/arrays';
import getInitials from 'src/utils/getInitials';
import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles((theme) => ({
  root: {},
  edit: {
    cursor: 'pointer'
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  className, data, onDuplicate, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [components, setComponents] = useState([]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const toggleAvailableProduct = async (productId) => {
    const response = await ProductComponentService.toggle(productId);

    if (response?.code === 200) {
      const newProducts = components.reduce((result, item) => {
        if (item.id === productId) {
          return result.concat({ ...item, is_available: !item?.is_available });
        }
        return result.concat(item);
      }, []);

      setComponents(newProducts);
    }
  };

  const goToEdit = (componentId) => {
    navigate(`/app/componentes/${componentId}`, { replace: true });
  };

  const duplicate = (component) => {
    delete component?.id;
    onDuplicate({
      ...component,
      name: `${component?.name} - Copia`
    });
  };

  useEffect(() => {
    if (Array.isArray(data)) {
      setComponents(data);
    }
  }, [data]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nombre
                </TableCell>
                <TableCell width="250">
                  Precio
                </TableCell>
                <TableCell width="150">
                  Disponible
                </TableCell>
                <TableCell width="70" />
                <TableCell width="70" />
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(components, 'name').slice(page * limit, (limit * (page + 1))).map((component) => (
                <TableRow
                  hover
                  key={component.id}
                >
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Avatar
                        className={classes.avatar}
                        src={component.image}
                      >
                        {getInitials(component.name)}
                      </Avatar>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {component.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <CurrencyFormat value={component?.price} displayType="text" thousandSeparator="." decimalSeparator="," prefix="$" />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Switch
                      color="primary"
                      checked={component.is_available}
                      onChange={() => component && toggleAvailableProduct(component?.id)}
                      name="product_available"
                    />
                  </TableCell>
                  <TableCell>
                    <ControlPointDuplicateIcon
                      className={classes.edit}
                      onClick={() => duplicate(component)}
                    />
                  </TableCell>
                  <TableCell>
                    <EditIcon className={classes.edit} onClick={() => goToEdit(component?.id)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={components.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  onDuplicate: PropTypes.func,
};

export default Results;
