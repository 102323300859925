import dayjs from 'dayjs';
import { getDate2 } from 'src/utils/formatDate';
import HttpRequest from './HttpRequest';
import store from '../redux/store';

export default class OrderService extends HttpRequest {
  static endpoint = 'me/admin';

  static async getAll() {
    this.setMicroservice('orders');
    const cityId = store.getState()?.app?.city;
    if (cityId === 'general') {
      this.endpoint = 'order?page=1&perPage=1000&today=true';
    } else {
      this.endpoint = `order?page=1&perPage=1000&today=true&cityId=${cityId}`;
    }
    const response = await this.get();
    return response;
  }

  static async getAllByDate(date = dayjs(new Date()).format('YYYY-MM-DD')) {
    this.setMicroservice('orders');
    this.endpoint = `order?page=1&perPage=1000&date=${date}`;
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.setMicroservice('orders');
    this.endpoint = 'order';
    const response = await this.get(id);
    return response;
  }

  static async getByBranchOfficeId(id, page, perPage = 10, date = dayjs(new Date()).format('YYYY-MM-DD'), isDevelop) {
    this.setMicroservice('orders');
    this.endpoint = `order/by-branch-office/${id}?page=${page}&perPage=${perPage}&isDevelop=${isDevelop}&date=${getDate2(date)}`;

    const response = await this.get();
    return response;
  }

  static async getByStatus(id, status, page, perPage, date = dayjs(new Date()).format('YYYY-MM-DD'), isDevelop) {
    this.setMicroservice('orders');
    this.endpoint = `order/by-branch-office/${id}?page=${page}&perPage=${perPage}&isDevelop=${isDevelop}&date=${getDate2(date)}&status=${status}`;

    const response = await this.get();
    return response;
  }

  /** este endpoint genera problemas por que no existe y vuelve y comprueba y asi en bucle */

  static async getOrdersReport(status, date) {
    this.setMicroservice('orders');
    this.endpoint = `me/manager/order?page=1&perPage=10000&status=${status}&fromDate=${getDate2(date)}&toDate=${getDate2(date)}`;
    const response = await this.get();
    return response;
  }

  static async updateStatus(id, data) {
    this.setMicroservice('orders');
    this.endpoint = 'me/common/update-status';
    const response = await this.put(id, data);
    return response;
  }

  static async commandOrder(id, data) {
    this.setMicroservice('orders');
    this.endpoint = `me/manager/order/${id}/new-command`;
    const response = await this.putNew(data);
    return response;
  }

  static async assignDriver(data) {
    this.setMicroservice('orders');
    this.endpoint = 'me/common/assign-local-driver';
    const response = await this.post(data);
    return response;
  }

  static async getByBranchOffice(id = null, date = new Date()) {
    const today = dayjs(date.toLocaleString('en-US', { timeZone: 'America/Bogota' })).format('YYYY-MM-DD');
    this.setMicroservice('orders');
    this.endpoint = 'order/by-branch-office';
    const response = await this.get(`${id}?page=1&perPage=10&hasAll=true&date=${today}`);
    return response;
  }

  static async generalReport(body) {
    this.setMicroservice('orders');
    this.endpoint = 'me/common/general-report-csv';
    const response = await this.post(body);
    return response;
  }

  static async createOrder(data) {
    this.setMicroservice('orders');
    this.endpoint = 'order';
    const response = await this.post(data);
    return response;
  }
}
