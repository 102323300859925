import HttpRequest from './HttpRequest';

export default class BranchOfficeService extends HttpRequest {
  static endpoint = 'branch-offices';

  static async getAll() {
    this.endpoint = 'partners/own-branch-offices';
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'branch-offices';
    const response = await this.get(id);
    return response;
  }

  static async getByPartner(id) {
    this.endpoint = 'branch-offices/partner';
    const response = await this.get(`${id}?slim=true`);
    return response;
  }

  static async getWithProductDiscounts(id) {
    this.endpoint = 'branch-offices';
    const response = await this.get(`${id}/products-with-discount?all=true`);
    return response;
  }

  static async getWithProducts(id) {
    this.endpoint = 'branch-offices';
    const response = await this.get(`${id}/products`);
    return response;
  }

  static async create(data) {
    this.endpoint = 'branch-offices';
    const response = await this.post(data);
    return response;
  }

  static async createPublic(data) {
    this.endpoint = 'branch-offices/public-create';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'branch-offices';
    const response = await this.put(id, data);
    return response;
  }

  static async updateSchedule(id, data) {
    this.endpoint = 'branch-offices';
    const response = await this.put(`${id}/schedule`, data);
    return response;
  }

  static async updateIsWorking(data) {
    this.endpoint = 'branch-offices/update-is-working';
    const response = await this.putNew(data);
    return response;
  }

  static async getDeliveryCostAndDistance(id, position, cityId) {
    this.endpoint = `branch-offices/${id}/delivery-cost?lat=${position.lat}&lng=${position.lng}&city_id=${cityId}`;
    const response = await this.get();
    return response;
  }
}
