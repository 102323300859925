import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Card,
  makeStyles,
  Slide,
} from '@material-ui/core';
import {
  Add,
  Delete
} from '@material-ui/icons';

const ToppingCard = ({
  association,
  topping,
  index,
  attachTopping,
  disAttachTopping
}) => {
  const useStyles = makeStyles((theme) => ({
    icon: {
      cursor: 'pointer',
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      padding: '2px',
      borderRadius: '50%',
    },
    addTopping: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main
      },
      transition: 'color 0.2s ease 0s',
    },
    description: {
      marginTop: '3px',
      fontSize: '12px',
      color: '#757575'
    },
    required: {
      width: 'fit-content',
      backgroundColor: '#ff3364',
      borderBottomRightRadius: '5px',
      color: '#fff',
      padding: '5px 8px',
    },
    card: {
      width: '100%',
      backgroundColor: theme.palette.grey[100],
    }
  }));
  const classes = useStyles();

  const handleAction = () => {
    if (association) {
      attachTopping(topping);
    } else {
      disAttachTopping(index);
    }
  };

  return (
    <>
      {topping && (
        <Slide timeout={450} direction="left" in mountOnEnter unmountOnExit>
          <Card className={classes.card}>
            {topping?.is_required && (
              <Box className={classes.required}>
                <Typography style={{ fontSize: '11px', fontWeight: 'bold' }}>
                  Obligatorio
                </Typography>
              </Box>
            )}
            <Box
              p={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box width="45%">
                <Typography style={{ fontSize: '16px', wordBreak: 'break-all' }} variant="h5">{topping?.title}</Typography>
                <Typography className={classes.description} style={{ wordBreak: 'break-all' }} variant="body2">{topping?.description}</Typography>
              </Box>
              <Box pl={2} width="55%" display="flex" justifyContent="flex-end">
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.addTopping}
                  onClick={() => handleAction()}
                >
                  <Box mx={0.8} className={classes.icon}>
                    {association && (
                      <Add style={{ color: '#fff', fontSize: '16px' }} fontSize="small" />
                    )}
                    {!association && (
                      <Delete style={{ color: '#fff', padding: '1px', fontSize: '16px' }} fontSize="small" />
                    )}
                  </Box>
                  <Typography style={{ fontSize: '15px' }} variant="h5">{association ? 'Asociar' : 'Desasociar'}</Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Slide>
      )}
    </>
  );
};

ToppingCard.propTypes = {
  association: PropTypes.bool,
  topping: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    is_required: PropTypes.bool,
    max_items: PropTypes.number,
    order: PropTypes.number,
    items: PropTypes.array,
  }),
  index: PropTypes.number,
  attachTopping: PropTypes.func,
  disAttachTopping: PropTypes.func
};

ToppingCard.defaultProps = {
  association: false,
  index: 0,
  attachTopping: () => { },
  disAttachTopping: () => { }
};

export default ToppingCard;
