import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRoutes, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { selector as UserSelector, actions as UserActions } from './redux/ducks/user';
import AuthService from './services/AuthService';

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector(UserSelector);
  const routing = useRoutes(routes);

  useEffect(() => {
    const foo = async () => {
      if (token) {
        const authenticated = await AuthService.validate();
        const authenticatedManager = await AuthService.refreshManager();

        if (authenticated?.code === 200) {
          const data = await AuthService.refresh();
          if (data?.code === 200) {
            dispatch(UserActions.set(data?.data?.data));
            dispatch(UserActions.tokenize(data?.data?.token));
          }
        } else if (authenticatedManager?.code === 200) {
          dispatch(UserActions.set(authenticatedManager?.data?.data));
          dispatch(UserActions.tokenize(authenticatedManager?.data?.token));
        } else {
          dispatch(UserActions.clear());
          navigate('/auth', { replace: true });
        }
      }
    };

    foo();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
};

export default App;
