import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Container,
  makeStyles,
  Card,
  Box,
} from '@material-ui/core';
import Page from 'src/components/Page';
import LoginDashView from './LoginDashView';
import LoginManagerView from './LoginManagerView';
import OverlayPanel from './components/OverlayPanel';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  card: {
    borderRadius: 20,
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: 570,
  },
  rightPanel: {
  },
  containerForm: {
    height: '100%',
    position: 'absolute',
    top: 0,
    transition: 'all 0.6s ease-in-out',
  },
  loginManager: {
    opacity: 0,
  },
  loginManagerActive: {
    animation: '$show 0.6s',
    opacity: 1,
    transform: 'translateX(100%)',
    zIndex: 5,
  },
  loginDashActive: {
    opacity: 0,
    transform: 'translateX(100%)'
  },
  containerOverlay: {
    transition: 'transform 0.6s ease-in-out',
    zIndex: '100',
    backgroundImage: 'url("static/images/manager.jpg")',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  containerOverlayActive: {
    transform: 'translateX(-100%)',
  },
  overlay: {
    transform: 'translateX(0)',
    transition: 'transform 0.6s ease-in-out',
    zIndex: '50',
  },
  filter: {
    filter: 'opacity(50%)',
  },
  boxBack: {
    backgroundImage: 'url("static/images/allies.jpg")',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  overlayActive: {
    transform: 'translateX(50%)',
  },
  overlatLeft: {
    transform: 'translateX(-20%)'
  },
  overlayLeftActive: {
    transform: 'translateX(0)'
  },
  overlayRight: {
    right: 0,
    transform: 'translateX(0)'
  },
  overlayRightActive: {
    transform: 'translateX(20%)'
  },
  '@keyframes show': {
    '0%, 49.99%': {
      opacity: 0,
      zIndex: 1
    },
    '50%, 100%': {
      opacity: 1,
      zIndex: 5
    }
  }
}));

const AuthView = () => {
  const classes = useStyles();
  const [rightPanel, setRightPanel] = useState(false);
  return (
    <Page
      className={classes.root}
      title="Bienvenido"
    >
      <Container
        maxWidth="lg"
      >
        <Card
          className={clsx(
            classes.card,
            {
              [classes.rightPanel]: rightPanel
            }
          )}
        >
          <Box
            width={110}
            height={38}
            m={3}
          >
            <img src="/static/logo_aliados_green.svg" alt="logo_adomi_aliados" />
          </Box>
          <Box
            className={clsx(
              classes.containerForm,
              classes.loginManager,
              {
                [classes.loginManagerActive]: rightPanel,
              }
            )}
            left={0}
            zIndex={1}
            width="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <LoginManagerView />
          </Box>
          <Box
            className={clsx(
              classes.containerForm,
              {
                [classes.loginDashActive]: rightPanel,
              }
            )}
            left={0}
            zIndex={2}
            width="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <LoginDashView />
          </Box>
          <Box
            className={clsx(
              classes.containerOverlay,
              {
                [classes.containerOverlayActive]: rightPanel,
                [classes.boxBack]: rightPanel
              }
            )}
            height="100%"
            width="50%"
            position="absolute"
            left="50%"
            overflow="hidden"
            top={0}
          >
            <Box
              className={classes.filter}
              bgcolor="#009739"
              width="200%"
              height="100%"
              position="absolute"
              left="-100%"
            >
              h
            </Box>
            <Box
              className={clsx(
                classes.overlay,
                {
                  [classes.overlayActive]: rightPanel
                }
              )}
              width="200%"
              height="100%"
              position="absolute"
              left="-100%"
            >
              <OverlayPanel
                className={clsx(
                  classes.overlatLeft,
                  {
                    [classes.overlayLeftActive]: rightPanel
                  }
                )}
                title="Aliado"
                description="Ingresa como aliado y administra la logistica de paquetería, gestión de productos y sucursales de tu marca al instante.."
                setForm={() => setRightPanel(false)}
                isLoginDash
              />
              <OverlayPanel
                className={clsx(
                  classes.overlayRight,
                  {
                    [classes.overlayRightActive]: rightPanel
                  }
                )}
                title="Gerente"
                description="Ingresa como gerente y gestiona todas tus ordenes de una manera facil y rapida."
                setForm={() => setRightPanel(true)}
              />
            </Box>
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default AuthView;
