import HttpRequest from './HttpRequest';

export default class ProductsLogisticService extends HttpRequest {
  static endpoint = 'products-logistic';

  static async getAll() {
    this.endpoint = 'products-logistic';
    const response = await this.get();
    return response;
  }
}
