import dayjs from 'dayjs';

const getHour = (date) => {
  if (!date || date?.length === 0) return null;
  return dayjs(date).format('h:mm a');
};

const getDate = (date) => {
  if (!date || date?.length === 0) return null;
  return dayjs(date).format('DD/MM/YYYY');
};

const getDate2 = (date) => {
  if (!date || date?.length === 0) return null;
  return dayjs(date).format('YYYY-MM-DD');
};

export { getHour, getDate, getDate2 };
