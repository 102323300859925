import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router';
import Results from './Results';
import orderBy from '../../../utils/arrays';

export default function ManagerOrderListView() {
  const [orders, setOrders] = useState([]);
  const { filter, setFilter } = useOutletContext();
  const { numberPage, setNumberPage } = useOutletContext();
  const { branchOffice } = useOutletContext();
  const { pendingOrders } = useOutletContext();
  const { timers } = useOutletContext();
  const { inProgressOrders } = useOutletContext();
  const { sentOrders } = useOutletContext();
  const { sentLocalOrders } = useOutletContext();
  const { toBeSentOrders } = useOutletContext();
  const { finishedOrders } = useOutletContext();
  const { cancelledOrders } = useOutletContext();
  const { allOrders } = useOutletContext();
  const { refresh } = useOutletContext();
  const { isOpen } = useOutletContext();
  const [buttonAllActived, setButtonAllActived] = useState(true);

  const toggleOrder = (index) => {
    const newOrders = orders.slice();
    newOrders[index].show = !newOrders[index].show;
    setOrders(newOrders);
  };

  const changePage = (page) => {
    setNumberPage(page);
  };

  const handleFilter = (value) => {
    setFilter(value);
    if (value !== filter) setNumberPage(1);
    let dataOrders = [];
    switch (value) {
      case 'in_progress':
        dataOrders = inProgressOrders?.orders.map((item) => {
          return {
            ...item,
            show: true
          };
        });
        break;
      case 'finished':
        dataOrders = finishedOrders?.orders.map((item) => {
          return {
            ...item,
            show: true
          };
        });
        break;
      case 'cancelled':
        dataOrders = cancelledOrders?.orders.map((item) => {
          return {
            ...item,
            show: true
          };
        });
        break;
      case 'pending_to_be_sent':
        dataOrders = toBeSentOrders?.orders.map((item) => {
          return {
            ...item,
            show: true
          };
        });
        break;
      case 'sent_local':
        dataOrders = sentLocalOrders?.orders.map((item) => {
          return {
            ...item,
            show: true
          };
        });
        break;
      case 'sent':
        dataOrders = sentOrders?.orders.map((item) => {
          return {
            ...item,
            show: true
          };
        });
        break;
      case 'created':
        dataOrders = pendingOrders?.orders.map((item) => {
          return {
            ...item,
            show: true,
          };
        });
        break;
      default:
        dataOrders = allOrders?.orders.map((item) => {
          return {
            ...item,
            show: false,
          };
        });
        break;
    }
    const data = orderBy(dataOrders, 'reference', 'high').reduce((array, item) => {
      let statusOrder = 0;
      switch (item?.status) {
        case 'in_progress':
          statusOrder = 1;
          break;
        case 'finished':
          statusOrder = 2;
          break;
        case 'rejected':
          statusOrder = 3;
          break;
        case 'cancelled':
          statusOrder = 4;
          break;
        default:
          statusOrder = 0;
      }
      return array.concat({ ...item, statusOrder });
    }, []);
    setOrders(orderBy(data, 'statusOrder'));
    setButtonAllActived(false);
  };

  useEffect(() => {
    handleFilter(filter);
  }, [
    pendingOrders,
    inProgressOrders,
    toBeSentOrders,
    sentOrders,
    sentLocalOrders,
    finishedOrders,
    cancelledOrders,
    allOrders
  ]);

  return (
    <div>
      <Results
        buttonAllActived={buttonAllActived}
        data={orders}
        onRefresh={refresh}
        onChange={handleFilter}
        numberStatus={{
          cancelled: cancelledOrders?.page_info?.total || 0,
          finished: finishedOrders?.page_info?.total || 0,
          in_progress: inProgressOrders?.page_info?.total || 0,
          pending_to_be_sent: toBeSentOrders?.page_info?.total || 0,
          sent_local: sentLocalOrders?.page_info?.total || 0,
          sent: sentOrders?.page_info?.total || 0,
          created: pendingOrders?.page_info?.total || 0,
        }}
        totalOrders={allOrders?.page_info?.total || 0}
        numberPage={numberPage}
        changePage={changePage}
        branchOffice={branchOffice}
        toggleOrder={toggleOrder}
        timers={timers}
        pendingOrders={pendingOrders}
        filter={filter}
        isOpen={isOpen}
      />
    </div>
  );
}
