import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import EnvironmentService from 'src/services/EnviromentService';
import { makeStyles } from '@material-ui/core';
import { CancelRounded } from '@material-ui/icons';

const SupportModal = ({
  toggleSupportModal
}) => {
  const [urlBanner, setUrlBanner] = useState('');

  const useStyles = makeStyles(() => ({
    SupportModal: {
      backgroundColor: 'rgba(46, 44, 54, 0.4)',
      position: 'fixed',
      top: 0,
      right: 0,
      height: '100%',
      width: '100%',
      zIndex: '3',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pane: {
      height: '100%',
      width: '100%',
      position: 'fixed'
    },
    modal: {
      borderRadius: '8px',
      width: '600px',
      height: '350px',
      display: 'flex',
      justifyContent: 'flex-end',
      backgroundImage: `url(${urlBanner})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      bottom: '0',
      animation: 'showPopUp 0.5s',
      position: 'relative',
    },
  }));

  const classes = useStyles();

  const getBanner = async () => {
    const response = await EnvironmentService.getByKey('support_image');
    if (response?.code === 200) setUrlBanner(response?.data?.value);
  };

  useEffect(() => {
    getBanner();
  }, []);

  return urlBanner && urlBanner?.length > 0 && (
    <div className={classes.SupportModal}>
      <div className={classes.pane} onClick={() => toggleSupportModal()} />
      <div className={classes.modal}>
        <CancelRounded style={{ cursor: 'pointer', margin: 5 }} color="primary" onClick={() => toggleSupportModal()} />
      </div>
    </div>
  );
};

SupportModal.propTypes = {
  toggleSupportModal: PropTypes.func
};

export default SupportModal;
