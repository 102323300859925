import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  success: {
    width: 80,
    height: 105,
    margin: '0 auto',
  },
  checkIcon: {
    width: 80,
    height: 80,
    position: 'relative',
    borderRadius: '50%',
    boxSizing: 'content-box',
    border: '4px solid #009379',
    '&::before': {
      top: 3,
      left: -2,
      width: 30,
      transformOrigin: '100% 50%',
      borderRadius: '100px 0 0 100px'
    },
    '&::after': {
      top: 0,
      left: 30,
      width: 60,
      transformOrigin: '0 50%',
      borderRadius: '0 100px 100px 0',
      animation: '$rotate-circle 4.25s ease-in'
    },
    '&::before, &::after': {
      content: '""',
      height: 100,
      position: 'absolute',
      background: '#f4f7fa',
      transform: 'rotate(-45deg)'
    },
  },
  iconLine: {
    height: 5,
    backgroundColor: '#009379',
    display: 'block',
    borderRadius: 2,
    position: 'absolute',
    zIndex: '10'
  },
  lineTip: {
    top: 46,
    left: 14,
    width: 25,
    transform: 'rotate(45deg)',
    animation: '$icon-line-tip 0.75s'
  },
  lineLong: {
    top: 38,
    right: 8,
    width: 47,
    transform: 'rotate(-45deg)',
    animation: '$icon-line-long 0.75s'
  },
  iconCircle: {
    top: -4,
    left: -4,
    zIndex: 10,
    width: 80,
    height: 80,
    borderRadius: '50%',
    position: 'absolute',
    boxSizing: 'content-box',
    border: '4px solid rgba(76, 175, 80, .5)'
  },
  iconFix: {
    top: 8,
    width: 5,
    left: 26,
    zIndex: 1,
    height: 85,
    position: 'absolute',
    transform: 'rotate(-45deg)',
    backgroundColor: '#f4f7fa'
  },
  '@keyframes rotate-circle': {
    '0%': {
      transform: 'rotate(-45deg)'
    },
    '5%': {
      transform: 'rotate(-45deg)'
    },
    '12%': {
      transform: 'rotate(-405deg)',
    },
    '100%': {
      transform: 'rotate(-405deg)'
    }
  },
  '@keyframes icon-line-tip': {
    '0%': {
      width: 0,
      left: 1,
      top: 19,
    },
    '54%': {
      width: 0,
      left: 1,
      top: 19,
    },
    '70%': {
      width: 50,
      left: -8,
      top: 37,
    },
    '84%': {
      width: 17,
      left: 21,
      top: 48,
    },
    '100%': {
      width: 25,
      left: 14,
      top: 45
    }
  },
  '@keyframes icon-line-long': {
    '0%': {
      width: 0,
      right: 46,
      top: 54
    },
    '65%': {
      width: 0,
      right: 46,
      top: 54
    },
    '84%': {
      width: 55,
      right: 0,
      top: 35,
    },
    '100%': {
      width: 47,
      right: 8,
      top: 38,
    }
  }
}));

const SucessCheck = ({
  text
}) => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.success}>
        <Box className={classes.checkIcon}>
          <Box className={clsx(classes.iconLine, classes.lineTip)} />
          <Box className={clsx(classes.iconLine, classes.lineLong)} />
          <Box className={classes.iconCircle} />
          <Box className={classes.iconFix} />
        </Box>
      </Box>
      <Typography
        variant="body2"
        color="primary"
        style={{
          fontSize: 19,
          fontWeight: 500
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

SucessCheck.propTypes = {
  text: PropTypes.string
};

export default SucessCheck;
