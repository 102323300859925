import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { v4 as uuid4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Tabs,
  Tab,
} from '@material-ui/core';
import HourForm from 'src/components/HourForm';
import orderBy from 'src/utils/arrays';
import Errors from '../../../components/Errors';
import useErrors from '../../../hooks/useErrors';
import BranchOfficeService from '../../../services/BranchOfficeService';

// utils

const useStyles = makeStyles(() => ({
  root: {},
  tabs: {
    '& .MuiTabs-flexContainer': {
      display: 'flex',
      justifyContent: 'space-between',
    }
  },
  tab: {
    minWidth: '2rem'
  }
}));

const initialSchedule = {
  Monday: [],
  Tuesday: [],
  Wednesday: [],
  Thursday: [],
  Friday: [],
  Saturday: [],
  Sunday: [],
};

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const CreateBranchOfficeForm = ({
  className, onChange, branchOffice, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [schedule, setSchedule] = useState(initialSchedule);
  const { setErrors } = useErrors();

  const handleChangeTab = (e, value) => setActiveIndex(value);

  const handleRemoveHour = (hours) => {
    setSchedule({
      ...schedule,
      [days[activeIndex]]: schedule?.[days[activeIndex]]?.filter((h) => !(h === hours))
    });
  };

  const addNewSchedule = () => {
    const uui = uuid4();
    const newSchedule = schedule?.[days[activeIndex]]?.concat({
      id: uui, start: '', end: '', estimated_delivery_time: { from: { days: '0', hours: '0', minutes: '30' }, to: { days: '0', hours: '1', minutes: '0' } }
    });

    setSchedule({
      ...schedule,
      [days[activeIndex]]: newSchedule
    });
  };

  const handleChangeSchedule = (hours) => {
    const newSchedule = schedule?.[days[activeIndex]]
      ?.reduce((array, item) => {
        if (item?.id === hours?.id) {
          return array.concat(hours);
        }
        return array.concat(item);
      }, []);

    setSchedule({
      ...schedule,
      [days[activeIndex]]: newSchedule
    });
  };

  const isEmptyObj = (object = {}) => {
    const toArr = Object.values(object);
    const isEmpty = toArr.some((val) => val === '');
    return isEmpty;
  };

  const scheduleIsCompleted = () => {
    let isOKSchedule = true;
    let isOKEstimatedTime = true;

    days.map((day) => {
      schedule?.[day]?.map((s) => {
        if (isOKSchedule) {
          if (s?.start === '' || s?.end === '') {
            isOKSchedule = false;
          }
        }
        if (isOKEstimatedTime) {
          if (isEmptyObj(s?.estimated_delivery_time?.from)
            || isEmptyObj(s?.estimated_delivery_time?.to)) {
            isOKEstimatedTime = false;
          }
        }
        return s;
      });
      return day;
    });

    return {
      isOKSchedule,
      isOKEstimatedTime
    };
  };

  const validateSchedulesTime = () => {
    let isOk = true;
    let typeError;

    days.map((day) => {
      if (isOk) {
        schedule?.[day].map((s) => {
          const { start, end, estimated_delivery_time: ET } = s;

          if (end < start) {
            typeError = 'Por favor verifique el horario de atención esté correctamente agregado';
            isOk = false;
            return s;
          }
          if ((ET?.to?.days > 0 || ET?.from.days > 0) && (ET?.to.days < ET?.from.days)) {
            typeError = 'Por favor verifique los días en su tiempo estimado de entrega esté correctamente agregado';
            isOk = false;
            return s;
          }
          if (ET?.to?.days === ET?.from?.days && ET?.to.hours < ET?.from.hours) {
            typeError = 'Por favor verifique las horas en su tiempo estimado de entrega esté correctamente agregado';
            isOk = false;
            return s;
          }
          if ((ET?.to?.days === ET?.from.days
            && ET?.to.hours === ET?.from.hours) && ET?.to.minutes < ET?.from.minutes) {
            typeError = 'Por favor verifique los minutos en su tiempo estimado de entrega esté correctamente agregado';
            isOk = false;
            return s;
          }
          return s;
        });
      }
      return day;
    });
    return {
      isOk,
      typeError
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      brand_office_id: branchOffice?.id,
      schedule: {
        Holidays: [],
        ...schedule
      }
    };

    if (!branchOffice?.id) {
      errors.push('No es posible actualizar esta sucursal');
    }

    if (!scheduleIsCompleted().isOKSchedule) {
      errors.push('No puede haber horarios sin hora definida');
    }

    if (!scheduleIsCompleted().isOKEstimatedTime) {
      errors.push('No puede haber tiempos estimados sin tiempos definidos');
    }

    if (!validateSchedulesTime().isOk) {
      errors.push(validateSchedulesTime().typeError);
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    if (errors?.length === 0 && branchOffice?.id) {
      const response = await BranchOfficeService.updateSchedule(branchOffice?.id, data);

      if (response?.code === 200) {
        setErrors([]);
        navigate('/app/sucursales', { replace: true });
      } else {
        setErrors(response?.errors === undefined ? [] : response?.errors);
      }
    }
  };

  useEffect(() => {
    if (branchOffice?.schedule) {
      setSchedule(branchOffice?.schedule);
    }
  }, [branchOffice]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title={`Horario de ${branchOffice?.brand?.name} ${branchOffice?.name}`}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Tabs value={activeIndex} className={classes?.tabs} onChange={handleChangeTab}>
                <Tab label="Lunes" className={classes?.tab} {...a11yProps(0)} />
                <Tab label="Martes" className={classes?.tab} {...a11yProps(1)} />
                <Tab label="Miércoles" className={classes?.tab} {...a11yProps(2)} />
                <Tab label="Jueves" className={classes?.tab} {...a11yProps(3)} />
                <Tab label="Viernes" className={classes?.tab} {...a11yProps(4)} />
                <Tab label="Sábado" className={classes?.tab} {...a11yProps(5)} />
                <Tab label="Domingo" className={classes?.tab} {...a11yProps(6)} />
              </Tabs>
            </Grid>
          </Grid>

          {
            orderBy(schedule?.[days[activeIndex]], 'start')?.map((hours, index) => (
              <>
                <HourForm
                  key={hours?.id}
                  hours={hours}
                  withETime
                  onChange={handleChangeSchedule}
                  onRemove={handleRemoveHour}
                />
                {schedule?.[days[activeIndex]].length === index + 1
                  ? null
                  : <Divider style={{ margin: '1rem 0' }} />}
              </>
            ))
          }

          <Grid container spacing={3}>
            <Box p={2}>
              <Button
                variant="outlined"
                disableElevation
                onClick={addNewSchedule}
              >
                Agregar otro horario
              </Button>
            </Box>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            Guardar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

CreateBranchOfficeForm.propTypes = {
  className: PropTypes.string,
  hasImage: PropTypes.bool,
  onChange: PropTypes.func,
  branchOffice: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    schedule: PropTypes.object,
    brand: {
      name: PropTypes.string,
      logo: PropTypes.string
    },
    estimated_delivery_time: PropTypes.object,
  })
};

CreateBranchOfficeForm.defaultProps = {
  className: '',
  hasImage: false,
  onChange: () => { },
  branchOffice: {
    id: '',
    name: '',
    description: '',
    schedule: {},
    brand: {
      name: '',
      logo: ''
    },
  }
};

export default CreateBranchOfficeForm;
