import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { getDate, getHour } from 'src/utils/formatDate';
import cargandoGif from 'src/assets/image/cargando.gif';
import ProductsLogisticService from 'src/services/ProductsLogisticService';
import {
  Box,
  Grid,
  Card,
  Typography,
  Avatar,
  makeStyles
} from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';
import {
  ArrowForwardRounded,
  Block,
  Check,
  SwapHoriz
} from '@material-ui/icons';
import { IoIosPerson } from 'react-icons/io';
import { MdDeliveryDining } from 'react-icons/md';
import OrderCost from 'src/components/OrderCost';

const useStyles = makeStyles((theme) => ({
  service: {
    overflow: 'unset',
    position: 'relative',
    width: '100%',
    borderRadius: 20,
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
  },
  hoverTransition: {
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.03)'
    },
    transition: 'all 0.5s ease'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: 30,
    height: 30,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  avatarProduct: {
    width: '100%',
    height: '100%',
  },
  iconStatus: {
    fontSize: 28,
    color: theme.palette.primary.main
  },
  gif: {
    maxWidth: '100%',
    minWidth: '100%',
    maxHeight: '100%',
    minHeight: '100%'
  },
  text: {
    fontSize: 12,
    fontWeight: 500,
    color: '#fff'
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 13,
    fontWeight: 500,
    color: '#fff'
  },
  iconSwap: {
    fontSize: 15,
    color: '#fff'
  },
  arrowIcon: {
    color: theme.palette.primary.main,
    fontSize: 25,
  },
  pointer: {
    animation: '$point 1.25s infinite alternate',
  },
  containerIcon: {
    transform: 'translateY(-40%)'
  },
  bgIcon: {
    backgroundColor: '#d4edda',
    borderRadius: '50%',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
  },
  cardOrder: {
    boxShadow: 'none'
  },
  scroll: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  },
  scale: {
    animation: '$scale 0.7s ease both'
  },
  '@keyframes point': {
    '0%': {
      transform: 'translateX(0)',
    },
    '100%': {
      transform: 'translateX(.125em)'
    }
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(0)'
    },
    '30%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

const ServiceItem = ({
  image,
  product,
  item
}) => {
  const classes = useStyles();
  const statusTranslate = (status) => {
    switch (status) {
      case 'finished':
        return 'Finalizado';
      case 'rejected':
        return 'Rechazado';
      case 'cancelled':
        return 'Cancelado';
      case 'pending_to_be_sent':
        return 'Asignando driver';
      case 'sent':
        return 'Enviado';
      default:
        return status;
    }
  };
  return (
    <Card
      className={clsx(classes.service, classes.scale)}
    >
      <Box px={3} pt={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box ml={0.5}>
              <Typography color="primary" style={{ fontWeight: 600, fontSize: '22px' }} variant="h4">
                Orden #
                {item?.reference}
              </Typography>
              <Box display="flex" mt={0.5}>
                <Typography style={{ color: '#A5A5A5', fontWeight: 700, marginRight: '5px' }} variant="body2">{getDate(item?.created_at)}</Typography>
                <Typography style={{ color: '#A5A5A5', fontWeight: 700 }} variant="body2">{getHour(item?.created_at)}</Typography>
              </Box>
            </Box>
          </Box>
          <Box pr={1.5} display="flex" flexDirection="column" alignItems="center">
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={item?.status === 'pending_to_be_sent' ? 1 : 0.3}
              width={item?.status === 'pending_to_be_sent' ? 50 : 'fit-content'}
              height={item?.status === 'pending_to_be_sent' ? 50 : 'fit-content'}
            >
              {item?.status === 'pending_to_be_sent' && (
                <>
                  <Box position="absolute" top={0}>
                    <img className={classes.gif} src={cargandoGif} alt="cargando" />
                  </Box>
                  <IoIosPerson className={classes.iconStatus} />
                </>
              )}
              {item?.status === 'sent' && (
                <MdDeliveryDining className={classes.iconStatus} />
              )}
              {item?.status === 'finished' && (
                <Check className={classes.iconStatus} />
              )}
              {(item?.status === 'cancelled' || item?.status === 'rejected') && (
                <Block className={classes.iconStatus} />
              )}
            </Box>
            <Typography variant="body2" style={{ fontWeight: 600, fontSize: '13px', textAlign: 'center' }}>
              {statusTranslate(item?.status)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        p={2}
        px={1}
        pt={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          width="65%"
          borderRadius={10}
          display="flex"
          flexDirection="column"
          px={2.5}
          py={1}
        >
          <Box
            display="flex"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                border="3px solid #97D4AE"
                width={26}
                height={26}
                bgcolor="#009739"
              >
                <Typography
                  className={classes.text}
                  variant="body2"
                >
                  A
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
              >
                {[0, 1].map(() => (
                  <Box
                    my={0.5}
                    width={8}
                    height={8}
                    borderRadius="50%"
                    bgcolor="#97D4AE"
                  />
                ))}
              </Box>
            </Box>
            <Box
              width="90%"
              alignSelf="start"
              ml={1}
              bgcolor="#009739"
              borderRadius={5}
              px={1}
              py={0.7}
            >
              <Typography
                className={classes.name}
                variant="body2"
                color="primary"
              >
                {item?.data_branch_office?.address}
              </Typography>
            </Box>
          </Box>
          <Box
            position="relative"
            display="flex"
            alignItems="center"
          >
            {item?.roundtrip && (
              <Box
                style={{
                  animationDelay: '0s'
                }}
                position="absolute"
                top={-8}
                left={-20}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                border="3px solid #97D4AE"
                width={23}
                height={23}
                bgcolor="#009739"
              >
                <SwapHoriz className={classes.iconSwap} />
              </Box>
            )}
            <Avatar
              className={clsx(
                classes.avatar,
              )}
              style={{
                animationDelay: `${0.6 + 0.6 * 3}s`
              }}
            >
              <MdDeliveryDining size={19} />
            </Avatar>
            <Box
              className={classes.containerIcon}
              position="absolute"
              top="50%"
              left={35}
            >
              <ArrowForwardRounded
                className={clsx(
                  classes.arrowIcon,
                  classes.pointer
                )}
              />
            </Box>
            <Box
              display="flex"
              ml={5.5}
              width="100%"
            >
              <Box
                width={40}
                height={40}
                display="flex"
                alignItems="center"
                justifyContent="center"
                className={classes.bgIcon}
              >
                <Box
                  width={25}
                  height={25}
                >
                  <img
                    className={classes.avatarProduct}
                    src={image}
                    alt={product?.prices[0]?.name}
                  />
                </Box>
              </Box>
              <Box
                ml={1.2}
                display="flex"
                flexDirection="column"
                width="80%"
              >
                <Typography
                  className={classes.name}
                  style={{
                    color: '#000'
                  }}
                  variant="body2"
                >
                  {product?.prices[0]?.name}
                </Typography>
                <Box mt={0.3}>
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: 600
                    }}
                    color="primary"
                    variant="body2"
                  >
                    $
                    <CurrencyFormat
                      value={product?.price || 0}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box
                display="flex"
                flexDirection="column-reverse"
              >
                {[0, 1].map(() => (
                  <Box
                    my={0.5}
                    width={8}
                    height={8}
                    borderRadius="50%"
                    bgcolor="#ed88aa"
                  />
                ))}
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                border="3px solid #ed88aa"
                width={26}
                height={26}
                bgcolor="#e0336d"
              >
                <Typography
                  className={classes.text}
                  variant="body2"
                >
                  B
                </Typography>
              </Box>
            </Box>
            <Box
              width="90%"
              ml={1}
              bgcolor="#e0336d"
              borderRadius={5}
              px={1}
              py={0.7}
              alignSelf="end"
            >
              <Typography
                className={classes.name}
                variant="body2"
              >
                {item?.address?.address1}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          py={1}
          pr={1}
          display="flex"
        >
          <OrderCost
            className={classes.cardOrder}
            distance={item?.distance}
            deliveryCost={item?.delivery_cost}
            column
          />
        </Box>
      </Box>
    </Card>
  );
};

const Results = ({
  services
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  const getImageById = (id) => {
    return products.find((item) => item?.product_id === id)?.image;
  };

  const getProducts = async () => {
    const response = await ProductsLogisticService.getAll();
    if (response?.code === 200) {
      setProducts(response?.data);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Box
      maxHeight="100%"
      className={classes.scroll}
      display="flex"
      my={2}
    >
      <Box
        px={2.5}
        py={0.5}
        width="100%"
      >
        <Grid
          container
          spacing={2}
        >
          {services.map((item) => (
            <Grid
              item
              xs={12}
              md={6}
            >
              <Box
                onClick={() => navigate(`/app/paqueteria/${item?.id}`)}
                className={classes.hoverTransition}
              >
                <ServiceItem
                  image={getImageById(item?.order_products[0].product_id)}
                  product={item?.order_products[0]}
                  item={item}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

ServiceItem.propTypes = {
  image: PropTypes.string,
  product: PropTypes.object,
  item: PropTypes.object
};

Results.propTypes = {
  services: PropTypes.array
};

export default Results;
