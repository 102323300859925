import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useOnScreen from 'src/hooks/useOnScreen';
import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Restaurant } from '@material-ui/icons';
import Results from '../Results';

const useStyles = makeStyles((theme) => ({
  iconFood: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.primary.main,
    fontSize: '25px'
  },
}));

const Section = ({
  category,
  setSelectedCategory,
  index,
  loading,
}) => {
  const classes = useStyles();
  const refSection = useRef(null);
  const onScreen = useOnScreen(refSection, '-345px');

  useEffect(() => {
    if (refSection && onScreen) setSelectedCategory(index);
  }, [onScreen]);

  return (
    <section ref={refSection} id={category?.name}>
      <Box pt={3} display="flex" alignItems="center" height="fit-content">
        <Restaurant className={classes.iconFood} />
        <h1 style={{ fontSize: 32 }}>
          {
            loading
              ? (
                <Skeleton animation="wave" variant="text" width="200px" height="38px" />
              )
              : category?.name
          }
        </h1>
      </Box>
      <Results data={category?.products} loading={loading} />
    </section>
  );
};

Section.propTypes = {
  category: PropTypes.object,
  setSelectedCategory: PropTypes.func,
  index: PropTypes.number,
  loading: PropTypes.bool,
};

Section.defaultProps = {
  loading: false,
};

export default Section;
