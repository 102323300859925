import React, { useEffect, useState } from 'react';
import LoadingBox from 'src/components/LoadingBox';
import Selector from 'src/components/Selector';
import { VscPackage } from 'react-icons/vsc';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import {
  makeStyles,
  Container,
  Card,
  Typography,
  FormControl,
  TextField,
  Grid,
  Box
} from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';
import Page from 'src/components/Page';
import TabFilters from 'src/components/TabFilters';
import OrderService from 'src/services/OrderService';
import BranchOfficeService from 'src/services/BranchOfficeService';
import isArray from 'src/utils/is_array';
import { Assessment, CreditCard, MonetizationOn } from '@material-ui/icons';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f6f6fa',
    height: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  card: {
    position: 'relative',
    overflow: 'unset',
    height: '100%',
    borderRadius: 20,
    backgroundColor: '#f4f7fa',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
  },
  container: {
    paddingLeft: 20,
    paddingRight: 20,
    height: '100%'
  },
  pagination: {
    '& svg, & button, & li div': {
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  },
  select: {
    fontSize: 13
  },
  formControl: {
    width: '100%'
  },
  bgIcon: {
    backgroundColor: 'rgba(67, 160, 71, 0.3)',
    borderRadius: '50%'
  },
  totalCard: {
    width: '100%',
    padding: '35px 20px',
    display: 'flex',
    justifyContent: 'center'
  }
}));

const selectorStyles = {
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: '#f4f7fa',
      borderColor: '#009739',
      boxShadow: isFocused ? '#009739 0 0 0 1px' : undefined,
      '&:hover': {
        borderColor: isFocused && '#009739'
      },
      cursor: 'pointer'
    };
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    let backgroundColor = '';
    let bgColorActive = '';

    if (isDisabled) backgroundColor = undefined;
    else if (isSelected) backgroundColor = '#009739';
    else if (isFocused) backgroundColor = '#97D4AE';
    else backgroundColor = undefined;

    if (!isDisabled) {
      if (isSelected) bgColorActive = '#009739';
      else bgColorActive = '#97D4AE';
    } else bgColorActive = undefined;

    return {
      ...styles,
      backgroundColor,
      color: isSelected ? '#fff' : '#000',
      cursor: 'pointer',

      ':active': {
        ...styles[':active'],
        backgroundColor: bgColorActive
      }
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, fontSize: 11 }),
  singleValue: (styles) => ({ ...styles, fontSize: 14 })
};

const filtros = [
  {
    key: 1,
    value: 'finished',
    title: 'Completado',
    total: 0
  },
  {
    key: 2,
    value: 'rejected',
    title: 'Rechazado',
    total: 0
  },
  {
    key: 3,
    value: 'cancelled',
    title: 'Cancelado',
    total: 0
  }
];

const useStylesPicker = makeStyles((theme) => ({
  root: {
    borderRadius: 9,
    width: '170px',
    backgroundColor: '#fcfcfb',
    boxShadow:
      'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: theme.spacing(1.5)
    },
    '& .MuiOutlinedInput-notchedOutline': {
      outlined: 'none',
      border: 'none'
    },
    '& .MuiInputBase-root': {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: '"Poppins", sans-serif',
      color: '#000'
    },
    '& .MuiIconButton-root': {
      padding: theme.spacing(0)
    },
    '& .MuiIconButton-label': {
      borderRadius: 6,
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(0.4)
    },
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      color: '#fff'
    }
  }
}));

const DatePickerComponent = (props) => {
  const classes = useStylesPicker();
  return (
    <TextField
      {...props}
      disabled
      className={classes.root}
      variant="outlined"
      size="small"
    />
  );
};

const ManagerReportView = () => {
  const classes = useStyles();
  const [filters, setFilters] = useState(filtros);
  const [filter, setFilter] = useState(filtros[0]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [services, setServices] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const [branchOffice, setBranchOffice] = useState({
    id: '',
    name: ''
  });
  const [loading, setLoading] = useState(false);
  const [totals, setTotals] = useState(0);
  const [cash, setCash] = useState(0);
  const [card, setCard] = useState(0);

  const getTotal = (orders) => {
    let total = 0;
    orders.forEach((item) => {
      total += item?.sub_total;
    });
    setTotals(total);
  };

  const getCashAndCard = (orders) => {
    let totalCash = 0;
    let totalCard = 0;
    orders.forEach((item) => {
      if (item?.payment_method === 'cash') {
        totalCash += item?.sub_total;
      } else {
        totalCard += item?.sub_total;
      }
    });
    setCash(totalCash);
    setCard(totalCard);
  };

  const updateFilters = (number, state) => {
    setFilters((prev) => {
      if (!isArray(prev)) return [];
      return prev.map((item) => {
        if (item.value === state) {
          return {
            ...item,
            total: number
          };
        }
        return item;
      });
    });
  };

  const getServices = async (id, isDevelop, date, status, action) => {
    setLoading(true);
    const response = await OrderService.getByStatus(
      id,
      status,
      1,
      10000,
      date,
      isDevelop
    );
    if (response?.success) {
      if (action === 'total') {
        if (status === filter.value) {
          getServices(id, isDevelop, date, status, 'set');
        }
        getTotal(response?.data?.orders);
        getCashAndCard(response?.data?.orders);
        updateFilters(response?.data?.page_info?.total, status);
      } else if (action === 'set') {
        const data = response?.data?.orders;
        setServices(data);
        setLoading(false);
      }
      getTotal(response?.data?.orders);
      getCashAndCard(response?.data?.orders);
    }
  };

  const refresh = (id, isDevelop) => {
    filters.forEach((item) => {
      getServices(id, isDevelop, selectedDate, item?.value, 'total');
    });
  };

  useEffect(() => {
    refresh(branchOffice?.id, branchOffice?.is_develop);
  }, [selectedDate]);

  const handleChangeBranchOffice = (value) => {
    const branchOfficeSelected = branchOffices.find(
      (item) => item?.id === value?.value
    );
    setBranchOffice(branchOfficeSelected);
    refresh(branchOfficeSelected?.id, branchOfficeSelected?.is_develop);
  };

  const getBranchOffices = async () => {
    const response = await BranchOfficeService.getAll();
    if (response?.code === 200) {
      setBranchOffices(response?.data);
      setBranchOffice(response?.data[0]);
      refresh(response?.data[0]?.id, response?.data[0]?.is_develop);
    }
  };

  const handleFilter = (filtro) => {
    setFilter(filtro);
    getServices(
      branchOffice?.id,
      branchOffice?.is_develop,
      selectedDate,
      filtro?.value,
      'set'
    );
  };

  useEffect(() => {
    getBranchOffices();
  }, []);

  return (
    <Page title="Segumiento" className={classes.root}>
      <Container className={classes.container}>
        <LoadingBox loading={false}>
          <Card className={classes.card}>
            <Box height="100%" py={2} display="flex" flexDirection="column">
              <Box
                pt={1}
                px={2.5}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center" width="50%" mr={1}>
                  <Box width="100%" mr={1}>
                    <Typography color="primary" variant="h3">
                      Reporte de servicios
                    </Typography>
                  </Box>
                  <FormControl required className={classes.formControl}>
                    <Selector
                      options={branchOffices?.map((item) => ({
                        value: item?.id,
                        label: item?.name
                      }))}
                      className={classes.select}
                      value={
                        Object.keys(branchOffice).length === 0
                          ? ''
                          : {
                              value: branchOffice?.id,
                              label: branchOffice?.name
                            }
                      }
                      onChange={handleChangeBranchOffice}
                      name="branch_office_id"
                      labelId="branch_office"
                      label="Sucursal"
                      placeholder="Selecciona una sucursal"
                      styles={selectorStyles}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center">
                  <MuiPickersUtilsProvider utils={DayJsUtils}>
                    <KeyboardDatePicker
                      variant="inline"
                      disableToolbar
                      format="DD/MM/YYYY"
                      id="date-picker-inline"
                      value={selectedDate}
                      maxDate={new Date()}
                      onChange={(date) => setSelectedDate(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      TextFieldComponent={DatePickerComponent}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              </Box>
              <TabFilters
                haveCountData
                filters={filters}
                selectedFilter={filter}
                onChange={handleFilter}
              />
              <Box
                maxHeight="70%"
                display="flex"
                flexDirection="row"
                width="100%"
              >
                <Box
                  flexGrow={1}
                  width="70%"
                  height={loading || services.length === 0 ? '100%' : null}
                >
                  <LoadingBox
                    loading={loading}
                    bgcolor="none"
                    title="Cargando ordenes..."
                  >
                    {services.length !== 0 ? (
                      <Results services={services} />
                    ) : (
                      <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <VscPackage
                            style={{
                              color: '#009739'
                            }}
                            size={60}
                          />
                          <Typography
                            color="primary"
                            variant="body2"
                            style={{
                              fontSize: 20,
                              fontWeight: 600
                            }}
                          >
                            No hay ordenes...
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </LoadingBox>
                </Box>
                <Box width="30%" p={2}>
                  <Grid
                    alignItems="center"
                    justifyContent="center"
                    container
                    spacing={2}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      width="100%"
                      px={1}
                    >
                      <Grid
                        style={{ height: 'fit-content' }}
                        item
                        // xs={1}
                        // lg={2}
                      >
                        <Card
                          style={{
                            width: '100%',
                            padding: '35px 20px',
                            margin: '10px 0'
                          }}
                          className={classes.card}
                        >
                          <Box display="flex" alignItems="center">
                            <Box
                              width="50px"
                              height="50px"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              className={classes.bgIcon}
                            >
                              <MonetizationOn
                                style={{ fontSize: 30 }}
                                color="primary"
                              />
                            </Box>
                            <Box ml={1.5}>
                              <Typography
                                style={{
                                  fontSize: 15,
                                  fontWeight: 600,
                                  color: '#959595'
                                }}
                                variant="body2"
                              >
                                Total efectivo
                              </Typography>
                              <Typography
                                style={{ fontSize: 18, fontWeight: 700 }}
                                color="primary"
                                variant="body2"
                              >
                                $
                                <CurrencyFormat
                                  value={cash}
                                  displayType="text"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                />
                              </Typography>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                      <Grid
                        style={{ height: 'fit-content' }}
                        item
                        // xs={12}
                        // lg={2}
                      >
                        <Card
                          style={{
                            width: '100%',
                            padding: '35px 20px',
                            margin: '10px 0'
                          }}
                          className={classes.card}
                        >
                          <Box display="flex" alignItems="center">
                            <Box
                              width="50px"
                              height="50px"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              className={classes.bgIcon}
                            >
                              <CreditCard
                                style={{ fontSize: 28 }}
                                color="primary"
                              />
                            </Box>
                            <Box ml={1.5}>
                              <Typography
                                style={{
                                  fontSize: 15,
                                  fontWeight: 600,
                                  color: '#959595'
                                }}
                                variant="body2"
                              >
                                Total tarjeta
                              </Typography>
                              <Typography
                                style={{ fontSize: 18, fontWeight: 700 }}
                                color="primary"
                                variant="body2"
                              >
                                $
                                <CurrencyFormat
                                  value={card}
                                  displayType="text"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                />
                              </Typography>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                      <Grid
                        style={{ height: 'fit-content' }}
                        item
                        // xs={12}
                        // lg={2}
                      >
                        <Card
                          className={classes.card}
                          style={{
                            width: '100%',
                            padding: '35px 20px',
                            margin: '10px 0'
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            <Box display="flex" alignItems="center">
                              <Box
                                width="50px"
                                height="50px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                className={classes.bgIcon}
                              >
                                <Assessment
                                  style={{ fontSize: 28 }}
                                  color="primary"
                                />
                              </Box>
                              <Box ml={1.5}>
                                <Typography
                                  style={{
                                    fontSize: 15,
                                    fontWeight: 600,
                                    color: '#959595'
                                  }}
                                  variant="body2"
                                >
                                  Total del día
                                </Typography>
                                <Typography
                                  style={{ fontSize: 18, fontWeight: 700 }}
                                  color="primary"
                                  variant="body2"
                                >
                                  $
                                  <CurrencyFormat
                                    value={totals}
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                  />
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Card>
        </LoadingBox>
      </Container>
    </Page>
  );
};

export default ManagerReportView;
