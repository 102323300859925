import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import {
  Add,
  Remove
} from '@material-ui/icons';

const QuantityInput = ({
  setContMaxQuantity,
  maxItems,
  contMaxQuantity,
  increaseContMax,
  decreaseContMax,
  max
}) => {
  const [quantity, setQuantity] = useState(0);

  const useStyles = makeStyles((theme) => ({
    field: {
      '& > input': {
        border: 'none',
        outline: 'none',
        MozAppearance: 'textfield'
      }
    },
    input: {
      backgroundColor: 'transparent',
      color: '#000',
      fontSize: '13px',
      height: '25px',
      width: '28px',
      textAlign: 'center',
    },
    btn: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50px',
      padding: '3px',
      height: '100%',
      color: '#fff',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#2e7d32'
      },
      '&:active': {
        backgroundColor: '#3e8e41',
        transform: 'scale(0.96)'
      },
      transition: 'background 0.2s ease 0s',
    }
  }));

  const classes = useStyles();

  const increase = () => {
    if (quantity < max && (contMaxQuantity < maxItems || maxItems === 0)) {
      setQuantity(quantity + 1);
      if (maxItems !== 0) increaseContMax(1);
    }
  };

  const decrease = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
      if (maxItems !== 0) decreaseContMax(1);
    }
  };

  const isDisabledIncrease = () => {
    return (quantity < max && (contMaxQuantity < maxItems || maxItems === 0));
  };

  useEffect(() => {
    if (quantity > max) {
      setContMaxQuantity(contMaxQuantity - (quantity - max));
      setQuantity(max);
    }
  }, [max]);

  useEffect(() => {
    setQuantity(0);
  }, [maxItems]);

  return (
    <Box
      className={classes.field}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box onClick={() => decrease()} className={classes.btn} display={quantity > 0 ? 'flex' : 'none'}>
        <Remove style={{ fontSize: '15px' }} />
      </Box>
      <input className={classes.input} type="text" name="quantity" value={quantity} disabled />
      <Box
        style={{ opacity: isDisabledIncrease() ? 1 : 0, cursor: isDisabledIncrease() ? 'pointer' : 'default' }}
        onClick={() => increase()}
        className={classes.btn}
        display="flex"
      >
        <Add style={{ fontSize: '15px' }} />
      </Box>
    </Box>
  );
};

QuantityInput.propTypes = {
  maxItems: PropTypes.number,
  contMaxQuantity: PropTypes.number,
  increaseContMax: PropTypes.func,
  decreaseContMax: PropTypes.func,
  max: PropTypes.number,
  setContMaxQuantity: PropTypes.number
};

export default QuantityInput;
