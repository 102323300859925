import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// MUI components
import { Box, Typography, makeStyles, Button } from '@material-ui/core';

// Images
import defaultProduct from 'src/assets/image/adomi_sent.png';

// Icons
import { ImageOutlined, Edit, Delete } from '@material-ui/icons';

// Hooks
import useModal from 'src/hooks/useModal';

// Components
import Modal from 'src/components/Modal';
import ImageUpload from 'src/components/ImageUploadModal';

const useStyles = makeStyles((theme) => ({
  addImage: {
    marginRight: theme.spacing(1),
    borderRadius: 10,
    maxWidth: 45,
    maxHeight: 45,
    minWidth: 45,
    minHeight: 45,
    padding: 5,
    border: `2px dotted ${theme.palette.primary.main}`
  },
  containerAvatar: {
    objectFit: 'cover',
    marginBottom: theme.spacing(1),
    width: 45,
    height: 45,
    borderRadius: 10,
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`
    }
  },
  avatar: {
    opacity: 1,
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '100%',
    minHeight: '100%'
  },
  previewImage: {
    objectFit: 'cover',
    marginRight: theme.spacing(1),
    width: 150,
    height: 150,
    borderRadius: 10,
    overflow: 'hidden'
  },
  avatarLoad: {
    opacity: 0
  },
  scroll: {
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  },
  bodyModal: {
    paddingTop: 0,
    paddingBottom: 0
  },
  containerButtons: {
    display: 'none',
    alignItems: 'items',
    justifyContent: 'flex-end',
    margin: '6px 3px'
  },
  overlay: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 3,
    backgroundColor: 'transparent',
    '&:hover': {
      opacity: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      '& $containerButtons': {
        display: 'flex'
      }
    },
    width: '100%',
    height: '100%',
    transition: 'opacity 0.5s ease'
  },
  buttonIcon: {
    borderRadius: '50%',
    minWidth: 10,
    padding: 4,
    margin: '0 3px'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white'
    }
  },
  header: {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8
  },
  button: {
    borderRadius: 50,
    width: 165,
    fontSize: 13,
    paddingTop: theme.spacing(0.7),
    paddingBottom: theme.spacing(0.7)
  }
}));

/**
 * MultiImages es un componente que me permite realizar todo el proceso de selección de carga de
 * multiples imagenes.
 * Para el buen funcionamiento del componente se es necesario cada una de las propiedades
 * @prop {Array} previewImages: Un arreglo de objetos que guarda cada una de las imagenes cargadas.
 * @prop {function(images)} onChangePreviewImages: Una función que me devuelve por parametros la lista de
 * imagenes en caso de que haya habido un cambio en alguna de las imagenes.
 * @prop {object} imageSelected: Un objeto que guarda la imagen seleccionada
 * su formato es el siguiente:
  {
    index: (Number) Posición en que se encuentra la imagen dentro de la lista de imagenes,
    image: (File) Intancia de la clase File que guarda la información de la imagen cargada,
    scale: (String) La escala de la imagen (1:1, 3:4, 5:3)
  }
 * @prop {function(image)} onChangeImage: Una función que me devuelve por parametros la imagen seleccionada.
 */

const MultiImages = ({
  previewImages,
  onChangePreviewImages,
  imageSelected,
  onChangeImage
}) => {
  const classes = useStyles();
  const [isOpen, toggle] = useModal();
  // Estado que establece cuando esta cargando alguna imagen
  const [loadingImg, setLoadingImg] = useState(false);
  const [edit, setEdit] = useState(false);

  const handleSave = (data) => {
    const { index, image, scale } = data;
    /*
      Se valida si la acción es crear una imagen o editar una ya existente. Si "index" es igual a -1
      quiere decir que la acción es de crear, por el contratrio se edita la imagen de la posición
      correspondiente al valor que contenga la variable index.
    */
    if (index !== -1) {
      previewImages[index] = {
        image,
        scale
      };
    } else {
      previewImages.push({
        image,
        scale
      });
    }
    onChangePreviewImages(previewImages.slice());
    // Se selecciona la imagen que se creo o edito
    onChangeImage({
      ...data,
      index: index !== -1 ? index : previewImages?.length - 1
    });
    toggle();
  };

  const removeImage = (index) => {
    previewImages.splice(index, 1);
    onChangePreviewImages(previewImages.slice());
    if (index === previewImages.length) {
      onChangeImage({
        ...previewImages[index - 1],
        index: index - 1
      });
      return;
    }
    if (previewImages.length === 0) {
      onChangeImage(null);
      return;
    }
    onChangeImage({
      ...previewImages[index],
      index
    });
  };

  useEffect(() => {
    setLoadingImg(true);
  }, [imageSelected]);

  return (
    <>
      <Box display="flex" flexDirection="column" width="100%">
        <Box
          display="flex"
          flexDirection="row-reverse"
          width="100%"
          className={classes.header}
          position="relative"
        >
          <Box
            width="fit-content"
            display="flex"
            flexDirection="column"
            alingItems="center"
          >
            <Box
              className={classes.scroll}
              mb={1}
              pr={1}
              maxHeight={100}
              overflow="auto"
            >
              {previewImages.length > 0 &&
                previewImages.map((image, index) => (
                  <Box
                    className={classes.containerAvatar}
                    onClick={() => onChangeImage({ ...image, index })}
                    border={
                      index === imageSelected?.index && '2px solid #009739'
                    }
                  >
                    <img
                      src={URL.createObjectURL(image?.image)}
                      className={classes.avatar}
                      alt="product_image"
                    />
                  </Box>
                ))}
            </Box>
            <Button
              className={classes.addImage}
              onClick={() => {
                setEdit(false);
                toggle();
              }}
              size="small"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <ImageOutlined
                  color="primary"
                  style={{
                    fontSize: 20
                  }}
                />
              </Box>
            </Button>
          </Box>
          <Box className={classes.previewImage} position="relative">
            <Box
              display={imageSelected?.image ? 'block' : 'none'}
              className={classes.overlay}
            >
              <Box className={classes.containerButtons}>
                <Button
                  onClick={() => {
                    setEdit(true);
                    toggle();
                  }}
                  className={classes.buttonIcon}
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  <Edit style={{ fontSize: '16px' }} />
                </Button>
                <Button
                  onClick={() => removeImage(imageSelected?.index)}
                  className={clsx(classes.buttonIcon, classes.error)}
                  variant="contained"
                  size="small"
                >
                  <Delete style={{ fontSize: '16px' }} />
                </Button>
              </Box>
            </Box>
            {loadingImg && (
              <Box
                position="absolute"
                zIndex={1}
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="#fff"
                borderRadius={10}
              >
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 500
                  }}
                  color="primary"
                >
                  Cargando...
                </Typography>
              </Box>
            )}
            <img
              onLoad={() => setLoadingImg(false)}
              loading="lazy"
              src={
                imageSelected?.image
                  ? URL.createObjectURL(imageSelected?.image)
                  : defaultProduct
              }
              className={clsx(classes.avatar, {
                [classes.avatarLoad]: loadingImg
              })}
              alt="product_image"
            />
          </Box>
        </Box>
      </Box>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        title="Cargar imagen"
        bodyClass={classes.bodyModal}
      >
        <ImageUpload
          onSave={handleSave}
          image={edit ? imageSelected?.image : null}
          scale={edit ? imageSelected?.scale : '3:4'}
          index={edit ? imageSelected?.index : -1}
        />
      </Modal>
    </>
  );
};

MultiImages.propTypes = {
  previewImages: PropTypes.array.isRequired,
  onChangePreviewImages: PropTypes.func.isRequired,
  imageSelected: PropTypes.shape({
    index: PropTypes.number,
    image: PropTypes.instanceOf(File),
    scale: PropTypes.oneOf(['5:3', '3:4', '1:1'])
  }).isRequired,
  onChangeImage: PropTypes.func.isRequired
};

export default MultiImages;
