import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import {
  GoogleMap, Marker, withGoogleMap, withScriptjs
} from 'react-google-maps';
import GoogleMapsApiService from 'src/services/GoogleMapsApiService';
import { Check, Edit, Room } from '@material-ui/icons';
import AddressSearch from './AddressSearch';

const useStyles = makeStyles(() => ({
  iconTitle: {
    fontSize: 27,
    marginRight: 2
  },
  address: {
    fontSize: 16,
    fontWeight: 500
  },
  button: {
    margin: '0 5px',
    textTransform: 'capitalize',
    width: 210,
    height: 'fit-content'
  }
}));

const GoogleMapsAddress = withScriptjs(withGoogleMap(({
  styles,
  changeElement,
  confirmAddress,
  address,
  positionAddress,
  handleAddress,
  confirmation
}) => {
  const classes = useStyles();
  const [step, setStep] = useState(address.length !== 0 && confirmation ? 1 : 0);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [addressText, setAddressText] = useState(address);
  const [center, setCenter] = useState(positionAddress);

  const handleChange = (data) => {
    setAddressText(data);
  };

  const handleCenter = (latitude, longitude) => {
    setCenter({ lat: latitude, lng: longitude });
  };

  const handleDragged = async (e) => {
    setLoadingAddress(true);
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    const response = await GoogleMapsApiService.getAddressByPosition(lat, lng);
    if (response?.status === 200 || response?.status === 201) {
      setLoadingAddress(false);
      setAddressText(response?.data?.results[0]?.formatted_address);
      setCenter({ lat, lng });
    }
  };

  const nextStep = () => {
    setStep((prev) => prev + 1);
    changeElement(step + 1);
  };

  const prevStep = () => {
    setStep((prev) => prev - 1);
    changeElement(step - 1);
  };

  return (
    <>
      {step === 0 && (
        <AddressSearch
          address={addressText}
          handleChange={handleChange}
          handlePosition={handleCenter}
          nextStep={confirmation ? nextStep : () => {}}
          handleAddress={handleAddress}
        />
      )}
      {step === 1 && (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          justifyContent="center"
        >
          <Box
            display="flex"
            alignItems="center"
            py={2}
            width="100%"
            justifyContent="center"
          >
            {loadingAddress ? (
              <CircularProgress size={30} />
            ) : (
              <>
                <Room
                  color="primary"
                  className={classes.iconTitle}
                />
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.address}
                >
                  {addressText}
                </Typography>
              </>
            )}
          </Box>
          <GoogleMap
            on
            center={center}
            defaultZoom={16}
            defaultOptions={{
              styles
            }}
          >
            <Marker
              draggable
              onDragEnd={handleDragged}
              position={center}
            />
          </GoogleMap>
          <Box
            display="flex"
            justifyContent="center"
          >
            <Button
              disabled={loadingAddress}
              variant="contained"
              color="primary"
              onClick={() => prevStep()}
              className={classes.button}
              startIcon={<Edit />}
            >
              Editar dirección
            </Button>
            <Button
              disabled={loadingAddress}
              variant="contained"
              color="primary"
              onClick={() => confirmAddress(addressText, center)}
              className={classes.button}
              startIcon={<Check />}
            >
              Confirmar dirección
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}));

GoogleMapsAddress.propTypes = {
  styles: PropTypes.array,
  changeElement: PropTypes.func,
  confirmAddress: PropTypes.func,
  address: PropTypes.string,
  positionAddress: PropTypes.object,
  handleAddress: PropTypes.func,
  confirmation: PropTypes.bool
};

export default GoogleMapsAddress;
