import React, { useState, useEffect } from 'react';
import CustomBrandService from 'src/services/CustomBrandService';
import { useSelector } from 'react-redux';
import { selector as UserSelector } from 'src/redux/ducks/user';
import useSearch from 'src/hooks/useSearch';
import {
  Container,
  Box,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Brand from './Brand';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const BrandProduct = () => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [brand, setBrand] = useState([]);
  const { dataFilterd, handleSearch, handleInitialData } = useSearch({ keys: ['name'] });
  const getBrands = async () => {
    const response = await CustomBrandService.getAllByPartnerId(user.id);
    if (response?.code === 200) {
      setBrand(response?.data);
      handleInitialData(response?.data);
    }
  };
  useEffect(() => {
    getBrands();
  }, []);
  const refresh = () => {
    getBrands();
  };
  useEffect(() => {
    if (Array.isArray(dataFilterd)) {
      setBrand(dataFilterd);
    }
  }, [dataFilterd]);

  return (
    <Page className={classes.root} title="Gestión de personalización">
      <Container>
        <Toolbar refresh={refresh} brand={brand} onSearch={handleSearch} />
        <Box width="100%" display="flex" mt={3}>
          <Grid container spacing={2}>
            {brand.length > 0 ? (
              brand.map((item) => (
                <Grid item md={6} xs={12}>
                  <Brand data={item} refresh={refresh} />
                </Grid>
              ))
            ) : (
              <Box ml={1.5}>
                <Typography>No hay marcas registradas</Typography>
              </Box>
            )}
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default BrandProduct;
