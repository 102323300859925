import app from './ducks/app';
import user from './ducks/user';
import modal from './ducks/modal';
import errors from './ducks/errors';
import alert from './ducks/alert';

export default {
  app,
  user,
  modal,
  errors,
  alert,
};
