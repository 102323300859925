import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  makeStyles,
  TextField,
  InputAdornment
} from '@material-ui/core';
import ProductsLogisticService from 'src/services/ProductsLogisticService';
import CurrencyFormat from 'react-currency-format';
import OptionSize from './components/OptionSize';
import OptionPay from './components/OptionPay';

const useStyles = makeStyles((theme) => ({
  scroll: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  },
  textField: {
    '& .MuiInputBase-root': {
      height: 35,
    }
  },
  scaleAnimation: {
    animation: '$scale 0.7s ease both'
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(0)'
    },
    '30%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

const CommodityManagment = ({
  productLogistic,
  setProductLogistic,
  setInsurance,
  setCollect,
  collect,
  insurance
}) => {
  const classes = useStyles();
  const [productsLogistic, setProductsLogistic] = useState([]);
  const changePrice = (e) => {
    setProductLogistic({
      ...productLogistic,
      price: e?.target?.value,
    });
  };

  const changeProduct = (e) => {
    const product = productsLogistic.find((item) => item.product_id === e?.target?.value);
    setProductLogistic({
      ...product,
      price: productLogistic?.price
    });
  };

  const getProductsLogistic = async () => {
    const response = await ProductsLogisticService.getAll();
    if (response?.code === 200) {
      setProductsLogistic(response?.data);
      setProductLogistic({
        ...response?.data[0],
        price: '0'
      });
    }
  };

  useEffect(() => {
    getProductsLogistic();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="74%"
      width="100%"
    >
      <Box
        className={classes.scroll}
        display="flex"
        flexDirection="column"
        width="100%"
        maxHeight="100%"
        mb={2}
        mt={3}
      >
        <Box
          px={1.5}
          pt={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            color="primary"
            variant="body2"
            style={{
              fontSize: 14,
            }}
          >
            Elija el tamaño del paquete
          </Typography>
          <Box ml={1}>
            <CurrencyFormat
              className={classes.textField}
              thousandSeparator="."
              decimalSeparator=","
              customInput={TextField}
              fullWidth
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 14
                }
              }}
              InputProps={{
                style: {
                  fontSize: 14
                },
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              placeholder="Valor de la mercancía"
              label="Valor de la mercancía"
              name="price"
              size="small"
              variant="outlined"
              value={productLogistic?.price}
              onChange={changePrice}
            />
          </Box>
        </Box>
        {productLogistic && productLogistic?.product_id?.length > 0 && (
          <Box
            mt={1.5}
            px={1}
          >
            <Box
              bgcolor="#d4edda"
              textAlign="center"
              borderRadius={5}
              p={1}
              className={classes.scaleAnimation}
            >
              <Typography
                color="primary"
                variant="body2"
                style={{
                  fontSize: 12
                }}
              >
                {productLogistic?.description}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          mt={1}
          display="flex"
          flexWrap="wrap"
        >
          {productsLogistic?.map((item) => (
            <OptionSize
              key={item?.product_id}
              value={item}
              changeProduct={changeProduct}
              selectProduct={productLogistic}
            />
          ))}
        </Box>
        <Box>
          <OptionPay
            selectProduct={Number(productLogistic?.price?.replace('.', '')) || 0}
            setInsurance={setInsurance}
            insurance={insurance}
            setCollect={setCollect}
            collect={collect}
          />
        </Box>
      </Box>
    </Box>
  );
};

CommodityManagment.propTypes = {
  productLogistic: PropTypes.object,
  setProductLogistic: PropTypes.func,
  setInsurance: PropTypes.func,
  insurance: PropTypes.bool,
  setCollect: PropTypes.func,
  collect: PropTypes.string
};

export default CommodityManagment;
