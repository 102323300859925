import React, { useEffect, useState, useReducer } from 'react';
import clsx from 'clsx';

// MUI components
import { Container, makeStyles } from '@material-ui/core';

// Services
import BranchOfficeService from 'src/services/BranchOfficeService';
import StockService from 'src/services/StockService';

// Components
import Page from 'src/components/Page';
import LoadingBox from 'src/components/LoadingBox';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: '#f6f6fa',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  container: {
    width: '100%',
    height: '100%'
  },
  loading: {
    height: '100%'
  }
}));

// Init state
const INITIAL_STATE = [];

// Types
const SET_STOCK = 'set_stock';
const ADD_QUANTITY = 'add_quantity';
const UPDATE_SIZES = 'update_size';
const TOGGLE_SHOW_SIZES = 'toggle_show_sizes';

// Actions
const actionSetStock = (stock) => {
  return {
    type: SET_STOCK,
    payload: stock
  };
};

const actionAddQuantity = (indexStock, indexSize, quantity) => {
  return {
    type: ADD_QUANTITY,
    payload: {
      indexStock,
      indexSize,
      quantity
    }
  };
};

const actionUpdateSizes = (indexStock, data) => {
  return {
    type: UPDATE_SIZES,
    payload: {
      indexStock,
      data
    }
  };
};

const actionToggleShowSizes = (indexStock) => {
  return {
    type: TOGGLE_SHOW_SIZES,
    payload: {
      indexStock
    }
  };
};

const stockReducer = (state, action) => {
  if (action.type === SET_STOCK) {
    return action.payload;
  }
  if (action.type === ADD_QUANTITY) {
    const { indexStock, indexSize, quantity } = action.payload;
    const copyStock = state.slice();
    copyStock[indexStock].size_quantities[indexSize].quantity += quantity;
    return copyStock;
  }
  if (action.type === UPDATE_SIZES) {
    const { indexStock, data } = action.payload;
    const copyStock = state.slice();
    copyStock[indexStock].size_quantities = data;
    return copyStock;
  }
  if (action.type === TOGGLE_SHOW_SIZES) {
    const { indexStock } = action.payload;
    const copyStock = state.slice();
    copyStock[indexStock].showSizes = !copyStock[indexStock].showSizes;
    return copyStock;
  }
  return state;
};

const StockView = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [stock, dispatch] = useReducer(stockReducer, INITIAL_STATE);
  const [branchOffices, setBranchOffices] = useState([]);
  const [branchOffice, setBranchOffice] = useState({
    id: '',
    name: ''
  });
  const [page, setPage] = useState(0);

  const getBranchOffices = async () => {
    const response = await BranchOfficeService.getAll();
    if (response?.code === 200) {
      setBranchOffices(response?.data);
      setBranchOffice(response?.data[0]);
    }
  };

  /**
   * filterStock me filtar aquellos productos del stock que tengan tallas asignadas
   * @param Array dataStock: El stock completo de la base de datos
   * @returns Array: me retorna un nuevo array con el stock filtrado
   */
  const filterStock = (dataStock) => {
    return dataStock?.filter(
      (item) => item?.size_quantities && item?.size_quantities?.length > 0
    );
  };

  const getStock = async () => {
    setLoading(true);
    const response = await StockService.getAllByBranchOffice(branchOffice?.id);
    if (response?.code === 200) {
      const stockFilter = filterStock(response?.data);
      dispatch(
        actionSetStock(
          stockFilter.map((item) => {
            return {
              ...item,
              showSizes: false
            };
          })
        )
      );
      setLoading(false);
    }
  };

  const addQuantity = (indexStock, indexSize, quantity) => {
    dispatch(actionAddQuantity(indexStock, indexSize, quantity));
  };

  const updateSizes = (indexStock, data) => {
    dispatch(actionUpdateSizes(indexStock, data));
  };

  const toggleShowSizes = (indexStock) => {
    dispatch(actionToggleShowSizes(indexStock));
  };

  useEffect(() => {
    getBranchOffices();
  }, []);

  useEffect(() => {
    if (branchOffice?.id) getStock();
  }, [branchOffice]);

  return (
    <Page
      className={clsx(classes.root, {
        [classes.loading]: loading
      })}
      title="Productos"
    >
      <Container className={classes.container} maxWidth={false}>
        <Toolbar
          branchOffices={branchOffices}
          branchOffice={branchOffice}
          handleBranchOffice={setBranchOffice}
        />
        <LoadingBox
          loading={loading}
          bgcolor="transparent"
          title="Cargando Inventario..."
        >
          <Results
            idBranchOffice={branchOffice?.id}
            addQuantity={addQuantity}
            updateSizes={updateSizes}
            toggleShowSizes={toggleShowSizes}
            data={stock}
            page={page}
            setPage={setPage}
          />
        </LoadingBox>
      </Container>
    </Page>
  );
};

export default StockView;
