import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';

import { selector as UserSelector } from '../../../redux/ducks/user';

import ProductComponentService from '../../../services/ProductComponentService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ProductListView = () => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [components, setComponents] = useState([]);

  const getProductComponents = async () => {
    const response = await ProductComponentService.getByBrandId(user?.brand_id);

    if (response?.code === 200) {
      setComponents(response?.data);
    }
  };

  const refresh = async () => {
    await getProductComponents();
  };

  const handleDuplicate = async (component) => {
    if (component?.name?.includes('- Copia')) {
      try {
        const response = await ProductComponentService.create(component);

        if (response?.code !== 201) return;

        refresh();
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (user?.brand_id) {
      getProductComponents();
    }
  }, [user]);

  return (
    <Page
      className={classes.root}
      title="Componentes de productos"
    >
      <Container maxWidth={false}>
        <Toolbar refresh={refresh} />
        <Results data={components} onDuplicate={handleDuplicate} />
      </Container>
    </Page>
  );
};

export default ProductListView;
