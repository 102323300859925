import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { actions as UserActions } from '../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    background: '#fffefe',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;'
  },
  avatar: {
    width: 60,
    height: 60
  },
  appBarShift: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 256px)',
      marginLeft: 256,
    }
  },
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  open,
  ...rest
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [notifications] = useState([]);

  const closeSession = () => {
    dispatch(UserActions.clear());
    navigate('/auth', { replace: true });
  };

  return (
    <AppBar
      className={clsx(
        classes.root,
        className,
        {
          [classes.appBarShift]: !open
        }
      )}
      position="fixed"
      {...rest}
    >
      <Toolbar>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <IconButton
            color="primary"
            onClick={() => onMobileNavOpen((prev) => !prev)}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" width={130} height={38}>
            <img src="/static/logo_aliados_green.svg" alt="adomi_partners_logo" />
          </Box>
          <Box
            display="flex"
            alignItems="center"
          >
            <IconButton color="primary">
              <Badge
                badgeContent={notifications.length}
                color="primary"
                variant="dot"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton color="primary" onClick={closeSession}>
              <InputIcon />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  open: PropTypes.bool
};

export default TopBar;
