import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selector as UserSelector } from 'src/redux/ducks/user';
import SizesService from 'src/services/SizesService';
import { 
  Container,
  Box,
  Grid,
  Typography,
  makeStyles
 } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Size from './Size';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))
const SizeProduct = () => {
  const classes = useStyles();
  const [size, setSize] = useState([]);
  const { user } = useSelector(UserSelector);
  const getSizes = async () => {
    const response = await SizesService.getByBrand(user.brand_id)
    if (response?.code === 200) {
      setSize(response?.data);
    }
  };
  useEffect(() => {
    getSizes();
  },[]);
  const refresh = () => {
    getSizes();
  }
  return (
  <Page
    className={classes.root}
    title="Gestión de personalización"
  >
    <Container>
      <Toolbar refresh={refresh} />
      <Box
        width="100%"
        display="flex" 
        mt={3}
      >
        <Grid container spacing={2}>
          {size.length > 0 ? (
            size.map((item) => (
              <Grid item md={6} xs={12}>
                <Size
                  data={item}
                  refresh={refresh}
                />
              </Grid>
           ))     
          )
          :
          (
            <Box>
              <Typography>
                No hay tallas registradas
              </Typography>
            </Box>
          )
          }
        </Grid>
      </Box>
    </Container>
  </Page>
  )
}

export default SizeProduct