import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import ColorService from 'src/services/ColorService';
import LoadingBox from 'src/components/LoadingBox';
import { useSelector } from 'react-redux';
import { selector as UserSelector } from '../../../redux/ducks/user';
import Head from './Head';
import Cards from './Cards';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: '#f6f6fa',
    color: theme.palette.primary.main,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ProductColors = () => {
  const { user } = useSelector(UserSelector);
  const [colores, setColores] = useState(null);

  const getColor = async () => {
    const Colors = await ColorService.getByBrand(user.brand_id);
    if (Colors?.code === 200) {
      setColores(Colors?.data);
    }
  };

  useEffect(() => {
    getColor();
  }, []);

  const refresh = () => {
    getColor();
  };

  const classes = useStyles();

  return (
    <Page className={classes.root} title="Colores">
      <LoadingBox>
        <Container>
          <Head refresh={refresh} />
          {colores?.length > 0 ? (
            <Grid container spacing={3} item xs={12} md={12}>
              {colores.map((color) => (
                <Cards data={color} refresh={refresh} />
              ))}
            </Grid>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={7}
            >
              <Typography variant="h2" color="primary">
                No hay colores registrados
              </Typography>
            </Box>
          )}
        </Container>
      </LoadingBox>
    </Page>
  );
};

export default ProductColors;
