import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Switch,
  Typography
} from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';

const ProductCard = ({
  toggleAvailableProduct,
  product,
  detailPrice
}) => {
  const useStyles = makeStyles((theme) => ({
    product: {
      position: 'relative',
      width: '100%',
      borderRadius: 10,
      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    },
    media: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      WebkitFilter: product?.is_available ? 'none' : 'grayscale(1)', /* Webkit */
      filter: product?.is_available ? 'none' : 'grayscale(1)', /* W3C */
      transition: 'all 0.5s ease'
    },
    price: {
      color: product?.is_available ? theme.palette.primary.main : '#A5A5A5',
      transition: 'all 0.5s ease',
      fontWeight: 600,
      marginTop: '12px',
      fontSize: 16
    },
    label: {
      color: product?.is_available ? theme.palette.primary.main : '#555',
      fontWeight: 500,
      marginLeft: 3,
      fontSize: '1rem',
      transition: 'all 0.7s ease'
    },
    bgCard: {
      backgroundColor: product?.is_available ? 'none' : 'rgba(240, 240, 240, 0.4)',
      pointerEvents: 'none',
      transition: 'all 0.5s ease'
    },
  }));

  const classes = useStyles();

  return (
    <Card className={classes.product}>
      <Box position="absolute" zIndex={1} width="100%" height="100%" className={classes.bgCard} />
      <Box display="flex" flexDirection="column" p={2} pl={0} pt={1}>
        <Box pl={0.5} display="flex" alignItems="center">
          <Switch
            className={classes.toggle}
            color="primary"
            checked={product?.is_available}
            onChange={() => product && toggleAvailableProduct(product?.id)}
            name="product_available"
          />
          <Typography
            className={classes.label}
            variant="body2"
          >
            {product?.is_available ? 'Activado' : 'Desactivado'}
          </Typography>
        </Box>
        <Box display="flex" mt={1} pl={2}>
          <Box display="flex" height={140} minWidth={200} maxWidth={200} width={200} borderRadius={10} overflow="hidden">
            <img
              className={classes.media}
              src={product?.primary_image}
              alt={product.name}
            />
          </Box>
          <CardContent style={{ paddingTop: 0 }}>
            <Typography style={{ fontWeight: 600 }} variant="h5" component="h2">
              {product.name}
            </Typography>
            <Typography
              style={{
                fontSize: '12px',
                marginTop: '2px',
                color: '#999',
                fontWeight: 600
              }}
              variant="body2"
            >
              <span>{detailPrice?.name}</span>
              {detailPrice?.description?.length > 0 && (
                <>
                  {` (${detailPrice?.description})`}
                </>
              )}
            </Typography>
            <Typography
              className={classes.price}
              variant="body2"
            >
              $
              <CurrencyFormat
                value={product?.has_discount
                  ? product?.discount?.discount_price
                  : product?.price}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
              />
            </Typography>
          </CardContent>
        </Box>
      </Box>
    </Card>
  );
};

ProductCard.propTypes = {
  toggleAvailableProduct: PropTypes.func,
  product: PropTypes.object,
  detailPrice: PropTypes.object
};

export default ProductCard;
