import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  makeStyles,
  Button,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
  ClickAwayListener,
  Typography,
  TextField
} from '@material-ui/core';
import {
  Edit,
  MoreHoriz,
  Timer,
  TimerOff,
  Add,
  Check,
  Close
} from '@material-ui/icons';
import CurrencyFormat from 'react-currency-format';
import ProductService from 'src/services/ProductService';
import Panel from './Panel';

const useStyles = makeStyles((theme) => ({
  containerAvatar: {
    objectFit: 'cover',
    marginRight: theme.spacing(2),
    width: 60,
    height: 60,
    borderRadius: 10,
    overflow: 'hidden'
  },
  avatar: {
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '100%',
    minHeight: '100%'
  },
  statusCell: {
    position: 'relative'
  },
  nameProduct: {
    fontSize: 15,
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  descripProduct: {
    color: '#A5A5A5',
    fontSize: 12,
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  row: {
    background: '#fff',
    '& td:first-child': {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10
    },
    '& td:last-child': {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10
    }
  },
  buttonIcon: {
    borderRadius: '50%',
    minWidth: 10,
    padding: 5,
    color: '#fff'
  },
  modal: {
    boxShadow:
      'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;',
    borderRadius: 25
  },
  bodyModal: {
    padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
    paddingRight: theme.spacing(1.5)
  },
  buttonFake: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    minWidth: 15,
    minHeight: 15,
    padding: 1,
    marginLeft: 8,
    color: '#fff',
    cursor: 'pointer',
    transition: 'all 0.3s ease'
  },
  buttonFakes: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    maxWidth: 20,
    maxHeight: 20,
    padding: 5,
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    marginLeft: 25,
    cursor: 'pointer'
  },
  input: {
    '& .MuiInputBase-root': {
      boxSizing: 'border-box',
      borderRadius: 6,
      backgroundColor: '#F5F7FB',
      border: '1px solid rgb(216, 216, 216)',
      '&.Mui-focused': {
        border: '0.13em solid #009739'
      },
      '&.Mui-error': {
        border: '0.13em solid #f44336'
      }
    },
    '& .MuiInputBase-root::after, & .MuiInputBase-root::before': {
      display: 'none'
    },
    '& .MuiFilledInput-input': {
      paddingTop: theme.spacing(1.1),
      paddingBottom: theme.spacing(1.1),
      fontFamily: '"Poppins", sans-serif',
      fontSize: 13,
      color: 'rgba(0, 0, 0, 0.8)',
      fontWeight: 500
    },
    '& .MuiFilledInput-input.Mui-disabled': {
      color: '#a8aab4'
    },
    '& .MuiFilledInput-input:-webkit-autofill': {
      borderRadius: 7
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
      marginTop: 0
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiInputAdornment-root': {
        transition: 'color 0.3s ease',
        color: '#8c9baf',
        '& .MuiIconButton-label': {
          transition: 'color 0.3s ease',
          color: '#8c9baf'
        }
      },
      '&.Mui-focused .MuiInputAdornment-root': {
        color: '#009739',
        '& .MuiIconButton-label': {
          color: '#009739'
        }
      }
    }
  },
  label: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 13,
    fontWeight: 500,
    color: '#a8aab4'
  },
  scale: {
    animation: '$scale 0.7s ease both'
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(0)'
    },
    '10%': {
      transform: 'scale(0)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

const useStylesTooltip = makeStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd',
    padding: 0
  }
}));

const RowProduct = ({
  products,
  product,
  setProductSelected,
  toggle,
  toggleAvailableProduct,
  tooltipOpen,
  tooltipTitle,
  categoryIsAvailable,
  showAddColor,
  onRefresh
}) => {
  const classes = useStyles();
  const classesToolTip = useStylesTooltip();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isEnable, setIsEnable] = useState(true);
  const [orders, setOrders] = useState(null);

  const goToEdit = (productId) => {
    navigate(`/app/productos/${productId}`, { replace: true });
  };

  const handleShowTooltip = (value) => {
    setOpen(value);
  };

  const handleUpdate = async (id, data) => {
    const errors = [];
    const newData = {
      name: data.name,
      description: data.description,
      prices: data.prices,
      order: parseInt(orders, 10)
    };

    if (newData?.order === 0) {
      errors.push('El orden no puede ser 0');
    }

    if (newData?.order === null) {
      errors.push('El orden no puede ser nulo');
    }

    if (newData?.order?.toString().includes(' ')) {
      errors.push('El orden no puede tener espacios');
    }

    if (newData?.order === undefined) {
      errors.push('El orden no puede ser indefinido');
    }

    if (Number.isNaN(newData?.order)) {
      errors.push('El orden debe de ser numerico');
    }

    if (newData?.order < 0) {
      errors.push('El orden no puede ser negativo');
    }

    if (errors.length > 0) {
      setIsEnable(!isEnable);
      return;
    }

    const response = await ProductService.update(id, newData);
    if (response.code === 200) {
      setIsEnable(!isEnable);
      onRefresh();
    }
  };

  const handleClose = () => {
    setOrders(product?.order);
    setIsEnable(!isEnable);
  };

  return (
    <TableRow className={classes.row} key={product.id}>
      <TableCell style={{ width: 100 }}>
        <Box mb={1}>
          <TextField
            className={classes.input}
            fullWidth
            disabled={isEnable}
            defaultValue={product?.order}
            InputLabelProps={{
              className: classes.label
            }}
            value={orders}
            name="order"
            type="text"
            placeholder="Cantidad"
            size="small"
            variant="filled"
            onChange={(e) => setOrders(e.target.value)}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Box>
        <Box display="flex" className={classes.scale}>
          {!isEnable ? (
            <>
              <Box className={classes.scale}>
                <Button
                  onClick={() => handleUpdate(product?.id, product)}
                  className={classes.buttonFake}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  <Check style={{ fontSize: '16px' }} />
                </Button>
              </Box>
              <Box className={classes.scale}>
                <Button
                  onClick={() => handleClose()}
                  className={classes.buttonFake}
                  variant="contained"
                  style={{ backgroundColor: '#f44336' }}
                >
                  <Close style={{ fontSize: '16px' }} />
                </Button>
              </Box>
            </>
          ) : (
            <Box
              onClick={() => setIsEnable(false)}
              className={classes.buttonFakes}
              color="primary"
            >
              <Edit style={{ fontSize: '14px' }} />
            </Box>
          )}
        </Box>
      </TableCell>
      <TableCell
        style={{
          width: 270
        }}
      >
        <Box display="flex" alignItems="center">
          <Box className={classes.containerAvatar}>
            <img
              src={product.primary_image}
              className={classes.avatar}
              alt="product_image"
            />
          </Box>
          <Box width={270}>
            <Typography variant="body2" className={classes.nameProduct}>
              {product.name}
            </Typography>
            <Typography variant="body2" className={classes.descripProduct}>
              {product.description}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      {products?.some((item) => item?.business === 'Ropa/Calzado') && (
        <TableCell style={{ width: 120 }}>
          {product?.business === 'Ropa/Calzado' && (
            <Box display="flex" flexDirection="column" width={130}>
              {product?.sizes?.length > 0 && (
                <Typography variant="body2" className={classes.descripProduct}>
                  Tallas:{' '}
                  {product?.sizes?.map((size, i) => (
                    <span>
                      {size}
                      {i === product?.sizes.length - 1 ? '' : ', '}
                    </span>
                  ))}
                </Typography>
              )}
              <Box display="flex" alignItems="center" mt={0.5}>
                {product?.color && (
                  <Box
                    mx={0.2}
                    bgcolor={`#${product?.color?.hexadecimal}`}
                    width={16}
                    height={16}
                    borderRadius="50%"
                    border="1px solid rgba(0, 0, 0, 0.1)"
                  />
                )}
                {showAddColor && (
                  <Box ml={0.5}>
                    <Button
                      onClick={() => {
                        setProductSelected(product);
                        toggle();
                      }}
                      className={classes.buttonIcon}
                      style={{
                        padding: 2
                      }}
                      variant="contained"
                      size="small"
                      color="primary"
                    >
                      <Add style={{ fontSize: '17px' }} />
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </TableCell>
      )}
      <TableCell>
        <Typography variant="body2" className={classes.nameProduct}>
          <CurrencyFormat
            value={product?.price}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix="$"
          />
        </Typography>
      </TableCell>
      <TableCell>
        <Tooltip title={tooltipTitle} open={tooltipOpen === product?.id}>
          <Switch
            color="primary"
            checked={product?.is_available}
            onChange={() => product && toggleAvailableProduct(product?.id)}
            name="product_available"
            disabled={categoryIsAvailable}
          />
        </Tooltip>
      </TableCell>
      <TableCell>
        <Grid item container justify="center" alignItems="center">
          {product?.show_by_schedule ? (
            <Timer color="primary" />
          ) : (
            <TimerOff color="primary" />
          )}
        </Grid>
      </TableCell>
      <TableCell>
        <Grid item container justify="center" alignItems="center">
          <Button
            onClick={() => goToEdit(product?.id)}
            className={classes.buttonIcon}
            variant="contained"
            size="small"
            color="primary"
          >
            <Edit style={{ fontSize: '16px' }} />
          </Button>
        </Grid>
      </TableCell>
      <TableCell className={classes?.statusCell}>
        <ClickAwayListener onClickAway={() => handleShowTooltip(false)}>
          <Box>
            <Tooltip
              classes={classesToolTip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              interactive
              open={open}
              onClose={() => handleShowTooltip(false)}
              title={
                <Panel
                  primaryImage={product?.primary_image}
                  productId={product?.id}
                  onRefresh={onRefresh}
                />
              }
            >
              <Button
                onClick={() => handleShowTooltip(true)}
                className={classes.buttonIcon}
                style={{
                  borderRadius: 10
                }}
                variant="contained"
                size="small"
                color="primary"
              >
                <MoreHoriz style={{ fontSize: '17px' }} />
              </Button>
            </Tooltip>
          </Box>
        </ClickAwayListener>
      </TableCell>
    </TableRow>
  );
};

RowProduct.propTypes = {
  products: PropTypes.array,
  product: PropTypes.object,
  setProductSelected: PropTypes.func,
  toggle: PropTypes.func,
  toggleAvailableProduct: PropTypes.func,
  tooltipOpen: PropTypes.string,
  tooltipTitle: PropTypes.string,
  categoryIsAvailable: PropTypes.bool,
  showAddColor: PropTypes.bool,
  onRefresh: PropTypes.func
};

RowProduct.defaultProps = {
  showAddColor: false
};

export default RowProduct;
