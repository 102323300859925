import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import CurrencyFormat from 'react-currency-format';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Chip,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles, TablePagination
} from '@material-ui/core';
import { MoreVert as MoreVertIcon, ListAlt as ListAltIcon } from '@material-ui/icons';
// utils
import orderBy from 'src/utils/arrays';
// service
import OrderService from 'src/services/OrderService';
import OrderDetailModal from '../../../components/OrderDetailModal';

const useStyles = makeStyles((theme) => ({
  root: {},
  moreVert: {
    cursor: 'pointer'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main,
  },
  false: {
    fill: '#aaa'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd',
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  },
  pending: {
    backgroundColor: '#FBC02D',
    color: 'white',
  },
  rejected: {
    backgroundColor: 'red',
    color: 'white'
  },
  accepted: {
    backgroundColor: '#43a047',
    color: 'white',
  },
  finished: {
    backgroundColor: '#1565C0',
    color: 'white',
  },
  in_process: {
    backgroundColor: '#eee'
  },
  sent: {
    color: 'white',
    backgroundColor: '#7B1FA2',
  }
}));

const Results = ({
  className, data, onRefresh, ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [panel, setPanel] = useState(null);
  const [panelPosition, setPanelPosition] = useState({ x: 0, y: 0 });
  const [orders, setOrders] = useState([]);
  const [detail, setDetail] = useState(null);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onClick = (e, order) => {
    setPanel(panel ? null : order?.id);
    setPanelPosition(panel ? { x: 0, y: 0 } : { x: e.clientX, y: e.clientY });
  };

  const closePanel = () => {
    setTimeout(() => {
      setPanel(null);
      setPanelPosition({ x: 0, y: 0 });
    }, 50);
  };

  const updateStatus = async (status) => {
    if (!panel) return;

    const response = await OrderService.updateStatus(panel, { status });

    if (response?.success) {
      onRefresh();
    }
  };

  const translate = (status) => {
    switch (status) {
      case 'CANCELLED':
        return 'Cancelada';
      case 'IN_PROGRESS':
        return 'En proceso';
      case 'PENDING_TO_BE_SENT':
        return 'Por enviar';
      case 'REJECTED':
        return 'Rechazada';
      case 'FINISHED':
        return 'Completada';
      case 'SENT':
        return 'En camino';
      case 'SENT_LOCAL':
        return 'En camino (local)';
      default:
        return 'Pendiente';
    }
  };

  const statusOrder = (status) => {
    switch (status) {
      case 'CANCELLED':
        return 'in_process';
      case 'IN_PROGRESS':
        return 'accepted';
      case 'PENDING_TO_BE_SENT':
        return 'accepted';
      case 'REJECTED':
        return 'rejected';
      case 'FINISHED':
        return 'finished';
      case 'SENT':
        return 'sent';
      case 'SENT_LOCAL':
        return 'sent';
      default:
        return 'pending';
    }
  };
  const translatePaymentMethod = (method) => {
    switch (method) {
      case 'cash':
        return 'Efectivo';
      case 'card':
        return 'Tarjeta';
      case 'datafono':
        return 'Datafono';
      default:
        return method;
    }
  };

  const handleOrderDetailClose = () => {
    setDetail(null);
  };

  /*
  const removeOrder = async (order) => {
    if (['in_process']?.includes(order?.status)) {
      // eslint-disable-next-line
      alert('No es posible eliminar un registro que se encuentre en proceso')
      return;
    }

    const response = await OrderService.remove(order?.id);

    if (!response?.code) {
      onRefresh();
    }
  };
  */

  const getHour = (hour) => {
    const hh = dayjs(hour).get('hour');
    const mm = dayjs(hour).get('minute');

    // return `${hh}:${mm < 10 ? `0${mm}` : mm}`;
    return `${hh < 13 ? hh : hh - 12}:${mm < 10 ? `0${mm}` : mm} ${hh < 12 ? 'AM' : 'PM'}`;
  };

  useEffect(() => {
    if (panel) {
      document.addEventListener('click', closePanel);
    }

    return () => {
      document.removeEventListener('click', closePanel);
    };
  }, [panel]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setOrders(() => data);
    }
  }, [data]);

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="100">
                    Referencia
                  </TableCell>
                  <TableCell>
                    Comercio
                  </TableCell>
                  <TableCell>
                    Metodo de pago
                  </TableCell>
                  <TableCell width="110" />
                  <TableCell width="100">
                    Inicio
                  </TableCell>
                  <TableCell width="100">
                    Cierre
                  </TableCell>
                  <TableCell width="120">
                    Monto
                  </TableCell>
                  <TableCell width="120">
                    Estado
                  </TableCell>
                  <TableCell width="20" />
                  <TableCell width="20" />
                  <TableCell width="20" />
                </TableRow>
              </TableHead>
              <TableBody>
                {orderBy(orders, 'name').slice(0, limit).map((order) => (
                  <TableRow
                    hover
                    key={order?.id}
                  >
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        #
                        {order?.reference}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {order?.data_branch_office?.join_name ? `${order?.data_branch_office?.brand?.name} ${order?.data_branch_office?.name}` : order?.data_branch_office?.brand?.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {translatePaymentMethod(order?.payment_method)}
                      </Typography>
                    </TableCell>
                    <TableCell width="20" />
                    <TableCell>
                      <Typography variant="body2">
                        {getHour(order?.created_at)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {order?.status === 'finished' ? getHour(order?.updated_at) : '-'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        $
                        <CurrencyFormat value={order?.total} displayType="text" thousandSeparator="." decimalSeparator="," />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={translate(order?.status?.toString()?.toUpperCase())}
                        className={classes?.[
                          statusOrder(order?.status?.toString()?.toUpperCase())
                        ]}
                      />
                    </TableCell>
                    <TableCell>
                      {order?.data_branch_office?.is_develop && (
                        <Chip label="Desarrollo" className={classes?.in_process} />
                      )}
                      {order?.data_branch_office?.is_demo && (
                        <Box ml={2}>
                          <Chip label="Demo" className={classes?.in_process} />
                        </Box>
                      )}
                    </TableCell>
                    <TableCell className={classes?.statusCell}>
                      <ListAltIcon
                        className={classes?.moreVert}
                        onClick={() => setDetail(order)}
                      />
                    </TableCell>
                    <TableCell className={classes?.statusCell}>
                      <MoreVertIcon
                        className={classes?.moreVert}
                        onClick={(e) => onClick(e, order)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={orders.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[100]}
          labelRowsPerPage="Filas por página"
        />
      </Card>
      {
        panel !== null && (
          <div
            className={classes?.statusPanel}
            style={{
              top: panelPosition?.y,
              left: panelPosition?.x - 120
            }}
          >
            <ul className={classes?.statusPanelList}>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => updateStatus('created')}>
                  Pendiente
                </a>
              </li>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => updateStatus('in_progress')}>
                  En progreso
                </a>
              </li>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => updateStatus('sent')}>
                  Enviada
                </a>
              </li>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => updateStatus('finished')}>
                  Completar
                </a>
              </li>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => updateStatus('rejected')}>
                  Rechazar
                </a>
              </li>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => updateStatus('cancelled')}>
                  Cancelar
                </a>
              </li>
            </ul>
          </div>
        )
      }
      {
        detail && (
          <OrderDetailModal detail={detail} onClose={handleOrderDetailClose} />
        )
      }
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  onRefresh: PropTypes.func,
  data: PropTypes.array.isRequired
};

Results.defaultProps = {
  className: '',
  onRefresh: () => null,
};

export default Results;
