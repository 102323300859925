import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Box, makeStyles } from '@material-ui/core';
import BrandService from 'src/services/BrandService';
import { useSelector } from 'react-redux';
import { selector as UserSelector } from '../../../redux/ducks/user';
import Aside from './components/Aside';
import Section from './components/Section';

const useStyles = makeStyles((theme) => ({
  scroll: {
    scrollBehavior: 'smooth',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const ManagerProductsView = () => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [categories, setCategories] = useState([]);

  const getProducts = async () => {
    const products = await BrandService.getProducts(user?.branch_office?.brand_id);
    if (products?.code === 200) {
      setCategories(products?.data?.brand_categories?.map((item) => {
        return {
          ...item,
          selected: false
        };
      }));
    }
  };

  const setSelectedCategory = (index) => {
    setCategories(categories?.map((category, i) => {
      if (index === i) {
        return {
          ...category,
          selected: true
        };
      }
      return {
        ...category,
        selected: false
      };
    }));
  };

  useEffect(() => {
    if (user?.branch_office?.brand_id) {
      getProducts();
    }
  }, [user]);

  return (
    <Box display="flex" height="100%" width="100%">
      <Aside categories={categories} />
      <Box
        className={clsx({ [classes.scroll]: categories.length !== 0 })}
        overflow={categories.length === 0 ? 'hidden' : 'auto'}
        p={3}
        pt={0}
        display="flex"
        flexDirection="column"
        height="100%"
        width={categories.length === 0 ? '100%' : '75%'}
      >
        {categories?.filter((item) => item?.is_main)?.map((category, index) => (
          <Section category={category} setSelectedCategory={setSelectedCategory} index={index} />
        ))}
        {categories.length === 0 && (
          <Section setSelectedCategory={setSelectedCategory} loading />
        )}
      </Box>
    </Box>
  );
};

export default ManagerProductsView;
