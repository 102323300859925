import { createDuck } from 'redux-duck';
import { createStructuredSelector } from 'reselect';

const duck = createDuck('app/modal');

// Define initial state
const initialState = false;

// Define constants
export const SET_MODAL = duck.defineType('SET_MODAL');

// Define actions
export const actions = ({
  set: duck.createAction(SET_MODAL),
});

// Define selector
export const selector = createStructuredSelector({
  modal: (state) => state.modal,
});

// Define reducer
export default duck.createReducer({
  [SET_MODAL]: (state, { payload }) => payload,
}, initialState);
