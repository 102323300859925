import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import DiscountService from 'src/services/DiscountService';
import { selector as UserSelector } from 'src/redux/ducks/user';
import BrandService from 'src/services/BrandService';
import LoadingBox from 'src/components/LoadingBox';
import ProductCardPreview from './ProductCardPreview';
import CreateDsicountForm from './CreateDiscountForm';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateDiscountView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const { user } = useSelector(UserSelector);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [discount, setDiscount] = useState({
    id: null,
    product_id: '',
    discount_percentage: '',
    discount_price: 0,
    start_date: '',
    end_date: '',
    primary_image: '',
    is_featured: false
  });
  const handleDiscountAttributes = values => {
    setDiscount({
      ...discount,
      ...values
    });
  };

  const getProducts = async () => {
    const response = await BrandService?.getProducts(user?.brand_id);
    if (response?.code === 200) {
      const productList = response?.data?.brand_categories?.reduce(
        (array, item) => {
          const lsit = item?.products;
          return [...array, ...lsit];
        },
        []
      );

      const data = productList?.reduce((array, item) => {
        if (!item?.dsicount?.id) {
          return array.concat({
            id: item?.id,
            name: item?.name,
            prices: item?.prices,
            on_discount: !!item?.discount?.id,
            primary_image: item?.primary_image
          });
        }

        return array;
      }, []);

      setProducts(data);
    }
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await DiscountService.getById(id);
        if (response?.code === 200) {
          setDiscount({
            ...response?.data,
            id: response?.data?.id,
            product_id: response?.data?.product_id,
            price: response?.data?.price,
            discount_percentage: response?.data?.discount_percentage,
            discount_price: response?.data?.discount_price,
            start_date: response?.data?.start_date,
            end_date: response?.data?.end_date
          });
        }
      })();
    }
  }, [id]);

  useEffect(() => {
    if (user?.id) {
      getProducts();
    }
  }, [user]);

  return (
    <LoadingBox loading={loading}>
      <Page className={classes.root} title="Crear descuento">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <ProductCardPreview discount={discount} products={products} />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <CreateDsicountForm
                edit={edit}
                discount={discount}
                products={products}
                onChange={handleDiscountAttributes}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </LoadingBox>
  );
};

CreateDiscountView.propTypes = {
  edit: PropTypes.bool
};

CreateDiscountView.defaultProps = {
  edit: false
};

export default CreateDiscountView;
