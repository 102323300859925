import cookie from 'js-cookie';
import { createDuck } from 'redux-duck';
import { createStructuredSelector } from 'reselect';

const duck = createDuck('app/user');

// Define initial state
const initialState = {
  user: null,
  token: cookie.get('token') || null,
};

// Define constants
export const SET_USER = duck.defineType('SET_USER');
export const SET_TOKEN = duck.defineType('SET_TOKEN');
export const CLEAR_ALL = duck.defineType('CLEAR_ALL');
export const SET_TEMPORAL_TOKEN = duck.defineType('SET_TEMPORAL_TOKEN');

// Define actions
export const actions = ({
  clear: duck.createAction(CLEAR_ALL),
  set: duck.createAction(SET_USER),
  tokenize: duck.createAction(SET_TOKEN),
  tokenizeTemporal: duck.createAction(SET_TEMPORAL_TOKEN),
});

// Define selector
export const selector = createStructuredSelector({
  user: (state) => state.user.user,
  token: (state) => state.user.token
});

// Define reducer
export default duck.createReducer({
  [SET_USER]: (state, { payload }) => ({
    ...state,
    user: payload
  }),
  [SET_TOKEN]: (state, { payload }) => {
    cookie.set('token', payload, { expires: 7 });
    return {
      ...state,
      token: payload
    };
  },
  [SET_TEMPORAL_TOKEN]: (state, { payload }) => {
    const inFifteenMinutes = new Date(new Date().getTime() + 60 * 60 * 1000);
    cookie.set('token', payload, { expires: inFifteenMinutes });
    return {
      ...state,
      token: payload
    };
  },
  [CLEAR_ALL]: () => {
    cookie.remove('token');
    return initialState;
  },
}, initialState);
