import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import BranchOfficeService from 'src/services/BranchOfficeService';
import BranchOfficeCardPreview from './BranchOfficeCardPreview';
import CreateScheduleForm from './CreateScheduleForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateScheduleView = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [branchOffice, setBranchOffice] = useState({
    id: '',
    name: '',
    description: '',
    schedule: null,
    brand: {
      name: '',
      logo: ''
    },
  });

  const handleBranchOfficeAttributes = (values) => {
    setBranchOffice(values);
  };

  const fetchBranchOfficeData = async () => {
    const response = await BranchOfficeService.getById(id);
    if (response?.code === 200) {
      setBranchOffice({
        id: response?.data?.id,
        name: response?.data?.name,
        address: response?.data?.address,
        schedule: response?.data?.schedule,
        brand: {
          name: response?.data?.brand?.name,
          logo: response?.data?.brand?.logo
        }
      });
    }
  };

  useEffect(() => {
    if (id) {
      fetchBranchOfficeData();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="CreateScheduleView"
    >
      <Container maxWidth="lg">
        <Grid
          container
          justifyContent="center"
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <BranchOfficeCardPreview branchOffice={branchOffice} />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <CreateScheduleForm
              branchOffice={branchOffice}
              onChange={handleBranchOfficeAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CreateScheduleView;
