import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItem,
  makeStyles,
  Typography
} from '@material-ui/core';
import { ArrowForwardIosRounded, Restaurant } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  iconFood: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.primary.main,
    fontSize: '25px'
  },
  category: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    color: '#000',
    fontSize: 16,
    borderRadius: 10,
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '& svg': {
        color: '#fff',
        fontWeight: 600
      }
    },
    transition: 'all 0.5s ease',
    cursor: 'pointer'
  },
  arrow: {
    marginLeft: theme.spacing(1.5),
    color: theme.palette.primary.main,
    fontSize: 18
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '& svg': {
      color: '#fff',
      fontWeight: 600
    }
  },
  scroll: {
    scrollBehavior: 'smooth',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  },
  listCategories: {
    height: '90%',
    overflow: 'auto'
  }
}));

const Aside = ({
  categories
}) => {
  const classes = useStyles();

  return categories?.length > 0 && (
    <Box
      display="flex"
      flexDirection="column"
      backgroundColor="#f7f8f9"
      border="1px solid #dcdcdc"
      width="25%"
      height="100%"
    >
      <Box height="100%">
        <Box height="10%" display="flex" alignItems="center" p={3} pb={2.5}>
          <Restaurant className={classes.iconFood} />
          <Typography style={{ fontSize: 21, fontWeight: 600 }} variant="h4">Categorias</Typography>
        </Box>

        <List className={clsx(classes.listCategories, classes.scroll)} component="nav" style={{ padding: '0 5px' }}>
          {categories.length > 0
            && categories.map((item) => (
              <ListItem className={clsx(classes.category, { [classes.selected]: item?.selected })} component="a" href={`#${item.name}`} key={item?.id}>
                {item.name}
                <ArrowForwardIosRounded className={classes.arrow} />
              </ListItem>
            ))}
        </List>
      </Box>
    </Box>
  );
};

Aside.propTypes = {
  categories: PropTypes.array
};

export default Aside;
