import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import FormBrand from './components/FormBrand';
import FormUser from './components/FormUser';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  }
}));

const RegisterView = () => {
  const classes = useStyles();
  const [brand, setBrand] = useState(null);
  const [step, setStep] = useState(0);

  const handleBrand = (data) => {
    setBrand(data);
  };

  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <Box
        display="flex"
        flexDirection="column"
        height={step === 0 ? '100%' : 'fit-content'}
        py={3}
        justifyContent={step === 0 ? 'center' : ''}
      >
        <Container maxWidth="sm">
          <Box
            mb={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              color="primary"
              variant="h2"
              style={{ textAlign: 'center' }}
            >
              Crear nuevo aliado de Adomi logistica
            </Typography>
          </Box>
          {step === 0 && (
            <FormBrand
              nextStep={nextStep}
              setBrand={handleBrand}
            />
          )}
          {step === 1 && (
            <FormUser
              prevStep={prevStep}
              brand={brand}
            />
          )}
          <Typography
            color="textSecondary"
            variant="body1"
          >
            Tienes una cuenta?
            {' '}
            <Link
              component={RouterLink}
              to="/auth/login-dashboard"
              variant="h6"
            >
              Iniciar sesión
            </Link>
          </Typography>
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
