import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  makeStyles,
  Typography,
  Grid,
  useMediaQuery
} from '@material-ui/core';
import {
  CreditCard,
  MonetizationOn,
  Assessment
} from '@material-ui/icons';
import CurrencyFormat from 'react-currency-format';

const Report = ({
  totalCash,
  totalCard,
  total
}) => {
  const matchesLg = useMediaQuery('screen and (min-width: 1280px)');
  const matchesXl = useMediaQuery('screen and (min-width: 1820px)');

  const useStyles = makeStyles((theme) => ({
    card: {
      display: 'flex',
      position: 'relative',
      width: '100%',
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      borderRadius: 10,
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    },
    bgIcon: {
      backgroundColor: 'rgba(67, 160, 71, 0.3)',
      borderRadius: '50%'
    },
    totalCard: {
      width: '100%',
      padding: matchesLg ? '35px 0' : '35px 20px',
      display: 'flex',
      justifyContent: matchesLg ? 'center' : null
    }
  }));

  const classes = useStyles();

  return (
    <Box
      pl={5}
      pr={2}
      width={matchesXl ? '30%' : '45%'}
      height="fit-content"
      display="flex"
    >
      <Grid alignItems="center" justifyContent="center" container spacing={2}>
        <Grid style={{ height: 'fit-content' }} item xs={12} lg={6}>
          <Card style={{ width: '100%', padding: '35px 20px' }} className={classes.card}>
            <Box display="flex" alignItems="center">
              <Box width="50px" height="50px" display="flex" justifyContent="center" alignItems="center" className={classes.bgIcon}>
                <MonetizationOn style={{ fontSize: 30 }} color="primary" />
              </Box>
              <Box ml={1.5}>
                <Typography style={{ fontSize: 15, fontWeight: 600, color: '#959595' }} variant="body2">
                  Total efectivo
                </Typography>
                <Typography style={{ fontSize: 20, fontWeight: 700, }} color="primary" variant="body2">
                  $
                  <CurrencyFormat
                    value={totalCash || 0}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid style={{ height: 'fit-content' }} item xs={12} lg={6}>
          <Card style={{ width: '100%', padding: '35px 20px' }} className={classes.card}>
            <Box display="flex" alignItems="center">
              <Box width="50px" height="50px" display="flex" justifyContent="center" alignItems="center" className={classes.bgIcon}>
                <CreditCard style={{ fontSize: 28 }} color="primary" />
              </Box>
              <Box ml={1.5}>
                <Typography style={{ fontSize: 15, fontWeight: 600, color: '#959595' }} variant="body2">
                  Total tarjeta
                </Typography>
                <Typography style={{ fontSize: 20, fontWeight: 700, }} color="primary" variant="body2">
                  $
                  <CurrencyFormat
                    value={totalCard || 0}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid style={{ height: 'fit-content' }} item xs={12}>
          <Card className={clsx(classes.card, classes.totalCard)}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box display="flex" alignItems="center">
                <Box width="50px" height="50px" display="flex" justifyContent="center" alignItems="center" className={classes.bgIcon}>
                  <Assessment style={{ fontSize: 28 }} color="primary" />
                </Box>
                <Box ml={1.5}>
                  <Typography style={{ fontSize: 15, fontWeight: 600, color: '#959595' }} variant="body2">
                    Total del día
                  </Typography>
                  <Typography style={{ fontSize: 20, fontWeight: 700, }} color="primary" variant="body2">
                    $
                    <CurrencyFormat
                      value={total || 0}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

Report.propTypes = {
  totalCash: PropTypes.number,
  totalCard: PropTypes.number,
  total: PropTypes.number,
};

export default Report;
