import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DashboardManegerLayout from 'src/layouts/DashboardManagerLayout';
import MainLayout from 'src/layouts/MainLayout';
import ManagerListView from 'src/views/managers/ManagerListView';
import CreateManagerView from 'src/views/managers/CreateManagerView';
import LoginAdminView from 'src/views/auth/LoginAdminView';
import LogoutView from 'src/views/auth/LogoutView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/products/ProductListView';
import ProductComponentListView from 'src/views/product-components/ProductComponentListView';
import CreateProductComponentView from 'src/views/product-components/CreateProductComponentView';
import CreateProductView from 'src/views/products/CreateProductView';
import CreateBrandCategoryView from 'src/views/products/CreateBrandCategoryView';
import CreateTitleView from 'src/views/products/CreateTitleView';
import BranchOfficeListView from 'src/views/branch-offices/BranchOfficeListView';
import DriverListView from 'src/views/drivers/DriverListView';
// import RegisterView from 'src/views/auth/RegisterView';
// import SettingsView from 'src/views/settings/SettingsView';
import DiscountListView from 'src/views/discounts/DiscountListView';
import CreateDiscountView from 'src/views/discounts/CreateDiscountView';
import CreateBranchOfficeScheduleView from 'src/views/branch-offices/CreateScheduleView';
import CreateProductScheduleView from 'src/views/products/CreateScheduleView';
import PartnerReportView from 'src/views/reports/PartnerReportView';
import AuthView from './views/auth/AuthView';
import CreateDriverView from './views/drivers/CreateDriverView';
import ProductReportView from './views/reports/ProductReportView';
import OrderListView from './views/orders/OrderListView';
import ManagerOrderListView from './views/managers-dash/ManagersOrderListView';
import ManagerReportView from './views/managers-dash/ManagerReportView';
import ManagerProductsView from './views/managers-dash/ManagerProductsView';
import ToppingsManagmentView from './views/products/ToppingsManagmentView';
import RegisterView from './views/auth/Register/RegisterView';
import RequestServiceView from './views/parcel/RequestServiceView';
import TrackingView from './views/parcel/TrackingView';
import ServicesListView from './views/parcel/ServicesListView';
import AddbranchOffices from './views/branch-offices/BranchOfficeListView/AddbranchOffices';
import StockView from './views/products/StockView';
import ServicesReport from './views/reports/ServicesReport';
import SizeProduct from './views/products/SizeProduct';
import ProductColors from './views/products/ProductColors';
import BrandProduct from './views/products/BrandProduct';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [

      { path: 'descuentos', element: <DiscountListView /> },
      { path: 'descuentos/crear', element: <CreateDiscountView /> },
      { path: 'descuentos/:id', element: <CreateDiscountView edit /> },

      { path: 'paqueteria', element: <ServicesListView /> },
      { path: 'paqueteria/solicitar-servicio', element: <RequestServiceView /> },
      { path: 'paqueteria/:id', element: <TrackingView /> },

      { path: 'productos', element: <ProductListView /> },
      { path: 'productos/crear/categoria', element: <CreateBrandCategoryView /> },
      { path: 'productos/inventario', element: <StockView /> },
      { path: 'productos/crear', element: <CreateProductView /> },
      { path: 'productos/titulos', element: <CreateTitleView /> },
      { path: 'productos/toppings', element: <ToppingsManagmentView /> },
      { path: 'productos/tallas', element: <SizeProduct /> },
      { path: 'productos/colores', element: <ProductColors /> },
      { path: 'productos/marcas', element: <BrandProduct />},
      { path: 'productos/:id', element: <CreateProductView edit /> },
      { path: 'productos/:id/horario', element: <CreateProductScheduleView /> },

      { path: 'componentes', element: <ProductComponentListView /> },
      { path: 'componentes/:id', element: <CreateProductComponentView edit /> },
      { path: 'componentes/crear', element: <CreateProductComponentView /> },

      { path: 'sucursales', element: <BranchOfficeListView /> },
      { path: 'sucursales/:id/horario', element: <CreateBranchOfficeScheduleView /> },
      { path: 'sucursales/crear', element: <AddbranchOffices /> },

      { path: 'ordenes/en-progreso', element: <OrderListView /> },

      { path: 'gerentes', element: <ManagerListView /> },
      { path: 'gerentes/crear', element: <CreateManagerView /> },
      { path: 'gerentes/:id/editar', element: <CreateManagerView edit /> },

      { path: 'repartidores', element: <DriverListView /> },
      { path: 'repartidores/crear', element: <CreateDriverView /> },

      { path: 'reportes/productos/', element: <ProductReportView /> },
      { path: 'reportes/ventas/', element: <PartnerReportView /> },
      { path: 'reportes/servicios', element: <ServicesReport /> },

      // { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <DashboardManegerLayout />,
    children: [
      { path: 'gerente/ordenes', element: <ManagerOrderListView /> },
      { path: 'gerente/reporte', element: <ManagerReportView /> },
      { path: 'gerente/productos', element: <ManagerProductsView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="gerente/ordenes" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'auth', element: <AuthView /> },
      { path: 'auth/register', element: <RegisterView /> },
      { path: 'login/admin/:token', element: <LoginAdminView /> },
      { path: 'logout', element: <LogoutView /> },
      // { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/productos" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
