import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Card,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  CardContent,
  makeStyles
} from '@material-ui/core';
import BranchOfficeService from '../../../services/BranchOfficeService';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    flex: 1,
    width: '100%',
  },
}));

const Toolbar = ({ className, onChange, ...rest }) => {
  const classes = useStyles();
  const [branchOfficeSelected, setBranchOfficeSelected] = useState('');
  const [branchOffices, setBranchOffices] = useState([]);

  const handleChange = (e) => {
    onChange(e?.target?.value);
  };

  const handleBranchOffice = (event) => {
    setBranchOfficeSelected(event?.target?.value);
    handleChange(event);
  };

  const fetchAllBranchOffices = async () => {
    const response = await BranchOfficeService.getAll();

    if (response?.code !== 200) {
      return;
    }

    setBranchOffices(response?.data);
  };

  useEffect(() => {
    fetchAllBranchOffices();
  }, []);

  useEffect(() => {
    if (branchOffices.length) {
      setBranchOfficeSelected(branchOffices[0]?.id);
      onChange(branchOffices[0]?.id);
    }
  }, [branchOffices]);

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Link to="/app/repartidores/crear">
          <Button
            color="primary"
            variant="contained"
          >
            Agregar Repartidor
          </Button>
        </Link>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container justify="space-between">
              {
                branchOffices.length
                  ? (
                    <Grid container item xs={12} md={5} justify="flex-end" alignItems="center">
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="sucursal">Sucursal</InputLabel>
                        <Select
                          required
                          name="sucursal"
                          labelId="sucursal"
                          label="Selecciona una sucursal"
                          value={branchOfficeSelected}
                          onChange={handleBranchOffice}
                        >
                          {
                            branchOffices.map((branchOffice) => (
                              branchOffice?.join_name ? (
                                <MenuItem key={branchOffice?.id} value={branchOffice?.id}>
                                  { `${branchOffice?.brand?.name} ${branchOffice?.name}`}
                                </MenuItem>
                              ) : (
                                <MenuItem key={branchOffice?.id} value={branchOffice?.id}>
                                  { branchOffice?.brand?.name}
                                </MenuItem>
                              )
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                  )
                  : null
              }
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func
};

export default Toolbar;
