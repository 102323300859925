import React, { useState, useRef } from 'react';
import CustomBrandService from 'src/services/CustomBrandService';
import { PropTypes } from 'prop-types';
import {
  Box,
  Typography,
  Button,
  TextField,
  makeStyles,
  FormControl,
  FormHelperText,
  InputAdornment,
  SvgIcon,
  CircularProgress
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import Modal from 'src/components/Modal';
import useModal from 'src/hooks/useModal';
import useErrors from 'src/hooks/useErrors';
import { useSelector } from 'react-redux';
import { selector as UserSelector } from 'src/redux/ducks/user';
import BrandLogo from './BrandILogo';

const useStyles = makeStyles(() => ({
  head: {
    background: 'white',
    padding: '1rem',
    borderRadius: '5px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: '1rem'
  }
}));

const Toolbar = ({ refresh, brand, onSearch }) => {
  const classes = useStyles();
  const [isOpen, toggle] = useModal();
  const { user } = useSelector(UserSelector);
  const { setErrors } = useErrors();
  const [localError, setLocalError] = useState([]);
  const inputLogo = useRef(null);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  
  const handleSuccess = () => {
    toggle();
    setErrors([]);
    setLocalError([]);
    refresh();
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const { nameAdd } = e.target;
    const errors = [];
    const data = {
      name: nameAdd.value,
      partner_id: user.id
    };
    if (nameAdd.value.trim().length === 0) {
      errors.push({
        field: 'nameAdd',
        error: 'El nombre de las marcas es obligatorio'
      });
    }
    if (errors.length > 0) {
      setErrors(errors.map((err) => err.error));
      setLocalError(errors);
      
    } else {
      setLoading(true);
    }
    const response = await CustomBrandService.create(data);
    if (response?.code === 201 || response?.code === 200) {
      if (
        response?.data?.logo === null &&
        file !== undefined &&
        file !== null
      ) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response?.data?.id);

        const responsee = await CustomBrandService.uploadLogo(formData);

        if (responsee?.code === 200 || responsee?.code === 201) {
          setTimeout(() => {
            handleSuccess();
          }, 3000);
        }
      } else {
        setTimeout(() => {
          handleSuccess();
        }, 2000);
      }
    } else {
      setErrors(response.errors);
    }
  };
  const handleUploadLogo = () => {
    if (inputLogo.current) {
      inputLogo.current.click();
    }
  };
  const handledModal = () => {
    toggle();
  };
  const handleSearch = (e) => {
    onSearch(e?.target?.value);
  };
  return (
    <div>
      <Box
        className={classes.head}
        display="flex"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="row">
          <Box margin="auto">
            <Typography variant="h2">Gestionar Marcas</Typography>
          </Box>
          <Box maxWidth={500} maxheight="15px" height="60px" ml={4}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Buscar marca"
              variant="outlined"
              onChange={handleSearch}
            />
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handledModal()}
        >
          Agregar Marca
        </Button>
      </Box>
      <Modal isOpen={isOpen} toggle={toggle} title="Agregar una marca">
        <form onSubmit={(e) => {
          handleSubmit(e);
        }}
        >
          <FormControl
            required
            variant="outlined"
            error={localError.some((e) => e.field === 'nameAdd')}
          >
            <Box maxWidth="250px" margin="auto">
              <BrandLogo
                ref={inputLogo}
                brand={brand}
                hasImage={false}
                onUpload={handleUploadLogo}
                setFile={setFile}
              />
            </Box>

            <Box>
              <TextField
                autoComplete="off"
                fullWidth
                label="Nombre de la marca"
                margin="normal"
                name="nameAdd"
                variant="outlined"
              />
              <Button disabled={loading} variant="contained" color="primary" type="submit" startIcon={loading? <CircularProgress size={20} /> : ''}>
                Agregar
              </Button>
            </Box>
            {localError.some((e) => e.field === 'nameAdd') && (
              <FormHelperText error>
                {localError?.find((e) => e.field === 'nameAdd')?.error || ''}
              </FormHelperText>
            )}
          </FormControl>
        </form>
      </Modal>
    </div>
  );
};
Toolbar.propTypes = {
  refresh: PropTypes.func,
  brand: PropTypes.array,
  onSearch: PropTypes.func
};

export default Toolbar;
