import HttpRequest from './HttpRequest';

export default class ColorService extends HttpRequest {
  static endpoint = 'colors';

  static async getByBrand(brandId) {
    this.endpoint = `colors/by-brand/${brandId}`;
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'colors';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.endpoint = 'colors';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'colors';
    const response = await this.put(id, data);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'colors';
    const response = await this.delete(id);
    return response;
  }
}
