import React, { useEffect, useState, useRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import pusher from 'src/utils/pusher';
import dayjs from 'dayjs';

// MUI components
import {
  makeStyles,
  Container,
  Card,
  Typography,
  FormControl,
  Button,
  Box
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

// Icons
import { Add } from '@material-ui/icons';
import { VscPackage } from 'react-icons/vsc';

// Services
import BranchOfficeService from 'src/services/BranchOfficeService';
import OrderService from 'src/services/OrderService';

// Components and Utils
import Page from 'src/components/Page';
import LoadingBox from 'src/components/LoadingBox';
import Selector from 'src/components/Selector';
import isArray from 'src/utils/is_array';
import orderBy from '../../../utils/arrays';
import TabFilters from '../../../components/TabFilters';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f6f6fa',
    height: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  card: {
    position: 'relative',
    overflow: 'unset',
    height: '100%',
    borderRadius: 20,
    backgroundColor: '#f4f7fa',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
  },
  container: {
    paddingLeft: 20,
    paddingRight: 20,
    height: '100%'
  },
  pagination: {
    '& svg, & button, & li div': {
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  },
  select: {
    fontSize: 13
  },
  formControl: {
    width: '100%'
  }
}));

const selectorStyles = {
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: '#f4f7fa',
      borderColor: '#009739',
      boxShadow: isFocused ? '#009739 0 0 0 1px' : undefined,
      '&:hover': {
        borderColor: isFocused && '#009739'
      },
      cursor: 'pointer'
    };
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    let backgroundColor = '';
    let bgColorActive = '';

    if (isDisabled) backgroundColor = undefined;
    else if (isSelected) backgroundColor = '#009739';
    else if (isFocused) backgroundColor = '#97D4AE';
    else backgroundColor = undefined;

    if (!isDisabled) {
      if (isSelected) bgColorActive = '#009739';
      else bgColorActive = '#97D4AE';
    } else bgColorActive = undefined;

    return {
      ...styles,
      backgroundColor,
      color: isSelected ? '#fff' : '#000',
      cursor: 'pointer',

      ':active': {
        ...styles[':active'],
        backgroundColor: bgColorActive
      }
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, fontSize: 11 }),
  singleValue: (styles) => ({ ...styles, fontSize: 14 })
};

/**
 * Filtros de los estados del servicio
 * Nota: Si va añadir un nuevo filtro por favor en el campo 'key' seguir de forma consecutiva,
  como se ve en el array tabs. Ej: 0, 1, 2, 4, 5, 6...
*/
const tabs = [
  {
    key: 0,
    value: 'sent',
    title: 'Enviado',
    total: 0,
    alert: false
  },
  {
    key: 1,
    value: 'pending_to_be_sent',
    title: 'Asignando driver',
    total: 0,
    alert: false
  },
  {
    key: 2,
    value: 'finished',
    title: 'Completado',
    total: 0,
    alert: false
  },
  {
    key: 3,
    value: 'rejected',
    title: 'Rechazado',
    total: 0,
    alert: false
  },
  {
    key: 4,
    value: 'cancelled',
    title: 'Cancelado',
    total: 0,
    alert: false
  },
  {
    key: 5,
    value: 'all',
    title: 'Todos',
    total: 0,
    alert: false
  }
];

const PER_PAGE = 10; // Numero de servicios a mostrar por pagina

const ServicesListView = () => {
  const classes = useStyles();
  const [services, setServices] = useState([]);
  const [filters, setFilters] = useState(tabs);
  const [filter, setFilter] = useState(tabs[0]);
  const [actPage, setActPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [branchOffices, setBranchOffices] = useState([]);
  const [branchOffice, setBranchOffice] = useState({
    id: '',
    name: ''
  });

  /**
   *Refs que guardan los datos de sus respectivos estados, para poder acceder a sus valores dentro
   de los eventos del pusher.
  */

  const filtersRef = useRef(filters);
  const filterRef = useRef(filter);
  const actPageRef = useRef(actPage);
  const channelRef = useRef('');

  const getCountPagination = () => {
    return Math.ceil(filters[filter?.key]?.total / PER_PAGE) || 0;
  };

  /**
   * Metodo que me permite actualizar algún campo de un filtro
   *
   * @param string status: Guarda el filtro que quiero actualizar.
   * @param string field: Guarda el nombre del campo que se desea editar
   * (value, title, total, alert).
   * @param any value: Guarda el valor nuevo que se le desea asignar al campo.
   */
  const updateFilters = (status, field, value) => {
    // Se actualiza el filtro del estado filters y de su Ref.
    filtersRef.current = filtersRef.current.map((tab) => {
      // Se verifica si es el filtro que se desea editar
      if (tab?.value === status) {
        return {
          ...tab,
          [field]: value // Se le asigna al campo su nuevo valor
        };
      }
      return tab;
    });

    setFilters((prev) => {
      if (!isArray(prev)) return [];
      return prev.map((tab) => {
        if (tab?.value === status) {
          return {
            ...tab,
            [field]: value
          };
        }
        return tab;
      });
    });
  };

  /**
   * Función que me permite alertar a uno de los filtros para que inicie su animación.
   *
   * @param string status: Guarda el filtro que se desea alertar.
   */
  const alertChange = (status) => {
    // Se envia un true al campo alert del filtro para que inicie la animación
    updateFilters(status, 'alert', true);

    // Pasados 1.2 segundos se envia un false al campo alert del filtro para que pare la animación
    setTimeout(() => {
      updateFilters(status, 'alert', false);
    }, 1200);
  };

  /**
   * Función que comprueba si el filtro tuvo un aumento en su cantidad total de servicios
   *
   * @param number status: Guarda la nueva cantidad total del filtro
   * @param string status: Guarda el filtro que se desea comprobar su cantidad total.
   */
  const onChangeTotal = (newTotal, status) => {
    // prevTotal me guarda la anterior cantidad total del filtro
    const prevTotal = filtersRef.current.find((tab) => tab?.value === status)
      ?.total;
    if (prevTotal < newTotal && filterRef.current?.value !== status) {
      // Si el filtro tuvo un aumento en su cantidad total se alerta para que inicie su animación
      alertChange(status);
    }
  };

  /**
   * Función que me ordena la lista de servicios en base a su numero de referencia y establece
   * a cada uno de los servicios una prioridad en base a su estado. Siendo 0 el de mayor prioridad
   *
   * @param array dataServices: Guarda el listado de servicios
   * @return array: la lista ya ordenada con sus estados ya priorizados
   */
  const orderServices = (dataServices) => {
    // El orderBy me ordena el numero de referencia de mayor a menor.
    return orderBy(dataServices, 'reference', 'high').reduce((array, item) => {
      let statusOrder = 0;
      switch (item?.status) {
        case 'pending_to_be_sent':
          statusOrder = 1;
          break;
        case 'finished':
          statusOrder = 2;
          break;
        case 'rejected':
          statusOrder = 3;
          break;
        case 'cancelled':
          statusOrder = 3;
          break;
        default:
          statusOrder = 0;
      }
      // Se establece la prioridad en base al estado del servicio.
      return array.concat({ ...item, statusOrder });
    }, []);
  };

  const handleFilter = (value) => {
    setFilter(value);
    filterRef.current = value;
  };

  /**
   * getAll trae todos los servicios sin importar su estado
   * @param string id: id de la sucursal seleccionada
   * @param boolean isDevelop: Establece si la sucursal es de desarrollo
   * @param number page: El numero de pagina del listado de servicios
   * @param string action: Establece la acción que se desea realizar, total|set.
   * si action es igual a 'total', se va a obtener el numero total de servicios.
   * si action es igual 'set', se va actualizar el estado services, para poder mostrar
   * en pantalla el listado de servicios.
   */

  const getAll = async (id, isDevelop, page, action) => {
    const response = await OrderService.getByBranchOfficeId(
      id,
      page,
      PER_PAGE,
      dayjs(new Date()).format('YYYY-MM-DD'),
      isDevelop
    );
    if (response?.success) {
      if (action === 'total') {
        updateFilters('all', 'total', response?.data?.page_info?.total);
      } else if (action === 'set') {
        const data = orderServices(response?.data?.orders);
        /**
         * El orderBy orderna los servicios en base a la prioridad de cada estado del servicio,
         * la cual se establecio en la función orderServices.
         */
        setServices(orderBy(data, 'statusOrder'));
        setLoading(false);
      }
    }
  };

  /**
   * getServices trae los servicios por su estado, en caso de que el status sea igual a 'all',
   * entonces simplemente se retorna todos lo servicios sin importar su estado.
   * @param string id: id de la sucursal seleccionada
   * @param string status: El estado por el que se desea filtrar el listado de servicios
   * @param boolean isDevelop: Establece si la sucursal es de desarrollo
   * @param number page: El numero de pagina del listado de servicios
   * @param string action: Establece la acción que se desea realizar, total|set.
   * si action es igual a 'total', se va a obtener el numero total de servicios.
   * si action es igual 'set', se va actualizar el estado services, para poder mostrar
   * en pantalla el listado de servicios.
   */

  const getServices = async (id, isDevelop, status, page, action) => {
    setLoading(true);
    if (status === 'all') {
      getAll(id, isDevelop, page, action);
      return;
    }
    /*
     * Se obtiene el listado de servicios en base a la sucursal, su estado, el numero de pagina,
     * el numero de servicios a retornar por pagina y la fecha. Para el buen funcionamiento,
     * siempre se manda el isDevelop.
     */
    const response = await OrderService.getByStatus(
      id,
      status,
      page,
      PER_PAGE,
      dayjs(new Date()).format('YYYY-MM-DD'),
      isDevelop
    );
    if (response?.success) {
      if (action === 'total') {
        /**
         * En caso de que a la hora de obtener la cantidad total de servicios que hay
         * en cada filtro, y uno de ellos este seleccinado, entonces se debe mostrar los servicios
         * de ese filtro.
         */
        if (filterRef.current?.value === status) {
          /**
           * En el momento de mostrar los servicios del filtro seleccionado, se debe ajustar
           * el numero de pagina, dependiento del caso.
           * Si por ejemplo el numero de paginas es menor a la pagina seleccionada, entonces
           * se debe ajustar el numero de pagina a una menor.
           * Y en caso de que el numero de paginas es igual a 0, entonces simplemente se ajusta
           * el numero de pagina a la primera.
           */
          const contPage = Math.ceil(
            response?.data?.page_info?.total / PER_PAGE
          );
          if (contPage <= actPageRef.current) {
            getServices(id, isDevelop, status, contPage, 'set');
          } else if (actPageRef.current === 1 || actPageRef.current === 0) {
            getServices(id, isDevelop, status, 1, 'set');
          }
        }
        onChangeTotal(response?.data?.page_info?.total, status);
        updateFilters(status, 'total', response?.data?.page_info?.total);
      } else if (action === 'set') {
        setActPage(page);
        actPageRef.current = page;
        const data = orderServices(response?.data?.orders);
        /**
         * El orderBy orderna los servicios en base a la prioridad de cada estado del servicio,
         * la cual se establecio en la función orderServices.
         */
        setServices(orderBy(data, 'statusOrder'));
        setLoading(false);
      }
    }
  };

  const handlePage = (page) => {
    // Se obtienen los servicios de la pagina seleccionada.
    getServices(
      branchOffice?.id,
      branchOffice?.is_develop,
      filter?.value,
      page,
      'set'
    );
    setActPage(page);
    actPageRef.current = page;
  };

  /**
   * Esta función me permite obtener tanto el listado de servicios dependiendo del
   * filtro seleccionado, como la cantidad total de servicios que hay en cada filtro
   * @param string id: id de la sucursal seleccionada
   * @param boolean isDevelop: Establece si la sucursal es de desarrollo
   */
  const refresh = (id, isDevelop) => {
    filters.forEach((item) => {
      getServices(id, isDevelop, item?.value, actPage, 'total');
    });
  };

  /**
   * Esta función me permite subscribirme a un nuevo canal del pusher, ya que cada canal escucha
   * a una sucursal diferente, por lo que si se selecciona una sucursal diferente nos tenemos
   * que subscribir a ese nuevo canal de la sucursal.
   *
   * @param string id: id de la sucursal seleccionada
   * @param boolean isDevelop: Establece si la sucursal es de desarrollo
   */
  const subscribeChannel = (id, isDevelop) => {
    // Se inicializan los filtros y el filtro seleccionado.
    filtersRef.current = tabs;
    setFilters(tabs);
    filterRef.current = {
      ...tabs[0]
    };
    setFilter(tabs[0]);

    pusher.unsubscribe(channelRef.current); // Nos desubcribimos al canal anterior
    refresh(id, isDevelop); // Obtenemos los servicios de la nueva sucursal
    // Y nos subcribimos a la nueva sucursal
    channelRef.current = pusher.subscribe(`BRANCHOFFICE_${id}`);
    ['changes-orders'].map((item) => {
      channelRef.current.bind(item, () => {
        if (['changes-orders'].some((event) => item === event)) {
          refresh(id, isDevelop);
          if (filterRef.current?.value === 'all')
            getAll(id, actPageRef.current, 'set');
        }
      });
      return item;
    });
  };

  const handleChangeBranchOffice = (value) => {
    const branchOfficeSelected = branchOffices.find(
      (item) => item?.id === value?.value
    );
    setBranchOffice(branchOfficeSelected);
    subscribeChannel(
      branchOfficeSelected?.id,
      branchOfficeSelected?.is_develop
    );
  };

  const getBranchOffices = async () => {
    const response = await BranchOfficeService.getAll();
    if (response?.code === 200) {
      setBranchOffices(response?.data);
      setBranchOffice(response?.data[0]);
      subscribeChannel(response?.data[0]?.id, response?.data[0]?.is_develop);
    }
  };

  useEffect(() => {
    getBranchOffices();
    return () => {
      pusher.unsubscribe(channelRef.current);
    };
  }, []);

  useEffect(() => {
    handlePage(1);
  }, [filter]);

  return (
    <Page title="Segumiento" className={classes.root}>
      <Container className={classes.container}>
        <LoadingBox loading={false}>
          <Card className={classes.card}>
            <Box height="100%" py={2} display="flex" flexDirection="column">
              <Box
                pt={1}
                px={2.5}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center" width="50%" mr={1}>
                  <Box width="100%" mr={1}>
                    <Typography color="primary" variant="h2">
                      Paquetería{' '}
                      <span
                        style={{
                          fontSize: 15,
                          fontWeight: 400
                        }}
                      >
                        / Servicios
                      </span>
                    </Typography>
                  </Box>
                  <FormControl required className={classes.formControl}>
                    <Selector
                      options={branchOffices?.map((item) => ({
                        value: item?.id,
                        label: item?.name
                      }))}
                      className={classes.select}
                      value={
                        Object.keys(branchOffice).length === 0
                          ? ''
                          : {
                              value: branchOffice?.id,
                              label: branchOffice?.name
                            }
                      }
                      onChange={handleChangeBranchOffice}
                      name="branch_office_id"
                      labelId="branch_office"
                      label="Sucursal"
                      placeholder="Selecciona una sucursal"
                      styles={selectorStyles}
                    />
                  </FormControl>
                </Box>
                <Box width="50%" display="flex" justifyContent="end">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      borderRadius: 50
                    }}
                    endIcon={<Add />}
                    component={RouterLink}
                    to="/app/paqueteria/solicitar-servicio"
                  >
                    Solicitar servicio
                  </Button>
                </Box>
              </Box>
              <TabFilters
                haveCountData
                haveAnimationEvent
                filters={filters}
                selectedFilter={filter}
                onChange={handleFilter}
              />
              <Box width="100%" display="flex" justifyContent="center" mt={2}>
                {getCountPagination() > 0 && (
                  <Pagination
                    size="small"
                    className={classes.pagination}
                    count={getCountPagination()}
                    page={actPage}
                    onChange={(e, page) => handlePage(page)}
                    color="primary"
                  />
                )}
              </Box>
              <Box
                maxHeight="67%"
                height={loading || services.length === 0 ? '67%' : null}
              >
                <LoadingBox
                  loading={loading}
                  bgcolor="none"
                  title="Cargando ordenes..."
                >
                  {services.length !== 0 ? (
                    <Results services={services} />
                  ) : (
                    <Box
                      height="100%"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <VscPackage
                          style={{
                            color: '#009739'
                          }}
                          size={60}
                        />
                        <Typography
                          color="primary"
                          variant="body2"
                          style={{
                            fontSize: 20,
                            fontWeight: 600
                          }}
                        >
                          No hay ordenes...
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </LoadingBox>
              </Box>
            </Box>
          </Card>
        </LoadingBox>
      </Container>
    </Page>
  );
};

export default ServicesListView;
