import HttpRequest from './HttpRequest';

export default class CityService extends HttpRequest {
  static endpoint = 'cities';

  static async getAll() {
    this.endpoint = 'cities';
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'cities';
    const response = await this.get(id);
    return response;
  }
}
