import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  progressWrapper: {
    borderRadius: 8,
    backgroundColor: '#D9E8F1',
    transform: 'translateY(-50%)'
  },
  progressLine: {
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
    transition: 'width 0.5s'
  },
  stepVisited: {
    backgroundColor: theme.palette.primary.main,
  },
  stepUnvisited: {
    backgroundColor: '#D9E8F1',
  },
  step: {
    borderRadius: '50%',
    transition: 'background 0.5s ease'
  },
  iconVisited: {
    color: '#fff'
  },
  iconUnvisited: {
    color: theme.palette.primary.main
  }
}));

const ProgressBar = ({
  destination,
  items
}) => {
  const classes = useStyles();

  const isVisited = (item) => item <= destination;

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="10%"
    >
      <Box
        className={classes.progressWrapper}
        display="flex"
        position="absolute"
        top="50%"
        height={10}
        width={`calc(150px * ${items.length - 1} + 37px)`}
      >
        <Box
          className={classes.progressLine}
          width={`calc(150px * ${destination} + 37px)`}
          height="100%"
        />
      </Box>
      {items.map((item, index) => {
        const Icon = item?.icon;
        return (
          <Box
            position="relative"
            key={item?.key}
            display="flex"
            minWidth={index !== items?.length - 1 ? 150 : 'fit-content'}
          >
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={clsx(
                classes.step,
                {
                  [classes.stepVisited]: isVisited(index),
                  [classes.stepUnvisited]: !isVisited(index),
                }
              )}
              width={37}
              height={37}
            >
              {Icon && (
                <Icon
                  className={clsx(
                    {
                      [classes.iconVisited]: isVisited(index),
                      [classes.iconUnvisited]: !isVisited(index),
                    }
                  )}
                  size={20}
                />
              )}
            </Box>
            <Box
              className={classes.textStep}
              position="absolute"
              bottom={-23}
              right={index !== items?.length - 1 ? 90 : null}
              left={index === items?.length - 1 ? -20 : null}
            >
              <Typography
                variant="body2"
                color="primary"
                style={{
                  fontWeight: isVisited(index) ? 500 : 300,
                }}
              >
                {item?.title}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

ProgressBar.propTypes = {
  destination: PropTypes.number,
  items: PropTypes.array
};

export default ProgressBar;
