import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Toolbar from './Toolbar';
import Results from './Results';
import DriverService from '../../../services/DriverService';
import { selector as UserSelector } from '../../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DriverList = () => {
  const classes = useStyles();
  const [drivers, setDrivers] = useState([]);
  const [branchOfficeSelected, setBranchOfficeSelected] = useState([]);
  const { user } = useSelector(UserSelector);

  const getDrivers = async (branchOfficeId) => {
    const fetchData = await DriverService.getAll(branchOfficeId);

    if (fetchData?.code === 200) {
      setDrivers(fetchData?.data);
      setBranchOfficeSelected(branchOfficeId);
    }
  };

  const refresh = async () => {
    if (user?.id) {
      await getDrivers();
    }
  };

  const unlinkFromBranchOffice = async (driverId, firstName, lastName) => {
    await Swal.fire({
      title: 'Desvincular driver',
      text: `¿Estas seguro que deseas desvincular al repartidor ${firstName} ${lastName}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#05B40DBF',
      cancelButtonColor: '#b7b4b4',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar'
    }).then(async (answer) => {
      if (answer.isConfirmed) {
        await DriverService.unlinkFromBranchOffice(driverId, branchOfficeSelected);
        await Swal.fire({
          text: 'El repartidor se desvinculo correctamente',
          icon: 'success',
        });
        await getDrivers(branchOfficeSelected);
      }
    });
  };

  useEffect(() => {
    getDrivers();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Repartidores"
    >
      <Container maxWidth={false}>
        <Toolbar
          refresh={refresh}
          onChange={getDrivers}
        />
        <Box mt={3}>
          <Results
            data={drivers}
            onRefresh={refresh}
            onUnlinkFromBranchOffice={unlinkFromBranchOffice}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default DriverList;
