import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { selector as UserSelector } from 'src/redux/ducks/user';
import BrandCategoryService from 'src/services/BrandCategoryService';
import BrandCategoryListView from './BrandCategoryListView';
import CreateBrandCategoryForm from './CreateBrandCategoryForm';
import BrandService from '../../../services/BrandService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateBrandCategoryView = () => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [edit, setEdit] = useState(false);
  const [category, setCategory] = useState({
    id: null,
    name: '',
    image: '',
    is_main: true,
    parent_id: null,
    slug: ''
  });
  const [categories, setCategories] = useState([]);

  const fetchData = async () => {
    const response = await BrandService.getProducts(user?.brand_id);
    if (response?.code === 200) {
      setCategories(response?.data?.brand_categories ?? []);
    }
  };

  const handleEdit = (item) => {
    setCategory(item);
    setEdit(true);
  };

  const handleChange = (value) => {
    setCategory(value);
  };

  const handleCancel = () => {
    setEdit(false);
    setCategory({
      id: null,
      name: '',
      image: '',
      is_main: true,
      parent_id: ''
    });
  };

  const handleRemove = async (item) => {
    const selected = categories?.find((cat) => cat?.id === item?.id);

    if (selected?.products?.length > 0) return;

    const response = await BrandCategoryService.remove(item?.id);
    if (!response?.code) {
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page className={classes.root} title="Crear Categoría">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CreateBrandCategoryForm
              edit={edit}
              onRefresh={fetchData}
              onChange={handleChange}
              onCancel={handleCancel}
              category={category}
              categories={categories.filter((item) => item.is_main)}
            />
          </Grid>
          <Grid item xs={12}>
            <BrandCategoryListView
              data={categories}
              user={user}
              onEdit={handleEdit}
              onRemove={handleRemove}
              onRefresh={fetchData}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

CreateBrandCategoryView.propTypes = {};

CreateBrandCategoryView.defaultProps = {};

export default CreateBrandCategoryView;
