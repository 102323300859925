import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap, Polygon, DirectionsRenderer, withGoogleMap, withScriptjs
} from 'react-google-maps';
import { FcHome } from 'react-icons/fc';
import { MdDeliveryDining } from 'react-icons/md';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import { Avatar, makeStyles } from '@material-ui/core';
import './GoogleMapsParcel.css';
import GoogleMapsApiService from 'src/services/GoogleMapsApiService';

const useStyles = makeStyles(() => ({
  marker: {
    width: 37,
    height: 37,
    borderRadius: '50%',
    backgroundSize: 'cover',
  },
  iconLoop: {
    fontSize: 12,
    color: '#fff'
  },
}));

const optionsPolygon = {
  fillColor: 'lightgreen',
  fillOpacity: 0.5,
  strokeColor: 'green',
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  zIndex: 1
};

const optionsDirections = {
  polylineOptions: {
    strokeColor: '#009739',
    strokeWeight: 4,
    zIndex: 2
  },
  suppressMarkers: true,
};

const GoogleMapsParcel = withScriptjs(withGoogleMap(({
  styles,
  addressA,
  addressB,
  setAddressB,
  polygons,
  checkAddressContain,
  toggleWarning,
  draggableB,
  driver,
  origin,
  destination,
  centerDirections,
  setCenterDirections
}) => {
  const classes = useStyles();
  const [center, setCenter] = useState({
    lat: 4.1156735,
    lng: -72.9301367
  });
  const [zoom, setZoom] = useState(6);
  const [directions, setDirections] = useState(null);
  const [positionA, setPositionA] = useState({ lat: 0, lng: 0 });
  const [positionB, setPositionB] = useState({ lat: 0, lng: 0 });

  const handleDragged = async (e) => {
    const latitude = e.latLng.lat();
    const longitude = e.latLng.lng();
    if (!checkAddressContain({ lat: latitude, lng: longitude })) {
      setPositionB({
        lat: addressB?.latitude,
        lng: addressB?.longitude
      });
      toggleWarning();
      return;
    }
    const response = await GoogleMapsApiService.getAddressByPosition(latitude, longitude);
    if (response?.status === 200 || response?.status === 201) {
      setAddressB({
        ...addressB,
        address1: response?.data?.results[0]?.formatted_address,
        latitude,
        longitude
      });
    }
  };

  const fetchDirections = () => {
    const service = new window.google.maps.DirectionsService();
    service.route(
      {
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === 'OK' && result) {
          setDirections(result);
        }
      }
    );
  };

  useEffect(() => {
    if (centerDirections) {
      setTimeout(() => {
        setCenterDirections(false);
      }, 2000);
    }
  }, [directions]);

  useEffect(() => {
    if (Object.keys(origin).length !== 0 && Object.keys(destination).length !== 0) {
      fetchDirections();
    }
  }, [
    origin,
    destination
  ]);

  useEffect(() => {
    if (Object.keys(addressA).length !== 0) {
      const latLng = {
        lat: addressA?.latitude,
        lng: addressA?.longitude
      };
      setPositionA(latLng);
      if (!directions) {
        setCenter(latLng);
        if (zoom !== 16) setZoom(16);
      }
    }
  }, [addressA?.latitude, addressA?.longitude]);

  useEffect(() => {
    if (addressB?.address1?.length !== 0) {
      const latLng = {
        lat: addressB?.latitude,
        lng: addressB?.longitude
      };
      setPositionB(latLng);
      if (!directions) {
        setCenter(latLng);
        if (zoom !== 16) setZoom(16);
      }
    }
  }, [addressB?.latitude, addressB?.longitude]);

  return (
    <GoogleMap
      on
      center={center}
      zoom={zoom}
      defaultOptions={{
        styles,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
        clickableIcons: false,
        zoomControl: true,
      }}
    >
      {Object.keys(driver).length !== 0 && (
        <MarkerWithLabel
          onDragEnd={handleDragged}
          position={{
            lat: driver?.position?.latitude,
            lng: driver?.position?.longitude
          }}
          icon={{
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 11,
            fillColor: '#fff',
            fillOpacity: 0,
            strokeOpacity: 0,
          }}
          labelStyle={{
            borderRadius: '50%',
            boxShadow: 'rgba(0, 0, 0, 0.6) 0px 8px 16px 0px',
            animationName: 'bounce',
            animationTimingFunction: 'ease',
            animationDuration: '2s'
          }}
          labelAnchor={new window.google.maps.Point(19, 19)}
        >
          <Avatar
            className={classes.marker}
            style={{
              backgroundColor: '#4aa959'
            }}
          >
            <MdDeliveryDining color="#fff" size={23} />
          </Avatar>
        </MarkerWithLabel>
      )}
      {Object.keys(addressA).length !== 0 && (
        <MarkerWithLabel
          icon={{
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 11,
            fillColor: '#fff',
            fillOpacity: 0,
            strokeOpacity: 0,
          }}
          labelStyle={{
            borderRadius: '50%',
            boxShadow: 'rgba(0, 0, 0, 0.6) 0px 8px 16px 0px',
            animationName: 'bounce',
            animationTimingFunction: 'ease',
            animationDuration: '2s',
            overflow: 'visible',
          }}
          position={positionA}
          labelAnchor={new window.google.maps.Point(19, 19)}
        >
          <div
            className={classes.marker}
            style={{
              backgroundImage: `url(${addressA?.brand?.logo})`,
            }}
          />
        </MarkerWithLabel>
      )}
      {addressB?.address1?.length !== 0 && (
        <MarkerWithLabel
          draggable={draggableB}
          onDragEnd={handleDragged}
          position={positionB}
          icon={{
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 11,
            fillColor: '#fff',
            fillOpacity: 0,
            strokeOpacity: 0,
          }}
          labelStyle={{
            borderRadius: '50%',
            boxShadow: 'rgba(0, 0, 0, 0.6) 0px 8px 16px 0px',
            animationName: 'bounce',
            animationTimingFunction: 'ease',
            animationDuration: '2s'
          }}
          labelAnchor={new window.google.maps.Point(19, 19)}
        >
          <Avatar
            className={classes.marker}
            style={{
              backgroundColor: '#D9E8F1'
            }}
          >
            <FcHome size={23} />
          </Avatar>
        </MarkerWithLabel>
      )}
      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{
            ...optionsDirections,
            preserveViewport: !centerDirections,
          }}
        />
      )}
      {polygons && polygons.map((polygon) => (
        <Polygon
          paths={polygon?.paths}
          options={optionsPolygon}
        />
      ))}
    </GoogleMap>
  );
}));

GoogleMapsParcel.propTypes = {
  styles: PropTypes.array,
  addressA: PropTypes.object,
  addressB: PropTypes.object,
  setAddressB: PropTypes.func,
  polygons: PropTypes.array,
  checkAddressContain: PropTypes.func,
  toggleWarning: PropTypes.func,
  draggableB: PropTypes.bool,
  driver: PropTypes.object,
  origin: PropTypes.object,
  destination: PropTypes.object,
  centerDirections: PropTypes.bool,
  setCenterDirections: PropTypes.func
};

GoogleMapsParcel.defaultProps = {
  styles: [],
  addressA: {},
  addressB: {},
  setAddressB: () => {},
  polygons: [],
  checkAddressContain: () => {},
  toggleWarning: () => {},
  draggableB: false,
  driver: {},
  origin: {
    lat: 0,
    lng: 0
  },
  destination: {
    lat: 0,
    lng: 0
  },
  centerDirections: true,
  setCenterDirections: () => {},
};

export default GoogleMapsParcel;
