import HttpRequest from './HttpRequest';

export default class CountryService extends HttpRequest {
  static endpoint = 'countries';

  static async getAll() {
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    const response = await this.put(id, data);
    return response;
  }
}
