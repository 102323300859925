import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import pusher from 'src/utils/pusher';
import { useSelector, useDispatch } from 'react-redux';
import { selector as UserSelector } from 'src/redux/ducks/user';
import { actions as AlertActions } from 'src/redux/ducks/alert';
import OrderService from 'src/services/OrderService';
import BranchOfficeService from 'src/services/BranchOfficeService';
import { Outlet, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Timer from 'src/components/Timer';
import SupportModal from 'src/components/SupportModal';
import ToolbarManagers from '../../views/managers-dash/ToolbarManagers';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f6fbf4',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: '100%'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: '100%'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardManagerLayout = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { user, token } = useSelector(UserSelector);
  const dispatch = useDispatch();
  const [branchOffice, setBranchOffice] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('created');
  const [numberPage, setNumberPage] = useState(1);
  const [pendingOrders, setPendingOrders] = useState(null);
  const [timers, setTimers] = useState([]);
  const [inProgressOrders, setInProgressOrders] = useState(null);
  const [sentOrders, setSentOrders] = useState(null);
  const [sentLocalOrders, setSentLocalOrders] = useState(null);
  const [toBeSentOrders, setToBeSentOrders] = useState(null);
  const [finishedOrders, setFinishedOrders] = useState(null);
  const [cancelledOrders, setCancelledOrders] = useState(null);
  const [allOrders, setAllOrders] = useState(null);
  const [date] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [supportModal, setSupportModal] = useState(true);
  const refInterval = useRef(null);

  const toggleSupportModal = () => {
    setSupportModal(!supportModal);
  };

  const updateTimer = (timer, index) => {
    if (timers?.length === pendingOrders?.orders?.length) {
      timers[index] = timer;
      setTimers(timers.slice());
    }
  };

  const getCreatAt = (createAt) => {
    const createdAt = new Date(createAt);
    createdAt.setMinutes(createdAt.getMinutes() + 5);
    return createdAt;
  };

  const getTimeRemainder = (createdAt) => {
    // Obtiene la diferencia de tiempo desde que se creo la orden y la hora actual
    const total = Date.parse(createdAt) - Date.parse(new Date());
    const sec = Math.floor((total / 1000) % 60); // Obtenemos los segundos
    const min = Math.floor((total / 1000 / 60) % 60); // Obtenemos los minutos
    return { total, sec, min };
  };

  // Me retorna el formato del contador (00:00)
  const getFormatTimer = (time) => {
    const { min, sec } = time;
    return `${min > 9 ? min : `0${min}`}:${sec > 9 ? sec : `0${sec}`}`;
  };

  // Me permite inicializar el contador, por donde va en tiempo real
  const getInitTimers = (data) => {
    return data?.map((item) => {
      const createdAt = getCreatAt(item?.order_status[0]?.created_at);
      const { total, min, sec } = getTimeRemainder(createdAt);
      if (total >= 0) {
        let warning = false;
        if ((min === 2 && sec === 0) || (min < 2 && min >= 0 && sec >= 0)) {
          warning = true;
        }
        return {
          id: item.id,
          reference: item?.reference,
          warning,
          createdAt: item?.order_status[0]?.created_at,
          time: getFormatTimer({ min, sec }),
          idInterval: null
        };
      }
      return {
        id: item.id,
        reference: item?.reference,
        warning: false,
        createdAt: item?.order_status[0]?.created_at,
        time: '00:00',
        idInterval: null
      };
    });
  };

  const fetchBranchOfficeData = async () => {
    const response = await BranchOfficeService.getById(user?.branch_office_id);
    if (response?.code === 200) {
      setBranchOffice(response.data);
      setIsOpen(response.data?.is_working);
    }
  };

  const getOrdersStatus = async (status, page) => {
    const response = await OrderService.getByStatus(
      user?.branch_office_id,
      status,
      page,
      10,
      date || null,
      user?.branch_office?.is_develop
    );
    if (response?.data) {
      switch (status) {
        case 'in_progress':
          setInProgressOrders(response?.data);
          break;
        case 'finished':
          setFinishedOrders(response?.data);
          break;
        case 'cancelled':
          setCancelledOrders(response?.data);
          break;
        case 'pending_to_be_sent':
          setToBeSentOrders(response?.data);
          break;
        case 'sent_local':
          setSentLocalOrders(response?.data);
          break;
        case 'sent':
          setSentOrders(response?.data);
          break;
        case 'created':
          setPendingOrders(response?.data);
          setTimers(getInitTimers(response?.data?.orders));
          break;
        default:
          break;
      }
    }
  };

  const getOrdersAll = async (page) => {
    const response = await OrderService.getByBranchOfficeId(
      user?.branch_office_id,
      page,
      10,
      date || null,
      user?.branch_office?.is_develop
    );

    if (response?.data) {
      setAllOrders(response?.data);
    }
  };

  const getOrders = () => {
    getOrdersStatus('created', filter === 'created' ? numberPage : 1);
    getOrdersStatus('in_progress', filter === 'in_progress' ? numberPage : 1);
    getOrdersStatus(
      'pending_to_be_sent',
      filter === 'pending_to_be_sent' ? numberPage : 1
    );
    getOrdersStatus('sent', filter === 'sent' ? numberPage : 1);
    getOrdersStatus('sent_local', filter === 'sent_local' ? numberPage : 1);
    getOrdersStatus('finished', filter === 'finished' ? numberPage : 1);
    getOrdersStatus('cancelled', filter === 'cancelled' ? numberPage : 1);
    getOrdersAll(filter === '' ? numberPage : 1);
  };

  const refresh = async () => {
    if (user?.id) {
      getOrders();
    }
  };

  const cancelOrder = async (id) => {
    const response = await OrderService.updateStatus(id, {
      status: 'cancelled'
    });
    if (response?.success) {
      refresh();
    }
  };

  useEffect(() => {
    if (user?.id) {
      const channel = pusher.subscribe(
        `BRANCHOFFICE_${user?.branch_office_id}`
      );

      [
        'create-order',
        'changes-orders',
        'changes-is-working',
        'new-order',
        'create-order-product',
        'create-order-status',
        'command-order',
        'finish-order',
        'client-cancel-order',
        'manager-cancel-order'
      ].map((item) => {
        channel.bind(item, (message) => {
          if (['create-order']?.some((event) => event === item)) {
            refresh();
          }
          if (['changes-orders']?.some((event) => event === item)) {
            refresh();
          }

          if (['changes-is-working']?.some((event) => event === item)) {
            setIsOpen(message?.message?.is_working);
          }
        });
        return item;
      });
    }
  }, [user]);

  useEffect(() => {
    refresh();
  }, [user, numberPage]);

  useEffect(() => {
    if (user) {
      fetchBranchOfficeData();
    }
  }, [user]);

  useEffect(() => {
    if (pendingOrders?.orders?.length > 0 && !refInterval.current) {
      dispatch(AlertActions.set());
      const id = setInterval(() => {
        dispatch(AlertActions.set());
      }, 3000);
      refInterval.current = id;
    } else if (pendingOrders?.orders?.length === 0 && refInterval.current) {
      clearInterval(refInterval.current);
      refInterval.current = null;
    }
  }, [pendingOrders?.orders?.length]);

  useEffect(() => {
    if (!token || (user && (!token || !user?.is_manager)))
      navigate('/auth', { replace: true });
  }, [user, token]);

  return (
    <div className={classes.root}>
      {timers?.length === pendingOrders?.orders?.length &&
        timers?.map((timer, index) => (
          <Timer
            valueTime={5}
            setTimer={updateTimer}
            index={index}
            timer={timer}
            cancelOrder={cancelOrder}
          />
        ))}
      <ToolbarManagers
        branchOffice={branchOffice}
        totalOrders={allOrders?.page_info?.total || 0}
        pendingOrders={pendingOrders?.page_info?.total || 0}
        isOpen={isOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet
              context={{
                branchOffice,
                filter,
                setFilter,
                numberPage,
                setNumberPage,
                pendingOrders,
                timers,
                inProgressOrders,
                sentOrders,
                sentLocalOrders,
                toBeSentOrders,
                finishedOrders,
                cancelledOrders,
                allOrders,
                refresh,
                isOpen
              }}
            />
          </div>
        </div>
      </div>
      {supportModal && <SupportModal toggleSupportModal={toggleSupportModal} />}
    </div>
  );
};

export default DashboardManagerLayout;
