import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PlacesAutocomplete, {
  getLatLng, geocodeByAddress,
} from 'react-places-autocomplete';
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Room } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  searchInput: {
    outline: 'none',
    border: 'none',
    background: '#f6f6fa',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4.7),
    borderRadius: 50,
    width: '100%',
    color: '#717375',
    fontWeight: 500,
    fontSize: 14,
    fontFamily: '"Poppins", sans-serif',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;'
  },
  iconTitle: {
    fontSize: 27,
    position: 'absolute',
    top: '50%',
    left: 24,
    transform: 'translateY(-50%)'
  },
  dropDown: {
    maxHeight: 355,
    overflow: 'auto',
    marginTop: theme.spacing(2),
    borderBottom: '1px solid #ddd',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  },
  suggestionItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.7),
    cursor: 'pointer',
    '&:hover': {
      background: '#f6f6fa'
    },
    borderTop: '1px solid #ddd',
  },
}));

const searchOptions = {
  componentRestrictions: {
    country: ['co']
  }
};

const AddressSearch = ({
  address,
  handleChange,
  handlePosition,
  nextStep,
  handleAddress,
}) => {
  const classes = useStyles();

  const handleSelect = async (selectAddress) => {
    nextStep();
    handleChange(selectAddress);
    const results = await geocodeByAddress(selectAddress);
    const { lat, lng } = await getLatLng(results[0]);
    handlePosition(lat, lng);
    handleAddress(selectAddress, {
      lat,
      lng
    });
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading
      }) => (
        <div>
          <Box
            position="relative"
            px={2}
          >
            <Room
              className={classes.iconTitle}
              color="primary"
            />
            <input
              {...getInputProps({
                autoFocus: true,
                placeholder: 'Busca tu dirección aquí...',
                className: classes.searchInput
              })}
            />
          </Box>
          <div className={classes.dropDown}>
            {loading && (
              <Box
                height={355}
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <CircularProgress size={50} />
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{
                      fontSize: 17,
                      fontWeight: 600,
                      marginTop: 12
                    }}
                  >
                    Buscando dirección...
                  </Typography>
                </Box>
              </Box>
            )}
            {!loading && suggestions.map((suggestion) => {
              const className = clsx(
                classes.suggestionItem
              );
              // inline style for demonstration purpose
              return (
                <div
                  key={suggestion?.id}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                  })}
                >
                  <Room
                    style={{
                      fontSize: 27
                    }}
                    color="primary"
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    ml={0.5}
                  >
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontSize: 17, fontWeight: 600 }}
                    >
                      {suggestion?.formattedSuggestion?.mainText}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ fontSize: 13 }}
                    >
                      {suggestion?.formattedSuggestion?.secondaryText}
                    </Typography>
                  </Box>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

AddressSearch.propTypes = {
  address: PropTypes.string,
  handleChange: PropTypes.func,
  handlePosition: PropTypes.func,
  nextStep: PropTypes.func,
  handleAddress: PropTypes.func,
  variant: PropTypes.string
};

export default AddressSearch;
