import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Container, Grid, makeStyles } from '@material-ui/core';
import ToppingService from 'src/services/ToppingService';
import Page from 'src/components/Page';
import LoadingBox from 'src/components/LoadingBox';
import ToppingsAside from 'src/components/ToppingsAside/ToppingsAside';
import CreateToppingModal from 'src/components/CreateToppingModal';
import ProductCardPreview from './ProductCardPreview';
import CreateProductForm from './CreateProductForm';
import ProductService from '../../../services/ProductService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateProductView = ({ edit }) => {
  const classes = useStyles();
  const [showCreateTopping, setShowCreateTopping] = useState(false);
  const [showAside, setShowAside] = useState(false);
  const { id } = useParams();
  const [hasImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [imgScale, setImgScale] = useState('3:4');
  const [product, setProduct] = useState({
    id: null,
    name: '',
    primary_image: '',
    description: '',
    price: 0,
    reference: '',
    sku: '',
    components: [],
    estimated_time: '',
    is_featured: '',
    has_time: '',
    on_sale: '',
    is_combo: '',
    show_by_schedule: '',
    estimated_preparation_time: {},
    new_customize: {
      sections: []
    },
    business: '',
    sizes: [],
    color_id: null,
    seo: {
      tags: [],
      seoDescription: "",
      slug: '' 
    }

  });
  const [listToppings, setListToppings] = useState([]);

  const toggleAside = () => {
    setShowAside(!showAside);
  };

  const toggleCreateTopping = () => {
    setShowCreateTopping(!showCreateTopping);
  };

  const handleCustomize = (sections) => {
    setProduct({
      ...product,
      new_customize: {
        sections
      }
    });
  };

  const handleProductAttributes = (values) => {
    setProduct(values);
  };

  const getProduct = async () => {
    const response = await ProductService.getById(id);
    if (response?.code === 200) {
      setProduct({
        ...response?.data
      });
    }
  };

  const getToppings = async () => {
    try {
      const response = await ToppingService.getAll();
      if (response?.code === 200) {
        setListToppings(response?.data);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  const refresh = () => {
    getToppings();
  };

  useEffect(() => {
    getToppings();
    if (id) {
      getProduct();
    }
  }, [id]);

  return (
    <LoadingBox loading={loading}>
      <Page className={classes.root} title="Crear Categoría">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <ProductCardPreview
                product={product}
                hasImage={hasImage}
                setImgScale={setImgScale}
                setPreviewImage={setPreviewImage}
                previewImage={previewImage}
                scale={imgScale}
              />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <CreateProductForm
                previewImage={previewImage}
                toggleAside={toggleAside}
                edit={edit}
                hasImage={hasImage}
                product={product}
                onChange={handleProductAttributes}
                setLoading={setLoading}
                imgScale={imgScale}
              />
            </Grid>
          </Grid>
        </Container>
        <ToppingsAside
          toppings={listToppings}
          toppingsAttachment={product?.new_customize?.sections}
          onChangeCustomize={handleCustomize}
          toggleAside={toggleAside}
          show={showAside}
          toggleModal={toggleCreateTopping}
        />
        {showCreateTopping && (
          <CreateToppingModal
            toggleCreateTopping={toggleCreateTopping}
            onRefresh={refresh}
          />
        )}
      </Page>
    </LoadingBox>
  );
};

CreateProductView.propTypes = {
  edit: PropTypes.bool
};

CreateProductView.defaultProps = {
  edit: false
};

export default CreateProductView;
