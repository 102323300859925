import React from 'react';
import PropTypes from 'prop-types';
import { SortableElement } from 'react-sortable-hoc';

import { TableRow } from '@material-ui/core';

const TableRowSortable = ({ className, children }) => (
  <TableRow className={className}>
    {children}
  </TableRow>
);

TableRowSortable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

TableRowSortable.defaultProps = {
  children: '',
  className: ''
};

export default SortableElement(TableRowSortable);
