import axios from 'axios';
import HttpRequest from './HttpRequest';

export default class EnvironmentService extends HttpRequest {
  static endpoint = 'environment';

  static async getByKey(key) {
    try {
      const uri = `https://us-central1-adomi-dev.cloudfunctions.net/InitHandler/environment/find?key=${key}`;
      const response = await axios.get(uri);

      if (response?.status === 200) {
        return {
          data: response?.data,
          code: 200,
          message: 'Environment image',
          errors: [],
        };
      }

      return {
        data: null,
        code: 404,
        message: '',
        errors: ['No se encontró un registro con esa key'],
      };
    } catch (e) {
      console.log(e);
      return {
        data: null,
        code: 500,
        message: '',
        errors: [e.message]
      };
    }
  }
}
