import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  cardForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;',
  },
  avatar: {
    width: 70,
    height: 70
  },
  buttonAvatar: {
    borderRadius: '50%',
    minWidth: 30,
    padding: 7,
  }
}));

const FormBrand = ({
  nextStep,
  setBrand
}) => {
  const classes = useStyles();
  const refInput = useRef();
  const [previewImage, setPreviewImage] = useState(null);

  const onUpload = () => {
    refInput.current.click();
  };

  useEffect(() => {
    refInput?.current?.addEventListener('change', () => {
      setPreviewImage(URL.createObjectURL(refInput?.current?.files[0]));
    });
  }, []);

  return (
    <>
      <Typography
        color="textPrimary"
        gutterBottom
        variant="body2"
        style={{
          marginTop: 15,
          textAlign: 'center'
        }}
      >
        1. Crea tu marca de adomi logistica
      </Typography>
      <Formik
        initialValues={{
          name: '',
          description: ''
        }}
        validationSchema={
          Yup.object().shape({
            name: Yup.string().max(255).required('El nombre es requerido'),
            description: Yup.string().max(255).required('La descripción es requerido'),
          })
        }
        onSubmit={(values) => {
          const data = {
            name: values.name,
            description: values.description,
            file: refInput?.current?.files[0]
          };
          setBrand(data);
          nextStep();
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            className={classes.root}
            onSubmit={handleSubmit}
          >
            <Card
              className={classes.cardForm}
            >
              <Box
                display="flex"
                alignItems="center"
              >
                <Box
                  position="relative"
                  mr={3}
                >
                  <Avatar
                    className={classes.avatar}
                    alt="brand"
                    src={previewImage || ''}
                  />
                  <Box
                    position="absolute"
                    bottom={0}
                    right={-5}
                  >
                    <Button
                      className={classes.buttonAvatar}
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={onUpload}
                    >
                      <CameraAlt style={{ fontSize: '16px' }} />
                    </Button>
                    <input
                      name="logo[]"
                      type="file"
                      accept="image/*"
                      ref={refInput}
                      style={{ display: 'none' }}
                    />
                  </Box>
                </Box>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Nombre"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={touched.description && errors.description}
                      label="Descripción"
                      margin="normal"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
            <Box my={2}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Crear Marca
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

FormBrand.propTypes = {
  nextStep: PropTypes.func,
  setBrand: PropTypes.func
};

export default FormBrand;
