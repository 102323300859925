import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import ManagerService from '../../../services/ManagerService';
import BranchOfficeService from '../../../services/BranchOfficeService';
import CountryService from '../../../services/CountryService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white'
    }
  }
}));

const CreateManagerForm = forwardRef(
  (
    {
      className,
      manager,
      edit,
      onChange,
      hasImage,
      setLoading,
      typeForm,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { setErrors } = useErrors();
    const { toggleModal } = useConfirmation();
    const [countrySelected, setCountrySelected] = useState('');
    const [countries, setCountries] = useState([]);
    const [branchOfficeSelected, setBranchOfficeSelected] = useState('');
    const [branchOffices, setBranchOffices] = useState([]);

    const handleChange = (event) => {
      onChange({
        [event.target.name]: event.target.value
      });
    };

    const handleCountry = (event) => {
      setCountrySelected(event.target.value);
      handleChange(event);
    };

    const handleBranchOffice = (event) => {
      setBranchOfficeSelected(event.target.value);
      handleChange(event);
    };

    const getValuesForm = (type, e) => {
      if (type === 'password') {
        return {
          first_name: manager?.first_name,
          last_name: manager?.last_name,
          avatar: manager?.avatar || '',
          email: manager?.email,
          phone: Number(manager?.phone),
          branch_office_id: manager?.branch_office_id,
          country_id: manager?.country_id,
          is_partner: manager?.is_partner,
          is_marketing: manager?.is_marketing,
          is_photographer: manager?.is_photographer,
          is_order_taker: manager?.is_order_taker,
          password: e.target?.password?.value
        };
      }
      return {
        first_name: e.target?.first_name?.value,
        last_name: e.target?.last_name?.value,
        avatar: e.target?.avatar?.value,
        email: e.target?.email?.value,
        phone: Number(e.target?.phone?.value),
        branch_office_id: e.target?.branch_office_id?.value,
        country_id: e.target?.country_id?.value,
        is_partner: e.target?.is_partner?.value,
        is_marketing: e.target?.is_marketing?.value,
        is_photographer: e.target?.is_photographer?.value,
        is_order_taker: e.target?.is_order_taker?.value,
        password: e.target?.password?.value
      };
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const errors = [];
      const data = getValuesForm(typeForm, e);

      if (data?.first_name?.length === 0) {
        errors.push('El nombre del gerente es obligatorio');
      }
      if (data?.last_name?.length === 0) {
        errors.push('Los apellidos del gerente son obligatorios');
      }

      if (data?.is_partner.length === 0) {
        errors.push('Es administrador, es un campo obligatorio');
      }
      if (data?.is_marketing.length === 0) {
        errors.push('Es marketing,  es un campo obligatorio');
      }
      if (data?.is_photographer.length === 0) {
        errors.push('Es fotografo, es un campo obligatorio');
      }
      if (data?.is_order_taker.length === 0) {
        errors.push('Recibidor de ordenes, es un campo obligatorio');
      }
      if (data?.phone.length === 0) {
        errors.push('El teléfono del gerente es obligatorio');
      }
      if (data?.email.length === 0) {
        errors.push('El email del gerente es obligatorio');
      }
      if (data?.password?.length === 0) {
        errors.push('La contraseña es obligatoria');
      }
      if (data?.password) {
        if (data?.password.length < 8) {
          errors.push('La contraseña debe tener 8 caracteres como mínimo');
        }
      }

      if (data?.first_name.length === 0 || data.phone === '') {
        setErrors(errors);
        return;
      }

      if (errors.length > 0) {
        setErrors(errors);
        return;
      }

      setLoading(true);

      const response = edit
        ? await ManagerService.update(manager?.id, data)
        : await ManagerService.create(data);

      if (response?.code === 201 || response?.code === 200) {
        setErrors([]);
        if (
          response.data.avatar !== null &&
          ref.current &&
          ref.current.files[0] !== undefined
        ) {
          await ManagerService.deleteImage(response.data.id);
          const formData = new FormData();
          formData.append('file', ref.current.files[0]);
          formData.append('id', response.data.id);
          await ManagerService.uploadImage(formData);
        }
        if (
          response.data.avatar === null &&
          ref?.current?.files !== undefined
        ) {
          const formData = new FormData();
          formData.append('file', ref.current.files[0]);
          formData.append('id', response.data.id);
          await ManagerService.uploadImage(formData);
        }
        setLoading(false);
        navigate('/app/gerentes');
      } else {
        setErrors(response.errors);
        setLoading(false);
      }
    };

    const deleteManager = async () => {
      if (manager?.id) {
        await ManagerService.deleteImage(manager?.id);
        const response = await ManagerService.remove(manager?.id);
        if (!response) {
          navigate('/app/gerentes', { replace: true });
        }
      }
    };

    useEffect(() => {
      (async () => {
        const response = await CountryService.getAll();

        if (response?.code !== 200) {
          setErrors(['Ocurrió un error al intentar mostrar los países']);
          return;
        }

        setCountries(response.data);
      })();
    }, []);

    useEffect(() => {
      setCountrySelected(manager?.country_id);
    }, [manager?.country_id]);

    useEffect(() => {
      (async () => {
        const response = await BranchOfficeService.getAll();

        if (response?.code !== 200) {
          setErrors(['Ocurrió un error al intentar mostrar las sucursales']);
          return;
        }

        setBranchOffices(response.data);
      })();
    }, []);

    useEffect(() => {
      setBranchOfficeSelected(manager?.branch_office_id);
    }, [manager?.branch_office_id]);

    return (
      <>
        {typeForm === 'password' ? (
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardHeader
                subheader="La información puede ser editada"
                title="Gerente"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Contraseña"
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={manager?.password}
                      variant="outlined"
                    />
                  </Grid>
                  {hasImage && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Perfil"
                        name="avatar"
                        onChange={handleChange}
                        required
                        value={manager?.avatar}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  <input
                    name="perfil[]"
                    type="file"
                    ref={ref}
                    style={{ display: 'none' }}
                  />
                </Grid>
              </CardContent>
              <Errors time={0} />
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                {edit && (
                  <Box mr={2}>
                    <Button
                      type="button"
                      onClick={() => toggleModal()}
                      className={classes.error}
                      variant="contained"
                    >
                      Eliminar
                    </Button>
                  </Box>
                )}
                <Button type="submit" color="primary" variant="contained">
                  {edit ? 'Editar' : 'Guardar'}
                </Button>
              </Box>
            </Card>
            <ConfirmationModal onAccept={deleteManager} />
          </form>
        ) : (
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardHeader
                subheader="La información puede ser editada"
                title="Gerente"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      required
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="pais">Seleccione un país</InputLabel>
                      <Select
                        required
                        name="country_id"
                        labelId="pais"
                        label="Selecciona un país"
                        value={countrySelected}
                        onChange={handleCountry}
                      >
                        <MenuItem value="0" />
                        {countries.map((country) => (
                          <MenuItem key={country.id} value={country.id}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      required
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="marca">
                        Seleccione una sucursal
                      </InputLabel>
                      <Select
                        required
                        name="branch_office_id"
                        labelId="sucursal"
                        label="Selecciona una sucursal"
                        value={branchOfficeSelected}
                        onChange={handleBranchOffice}
                      >
                        <MenuItem value="0" />
                        {branchOffices.map((branchOffice) => (
                          <MenuItem
                            key={branchOffice?.id}
                            value={branchOffice?.id}
                          >
                            {branchOffice?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Nombre completo"
                      name="first_name"
                      onChange={handleChange}
                      required
                      value={manager?.first_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Apellidos"
                      name="last_name"
                      onChange={handleChange}
                      required
                      value={manager?.last_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      required
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="isPartner">Es Administrador</InputLabel>
                      <Select
                        required
                        name="is_partner"
                        labelId="isPartner"
                        label="Es Administrador"
                        value={manager?.is_partner}
                        onChange={handleChange}
                      >
                        {[true, false].map((answer) => (
                          <MenuItem key={answer.toString()} value={answer}>
                            {answer.toString() === 'true' ? 'Si' : 'No'}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      required
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="isMarketing">Es marketing</InputLabel>
                      <Select
                        required
                        name="is_marketing"
                        labelId="isMarketing"
                        label="Es marketing"
                        value={manager?.is_marketing}
                        onChange={handleChange}
                      >
                        {[true, false].map((answer) => (
                          <MenuItem key={answer.toString()} value={answer}>
                            {answer.toString() === 'true' ? 'Si' : 'No'}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      required
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="isPhotographer">Es fotografo</InputLabel>
                      <Select
                        required
                        name="is_photographer"
                        labelId="isPhotographer"
                        label="Es fotografo"
                        value={manager?.is_photographer}
                        onChange={handleChange}
                      >
                        {[true, false].map((answer) => (
                          <MenuItem key={answer.toString()} value={answer}>
                            {answer.toString() === 'true' ? 'Si' : 'No'}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      required
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="isOrderTaker">
                        Recibidor de ordenes
                      </InputLabel>
                      <Select
                        required
                        name="is_order_taker"
                        labelId="isOrderTaker"
                        label="Es recibidor de pedidos"
                        value={manager?.is_order_taker}
                        onChange={handleChange}
                      >
                        {[true, false].map((answer) => (
                          <MenuItem key={answer.toString()} value={answer}>
                            {answer.toString() === 'true' ? 'Si' : 'No'}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Teléfono"
                      name="phone"
                      onChange={handleChange}
                      required
                      value={manager?.phone}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      onChange={handleChange}
                      required
                      value={manager?.email}
                      variant="outlined"
                    />
                  </Grid>
                  {!edit && (
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Contraseña"
                        name="password"
                        onChange={handleChange}
                        type="password"
                        value={manager?.password}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  {hasImage && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Perfil"
                        name="avatar"
                        onChange={handleChange}
                        required
                        value={manager?.avatar}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  <input
                    name="perfil[]"
                    type="file"
                    ref={ref}
                    style={{ display: 'none' }}
                  />
                </Grid>
              </CardContent>
              <Errors time={0} />
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                {edit && (
                  <Box mr={2}>
                    <Button
                      type="button"
                      onClick={() => toggleModal()}
                      className={classes.error}
                      variant="contained"
                    >
                      Eliminar
                    </Button>
                  </Box>
                )}
                <Button type="submit" color="primary" variant="contained">
                  {edit ? 'Editar' : 'Guardar'}
                </Button>
              </Box>
            </Card>
            <ConfirmationModal onAccept={deleteManager} />
          </form>
        )}
      </>
    );
  }
);

CreateManagerForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  onChange: PropTypes.func,
  typeForm: PropTypes.string,
  setLoading: PropTypes.func,
  manager: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
    email: PropTypes.string,
    country_id: PropTypes.string,
    branch_office_id: PropTypes.string,
    phone: PropTypes.number,
    is_partner: PropTypes.bool,
    is_marketing: PropTypes.bool,
    is_photographer: PropTypes.bool,
    is_order_taker: PropTypes.bool,
    password: PropTypes.string
  })
};

CreateManagerForm.defaultProps = {
  className: '',
  edit: false,
  hasImage: false,
  typeForm: '',
  onChange: () => {},
  setLoading: () => {},
  manager: {
    id: null,
    first_name: '',
    last_name: '',
    avatar: '',
    email: '',
    phone: '',
    country_id: '',
    branch_office_id: '',
    password: ''
  }
};

export default CreateManagerForm;
