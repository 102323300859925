import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ProductComponentService from 'src/services/ProductComponentService';
import LoadingBox from 'src/components/LoadingBox';
import ProductComponentCardPreview from './ProductComponentCardPreview';
import CreateProductComponentForm from './CreateProductComponentForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateProductView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [hasImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [component, setComponent] = useState({
    id: null,
    name: '',
    image: '',
    price: 0.00,
    adomi_category_id: '',
  });

  const inputRef = useRef();

  const handleProductAttributes = (values) => {
    setComponent(values);
  };

  const handleUploadImage = () => inputRef.current.click();

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await ProductComponentService.getById(id);
        if (response?.code === 200) {
          setComponent({
            id: response?.data?.id,
            name: response?.data?.name,
            price: response?.data?.price,
            image: response?.data?.image,
          });
        }
      })();
    }
  }, [id]);

  return (
    <LoadingBox loading={loading}>
      <Page
        className={classes.root}
        title="Crear Categoría"
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
            >
              <ProductComponentCardPreview
                ref={inputRef}
                component={component}
                hasImage={hasImage}
                onUpload={handleUploadImage}
              />
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xs={12}
            >
              <CreateProductComponentForm
                ref={inputRef}
                edit={edit}
                hasImage={hasImage}
                component={component}
                setLoading={setLoading}
                onChange={handleProductAttributes}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </LoadingBox>
  );
};

CreateProductView.propTypes = {
  edit: PropTypes.bool,
};

CreateProductView.defaultProps = {
  edit: false
};

export default CreateProductView;
