import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Card,
  Grid,
  Button,
  Divider,
  CardActions,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';
import useModal from 'src/hooks/useModal';
import Modal from 'src/components/Modal';
import ImageUpload from '../../../components/ImageUploadModal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  avatar: {
    width: 'auto',
    height: 'auto'
  },
  bodyModal: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

const ProductCardPreview = ({
  className,
  product,
  hasImage,
  setImgScale,
  setPreviewImage,
  previewImage,
  scale,
  ...rest
}) => {
  const classes = useStyles();
  const [ isOpen, toggle ] = useModal();

  const handleSave = (data) => {
    setPreviewImage(data?.image);
    setImgScale(data?.scale);
    toggle();
  };

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <CardContent>
          <Grid container spacing={1}>
            <Grid container item xs={3} direction="column" justify="center" alignItems="center">
              <Avatar
                className={classes.avatar}
                alt="product"
                src={previewImage ? URL.createObjectURL(previewImage) : product?.primary_image}
                variant="square"
              />
            </Grid>
            <Grid item xs={9}>
              <Typography
                align="left"
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                {product?.name}
              </Typography>
              <Typography
                align="left"
                color="textPrimary"
                variant="body1"
              >
                {product?.description}
              </Typography>
              <Typography
                align="left"
                color="textPrimary"
                variant="body1"
              >
                {product?.price > 0 ? `COP $${product?.price}` : ''}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        {
          !hasImage && (
            <>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  fullWidth
                  variant="text"
                  onClick={() => toggle()}
                >
                  Subir Imagen
                </Button>
              </CardActions>
            </>
          )
        }
      </Card>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        title="Cargar imagen"
        bodyClass={classes.bodyModal}
      >
        <ImageUpload
          onSave={handleSave}
          product={product}
          image={previewImage}
          scale={scale}
        />
      </Modal>
    </>
  );
};

ProductCardPreview.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object,
  hasImage: PropTypes.bool,
  onUpload: PropTypes.func,
  setImgScale: PropTypes.func,
  setPreviewImage: PropTypes.func,
  previewImage: PropTypes.object,
  scale: PropTypes.string
};

ProductCardPreview.defaultProps = {
  className: '',
  product: {},
  hasImage: false,
  onUpload: () => { },
  setPreviewImage: () => { },
  previewImage: null,
  scale: ''
};

export default ProductCardPreview;
