import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';
import orderBy from 'src/utils/arrays';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useConfirmation from '../../../hooks/useConfirmation';

// utils

const useStyles = makeStyles((theme) => ({
  root: {},
  removeIcon: {
    cursor: 'pointer',
    marginLeft: '1rem'
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  className, data, onRemove, onEdit, ...rest
}) => {
  const classes = useStyles();
  const { toggleModal } = useConfirmation();
  const [selectedItem, setSelectedItem] = useState('');
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const openConfirmationModal = (item) => {
    toggleModal();
    setSelectedItem(item);
  };

  const editItem = (item) => {
    onEdit(item);
  };

  const deleteCustomizeSection = () => {
    onRemove(selectedItem, true);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nombre
                </TableCell>
                <TableCell width="150" />
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(data, 'name').slice(0, limit).map((item) => (
                <TableRow
                  hover
                  key={item}
                >
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {item}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <EditIcon
                      className={classes?.removeIcon}
                      onClick={() => editItem(item)}
                    />
                    <DeleteIcon
                      className={classes?.removeIcon}
                      onClick={() => openConfirmationModal(item)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={data.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <ConfirmationModal onAccept={deleteCustomizeSection} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  data: PropTypes.array.isRequired
};

export default Results;
