import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  makeStyles,
  Box,
  Typography,
  Divider
} from '@material-ui/core';
import {
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';

const useStyles = makeStyles({
  removeIcon: {
    marginTop: '1rem',
    cursor: 'pointer'
  }
});

const HourForm = ({
  hours, withETime, onChange, onRemove
}) => {
  const classes = useStyles();
  const [state, setState] = useState(hours);

  const handleChange = (e) => {
    const value = e?.target?.value;
    const objName = e?.target?.name.split('.');
    let data = {};
    if (objName?.length > 1) {
      const [type, name] = objName;
      data = {
        ...state,
        estimated_delivery_time: {
          ...state.estimated_delivery_time,
          [type]: {
            ...state.estimated_delivery_time?.[type],
            [name]: `${value}`
          }
        }
      };
    } else {
      data = {
        ...state,
        [e?.target?.name]: `${value}:00`
      };
    }

    setState(data);
    onChange(data);
  };

  const handleRemove = () => {
    onRemove(hours);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={5}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          helperText="Hora de apertura"
          label="Inicio"
          name="start"
          onChange={handleChange}
          required
          type="time"
          value={hours?.start}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          helperText="Hora de cierre"
          label="Cierre"
          name="end"
          onChange={handleChange}
          required
          type="time"
          value={hours?.end}
          variant="outlined"
        />
      </Grid>
      <Grid container item xs={2} alignItems="flex-start" justify="center">
        <DeleteOutlineIcon className={classes?.removeIcon} onClick={handleRemove} />
      </Grid>
      {
          withETime && (
            <>
              <Box m={3}>
                <Grid container spacing={3} justifyContent="space-between">
                  <Grid item xs={12}>
                    <Typography color="textPrimary" variant="h6">Tiempo estimado de entrega</Typography>
                  </Grid>
                  <Grid container item spacing={1} xs={12} md={6}>
                    <Grid item xs={12}>
                      <Typography color="textPrimary" variant="h6">Desde:</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Días"
                        name="from.days"
                        onChange={handleChange}
                        requireid
                        value={hours?.estimated_delivery_time?.from?.days}
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Horas"
                        name="from.hours"
                        onChange={handleChange}
                        required
                        value={hours?.estimated_delivery_time?.from?.hours}
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Minutos"
                        name="from.minutes"
                        onChange={handleChange}
                        required
                        value={hours?.estimated_delivery_time?.from?.minutes}
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid container item spacing={1} xs={12} md={6}>
                    <Grid item xs={12}>
                      <Typography color="textPrimary" variant="h6">Hasta:</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Días"
                        name="to.days"
                        onChange={handleChange}
                        required
                        value={hours?.estimated_delivery_time?.to?.days}
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Horas"
                        name="to.hours"
                        onChange={handleChange}
                        required
                        value={hours?.estimated_delivery_time?.to?.hours}
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Minutos"
                        name="to.minutes"
                        onChange={handleChange}
                        required
                        value={hours?.estimated_delivery_time?.to?.minutes}
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </>
          )
        }
    </Grid>
  );
};

HourForm.propTypes = {
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  withETime: PropTypes.bool,
  hours: PropTypes.shape({
    id: PropTypes.string,
    end: PropTypes.string,
    start: PropTypes.string,
    estimated_delivery_time: PropTypes.shape({
      from: {
        days: PropTypes.string,
        hours: PropTypes.string,
        minutes: PropTypes.string,
      },
      to: {
        days: PropTypes.string,
        hours: PropTypes.string,
        minutes: PropTypes.string,
      }
    })
  })
};

HourForm.defaultProps = {
  onChange: () => null,
  onRemove: () => null,
  withETime: false,
  hours: {
    id: '',
    end: '',
    start: '',
    estimated_delivery_time: {
      from: {
        days: '',
        hours: '',
        minutes: '',
      },
      to: {
        days: '',
        hours: '',
        minutes: '',
      }
    }
  }
};

export default HourForm;
