import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  RadioGroup,
  Radio,
  Box,
  Typography,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import {
  ImageOutlined,
} from '@material-ui/icons';
import './ImageUploadModal.css';

const ImageUpload = ({
  onSave,
  image,
  scale,
  product,
  index
}) => {
  const inputRef = useRef();
  const [verticalActived, setVerticalActived] = useState(false);
  const [imgScale, setImgScale] = useState(scale);
  const [previewImage, setPreviewImage] = useState(image);

  const onUpload = () => inputRef.current.click();

  const handleChange = (event) => {
    setImgScale(event.target.value);

    if (event.target.value === '5:3') {
      setVerticalActived(true);
    }
    if (event.target.value === '3:4') {
      setVerticalActived(false);
    }
    if (event.target.value === '1:1') {
      setVerticalActived(false);
    }
  };
  useEffect(() => {
    if (scale === '5:3') {
      setVerticalActived(true);
    }
    if (scale === '3:4') {
      setVerticalActived(false);
    }
    if (scale === '1:1') {
      setVerticalActived(false);
    }
    inputRef?.current?.addEventListener('change', () => {
      setPreviewImage(inputRef?.current?.files[0]);
    });
  }, []);

  const handleSave = (e) => {
    if (Array.from(e?.target?.classList).some((c) => c?.includes('css-') || c?.includes('MuiButton-label'))) return;

    onSave({
      index,
      image: previewImage,
      scale: imgScale
    });
  };
  return (
    <Grid>
      <div className="imageUpload-conatainer">
        <h4 className="imageUpload__title">Importe la imagen y selecione su relación de aspecto</h4>
        <div className={verticalActived ? 'imageUpload__imagePreview-vertical' : 'imageUpload__imagePreview-horizontal'}>
          {(previewImage || product?.primary_image) && (
            <img src={previewImage ? URL.createObjectURL(previewImage) : product?.primary_image} alt="" />
          )}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <ImageOutlined
              color="primary"
              style={{
                fontSize: 55
              }}
            />
            <Typography
              variant="body2"
              color="primary"
              style={{
                fontSize: 17,
                fontWeight: 500
              }}
            >
              Selecciona una imagen
            </Typography>
          </Box>
        </div>
        <input name="image[]" type="file" ref={inputRef} style={{ display: 'none' }} />
        <div className="imageUpload__select">
          <FormControl
            disabled={!previewImage}
          >
            <RadioGroup
              row
              onChange={handleChange}
              defaultValue={imgScale || '3:4'}
            >
              <FormControlLabel value="1:1" control={<Radio color="primary" />} label="1:1" />
              <FormControlLabel value="3:4" control={<Radio color="primary" />} label="3:4" />
              <FormControlLabel value="5:3" control={<Radio color="primary" />} label="5:3" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="imageUpload__buttons">
          <button
            type="button"
            className="imageUpload__button-item buttom-confirm"
            onClick={onUpload}
          >
            Examinar
          </button>
          {previewImage && (
            <button
              type="button"
              className="imageUpload__button-item buttom-cancel"
              onClick={handleSave}
            >
              Guardar
            </button>
          )}
        </div>
      </div>
    </Grid>
  );
};

ImageUpload.propTypes = {
  onSave: PropTypes.func,
  product: PropTypes.object,
  scale: PropTypes.string,
  image: PropTypes.object,
  index: PropTypes.number
};

export default ImageUpload;
