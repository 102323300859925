import { createDuck } from 'redux-duck';
import { createStructuredSelector } from 'reselect';

const duck = createDuck('app/errors');

// Define initial state
const initialState = [];

// Define constants
export const SET_ERRORS = duck.defineType('SET_ERRORS');
export const CLEAR_ERRORS = duck.defineType('CLEAR_ERRORS');

// Define actions
export const actions = ({
  set: duck.createAction(SET_ERRORS),
  clear: duck.createAction(CLEAR_ERRORS),
});

// Define selector
export const selector = createStructuredSelector({
  errors: (state) => state.errors,
});

// Define reducer
export default duck.createReducer({
  [SET_ERRORS]: (state, { payload }) => payload,
  [CLEAR_ERRORS]: () => initialState,
}, initialState);
