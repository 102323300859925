import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import orderBy from 'src/utils/arrays';
import {
  Box,
  Typography,
  makeStyles,
  Collapse,
  IconButton,
  useMediaQuery,
  Card,
  Radio,
  TableCell,
  Table,
  useTheme
} from '@material-ui/core';
import {
  Edit,
  Add,
  KeyboardArrowDownRounded,
  Delete,
} from '@material-ui/icons';
import clsx from 'clsx';
import ToppingService from 'src/services/ToppingService';
import CurrencyFormat from 'react-currency-format';
import TableBodySortable from 'src/components/Table/TableBodySortable';
import TableRowSortable from 'src/components/Table/TableRowSortable';
import SortableHandleIcon from 'src/components/Icons/SortableHandleIcon';
import QuantityInput from './QuantityInput';

const Topping = ({
  className,
  view,
  onRefresh,
  toggleCreateTopping,
  toggleCreateItem,
  setTopping,
  setItem,
  topping,
  disAttachTopping,
  indexTopping,
  ...rest
}) => {
  const navigate = useNavigate();
  const matches = useMediaQuery('screen and (min-width: 465px)');
  const [contMaxQuantity, setContMaxQuantity] = useState(0);
  const [valueRadio, setValueRadio] = useState('');
  const [items, setItems] = useState(topping.items.slice());
  const [isShowOptions, setIsShowOptions] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      minHeight: '112px',
      width: '100%'
    },
    icon: {
      cursor: 'pointer',
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      padding: '2px',
      borderRadius: '50%',
      marginRight: '8px'
    },
    btn: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#2e7d32'
      },
      transition: 'background 0.2s ease 0s'
    },
    required: {
      width: 'fit-content',
      backgroundColor: '#ff3364',
      borderBottomRightRadius: '5px',
      color: '#fff',
      padding: '5px 8px',
    },
    description: {
      marginTop: '3px',
      fontSize: '12px',
      color: '#757575'
    },
    dropdown: {
      color: '#757575',
      transform: isShowOptions ? 'rotate(180deg)' : 'rotate(0deg)',
      transition: 'transform 0.3s ease 0s'
    },
    sortableIcon: {
      color: theme.palette.primary.main,
      fontSize: '22px',
      marginRight: '10px'
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
        color: 'white',
      }
    },
  }));

  const classes = useStyles();
  const theme = useTheme();

  const editAction = () => {
    if (!view) {
      setTopping(topping);
      toggleCreateTopping(true);
    } else {
      navigate('/app/productos/toppings');
    }
  };

  const addAction = () => {
    if (!view) {
      setTopping(topping);
      toggleCreateItem(false);
    } else {
      disAttachTopping(indexTopping);
    }
  };

  const increaseContMax = () => {
    setContMaxQuantity(contMaxQuantity + 1);
  };

  const decreaseContMax = () => {
    setContMaxQuantity(contMaxQuantity - 1);
  };

  const handleContMaxQuantity = (value) => {
    setContMaxQuantity(value);
  };

  const onUpdate = async (newItems) => {
    try {
      const response = await ToppingService.update(topping.id, { ...topping, items: newItems });
      if (response?.code === 200 || response?.code === 201) {
        onRefresh();
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const onChange = (index, value) => {
    items[index].value = value;
    setItems(items.slice());
    if (!view) {
      onUpdate(items.slice());
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const payload = arrayMove(
      items.map((item, order) => {
        if (order === oldIndex) {
          return ({ ...item, order: newIndex });
        }
        if (order < oldIndex) {
          return ({ ...item, order: order + 1 });
        }
        return ({ ...item, order });
      }),
      oldIndex,
      newIndex
    );
    const orderItems = orderBy(payload, 'order');
    setItems(orderItems);
    onUpdate(orderItems);
  };

  useEffect(() => {
    if (topping.type === 'radio') setValueRadio('');
    else if (topping.type === 'quantity') setContMaxQuantity(0);
  }, [topping.type]);

  useEffect(() => {
    setItems(topping.items.slice());
  }, [topping.items]);

  useEffect(() => {
    setContMaxQuantity(0);
  }, [topping.max_items]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box width="100%">
        {topping.is_required && (
          <Box className={classes.required}>
            <Typography style={{ fontSize: '11px', fontWeight: 'bold' }}>
              Obligatorio
            </Typography>
          </Box>
        )}
        <Box
          pt={topping.is_required ? 2.5 : 4.5}
          px={matches ? 2 : 1}
          pb={2}
          display="flex"
          flexDirection={matches ? 'row' : 'column'}
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => setIsShowOptions(!isShowOptions)} size="small" aria-label="drop-down">
              <KeyboardArrowDownRounded className={classes.dropdown} />
            </IconButton>
            <Box ml={1} display="flex" flexDirection="column">
              <Typography style={{ fontSize: '16px', wordBreak: 'break-all' }} variant="h5">{topping.title}</Typography>
              <Typography className={classes.description} style={{ wordBreak: 'break-all' }} variant="body2">{topping.description}</Typography>
            </Box>
          </Box>
          <Box pl={5} display="flex" mt={matches ? 0 : 2.5} justifyContent={matches ? 'flex-end' : 'flex-start'} alignItems="center">
            <Box
              onClick={() => editAction()}
              className={clsx(classes.icon, classes.btn)}
            >
              <Edit style={{ color: '#fff', padding: '2px' }} fontSize="small" />
            </Box>
            <Box
              onClick={() => addAction()}
              ml={1}
              className={clsx(classes.icon, classes.btn, { [classes.error]: view })}
            >
              {view && (
                <Delete style={{ padding: '1px' }} fontSize="small" />
              )}
              {!view && (
                <Add style={{ color: '#fff' }} fontSize="small" />
              )}
            </Box>
          </Box>
        </Box>

        <Collapse in={isShowOptions}>
          <Box px={matches ? 2.5 : 1}>
            {topping?.max_items !== 0 && (
              <Box mb={2}>
                <Typography style={{ fontSize: '13px', color: '#43a047' }} className={classes.description} variant="body2">
                  Selecciona máximo
                  {` ${topping.max_items} `}
                  opciones
                </Typography>
              </Box>
            )}
            {items.length > 0 && topping?.type === 'checkbox' && (
              <Box display="flex" justifyContent="flex-end">
                <Box pr={1.5}>
                  <Typography style={{ fontSize: '15px' }} variant="h6">
                    Si
                  </Typography>
                </Box>
                <Box pl={1.8} pr={0.2}>
                  <Typography style={{ fontSize: '15px' }} variant="h6">
                    No
                  </Typography>
                </Box>
              </Box>
            )}
            <Table>
              <TableBodySortable onSortEnd={onSortEnd} lockAxis="y" useDragHandle>
                {items.map((item, index) => (
                  <TableRowSortable
                    hover
                    index={index}
                    key={item?.id}
                  >
                    <TableCell width={871} padding="none" style={{ border: 'none' }}>
                      <Box py={2.5} pt={0} display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          {!view && (
                            <SortableHandleIcon className={classes.sortableIcon} />
                          )}
                          {!view && (
                            <Box
                              onClick={() => {
                                setTopping(topping);
                                setItem(item, index);
                              }}
                              className={clsx(classes.icon, classes.btn)}
                            >
                              <Edit style={{ color: '#fff', padding: '2px' }} fontSize="small" />
                            </Box>
                          )}
                          <Box ml={!view ? 0.5 : 0}>
                            <Typography variant="h5">{item.name}</Typography>
                            <Box mt={0.3} display="flex">
                              {item?.max > 0 && (
                                <Typography
                                  style={{ color: theme.palette.primary.main, margin: '0' }}
                                  className={classes.description}
                                  variant="body2"
                                >
                                  Máximo
                                  {` (${item.max})`}
                                </Typography>
                              )}
                              {item?.price > 0 && (
                                <Typography
                                  style={{
                                    fontWeight: 'bold',
                                    margin: '0',
                                    marginLeft: item?.max > 0 ? '7px' : '0'
                                  }}
                                  className={classes.description}
                                  variant="body2"
                                >
                                  {'+ '}
                                  <CurrencyFormat
                                    value={item?.price}
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="$"
                                  />
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Box>
                        <Box ml={1}>
                          {topping.type === 'radio' && (
                            <Radio
                              style={{ paddingRight: '0' }}
                              checked={valueRadio === item.name}
                              onChange={(e) => setValueRadio(e.target.value)}
                              value={item.name}
                              color="primary"
                            />
                          )}
                          {topping.type === 'checkbox' && (
                            <Box display="flex">
                              <Radio checked={item?.value} onChange={() => onChange(index, true)} color="primary" />
                              <Radio
                                style={{ paddingRight: '0' }}
                                checked={!item?.value}
                                onChange={() => onChange(index, false)}
                                color="primary"
                              />
                            </Box>
                          )}
                          {topping.type === 'quantity' && (
                            <QuantityInput
                              maxItems={topping.max_items}
                              contMaxQuantity={contMaxQuantity}
                              increaseContMax={increaseContMax}
                              decreaseContMax={decreaseContMax}
                              setContMaxQuantity={handleContMaxQuantity}
                              max={item?.max}
                            />
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRowSortable>
                ))}
              </TableBodySortable>
            </Table>
          </Box>
        </Collapse>
      </Box>
    </Card>
  );
};

Topping.propTypes = {
  view: PropTypes.bool,
  onRefresh: PropTypes.func,
  toggleCreateTopping: PropTypes.func,
  toggleCreateItem: PropTypes.func,
  className: PropTypes.string,
  setTopping: PropTypes.func,
  setItem: PropTypes.func,
  topping: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    is_required: PropTypes.bool,
    max_items: PropTypes.number,
    order: PropTypes.number,
    items: PropTypes.array,
  }),
  disAttachTopping: PropTypes.func,
  indexTopping: PropTypes.number
};

Topping.defaultProps = {
  view: false
};

export default Topping;
