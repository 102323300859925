import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './LoginManagerView.css';
import {
  Box,
  Button,
  Container,
  TextField,
  InputAdornment,
  CircularProgress,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Phone, Error } from '@material-ui/icons';
import AuthService from '../../services/AuthService';
import { actions as UserActions } from '../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& .MuiInputBase-root': {
      boxSizing: 'border-box',
      borderRadius: 15,
      backgroundColor: '#f3f7fa',
      '&.Mui-focused': {
        border: '0.13em solid #009739'
      },
      '&.Mui-error': {
        border: '0.13em solid #f44336'
      }
    },
    '& .MuiInputBase-root::after, & .MuiInputBase-root::before': {
      display: 'none'
    },
    '& .MuiFilledInput-input': {
      paddingTop: theme.spacing(3.5),
      paddingBottom: theme.spacing(1.5),
      paddingRight: theme.spacing(3.5),
      paddingLeft: theme.spacing(3.5),
      fontFamily: '"Poppins", sans-serif',
      fontSize: 14,
      fontWeight: 500
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(27px, 12px) scale(0.75)'
    },
    '& .MuiInputLabel-filled': {
      transform: 'translate(27px, 22px) scale(1)'
    },
    '& .MuiFilledInput-input:-webkit-autofill': {
      borderRadius: 15
    },
    '& .MuiInputBase-adornedEnd': {
      paddingRight: theme.spacing(3),
      '& .MuiInputAdornment-root': {
        transition: 'color 0.3s ease',
        color: '#8c9baf',
        '& .MuiIconButton-label': {
          transition: 'color 0.3s ease',
          color: '#8c9baf'
        }
      },
      '&.Mui-focused .MuiInputAdornment-root': {
        color: '#009739',
        '& .MuiIconButton-label': {
          color: '#009739'
        }
      },
      '&.Mui-error .MuiInputAdornment-root': {
        color: '#f44336',
        '& .MuiIconButton-label svg': {
          color: '#f44336'
        }
      }
    }
  },
  label: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 14,
    fontWeight: 500,
    color: '#a8aab4'
  },
  button: {
    borderRadius: 50,
    width: 220,
    fontSize: 14,
    paddingTop: theme.spacing(1.4),
    paddingBottom: theme.spacing(1.4)
  },
  conatinerAlerts: {
    backgroundColor: '#f8d7da',
    borderRadius: 10
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '19px',
    marginRight: '4px'
  },
  textAlert: {
    fontSize: '14px',
    wordBreak: 'break-all',
    color: theme.palette.error.main
  }
}));

const colombiaId = 'be8cc509-a489-4ec9-99d7-76b7f48258dd';
const peruInkaId = '0a51f475-1fe0-4403-bead-8f59760c9490';
const venekiaxId = '0a2aa4bd-f482-47ca-9e9c-a209f39b9366';

const regexDigit = /[0-9]/;

const LoginManagerView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const submitButtonRef = useRef();
  const codeInput2 = useRef(null);
  const codeInput3 = useRef(null);
  const codeInput4 = useRef(null);
  const [codeInputs, setCodeInputs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState(null);
  const [error, setError] = useState(null);
  const [countryId, setCountryId] = useState(colombiaId);
  const [codeRequested, setCodeRequested] = useState(false);

  const authenticate = async (e) => {
    setLoading(true);
    e.preventDefault();
    let c = colombiaId;
    const form = e.target;
    const p = Number(form?.phone?.value);

    switch (p) {
      case 4244344499:
        setCountryId(venekiaxId);
        c = venekiaxId;
        break;
      case 934019008:
        setCountryId(peruInkaId);
        c = peruInkaId;
        break;
      default:
        setCountryId(colombiaId);
        c = colombiaId;
    }

    if (!codeRequested) {
      const payload = {
        country_id: c,
        phone: Number(form.phone.value)
      };

      const response = await AuthService.requestCodeManager(payload);

      if (response?.code === 200) {
        setPhone(Number(form.phone.value));
        setCodeRequested(true);
      } else {
        setError('El usuario no existe');
      }
    } else {
      const payload = {
        country_id: countryId,
        sms_token: `${form.sms_token1.value}${form.sms_token2.value}${form.sms_token3.value}${form.sms_token4.value}`,
        phone
      };

      const response = await AuthService.loginManager(payload);

      if (response?.code === 200) {
        dispatch(UserActions.set(response?.data?.data));
        dispatch(UserActions.tokenize(response?.data?.token));
        navigate('/gerente/ordenes', { replace: true });
      } else {
        setError('Código inválido');
      }
    }
    setLoading(false);
  };

  const skipNextInput = (e, ref = null) => {
    if (Number(e?.target?.value) > 9) return;

    const value =
      e?.target?.value.trim().length === 0 ? '' : Number(e?.target?.value);

    setCodeInputs({
      ...codeInputs,
      [e?.target?.name]: value
    });

    if (!ref) {
      submitButtonRef?.current?.click();
    } else if (e?.target?.value.trim().length > 0) {
      ref?.current?.focus();
    }
  };

  const handleSubmit = async (e) => {
    setError(false);
    await authenticate(e);
  };

  // La siguiete maquetación contiene clases de estilos que tienen que ser inportadas de ed-grid.com

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        alignItems="center"
        py={3}
      >
        <form onSubmit={handleSubmit} autoComplete="off">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={2}
            textAlign="center"
          >
            <Typography
              variant="h2"
              color="primary"
              style={{
                fontWeight: 500,
                fontSize: 29
              }}
            >
              ¡Bienvenido de nuevo Gerente!
            </Typography>
            <Typography variant="body2" color="primary">
              {!codeRequested
                ? 'Iniciar sesión como gerente'
                : 'Ingresa tu codigo de verificación que se te envio por WhatssApp'}
            </Typography>
          </Box>
          {!codeRequested && (
            <TextField
              className={classes.input}
              fullWidth
              FormHelperTextProps={{
                style: {
                  fontWeight: 500,
                  fontFamily: '"Poppins", sans-serif'
                }
              }}
              label="Numero de teléfono"
              InputLabelProps={{
                className: classes.label
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Phone />
                  </InputAdornment>
                )
              }}
              margin="normal"
              name="phone"
              type="phone"
              variant="filled"
              disabled={codeRequested}
            />
          )}
          {codeRequested && (
            <Box mb={1}>
              <div className="ed-grid s-grid-4 s-mb-2 container-code">
                <input
                  name="sms_token1"
                  value={codeInputs?.sms_token1 ?? ''}
                  className="input full input-code"
                  onChange={(e) => skipNextInput(e, codeInput2)}
                  onKeyPress={(event) => {
                    if (!regexDigit.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <input
                  ref={codeInput2}
                  name="sms_token2"
                  value={codeInputs?.sms_token2 ?? ''}
                  className="input full input-code"
                  onChange={(e) => skipNextInput(e, codeInput3)}
                  onKeyPress={(event) => {
                    if (!regexDigit.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <input
                  ref={codeInput3}
                  name="sms_token3"
                  value={codeInputs?.sms_token3 ?? ''}
                  className="input full input-code"
                  onChange={(e) => skipNextInput(e, codeInput4)}
                  onKeyPress={(event) => {
                    if (!regexDigit.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <input
                  ref={codeInput4}
                  name="sms_token4"
                  value={codeInputs?.sms_token4 ?? ''}
                  className="input full input-code"
                  onChange={(e) => skipNextInput(e)}
                  onKeyPress={(event) => {
                    if (!regexDigit.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </Box>
          )}
          {error && (
            <Box display="flex" alignItems="center" mt={0.5}>
              <Error className={classes.error} size="small" />
              <Typography className={classes.textAlert} variant="body2">
                {error}
              </Typography>
            </Box>
          )}
          <div className="ed-item">
            <Box my={2} display="flex" justifyContent="center">
              <Button
                className={classes.button}
                color="primary"
                disabled={loading}
                startIcon={loading ? <CircularProgress size={25} /> : ''}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {codeRequested ? 'Validar código' : 'Solicitar código'}
              </Button>
            </Box>
          </div>
        </form>
      </Box>
    </Container>
  );
};

export default LoginManagerView;
