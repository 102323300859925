import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Dialog,
  Typography,
  Button,
  Modal,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import useConfirmation from '../hooks/useConfirmation';

const ConfirmationModal = ({ onAccept, text }) => {
  const matches = useMediaQuery('(min-width:470px)');
  const { modalIsOpen, toggleModal } = useConfirmation();

  const useStyles = makeStyles(() => ({
    buttons: {
      display: 'inline-block',
    },
    text: {
      marginTop: matches ? '0' : '7px',
      marginLeft: matches ? '7px' : '0'
    },

    button: {
      marginLeft: '10px'
    }
  }));

  const classes = useStyles();

  const confirm = () => {
    toggleModal();
    onAccept();
  };

  return (
    <Modal
      open={modalIsOpen}
      onClose={toggleModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Dialog onClose={toggleModal} aria-labelledby="simple-dialog-title" open={modalIsOpen}>
        <Box p={2}>
          <Grid container>
            <Grid item>
              <Box
                display="flex"
                flexDirection={matches ? 'row' : 'column'}
                justifyContent="center"
                alignItems="center"
              >
                <HelpIcon color="primary" fontSize="large" />
                <Typography variant="body2" className={classes.text} align="center">
                  {text}
                </Typography>
              </Box>
            </Grid>
            <Grid item container justify="flex-end">
              <Box mt={1} className={classes.buttons}>
                <Button size="small" onClick={toggleModal}>
                  Cancelar
                </Button>
                <Button
                  className={classes.button}
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={confirm}
                >
                  Aceptar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  onAccept: PropTypes.func,
  text: PropTypes.string
};

ConfirmationModal.defaultProps = {
  onAccept: () => {},
  text: '¿Está seguro que desea eliminar este registro?'
};

export default ConfirmationModal;
