import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Tabs, Tab } from 'react-tabs-scrollable';
import 'react-tabs-scrollable/dist/rts.css';
import './Results.css';
import { ArrowBackIosRounded, ArrowForwardIosRounded, Warning } from '@material-ui/icons';
import { GoSettings } from 'react-icons/go';
import { Pagination } from '@material-ui/lab';
import { getHour } from 'src/utils/formatDate';
import OrderService from 'src/services/OrderService';
import StatusModalInProgress from 'src/components/StatusModalOrder/StatusModalInProgress';
import StatusModalSentLocal from 'src/components/StatusModalOrder/StatusModalSentLocal';
import StatusModalPendingToBeSent from 'src/components/StatusModalOrder/StatusModalPendingToBeSent';
import StatusModalFinished from 'src/components/StatusModalOrder/StatusModalFinished';
import OrderModal from 'src/components/OrderModal';
import PrintModal from 'src/components/PrintModal';
import OrderItem from './components/OrderItem';
import Alerts from './components/Alerts';

const useStyles = makeStyles((theme) => ({
  root: {},
  containerCards: {
    paddingTop: theme.spacing(3),
  },
  orderItem: {
    position: 'relative',
    display: 'flex',
    borderRadius: 20,
  },
  textFilters: {
    color: '#8e8787',
    fontSize: 18,
    fontWeight: 500,
    marginLeft: 10
  },
  buttonIcon: {
    borderRadius: '50%',
    minWidth: 10,
    padding: 6
  },
  ButtonStatus: {
    padding: theme.spacing(2)
  },
  pane: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0,
    borderRadius: 10,
    top: 0,
    cursor: 'zoom-in',
    '&:hover': {
      opacity: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
    transition: 'all 0.2s ease 0s'
  },
  moreVert: {
    cursor: 'pointer'
  },
  statusButton: {
    whiteSpace: 'normal',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    gap: '.8rem',
    borderRadius: '10px',
    padding: '5px 15px',
    backgroundColor: 'rgb(243, 243, 243)',
    border: 'none',
    cursor: 'pointer',
    maxWidth: '150px',
    minWidth: '150px',
    transition: '1s',
    '& > div': {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgb(167, 167, 167)',
      padding: '5px',
      borderRadius: '100px',
      minHeight: '30px',
      minWidth: '30px',
      color: 'white',
      transition: '1s',
    },
    '&:focus': {
      backgroundColor: 'rgb(242, 255, 0)',
      boxShadow: '2px 4px 6px 0px rgba(189,189,189,1)',
      transition: '1s',
      '& > div': {
        backgroundColor: 'rgb(224, 51, 109)',
      }
    }
  },
  bgRed: {
    '& > div': {
      backgroundColor: '#F33D00',
    },
  },
  bgGreen: {
    '& > div': {
      backgroundColor: '#009739',
    },
  },
  statusButtonAll: {
    whiteSpace: 'normal',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    gap: '.8rem',
    borderRadius: '10px',
    maxWidth: '150px',
    minWidth: '150px',
    padding: '5px 15px',
    backgroundColor: 'rgb(242, 255, 0)',
    border: 'none',
    cursor: 'pointer',
    boxShadow: '2px 4px 6px 0px rgba(189,189,189,1)',
    transition: '1s',
    '& > div': {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgb(224, 51, 109)',
      padding: '5px',
      borderRadius: '100px',
      minHeight: '30px',
      minWidth: '30px',
      color: 'white',
      transition: '1s',
    },
  },
  bgWarning: {
    background: '#d95030',
    color: '#fff',
    fontWeight: 600,
    '& > div': {
      backgroundColor: theme.palette.primary.main
    }
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main,
  },
  false: {
    fill: '#aaa'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd',
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  },
  pending: {
    backgroundColor: '#FBC02D',
    color: 'white',
  },
  rejected: {
    backgroundColor: 'red',
    color: 'white'
  },
  accepted: {
    backgroundColor: '#43a047',
    color: 'white',
  },
  finished: {
    backgroundColor: '#1565C0',
    color: 'white',
  },
  in_process: {
    backgroundColor: '#eee'
  },
  sent: {
    color: 'white',
    backgroundColor: '#7B1FA2',
  },
  pagination: {
    '& svg, & button, & li div': {
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  },
  bounce: {
    animation: '$bounce 1s infinite both'
  },
  pulse: {
    animation: '$pulse 1s infinite both'
  },
  pulseCard: {
    animation: '$pulseCard 2s infinite',
  },
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': {
      transform: 'translateY(0)'
    },
    '40%': {
      transform: 'translateY(-30px)'
    },
    '60%': {
      transform: 'translateY(-15px)'
    },
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'translateY(0)',
      boxShadow: '0 0 0 0px rgba(244, 67, 54, 1)',
    },
    '20%, 50%, 80%': {
      transform: 'translateY(0)'
    },
    '40%': {
      transform: 'translateY(-30px)'
    },
    '60%': {
      transform: 'translateY(-15px)'
    },
    '100%': {
      transform: 'translateY(0)',
      boxShadow: '0 0 0 15px rgba(244, 67, 54, 0)'
    }
  },
  '@keyframes pulseCard': {
    '0%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 0px rgba(244, 67, 54, 1)',
    },
    '3.3%': {
      transform: 'scale(1.05)'
    },
    '16.5%': {
      transform: 'scale(1)'
    },
    '33%': {
      transform: 'scale(1.05)'
    },
    '100%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 16px rgba(244, 67, 54, 0)'
    }
  },
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
}));

const initTotal = {
  cancelled: 0,
  finished: 0,
  in_progress: 0,
  pending_to_be_sent: 0,
  sent_local: 0,
  sent: 0,
  created: 0,
};

const initChange = {
  cancelled: false,
  finished: false,
  in_progress: false,
  pending_to_be_sent: false,
  sent_local: false,
  sent: false,
  created: false,
};

const Results = ({
  data,
  numberStatus,
  onChange,
  onRefresh,
  totalOrders,
  numberPage,
  changePage,
  toggleOrder,
  timers,
  pendingOrders,
  filter,
  branchOffice,
  isOpen
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState({ });
  const [whatsappText, setWhatsappText] = useState('');
  const [totalStatus, setTotalStatus] = useState(initTotal);
  const [isChangeStatus, setIsChangeStatus] = useState(initChange);
  const [showOrder, setShowOrder] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [sentLocal, setSentLocal] = useState(false);
  const [pendingToBeSent, setPendingToBeSent] = useState(false);
  const [finished, setFinished] = useState(false);

  const toggleShow = (index) => {
    toggleOrder(index);
  };

  const toggleOrderModal = () => {
    setShowOrder(!showOrder);
  };

  const togglePrintModal = () => {
    setPrintModal(!printModal);
  };

  const statusTranslate = (status, shipType) => {
    switch (status) {
      case 'in_progress':
        return 'En proceso';
      case 'finished':
        return 'Finalizado';
      case 'rejected':
        return 'Rechazado';
      case 'cancelled':
        return 'Cancelado';
      case 'pending_to_be_sent':
        if (shipType === 'adomi') return 'Asignando driver';
        return 'Pendiente por enviar';
      case 'sent_local':
        return 'Enviado por local';
      case 'sent':
        return 'Enviado';
      case 'created':
        return 'Pendiente';
      default:
        return status;
    }
  };
  const statusOrder = (status) => {
    switch (status) {
      case 'CANCELLED':
        return 'rejected';
      case 'IN_PROGRESS':
        return 'accepted';
      case 'PENDING_TO_BE_SENT':
        return 'accepted';
      case 'REJECTED':
        return 'rejected';
      case 'FINISHED':
        return 'finished';
      case 'SENT':
        return 'sent';
      case 'SENT_LOCAL':
        return 'sent';
      default:
        return 'pending';
    }
  };

  const haveToppings = (sections) => {
    return sections.filter((section) => Object.keys(section).length > 0).length === 0;
  };

  const detailProduct = (product) => {
    return !haveToppings(product?.customize?.sections) || product.comment.length > 0;
  };

  const getDetailPrice = (prices) => {
    return prices.find((price) => price.is_default);
  };

  const handleStatus = (status, flag) => {
    if (status === 'in_progress') setInProgress(flag);
    else if (status === 'pending_to_be_sent') setPendingToBeSent(flag);
    else if (status === 'sent_local') setSentLocal(flag);
    else if (status === 'finished') setFinished(flag);
  };

  const getPaymentMethod = (o) => {
    let response;

    switch (o?.payment_method) {
      case 'cash':
        response = 'Efectivo';
        break;
      case 'card':
        response = 'Tarjeta';
        break;
      case 'datafono':
        response = 'Datafono';
        break;
      default:
        response = o?.payment_method;
        break;
    }

    return response;
  };

  const getCurrency = (value) => {
    return value?.toLocaleString('en-US')?.replace(/,/g, '.');
  };

  const getProductList = (products) => { // eslint-disable-line
    const response = products?.reduce((text, product, index) => ( // eslint-disable-line
      index > 0 // eslint-disable-line
        ? text + '(' + product?.amount + 'x) ' + product?.name + ' $' + (product?.has_discount ? getCurrency(product?.discount?.discount_price) : getCurrency(product?.price)) + '%0A' // eslint-disable-line
        : '(' + product?.amount + 'x) ' + product?.name + ' $' + (product?.has_discount ? getCurrency(product?.discount?.discount_price) : getCurrency(product?.price)) + '%0A' // eslint-disable-line
    ), '') // eslint-disable-line

    return response // eslint-disable-line
  };

  const getTotalProducts = (products) => {
    return products?.reduce((amount, product) => (
      amount + product?.total
    ), 0);
  };

  const getCouponValue = (subtotal, coupon) => {
    if (coupon !== null) {
      const { type, value } = coupon;
      if (type === 'amount') return value;
      if (type === 'percentage') return subtotal * (value / 100);
    }
    return 0;
  };

  const getDelivery = (o) => {
    if (o?.status === 'sent') {
      return o?.free_delivery ? `-$ ${getCurrency(o?.delivery_cost)} (Paga el Aliado)` : '$0 (Paga el Cliente)';
    }

    if (o?.status === 'sent_local') {
      return o?.free_delivery ? '(Paga el Aliado)' : '$0 (Paga el Cliente)';
    }

    return '';
  };

  const getTotalPartner = (o) => {
    const couponValue = (o?.coupon_data ? o?.coupon_value : 0);

    if (o?.status === 'sent' && o?.free_delivery) {
      return getTotalProducts(o?.order_products) - o?.delivery_cost - couponValue;
    }
    return getTotalProducts(o?.order_products) - couponValue;
  };

  const newBuildWhatsappText = (o) => {
    return (
      `*HOLA, LLEGÓ UN ADOMI*

*PEDIDO:* #${o?.reference} | ${getHour(o?.created_at)}

*TIPO DE ENVÍO:* ${o?.status === 'sent' ? 'Adomi' : 'Local'}

*FORMA DE PAGO:* ${getPaymentMethod(o)}

*INFORMACIÓN DEL COMERCIO:*

  *_Nombre_*
  ${o?.data_branch_office?.brand?.name} ${o?.branch_office_name}

  *_Dirección_*
  ${o?.data_branch_office?.address.replace(/#/g, 'N°')}
  https://maps.google.com/?q=${o?.data_branch_office?.latitude},${o?.data_branch_office?.longitude}
  
  *_Teléfono_* 
  ${o?.data_branch_office?.phone1}
  https://wa.me/57${o?.data_branch_office?.whatsapp1}

  _*Código para retirar el pedido:* ${o?.delivery_token}_


*RESUMEN DE PRODUCTOS*
${getProductList(o?.order_products)}
----------------------------------------
  *Total: $${getCurrency(getTotalProducts(o?.order_products))}*


*💲 VALOR A PAGAR AL ALIADO:*
  *- Productos:* $${getCurrency(getTotalProducts(o?.order_products))}
  *- Cupón:* ${o?.coupon_data ? `-$${getCurrency(o?.coupon_value)}` : '$0'}
  *- Domicilio:* ${getDelivery(o)}
----------------------------------------
  *Total: $${getCurrency(getTotalPartner(o))}*


*💲 VALOR A COBRAR AL USUARIO:*
  *- Productos:* $${getCurrency(getTotalProducts(o?.order_products))}
  *- Domicilio:* ${o?.free_delivery ? `GRATIS ~($${getCurrency(o?.delivery_cost)})~` : `$${getCurrency(o?.delivery_cost)}`}
  *- Propina:* $${o?.tip ? getCurrency(o?.tip) : 0}
  *- Plataforma:* $0
  *- Cupón:* -$${o?.coupon_data ? getCurrency(o?.coupon_value) : 0}
----------------------------------------
  *Total: $${getCurrency(o?.total)}*


*INFORMACIÓN DE ENTREGA*

  *Efectivo:* $${getCurrency(o?.cash_paid)}

  *_Nombre_*
  ${o?.data_client?.first_name} ${o?.data_client?.last_name}

  *_Dirección de entrega_*
  ${o?.address?.address1.replace(/#/g, 'N°')} ${o?.address?.reference && `(${o?.address?.reference.replace(/#/g, 'N°')})`}
  https://maps.google.com/?q=${o?.address?.latitude},${o?.address?.longitude}

  *_Teléfono_*
  ${o?.data_client?.phone}
  https://wa.me/57${o?.data_client?.phone}


_Una vez entregue la orden, complete la entrega escribiendo: *cerrar ${o?.reference}* [código de entrega]_
`
    );
  };

  const handleOrder = (value) => {
    const text = encodeURIComponent(newBuildWhatsappText(value));
    setWhatsappText(text);
    setOrder(value);
  };

  const updateOrder = async () => {
    try {
      const response = await OrderService.getById(order?.id);
      if (response.success) setOrder(response.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleStatusOrderFilter = (status) => {
    onChange(status);
  };

  const getCountPagination = () => {
    if (filter === 'created') return Math.ceil(numberStatus?.created / 10);
    if (filter === 'in_progress') return Math.ceil(numberStatus?.in_progress / 10);
    if (filter === 'pending_to_be_sent') return Math.ceil(numberStatus?.pending_to_be_sent / 10);
    if (filter === 'sent') return Math.ceil(numberStatus?.sent / 10);
    if (filter === 'sent_local') return Math.ceil(numberStatus?.sent_local / 10);
    if (filter === 'finished') return Math.ceil(numberStatus?.finished / 10);
    if (filter === 'cancelled') return Math.ceil(numberStatus?.cancelled / 10);
    if (filter === '') return Math.ceil(totalOrders / 10);
    return 0;
  };

  const updateStatus = async (status, id) => {
    const response = await OrderService.updateStatus(id, { status });
    if (response?.success) {
      if (showOrder) updateOrder();
      onRefresh();
    }
  };

  const alertChange = (status) => {
    setIsChangeStatus({
      ...isChangeStatus,
      [status]: true,
    });

    setTimeout(() => {
      setIsChangeStatus(initChange);
    }, 1200);
  };

  useEffect(() => {
    if (totalStatus.cancelled < numberStatus.cancelled && filter !== 'cancelled') {
      alertChange('cancelled');
    }
    if (totalStatus.finished < numberStatus.finished && filter !== 'finished') {
      alertChange('finished');
    } else if (totalStatus.in_progress < numberStatus.in_progress && filter !== 'in_progress') {
      alertChange('in_progress');
    } else if (totalStatus.pending_to_be_sent < numberStatus.pending_to_be_sent
      && filter !== 'pending_to_be_sent') {
      alertChange('pending_to_be_sent');
    } else if (totalStatus.sent < numberStatus.sent && filter !== 'sent') {
      alertChange('sent');
    } else if (totalStatus.sent_local < numberStatus.sent_local && filter !== 'sent_local') {
      alertChange('sent_local');
    }
    setTotalStatus(numberStatus);
  }, [numberStatus]);

  useEffect(() => {
    setIsChangeStatus({
      cancelled: numberStatus.cancelled > 0,
      finished: numberStatus.finished > 0,
      in_progress: numberStatus.in_progress > 0,
      pending_to_be_sent: numberStatus.pending_to_be_sent > 0,
      sent_local: numberStatus.sent_local > 0,
      sent: numberStatus.sent > 0,
      created: numberStatus.created > 0,
    });
    setTimeout(() => {
      setIsChangeStatus(initChange);
    }, 1000);
  }, []);

  return (
    <div className="results-layout">
      <div className="results-container">
        <Box display="flex" alignItems="center">
          <h1 style={{ color: '#009739' }}>Gestión de ordenes</h1>
          <Box ml={2.5} display="flex" alignItems="center">
            <GoSettings color="#8e8787" fontSize={25} />
            <Typography className={classes.textFilters} variant="body2">Filtros</Typography>
          </Box>
          {branchOffice && !isOpen && (
            <Box p={1.5} borderRadius={13} width={270} bgcolor="#016fa6" display="flex" alignItems="center" justifyContent="center" ml={2.5}>
              <Warning style={{ color: '#fff', fontSize: 19 }} />
              <Typography
                style={{
                  color: '#fff',
                  fontWeight: 700,
                  margin: '0 5px',
                }}
                variant="body2"
              >
                SUCURSAL CERRADA
              </Typography>
              <Warning style={{ color: '#fff', fontSize: 19 }} />
            </Box>
          )}
          {timers?.length === pendingOrders?.orders?.length && (
            <Alerts warnings={timers?.filter((item) => item?.warning)} />
          )}
        </Box>
        <Tabs
          activeTab={0}
          onTabClick={() => {}}
          leftBtnIcon={(
            <ArrowBackIosRounded
              className={clsx(
                {
                  [classes.pulseCard]: totalStatus.created > 0 && filter !== 'created',
                }
              )}
              style={{
                fontSize: 18,
                color: totalStatus.created > 0 && filter !== 'created' ? 'rgba(244, 67, 54, 1)' : '#009739',
                borderRadius: 10
              }}
            />
          )}
          rightBtnIcon={<ArrowForwardIosRounded style={{ fontSize: 18, color: '#009739' }} />}
        >
          <Tab
            className={clsx(
              filter === 'created' ? classes.statusButtonAll : classes.statusButton,
              {
                [classes.statusButtonAll]: isChangeStatus.created,
                [classes.bgWarning]: totalStatus.created > 0 && filter !== 'created',
                [classes.bounce]: totalStatus.created > 0 && filter !== 'created',
                [classes.pulse]: timers?.length === pendingOrders?.orders?.length
                  && timers.some((timer) => timer?.warning)
              }
            )}
            onClick={() => (handleStatusOrderFilter('created'))}
          >
            <div>{totalStatus?.created}</div>
            <p>Pendientes</p>
          </Tab>
          <Tab
            className={clsx(
              filter === 'in_progress' ? classes.statusButtonAll : classes.statusButton,
              {
                [classes.statusButtonAll]: isChangeStatus.in_progress,
                [classes.bounce]: isChangeStatus.in_progress
              }
            )}
            onClick={() => (handleStatusOrderFilter('in_progress'))}
          >
            <div>{totalStatus?.in_progress}</div>
            <p>En proceso</p>
          </Tab>
          <Tab
            className={clsx(
              filter === 'pending_to_be_sent' ? classes.statusButtonAll : classes.statusButton,
              {
                [classes.bgGreen]: filter !== 'pending_to_be_sent',
                [classes.statusButtonAll]: isChangeStatus.pending_to_be_sent,
                [classes.bounce]: isChangeStatus.pending_to_be_sent
              }
            )}
            onClick={() => (handleStatusOrderFilter('pending_to_be_sent'))}
          >
            <div>{totalStatus?.pending_to_be_sent}</div>
            <p>Por enviar</p>
          </Tab>
          <Tab
            type="button"
            className={clsx(
              filter === 'sent' ? classes.statusButtonAll : classes.statusButton,
              {
                [classes.statusButtonAll]: isChangeStatus.sent,
                [classes.bounce]: isChangeStatus.sent
              }
            )}
            onClick={() => (handleStatusOrderFilter('sent'))}
          >
            <div>{totalStatus?.sent}</div>
            <p>Enviados</p>
          </Tab>
          <Tab
            type="button"
            className={clsx(
              filter === 'sent_local' ? classes.statusButtonAll : classes.statusButton,
              {
                [classes.statusButtonAll]: isChangeStatus.sent_local,
                [classes.bounce]: isChangeStatus.sent_local
              }
            )}
            onClick={() => (handleStatusOrderFilter('sent_local'))}
          >
            <div>{totalStatus?.sent_local}</div>
            <p>Enviados por local</p>
          </Tab>
          <Tab
            type="button"
            className={clsx(
              filter === 'finished' ? classes.statusButtonAll : classes.statusButton,
              {
                [classes.statusButtonAll]: isChangeStatus.finished,
                [classes.bounce]: isChangeStatus.finished
              }
            )}
            onClick={() => (handleStatusOrderFilter('finished'))}
          >
            <div>{totalStatus?.finished}</div>
            <p>Entregados</p>
          </Tab>
          <Tab
            type="button"
            className={clsx(
              filter === 'cancelled' ? classes.statusButtonAll : classes.statusButton,
              {
                [classes.bgRed]: filter !== 'cancelled',
                [classes.statusButtonAll]: isChangeStatus.cancelled,
                [classes.bounce]: isChangeStatus.cancelled
              }
            )}
            onClick={() => (handleStatusOrderFilter('cancelled'))}
          >
            <div>{totalStatus?.cancelled}</div>
            <p>Cancelados</p>
          </Tab>
          <Tab type="button" className={filter === '' ? classes.statusButtonAll : classes.statusButton} onClick={() => (handleStatusOrderFilter(''))}>
            <div>{totalOrders}</div>
            <p>Todos</p>
          </Tab>
        </Tabs>
        <Box pt={2} position="relative">
          <Box width="100%" display="flex" justifyContent="center">
            <Pagination
              size="small"
              className={classes.pagination}
              count={getCountPagination()}
              page={numberPage}
              onChange={(e, page) => changePage(page)}
              color="primary"
            />
          </Box>
          <Grid className={classes.containerCards} container spacing={2}>
            {timers?.length === pendingOrders?.orders?.length
            && data.map((item, index) => {
              const indexTimer = timers?.findIndex((timer) => timer.id === item.id);
              const timer = indexTimer > -1 ? timers[indexTimer] : null;
              return (
                <Grid item xs={12} md={6} lg={4} xl={4} key={item?.id}>
                  <Box
                    className={clsx(
                      classes.orderItem,
                      { [classes.pulseCard]: timer?.warning }
                    )}
                  >
                    <OrderItem
                      order={item}
                      toggleOrderModal={toggleOrderModal}
                      togglePrintModal={togglePrintModal}
                      handleOrder={handleOrder}
                      handleStatus={handleStatus}
                      updateStatus={updateStatus}
                      statusOrder={statusOrder}
                      statusTranslate={statusTranslate}
                      haveToppings={haveToppings}
                      detailProduct={detailProduct}
                      getDetailPrice={getDetailPrice}
                      toggleShow={toggleShow}
                      index={index}
                      timer={timer}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        {
          showOrder && (
            <OrderModal
              order={order}
              toggleOrderModal={toggleOrderModal}
              togglePrintModal={togglePrintModal}
              handleOrder={handleOrder}
              handleStatus={handleStatus}
              updateStatus={updateStatus}
              statusOrder={statusOrder}
              statusTranslate={statusTranslate}
              haveToppings={haveToppings}
              detailProduct={detailProduct}
              getDetailPrice={getDetailPrice}
              getTotalProducts={getTotalProducts}
              getCouponValue={getCouponValue}
            />
          )
        }
        {
          printModal && (
            <PrintModal
              togglePrintModal={togglePrintModal}
              order={order}
              haveToppings={haveToppings}
              getDetailPrice={getDetailPrice}
              getTotalProducts={getTotalProducts}
              getCouponValue={getCouponValue}
              getPaymentMethod={getPaymentMethod}
            />
          )
        }
        {
        inProgress && (
          <StatusModalInProgress
            onClose={handleStatus}
            updateStatus={updateStatus}
            order={order}
            refresh={onRefresh}
          />
        )
        }
        {
          sentLocal && (
            <StatusModalSentLocal
              onClose={handleStatus}
              updateStatus={updateStatus}
              order={order}
              refresh={onRefresh}
              whatsappText={whatsappText}
            />
          )
        }
        {
          pendingToBeSent && (
            <StatusModalPendingToBeSent
              onClose={handleStatus}
              updateStatus={updateStatus}
              order={order}
              refresh={onRefresh}
              whatsappText={whatsappText}
            />
          )
        }
        {
          finished && (
            <StatusModalFinished
              onClose={handleStatus}
              updateStatus={updateStatus}
              order={order}
              refresh={onRefresh}
              whatsappText={whatsappText}
            />
          )
        }
      </div>
    </div>
  );
};

Results.propTypes = {
  data: PropTypes.array.isRequired,
  numberStatus: PropTypes.object,
  onChange: PropTypes.func,
  onRefresh: PropTypes.func,
  totalOrders: PropTypes.number,
  numberPage: PropTypes.number,
  changePage: PropTypes.func,
  toggleOrder: PropTypes.func,
  timers: PropTypes.array,
  pendingOrders: PropTypes.object,
  filter: PropTypes.string,
  branchOffice: PropTypes.object,
  isOpen: PropTypes.bool,
};

export default Results;
