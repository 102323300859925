import HttpRequest from './HttpRequest';

export default class AuthService extends HttpRequest {
  static endpoint = 'auth/partners';

  static async authenticate({ email, password }) {
    this.endpoint = 'auth/partners/login';
    const response = await this.post({ email, password });
    return response;
  }

  static async requestCodeManager(data) {
    this.endpoint = 'auth/managers/request/phone';
    const response = await this.post(data);
    return response;
  }

  static async requestCodePartner(data) {
    this.endpoint = 'auth/partners/request/phone';
    const response = await this.post(data);
    return response;
  }

  static async registerPartner(data) {
    this.endpoint = 'auth/partners/register';
    const response = await this.post(data);
    return response;
  }

  static async loginManager(data) {
    this.endpoint = 'auth/managers/login/phone';
    const response = await this.post(data);
    return response;
  }

  static async loginPartner(data) {
    this.endpoint = 'auth/partners/login/phone';
    const response = await this.post(data);
    return response;
  }

  static async validate() {
    this.endpoint = 'auth/partners/validate';
    const response = await this.get();
    return response;
  }

  static async refresh() {
    this.endpoint = 'auth/partners/refresh';
    const response = await this.get();
    return response;
  }

  static async refreshManager() {
    this.endpoint = 'auth/managers/refresh';
    const response = await this.get();
    return response;
  }
}
