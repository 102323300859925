import React from 'react';
import FormBranchOffice from 'src/components/FormBranchOffice';

function AddbranchOffices() {
  return (
    <>
      <FormBranchOffice />
    </>
  );
}

export default AddbranchOffices;
