import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import BranchOfficeService from 'src/services/BranchOfficeService';
import Results from './Results';
import Toolbar from './Toolbar';

import { selector as UserSelector } from '../../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  categoryTitle: {
    display: 'inline-block',
    marginRight: '1rem'
  },
  titleEditable: {
    fontSize: '2.18rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.167,
    letterSpacing: '-0.24px',
    color: '#888',
    marginBottom: '.5rem',
  },
  saveIcon: {
    color: 'white',
  },
  editButton: {
    cursor: 'pointer'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DiscountListView = () => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    const branchOffices = await BranchOfficeService?.getByPartner(user?.id);

    if (branchOffices?.code === 200 && branchOffices?.data?.length > 0) {
      const response = await BranchOfficeService.getWithProductDiscounts(
        branchOffices?.data?.[0]?.id
      );

      if (response?.code === 200 && response?.data?.length > 0) {
        setProducts(response?.data);
      }
    }
  };

  const refresh = async () => {
    if (user?.brand_id) {
      getProducts();
    }
  };

  useEffect(() => {
    if (user?.brand_id) {
      getProducts();
    }
  }, [user]);

  return (
    <Page
      className={classes.root}
      title="Descuentos"
    >
      <Container maxWidth={false}>
        <Toolbar refresh={refresh} />
        <Box mt={3}>
          <Results data={products} />
        </Box>
      </Container>
    </Page>
  );
};

export default DiscountListView;
