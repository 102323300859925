import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';

import { TableBody } from '@material-ui/core';

const TableBodySortable = ({ children }) => (
  <TableBody>
    {children}
  </TableBody>
);

TableBodySortable.propTypes = {
  children: PropTypes.node,
};

TableBodySortable.defaultProps = {
  children: ''
};

export default SortableContainer(TableBodySortable);
