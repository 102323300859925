import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// MUI components
import {
  Box,
  FormControl,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';

// Components
import Selector from 'src/components/Selector';

const selectorStyles = {
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: '#f4f7fa',
      borderColor: isFocused ? '#009739' : '#ccc',
      boxShadow: isFocused ? '#009739 0 0 0 1px' : undefined,
      '&:hover': {
        borderColor: isFocused && '#009739'
      },
      cursor: 'pointer'
    };
  },
  option: (styles, {
    isDisabled, isFocused, isSelected
  }) => {
    let backgroundColor = '';
    let bgColorActive = '';

    if (isDisabled) backgroundColor = undefined;
    else if (isSelected) backgroundColor = '#009739';
    else if (isFocused) backgroundColor = '#97D4AE';
    else backgroundColor = undefined;

    if (!isDisabled) {
      if (isSelected) bgColorActive = '#009739';
      else bgColorActive = '#97D4AE';
    } else bgColorActive = undefined;

    return {
      ...styles,
      backgroundColor,
      color: isSelected ? '#fff' : '#000',
      cursor: 'pointer',

      ':active': {
        ...styles[':active'],
        backgroundColor: bgColorActive
      },
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, fontSize: 11 }),
  singleValue: (styles) => ({ ...styles, fontSize: 14 }),
};

const useStyles = makeStyles(() => ({
  root: {},
  formControl: {
    width: '100%',
  },
  select: {
    fontSize: 13,
  },
}));

const Toolbar = ({
  className,
  branchOffices,
  branchOffice,
  handleBranchOffice
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleChangeBranchOffice = (value) => {
    const branchOfficeSelected = branchOffices.find((item) => item?.id === value?.value);
    handleBranchOffice(branchOfficeSelected);
  };

  return (
    <Box
      className={clsx(classes.root, className)}
    >
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        style={{
          gap: 15
        }}
      >
        <Box
          width={250}
        >
          <Typography
            variant="body2"
            style={{
              marginBottom: theme.spacing(0.5),
              fontSize: 12,
              fontWeight: 500
            }}
          >
            Sucursal
          </Typography>
          <FormControl
            required
            className={classes.formControl}
          >
            <Selector
              options={branchOffices?.map((item) => ({
                value: item?.id,
                label: item?.name
              }))}
              className={classes.select}
              value={Object.keys(branchOffice).length === 0 ? '' : {
                value: branchOffice?.id,
                label: branchOffice?.name
              }}
              onChange={handleChangeBranchOffice}
              name="branch_office_id"
              labelId="branch_office"
              label="Sucursal"
              placeholder="Selecciona una sucursal"
              styles={selectorStyles}
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  branchOffices: PropTypes.array,
  branchOffice: PropTypes.object,
  handleBranchOffice: PropTypes.func
};

Toolbar.defaultProps = {
  className: '',
  branchOffices: [],
  branchOffice: {},
  handleBranchOffice: () => {}
};

export default Toolbar;
