import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import Toolbar from './Toolbar';
import Results from './Results';
import ManagerService from '../../../services/ManagerService';
import { selector as UserSelector } from '../../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ManagerList = () => {
  const classes = useStyles();
  const [managers, setManagers] = useState([]);
  const { user } = useSelector(UserSelector);

  const getManagers = async () => {
    const fetchData = await ManagerService.getAll();

    if (fetchData?.code === 200) {
      setManagers(fetchData.data);
    }
  };

  const refresh = async () => {
    if (user?.id) {
      await getManagers();
    }
  };

  useEffect(() => {
    getManagers();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Gerentes"
    >
      <Container maxWidth={false}>
        <Toolbar refresh={refresh} />
        <Box mt={3}>
          <Results data={managers} onRefresh={refresh} />
        </Box>
      </Container>
    </Page>
  );
};

export default ManagerList;
