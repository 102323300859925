import React, { useState, useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Selector from 'src/components/Selector';
import _ from 'lodash';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import { capitalize } from 'src/utils/string';
import ProductComponentService from 'src/services/ProductComponentService';
import { selector as UserSelector } from 'src/redux/ducks/user';
import ComponentBrandService from 'src/services/ComponentBrandService';
import ComponentPackingService from 'src/services/ComponentPackingService';
import ComponentSizeUnitService from 'src/services/ComponentSizeUnitService';
import CurrencyFormat from 'react-currency-format';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import AdomiComponentService from '../../../services/AdomiComponentService';
import ComponentCategoryService from '../../../services/ComponentCategoryService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateProductComponentForm = forwardRef(({
  className, component, edit, onChange, setLoading, hasImage, ...rest
}, ref) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const [componentBrands, setComponentBrands] = useState([]);
  const [componentPacking, setComponentPacking] = useState([]);
  const [componentSizeUnits, setComponentSizeUnits] = useState([]);
  const [componentCategories, setComponentCategories] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [option, setOption] = useState(null);
  const { user } = useSelector(UserSelector);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const adomiComponentId = (!option?.__isNew__ && option?.isAdomi)
      ? option?.value : null;
    const errors = [];
    const file = ref.current.files[0];
    const data = {
      name: option ? option?.label : defaultOption?.label,
      brand_id: user?.brand_id,
      adomi_component_id: adomiComponentId,
      image: e.target?.image?.value,
      component_category_id: e?.target?.component_category_id?.value || null,
      component_brand_id: e?.target?.component_brand_id?.value || null,
      component_packaging_type_id: e?.target?.component_packaging_type_id?.value || null,
      component_size_unit_id: e?.target?.component_size_unit_id?.value || null,
      price: Number((e.target?.price?.value).replace('.', ''))
    };

    if (data?.name?.length === 0) {
      errors.push('El nombre del componente es obligatorio');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    setLoading(true);

    try {
      const response = edit
        ? await ProductComponentService.update(component?.id, data)
        : await ProductComponentService.create(data);

      if (response?.code === 201 || response?.code === 200) {
        setErrors([]);
        if (response?.data?.image !== null && file !== undefined) {
          await ProductComponentService.deleteImage(response?.data?.id);
          const formData = new FormData();
          formData.append('file', file);
          formData.append('id', response.data.id);
          const r = await ProductComponentService.uploadImage(formData);

          if (r?.code === 200 || r?.code === 201) {
            setTimeout(() => {
              return navigate('/app/componentes');
            }, 500);
          }
        }
        if (response?.data?.image === null && file !== undefined) {
          const formData = new FormData();

          // The third parameter is required for server
          formData.append('file', file);
          formData.append('id', response.data.id);
          const r = await ProductComponentService.uploadImage(formData);

          if (r?.code === 200 || r?.code === 201) {
            setTimeout(() => {
              return navigate('/app/componentes');
            }, 500);
          }
        }
        navigate('/app/componentes', { replace: true });
      } else {
        setErrors(response.errors);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const deleteComponent = async () => {
    if (component?.id) {
      await ProductComponentService.deleteImage(component?.id);
      const response = await ProductComponentService.remove(component?.id);
      if (response?.code === 204) {
        navigate('/app/componentes', { replace: true });
      } else {
        setErrors(Array.isArray(response?.errors) ? response?.errors : []);
      }
    }
  };

  const searchAdomiComponent = async (value) => {
    const response = await AdomiComponentService.search(value);

    if (response?.code === 200) {
      const data = await response?.data?.map((item) => {
        return ({
          value: item?.id,
          label: item?.name,
          image: item?.image,
          isAdomi: true,
        });
      });
      return data;
    }

    return [];
  };

  const searchBrandComponent = async (value) => {
    const response = await ProductComponentService.search(user?.brand_id, value);

    if (response?.code === 200) {
      const data = await response?.data?.map((item) => {
        return ({
          value: item?.id,
          label: item?.name,
          image: item?.image,
          isAdomi: false,
        });
      });
      return data;
    }

    return [];
  };

  const searchComponents = async (value) => {
    if (value.length < 3) return null;

    const adomiComponents = await searchAdomiComponent(value);
    const brandComponents = await searchBrandComponent(value);

    return [
      ...adomiComponents,
      ...brandComponents?.filter((brandComponent) => (
        !adomiComponents?.find((adomiComponent) => (
          adomiComponent?.id === brandComponent?.adomi_component_id
        ))))
    ];
  };

  const handleAdomiComponent = _.debounce((value) => {
    return new Promise((resolve) => {
      resolve(searchComponents(value));
    });
  }, 300);

  const handleInputChange = (value) => {
    setOption(value);
    setDefaultOption({
      value: value?.value,
      label: value?.label
    });
    onChange({
      ...component,
      name: value?.label,
      image: value?.isAdomi ? value?.image : component?.image
    });
  };

  const handleChange = (e) => {
    onChange({
      ...component,
      [e.target.name]: e.target.value
    });
  };

  const fetchComponentCategories = async () => {
    const response = await ComponentCategoryService.getAll();
    if (response?.code === 200) {
      setComponentCategories(response?.data);
    }
  };

  const fetchComponentBrands = async () => {
    const response = await ComponentBrandService.getAll();
    if (response?.code === 200) {
      setComponentBrands(response?.data);
    }
  };

  const fetchComponentPacking = async () => {
    const response = await ComponentPackingService.getAll();
    if (response?.code === 200) {
      setComponentPacking(response?.data);
    }
  };

  const fetchComponentSizeUnit = async () => {
    const response = await ComponentSizeUnitService.getAll();
    if (response?.code === 200) {
      setComponentSizeUnits(response?.data);
    }
  };

  const fetchComponentFeatures = async () => {
    await fetchComponentBrands();
    await fetchComponentCategories();
    await fetchComponentPacking();
    await fetchComponentSizeUnit();
  };

  useEffect(() => {
    if (edit && component?.id) {
      setDefaultOption({
        value: component?.id,
        label: component?.name
      });
    }
  }, [edit, component]);

  useEffect(() => {
    fetchComponentFeatures();
  }, []);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Componente"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={9}
            >
              {
                defaultOption
                  ? (
                    <Selector
                      type="async-creatable"
                      isClearable
                      defaultValue={defaultOption}
                      loadingMessage={() => 'Cargando...'}
                      noOptionsMessage={() => 'Sin resultados'}
                      formatCreateLabel={(string) => capitalize(string)}
                      onChange={handleInputChange}
                      placeholder="Nombre del componente"
                      loadOptions={handleAdomiComponent}
                    />
                  )
                  : (
                    <Selector
                      placeholder="Nombre del componente"
                    />
                  )
              }
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
            >
              <CurrencyFormat
                thousandSeparator="."
                decimalSeparator=","
                customInput={TextField}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                name="price"
                label="Precio"
                variant="outlined"
                value={component?.price}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category">Selecciona una categoría</InputLabel>
                <Select
                  name="component_category_id"
                  labelId="component_category"
                  label="Selecciona una categoría"
                  value={component?.component_category_id}
                  onChange={() => null}
                >
                  <MenuItem value="0">Selecciona una categoría</MenuItem>
                  {
                    componentCategories.map((componentCategory) => (
                      <MenuItem key={componentCategory.id} value={componentCategory.id}>
                        { componentCategory.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category">Selecciona una marca</InputLabel>
                <Select
                  name="component_brand_id"
                  labelId="component_brand"
                  label="Selecciona una marca"
                  value={component?.component_brand_id}
                  onChange={() => null}
                >
                  <MenuItem value="0">Selecciona una marca</MenuItem>
                  {
                    componentBrands.map((componentBrand) => (
                      <MenuItem key={componentBrand.id} value={componentBrand.id}>
                        { componentBrand.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category">Selecciona un empaque</InputLabel>
                <Select
                  name="component_packaging_type_id"
                  labelId="component_packaging_type"
                  label="Selecciona un empaque"
                  value={component?.component_packaging_type_id}
                  onChange={() => null}
                >
                  <MenuItem value="0">Selecciona un empaque</MenuItem>
                  {
                    componentPacking.map((componentPackingType) => (
                      <MenuItem key={componentPackingType.id} value={componentPackingType.id}>
                        { componentPackingType.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category">Selecciona la unidad</InputLabel>
                <Select
                  name="component_size_unit_id"
                  labelId="component_size_unit"
                  label="Selecciona la unidad"
                  value={component?.component_size_unit_id}
                  onChange={() => null}
                >
                  <MenuItem value="0">Selecciona la unidad</MenuItem>
                  {
                    componentSizeUnits.map((componentSizeUnit) => (
                      <MenuItem key={componentSizeUnit.id} value={componentSizeUnit.id}>
                        { componentSizeUnit.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <input name="image[]" type="file" ref={ref} style={{ display: 'none' }} />
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {
            edit && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            {edit ? 'Editar' : 'Guardar'}
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteComponent} />
    </form>
  );
});

CreateProductComponentForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  onChange: PropTypes.func,
  setLoading: PropTypes.func,
  component: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    adomi_component_id: PropTypes.string,
    component_brand_id: PropTypes.string,
    component_category_id: PropTypes.string,
    component_packaging_type_id: PropTypes.string,
    component_size_unit_id: PropTypes.string,
  })
};

CreateProductComponentForm.defaultProps = {
  className: '',
  edit: false,
  hasImage: false,
  onChange: () => { },
  setLoading: () => { },
  component: {
    id: null,
    name: '',
    image: '',
    price: 0.00,
    adomi_component_id: '',
  },
};

export default CreateProductComponentForm;
