import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  makeStyles,
  Typography
} from '@material-ui/core';
import { getDate, getHour } from 'src/utils/formatDate';

const useStyles = makeStyles(() => ({
  border: {
    borderBottom: '1px dashed #000'
  },
  font: {
    fontFamily: '"Courier New", Courier, monospace'
  },
  bold: {
    fontWeight: 'bold'
  },
  product: {
    '&:first-child': {
      paddingTop: 0
    }
  }
}));

const TicketComanda = forwardRef(({
  order,
  haveToppings,
  getDetailPrice,
}, ref) => {
  const classes = useStyles();
  const [products, setProducts] = useState([]);

  const getProducts = () => {
    return order?.order_products?.map((product) => {
      let sections = [];
      if (product?.customize?.sections?.length > 0) {
        sections = product?.customize?.sections?.map((section) => {
          let items = [];
          if (section?.type === 'radio') {
            items = section?.items.filter((item) => item.value);
          } else if (section?.type === 'checkbox') {
            items = section?.items.filter((item) => !item.value);
          } else {
            items = section?.items.filter((item) => item.value > 0);
          }

          if (items?.length === 0) return {};
          return {
            ...section,
            items
          };
        });
      }
      return {
        ...product,
        customize: {
          sections
        }
      };
    });
  };

  useEffect(() => {
    setProducts(getProducts());
  }, []);

  return (
    <Box px={2} py={1} ref={ref}>
      <Box py={1} display="flex" flexDirection="column" alignItems="items" textAlign="center" className={classes.border}>
        <Typography className={classes.font} style={{ fontWeight: 600 }} variant="h3">Comanda</Typography>
        <Typography className={classes.font} variant="body2">
          {'Pedido: '}
          #
          {order?.reference}
        </Typography>
        <Typography className={classes.font} variant="body2">
          {`Fecha: ${getDate(order?.created_at)} ${getHour(order?.created_at)}`}
        </Typography>
      </Box>
      <Box>
        <Box py={0.5} textAlign="center" className={classes.border}>
          <Typography className={clsx(classes.font, classes.bold)} variant="h6">RESUMEN PRODUCTOS</Typography>
        </Box>
        <Box py={1}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={clsx(classes.font, classes.bold)} variant="body2">Descripción</Typography>
            <Typography className={clsx(classes.font, classes.bold)} variant="body2">Cant.</Typography>
          </Box>
          <Box className={classes.products}>
            {products.map((product) => {
              const detailPrice = getDetailPrice(product?.prices);
              return (
                <Box py={1} display="flex" textAlign="left" justifyContent="space-between" className={clsx(classes.border, classes.product)}>
                  <Box>
                    <Typography style={{ wordBreak: 'break-all' }} className={classes.font} variant="body2">
                      {product?.name}
                    </Typography>
                    <Box>
                      <Typography style={{ wordBreak: 'break-all', fontSize: '12px' }} className={classes.font} variant="body2">
                        {detailPrice?.name}
                        {detailPrice?.description?.length > 0 && (
                          <>
                            {` (${detailPrice?.description})`}
                          </>
                        )}
                      </Typography>
                    </Box>
                    {product?.comment?.length > 0 && (
                      <Box>
                        <Typography style={{ wordBreak: 'break-all', fontSize: '12px' }} className={classes.font} variant="body2">
                          {'Comentario: '}
                          {product?.comment}
                        </Typography>
                      </Box>
                    )}
                    <Box>
                      {!haveToppings(product?.customize?.sections) && (
                        <Box>
                          {product?.customize?.sections?.map((section) => (
                            <>
                              {Object.keys(section)?.length > 0 && (
                                <Box>
                                  <Box>
                                    {section?.items?.map((item) => (
                                      <Typography style={{ wordBreak: 'break-all', fontSize: '12px' }} className={classes.font} variant="body2">
                                        {section?.type === 'quantity' && (
                                          <>
                                            {item?.value}
                                            {' x '}
                                          </>
                                        )}
                                        {item?.name}
                                        {section?.type === 'checkbox' && (
                                          <span style={{ fontWeight: 600 }}>
                                            {' (NO) '}
                                          </span>
                                        )}
                                      </Typography>
                                    ))}
                                  </Box>
                                </Box>
                              )}
                            </>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Typography style={{ marginLeft: '35px' }} className={classes.font} variant="body2">{product?.amount}</Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

TicketComanda.propTypes = {
  order: PropTypes.object,
  haveToppings: PropTypes.func,
  getDetailPrice: PropTypes.func,
};

export default TicketComanda;
