import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  // Chip,
  Button,
  // Table,
  // TableRow,
  // TableCell,
  // TableBody,
  // TableHead,
  Typography,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import { CancelRounded } from '@material-ui/icons';

const StatusModalSentLocal = ({
  onClose,
  updateStatus,
  refresh,
  whatsappText,
  order
}) => {
  const matches = useMediaQuery('screen and (min-width: 769px)');

  const useStyles = makeStyles(() => ({
    buttons: {
      display: 'inline-block',
    },
    overlay: {
      backgroundColor: 'rgba(46, 44, 54, 0.4)',
      position: 'fixed',
      top: 0,
      right: 0,
      height: '100%',
      width: '100%',
      zIndex: '3',
      display: matches ? 'flex' : null,
      justifyContent: matches ? 'center' : null,
      alignItems: matches ? 'center' : null,
    },
    modal: {
      border: '1px solid #e6ebf1',
      borderRadius: '8px',
      width: !matches ? '100%' : '450px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      bottom: '0',
      animation: 'showPopUp 0.5s',
      position: !matches ? 'absolute' : 'relative',
    },
    pane: {
      height: '100%',
      width: '100%',
      position: 'fixed'
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '8px 10px',
    },
    text: {
      color: '#999',
      fontSize: '13px',
      fontWeight: 500
    },
    btnConfirm: {
      textTransform: 'capitalize',
      borderRadius: 6,
      width: 125,
      fontWeight: 700,
      fontSize: '14px'
    },
  }));
  const classes = useStyles();

  const onPush = async () => {
    updateStatus('sent_local', order?.id);
    refresh();
    onClose('sent_local', false);
  };

  return (
    <div className={classes?.overlay}>
      <div className={classes.pane} onClick={() => onClose('sent_local', false)} />
      <div className={classes?.modal}>
        <div className={classes.header}>
          <CancelRounded style={{ cursor: 'pointer' }} color="primary" onClick={() => onClose('sent_local', false)} />
        </div>
        <Box>
          <Box p={3} pb={2.3} pt={0}>
            <div>
              <h2 style={{ fontSize: '20px' }}>¿Desea compartir por Whatsapp esta orden?</h2>
            </div>
            <Box mt={1}>
              <Typography variant="body2" className={classes.text}>
                Usando la opción compartir a whatsapp,
                podrá mandar el pedido distribuyendo la información por su whatsapp
              </Typography>
            </Box>
            <Box mt={1.8} textAlign="center">
              <a href={`https://wa.me?text=${whatsappText}`} target="_blank" rel="noreferrer">
                <Button className={classes.btnConfirm} size="small" variant="contained" color="primary" onClick={onPush}>Confirmar</Button>
              </a>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

StatusModalSentLocal.propTypes = {
  onClose: PropTypes.func,
  refresh: PropTypes.func,
  updateStatus: PropTypes.func,
  whatsappText: PropTypes.string,
  order: PropTypes.string
};

export default StatusModalSentLocal;
