import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ProductService from 'src/services/ProductService';
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ProductCard from './components/ProductCard';

const Results = ({
  data,
  loading
}) => {
  const [products, setProducts] = useState([]);

  const toggleAvailableProduct = async (productId) => {
    const response = await ProductService.toggle(productId);

    if (response?.code === 200) {
      const newProducts = products.reduce((result, item) => {
        if (item.id === productId) {
          return result.concat({ ...item, is_available: !item?.is_available });
        }
        return result.concat(item);
      }, []);

      setProducts(newProducts);
    }
  };

  const getDetailPrice = (prices) => {
    return prices.find((price) => price.is_default);
  };

  useEffect(() => {
    if (Array.isArray(data)) setProducts(data);
  }, [data]);

  return (
    <Box py={3} display="flex" height="100%" width="100%">
      <Grid container spacing={2}>
        {!loading && products?.map((product) => (
          <Grid item xs={12} lg={6} xl={4}>
            <ProductCard
              toggleAvailableProduct={toggleAvailableProduct}
              product={product}
              detailPrice={getDetailPrice(product?.prices)}
            />
          </Grid>
        ))}
        {loading && [1, 2, 3, 4, 5, 6, 7, 8, 9]?.map(() => (
          <Grid item xs={12} lg={4} xl={4}>
            <Skeleton animation="wave" variant="rect" style={{ borderRadius: 10 }} width="100%" height="210px" />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

Results.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool
};

Results.defaultProps = {
  loading: false,
};

export default Results;
