import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import DayJsUtils from '@date-io/dayjs';
import { getDate, getHour } from 'src/utils/formatDate';
import {
  Box,
  makeStyles,
  TextField,
  Grid,
  Card,
  Typography,
  Chip,
  Button,
  useMediaQuery,
  Grow,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Print,
  MonetizationOn,
  Warning
} from '@material-ui/icons';
import CurrencyFormat from 'react-currency-format';
import ReactToPrint from 'react-to-print';
import TicketReport from 'src/components/TicketReport';

const useStyles = makeStyles((theme) => ({
  statusButton: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: '.8rem',
    borderRadius: '10px',
    padding: '10px 15px',
    backgroundColor: 'rgb(243, 243, 243)',
    border: 'none',
    cursor: 'pointer',
    width: '150px',
    height: 'fit-content',
    transition: '1s',
    '& > div': {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgb(167, 167, 167)',
      padding: '5px',
      borderRadius: '100px',
      minHeight: '30px',
      minWidth: '30px',
      color: 'white',
      transition: '1s',
    },
    '&:focus': {
      backgroundColor: 'rgb(242, 255, 0)',
      boxShadow: '2px 4px 6px 0px rgba(189,189,189,1)',
      transition: '1s',
      '& > div': {
        backgroundColor: 'rgb(224, 51, 109)',
      }
    }
  },
  statusButtonAll: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: '.8rem',
    borderRadius: '10px',
    width: '150px',
    height: 'fit-content',
    padding: '10px 15px',
    backgroundColor: 'rgb(242, 255, 0)',
    border: 'none',
    cursor: 'pointer',
    boxShadow: '2px 4px 6px 0px rgba(189,189,189,1)',
    transition: '1s',
    '& > div': {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgb(224, 51, 109)',
      padding: '5px',
      borderRadius: '100px',
      minHeight: '30px',
      minWidth: '30px',
      color: 'white',
      transition: '1s',
    },
  },
  order: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    borderRadius: 10,
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
  },
  listOrders: {
    marginTop: theme.spacing(2),
    overflow: 'auto',
    maxHeight: '100%'
  },
  finished: {
    backgroundColor: '#1565C0',
    color: 'white',
  },
  rejected: {
    backgroundColor: 'red',
    color: 'white'
  },
  buttonIcon: {
    borderRadius: '50%',
    minWidth: 10,
    padding: 5,
    marginLeft: theme.spacing(2)
  },
  subTotal: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginRight: '5px',
    fontSize: 15
  },
  scroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  },
  alert: {
    backgroundColor: '#d4edda',
    borderRadius: 10,
  },
  warning: {
    color: theme.palette.primary.main,
    fontSize: '26px',
    marginRight: '8px'
  },
  textAlert: {
    fontSize: '16px',
    wordBreak: 'break-all',
    color: theme.palette.primary.main,
  },
}));

const useStylesPicker = makeStyles((theme) => ({
  root: {
    borderRadius: 9,
    width: '170px',
    backgroundColor: '#fcfcfb',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: theme.spacing(1.5),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      outlined: 'none',
      border: 'none'
    },
    '& .MuiInputBase-root': {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: '"Poppins", sans-serif',
      color: '#000'
    },
    '& .MuiIconButton-root': {
      padding: theme.spacing(0),
    },
    '& .MuiIconButton-label': {
      borderRadius: 6,
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(0.4),
    },
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      color: '#fff'
    }
  },
}));

const DatePickerComponent = (props) => {
  const classes = useStylesPicker();
  return (
    <TextField
      {...props}
      disabled
      className={classes.root}
      variant="outlined"
      size="small"
    />
  );
};

const ListOrders = ({
  numberStatus,
  orders,
  handleFilter,
  getOrders,
  filter,
  totalCash,
  totalCard,
  total
}) => {
  const classes = useStyles();
  const matchesLg = useMediaQuery('screen and (min-width: 1280px)');
  const matchesXl = useMediaQuery('screen and (min-width: 1820px)');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const reportRef = useRef();

  const reactToPrintTrigger = () => {
    return (
      <Button
        className={classes.buttonIcon}
        color="primary"
        variant="contained"
        size="small"
      >
        <Print style={{ fontSize: '16px' }} />
      </Button>
    );
  };

  const formatPaymentMethod = (method) => {
    switch (method) {
      case 'cash':
        return 'Efectivo';
      case 'card':
        return 'Tarjeta';
      case 'datafono':
        return 'Datafono';
      default:
        return '';
    }
  };

  const statusTranslate = (status, shipType) => {
    switch (status) {
      case 'in_progress':
        return 'En proceso';
      case 'finished':
        return 'Finalizado';
      case 'rejected':
        return 'Rechazado';
      case 'cancelled':
        return 'Cancelado';
      case 'pending_to_be_sent':
        if (shipType === 'adomi') return 'Asignando driver';
        return 'Pendiente por enviar';
      case 'sent_local':
        return 'Enviado por local';
      case 'sent':
        return 'Enviado';
      case 'created':
        return 'Pendiente';
      default:
        return status;
    }
  };

  const statusOrder = (status) => {
    switch (status) {
      case 'CANCELLED':
        return 'rejected';
      case 'IN_PROGRESS':
        return 'accepted';
      case 'PENDING_TO_BE_SENT':
        return 'accepted';
      case 'REJECTED':
        return 'rejected';
      case 'FINISHED':
        return 'finished';
      case 'SENT':
        return 'sent';
      case 'SENT_LOCAL':
        return 'sent';
      default:
        return 'pending';
    }
  };

  useEffect(() => {
    getOrders(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    handleFilter(filter);
  }, [orders]);

  return (
    <Box display="flex" flexDirection="column" width={matchesXl ? '70%' : '55%'} height="100%">
      <Box
        display="flex"
        flexDirection={matchesLg ? 'row' : 'column'}
        alignItems={matchesLg ? 'center' : 'start'}
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" mb={matchesLg ? 0 : 2}>
          <button
            type="button"
            className={clsx(
              filter === 'finished' ? classes.statusButtonAll : classes.statusButton,
            )}
            onClick={() => handleFilter('finished')}
          >
            <div>{numberStatus?.finished}</div>
            <p>Entregados</p>
          </button>
          <button
            type="button"
            className={clsx(
              filter === 'cancelled' ? classes.statusButtonAll : classes.statusButton,
            )}
            onClick={() => handleFilter('cancelled')}
          >
            <div>{numberStatus?.cancelled}</div>
            <p>Cancelados</p>
          </button>
          <button
            type="button"
            className={clsx(
              filter === 'rejected' ? classes.statusButtonAll : classes.statusButton,
            )}
            onClick={() => handleFilter('rejected')}
          >
            <div>{numberStatus?.rejected}</div>
            <p>Rechazados</p>
          </button>
        </Box>
        <Box display="flex" alignItems="center">
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <KeyboardDatePicker
              variant="inline"
              disableToolbar
              format="DD/MM/YYYY"
              id="date-picker-inline"
              value={selectedDate}
              maxDate={new Date()}
              onChange={(date) => setSelectedDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              TextFieldComponent={DatePickerComponent}
            />
          </MuiPickersUtilsProvider>
          {orders?.length > 0 && (
            <>
              <ReactToPrint
                trigger={() => reactToPrintTrigger()}
                content={() => {
                  return reportRef.current;
                }}
              />
              <Box display="none">
                <TicketReport
                  orders={orders}
                  branchOffice={orders?.at(0)?.data_branch_office}
                  status={statusTranslate(filter)}
                  date={getDate(selectedDate)}
                  totalCash={totalCash}
                  totalCard={totalCard}
                  total={total}
                  ref={reportRef}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Grid
        justifyContent={orders?.length === 0 ? 'center' : 'flex-start'}
        className={clsx(classes.listOrders, classes.scroll)}
        container
        spacing={2}
      >
        {orders?.length > 0 && orders?.map((order) => (
          <Grid item xs={12} lg={6} xl={3}>
            <Card className={classes.order}>
              <Box p={1}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Box ml={0.5}>
                      <Typography style={{ fontWeight: 600 }} variant="h4">
                        Pedido #
                        {order?.reference}
                      </Typography>
                      <Box display="flex">
                        <Typography style={{ color: '#A5A5A5', fontWeight: 700, marginRight: '5px' }} variant="body2">{getDate(order?.created_at)}</Typography>
                        <Typography style={{ color: '#A5A5A5', fontWeight: 700 }} variant="body2">{getHour(order?.created_at)}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Chip
                    style={{ fontWeight: 'bold', fontSize: '12px' }}
                    className={classes?.[
                      statusOrder(order?.status?.toString()?.toUpperCase())
                    ]}
                    label={statusTranslate(order?.status?.toString(), order?.ship_type)}
                  />
                </Box>
                <Box ml={0.3} mt={1} display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <MonetizationOn style={{ fontSize: '22px' }} color="primary" />
                    <Typography
                      style={{
                        color: '#A5A5A5',
                        fontWeight: 600,
                        marginRight: '5px',
                        marginLeft: '4px'
                      }}
                      variant="body2"
                    >
                      {formatPaymentMethod(order?.payment_method)}
                    </Typography>
                  </Box>
                  <Typography
                    className={classes.subTotal}
                    variant="body2"
                  >
                    $
                    <CurrencyFormat
                      value={order?.sub_total}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                    />
                  </Typography>
                </Box>
                <Box ml={0.5} mt={0.7} display="flex" alignItems="center" justifyContent="space-between">
                  <Typography
                    style={{
                      color: '#A5A5A5',
                      fontWeight: 600,
                      marginRight: '5px',
                      fontSize: 12
                    }}
                    variant="body2"
                  >
                    {order?.status === 'finished' && (
                      <>
                        H. Salida:
                        {' '}
                        {order?.order_status?.filter((s) => s?.status === 'sent')?.length > 0 ? (
                          <>
                            {getHour(order?.order_status?.filter((s) => s?.status === 'sent').slice(-1)[0]?.created_at)}
                          </>
                        ) : (
                          <>
                            -
                          </>
                        )}
                      </>
                    )}
                    {order?.status === 'cancelled' && (
                      <>
                        H. Cancelación:
                        {' '}
                        {getHour(order?.order_status?.filter((s) => s?.status === 'cancelled').slice(-1)[0]?.created_at) || '-'}
                      </>
                    )}
                    {order?.status === 'rejected' && (
                      <>
                        H. Rechazado:
                        {' '}
                        {getHour(order?.order_status?.filter((s) => s?.status === 'rejected').slice(-1)[0]?.created_at) || '-'}
                      </>
                    )}
                  </Typography>
                  {order?.status === 'finished' && (
                    <Typography
                      style={{
                        color: '#A5A5A5',
                        fontWeight: 600,
                        fontSize: 12
                      }}
                      variant="body2"
                    >
                      H. Entrega:
                      {' '}
                      {getHour(order?.order_status?.filter((s) => s?.status === 'finished').slice(-1)[0]?.created_at) || '-'}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
        {orders?.length === 0 && (
          <Grow in timeout={1500}>
            <Box
              className={classes.alert}
              textAlign="center"
              p={4}
              mx={3}
            >
              <Box display="flex" alignItems="center">
                <Warning className={classes.warning} size="small" />
                <Typography className={classes.textAlert} variant="body2">
                  En el día
                  {' '}
                  {getDate(selectedDate)}
                  {' '}
                  no hubo pedidos
                  {' '}
                  {`${statusTranslate(filter)?.toLowerCase()}s`}
                </Typography>
              </Box>
            </Box>
          </Grow>
        )}
      </Grid>
    </Box>
  );
};

ListOrders.propTypes = {
  numberStatus: PropTypes.object,
  orders: PropTypes.array,
  handleFilter: PropTypes.func,
  getOrders: PropTypes.func,
  filter: PropTypes.string,
  totalCash: PropTypes.number,
  totalCard: PropTypes.number,
  total: PropTypes.number,
};

export default ListOrders;
