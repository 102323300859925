import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Grid,
  Button,
  Checkbox,
  FormHelperText,
  Link,
  TextField,
  Typography,
  Avatar,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import Errors from 'src/components/Errors';
import CountryService from 'src/services/CountryService';
import BrandService from 'src/services/BrandService';
import AuthService from 'src/services/AuthService';
import { actions as UserActions } from '../../../../redux/ducks/user';
import useErrors from '../../../../hooks/useErrors';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 70,
    height: 70
  },
  formControl: {
    width: '100%',
  },
  conatinerAlerts: {
    backgroundColor: '#f8d7da',
    borderRadius: 10,
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '19px',
    marginRight: '4px'
  },
  textAlert: {
    fontSize: '14px',
    wordBreak: 'break-all',
    color: theme.palette.error.main,
  },
  alert: {
    padding: 0,
    paddingBottom: theme.spacing(3)
  }
}));

const regexUpper = /^(?=.*?[A-Z])/;
const regexLower = /^(?=.*?[a-z])/;
const regexDigit = /^(?=.*?[0-9])/;
const regexLetter = /^(?=.*[A-Za-z])/;

const FormUser = ({
  prevStep,
  brand
}) => {
  const classes = useStyles();
  const { setErrors } = useErrors();
  const [countries, setCountries] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createUser = async (values, brandId) => {
    setLoading(true);
    const data = {
      first_name: values.firstName,
      last_name: values.lastName,
      brand_id: brandId,
      email: values.email,
      country_id: values.countryId,
      phone: values.phone,
      password: values.password
    };

    const response = await AuthService.registerPartner(data);

    if (response.code === 200 || response.code === 201) {
      const res = await AuthService.authenticate({
        email: data.email,
        password: data.password
      });
      if (res?.code === 200) {
        dispatch(UserActions.set(res?.data?.data));
        dispatch(UserActions.tokenize(res?.data?.token));
        navigate('/app/sucursales', { replace: true });
      } else {
        setErrors(Array.isArray(res.errors) ? res.errors : []);
      }
    } else {
      setErrors(Array.isArray(response.errors) ? response.errors : []);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      const response = await CountryService.getAll();

      if (response?.code !== 200) {
        console.error(['Ocurrió un error al intentar mostrar los países']);
        return;
      }

      setCountries(response.data);
    })();
  }, []);

  return (
    <>
      <Typography
        color="textPrimary"
        gutterBottom
        variant="body2"
        style={{
          marginTop: 15,
          textAlign: 'center'
        }}
      >
        2. Crea una cuenta de usuario de adomi logistica
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        pt={1.5}
        pb={2}
      >
        <Avatar
          className={classes.avatar}
          alt="brand"
          src={brand?.file ? URL.createObjectURL(brand?.file) : ''}
        />
        <Typography
          variant="body2"
          style={{ marginTop: 6, fontSize: 15, fontWeight: 500 }}
        >
          {brand?.name}
        </Typography>
      </Box>
      <Errors className={classes.alert} time={0} />
      <Formik
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          countryId: '',
          phone: '',
          password: '',
          policy: false
        }}
        validationSchema={
          Yup.object().shape({
            email: Yup.string().email('Debe ser un email valido').max(255).required('El email es obligatorio'),
            firstName: Yup.string().max(255).required('El nombre es obligatorio'),
            lastName: Yup.string().max(255).required('Los apellidos son obligatorios'),
            countryId: Yup.string().required('Seleccione un país'),
            phone: Yup.string().max(255).required('El telefono es obligatorio'),
            password: Yup.string()
              .min(8, 'La contraseña debe ser de al menos 8 caracteres')
              .max(255)
              .matches(regexDigit, { message: 'Debe contener al menos un numero (0-9)' })
              .matches(regexLetter, { message: 'Debe contener al menos un caracter (a-z)' })
              .matches(regexLower, { message: 'Debe contener al menos una letra en minuscula' })
              .matches(regexUpper, { message: 'Debe contener al menos una letra en mayuscula' })
              .required('La contraseña es obligatoria'),
            policy: Yup.boolean().oneOf([true], 'Debe leer y aceptar los terminos y condiciones'),
          })
        }
        onSubmit={async (values) => {
          const { file } = brand;
          const data = {
            name: brand?.name,
            description: brand?.description,
            slug: ''
          };

          const response = await BrandService.createPublic(data);

          if (response.code === 200 || response.code === 201) {
            if (file) {
              const formData = new FormData();
              formData.append('file', file);
              formData.append('id', response.data.id);
              const r = await BrandService.uploadImagePublic(formData);
              if (r?.code !== 200 && r?.code !== 201) {
                return;
              }
            }
            createUser(values, response.data.id);
          } else {
            setErrors(Array.isArray(response.errors) ? response.errors : []);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label="Nombre"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label="Apellidos"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormControl
                  error={Boolean(touched.countryId && errors.countryId)}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="pais">Seleccione un país</InputLabel>
                  <Select
                    name="countryId"
                    labelId="pais"
                    label="Selecciona un país"
                    value={values.countryId}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <MenuItem value="0" />
                    {
                      countries.map((country) => (
                        <MenuItem key={country.id} value={country.id}>
                          {country.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                  {Boolean(touched.countryId && errors.countryId) && (
                    <FormHelperText error>
                      {errors.countryId}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  helperText={touched.phone && errors.phone}
                  label="Teléfono"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.phone}
                  variant="outlined"
                  type="tel"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Contraseña"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((prev) => !prev)}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Box
              alignItems="center"
              display="flex"
              ml={1}
            >
              <Checkbox
                checked={values.policy}
                name="policy"
                onChange={handleChange}
                color="primary"
              />
              <Typography
                color="textSecondary"
                variant="body1"
              >
                He leido
                {' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="#"
                  underline="always"
                  variant="h6"
                >
                  Terminos y condiciones
                </Link>
              </Typography>
            </Box>
            {Boolean(touched.policy && errors.policy) && (
              <FormHelperText error>
                {errors.policy}
              </FormHelperText>
            )}
            <Box
              my={2}
              display="flex"
              alignItems="center"
            >
              <Button
                disabled={isSubmitting || loading}
                fullWidth
                size="large"
                style={{ margin: 5 }}
                onClick={() => prevStep()}
              >
                {'< Regresar'}
              </Button>
              <Button
                color="primary"
                disabled={isSubmitting || loading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{ margin: 5 }}
                startIcon={isSubmitting || loading ? <CircularProgress size={25} /> : ''}
              >
                Registrarse ahora
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

FormUser.propTypes = {
  prevStep: PropTypes.func,
  brand: PropTypes.object
};

export default FormUser;
