import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Check } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  customRadio: {
    '& input[type="radio"]': {
      WebkitAppearance: 'none',
      appearance: 'none'
    },
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    width: '29%',
    height: 'fit-content',
    '& input[type="radio"]:checked + $radioButton': {
      border: '2px solid #009739',
      '& $img': {
        filter: 'grayscale(0)',
        opacity: 1
      },
      '& $checked': {
        backgroundColor: '#009739',
        border: 'none',
      },
      '& $icon': {
        color: '#fff'
      },
      '& $title': {
        color: '#009739'
      },
      '& $text': {
        fontWeight: 500,
        color: '#009739',
      },
    }
  },
  radioButton: {
    animation: '$scale 0.7s ease both',
    cursor: 'pointer',
    border: '2px solid #ccc',
    borderRadius: 10,
    transition: 'border 0.5s ease',
    '&:hover': {
      border: '2px solid #009739',
      '& $img': {
        filter: 'grayscale(0)',
        opacity: 1
      }
    }
  },
  checked: {
    backgroundColor: 'transparent',
    borderRadius: '50%',
    border: '2px solid #ccc',
  },
  icon: {
    color: 'transparent',
    fontSize: 16,
  },
  containerImg: {
    width: 60,
    height: 60
  },
  img: {
    width: '100%',
    height: '100%',
    filter: 'grayscale(1)',
    opacity: '0.5',
    transition: 'all 0.5s ease',
  },
  title: {
    color: '#999',
    fontWeight: 600,
    fontSize: 12,
    transition: 'color 0.5s ease',
    textTransform: 'capitalize'
  },
  text: {
    fontSize: 9,
    color: '#666',
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(0)'
    },
    '30%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

const OptionSize = ({
  value,
  changeProduct,
  selectProduct
}) => {
  const classes = useStyles();
  return (
    <label
      className={classes.customRadio}
      htmlFor={value?.product_id}
    >
      <input
        type="radio"
        name="size"
        id={value?.product_id}
        value={value?.product_id}
        onChange={changeProduct}
        checked={selectProduct?.product_id === value?.product_id}
      />
      <Box
        className={classes.radioButton}
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        height={160}
        pt={1}
        px={1}
      >
        <Box
          className={classes.checked}
          width={20}
          height={20}
          display="flex"
          alignSelf="end"
          alignItems="center"
          justifyContent="center"
        >
          <Check
            className={classes.icon}
          />
        </Box>
        <Box
          mt={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box className={classes.containerImg}>
            <img
              className={classes.img}
              src={value?.image}
              alt={`Paquete ${value?.name}`}
            />
          </Box>
          <Box
            mt={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              className={classes.title}
              variant="body2"
            >
              {value?.name}
            </Typography>
            <Box
              mt={0.5}
              textAlign="center"
            >
              <Typography
                className={classes.text}
                variant="body2"
              >
                {value?.size}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </label>
  );
};

OptionSize.propTypes = {
  value: PropTypes.object,
  changeProduct: PropTypes.func,
  selectProduct: PropTypes.object
};

export default OptionSize;
