import HttpRequest from './HttpRequest';

export default class ComponentSizeUnitService extends HttpRequest {
  static endpoint = 'component-size-units';

  static async getAll() {
    this.endpoint = 'component-size-units';
    const response = await this.get();
    return response;
  }
}
