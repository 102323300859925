import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ProductService from 'src/services/ProductService';
import ProductCardPreview from './ProductCardPreview';
import CreateScheduleForm from './CreateScheduleForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateScheduleView = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [product, setProduct] = useState({
    id: '',
    name: '',
    description: '',
    primary_image: '',
    price: '',
    prices: null,
    schedule: null,
  });

  const handleProductAttributes = (values) => {
    setProduct(values);
  };

  const fetchProductData = async () => {
    const response = await ProductService.getById(id);
    if (response?.code === 200) {
      setProduct({
        id: response?.data?.id,
        name: response?.data?.name,
        description: response?.data?.description,
        primary_image: response?.data?.primary_image,
        price: response?.data?.price,
        prices: response?.data?.prices,
        schedule: response?.data?.schedule,
      });
    }
  };

  useEffect(() => {
    if (id) {
      fetchProductData();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Horarios"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <ProductCardPreview product={product} />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <CreateScheduleForm
              product={product}
              onChange={handleProductAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CreateScheduleView;
