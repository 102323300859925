import HttpRequest from './HttpRequest';

export default class BrandCategoryService extends HttpRequest {
  static endpoint = 'brand-categories';

  static async getAll() {
    this.endpoint = 'brand-categories';
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'brand-categories';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.endpoint = 'brand-categories';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'brand-categories';
    const response = await this.put(id, data);
    return response;
  }

  static async toggle(id) {
    this.endpoint = 'brand-categories';
    const response = await this.get(`${id}/toggle`);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'brand-categories';
    const response = await this.delete(id);
    return response;
  }

  static async uploadImage(id) {
    this.endpoint = 'brand-categories/create-image';
    const response = await this.post(id);
    return response;
  }

  static async deleteImage(id) {
    this.endpoint = 'brand-categories/delete-image/:id';
    const response = await this.delete(id);
    return response;
  }

  static async getByIdWithProducts(brandId) {
    this.endpoint = `brand-categories/${brandId}/products`
    const response = await this.get();
    return response;
  }
}
