import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
  Menu,
  MenuItem,
  makeStyles
} from '@material-ui/core';

// Icons
import {
  Add,
  MoreVert as MoreVertIcon,
  Apps,
  Category
} from '@material-ui/icons';
import { FaBoxes, FaRuler } from 'react-icons/fa';
import { Search as SearchIcon } from 'react-feather';
import { AiFillTag } from 'react-icons/ai'

// Services
import ProductComponentService from 'src/services/ProductComponentService';
import ProductService from 'src/services/ProductService';
import BranchOfficeService from 'src/services/BranchOfficeService';
import StockService from 'src/services/StockService';

// Redux
import { selector as UserSelector } from 'src/redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  root: {},
  moreIcon: {
    cursor: 'pointer',
    marginLeft: '0.3rem'
  },
  categoryButton: {
    borderRadius: 5,
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
  },
  input: {
    '& .MuiInputBase-root': {
      boxSizing: 'border-box',
      borderRadius: 8,
      backgroundColor: '#e9e9f0',
      '&.Mui-focused': {
        border: '0.13em solid #009739',
      },
      '&.Mui-error': {
        border: '0.13em solid #f44336'
      }
    },
    '& .MuiInputBase-root::after, & .MuiInputBase-root::before': {
      display: 'none'
    },
    '& .MuiFilledInput-input': {
      paddingTop: theme.spacing(1.5),
      fontFamily: '"Poppins", sans-serif',
      fontSize: 14,
      fontWeight: 500
    },
    '& .MuiFilledInput-input:-webkit-autofill': {
      borderRadius: 8,
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
      marginTop: 0
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiInputAdornment-root': {
        transition: 'color 0.3s ease',
        color: '#8c9baf',
        '& .MuiIconButton-label': {
          transition: 'color 0.3s ease',
          color: '#8c9baf',
        },
      },
      '&.Mui-focused .MuiInputAdornment-root': {
        color: '#009739',
        '& .MuiIconButton-label': {
          color: '#009739',
        }
      },
    },
  },
  label: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 14,
    fontWeight: 500,
    color: '#a8aab4'
  },
}));

const Toolbar = ({ className, refresh, ...rest }) => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [anchorElement, setAnchorElement] = useState(null);
  const [dropdownMenuIsOpen, setDropdownMenuIsOpen] = useState(false);

  const toggleDropdownMenu = () => {
    setDropdownMenuIsOpen(!dropdownMenuIsOpen);
  };

  const openDropdownMenu = (event) => {
    setAnchorElement(event.currentTarget);
    toggleDropdownMenu();
  };

  const handleActiveOnlyProducts = async () => {
    if (user?.id) {
      const response = await BranchOfficeService.getAll();
      await ProductService.activeAllByBrand(user?.brand_id);

      if (response?.code === 200) {
        await response?.data?.map(async (branchOffice) => {
          if (branchOffice?.id) {
            await StockService?.activeAllByBranchOfficeId(branchOffice?.id);
          }
          return true;
        });

        toggleDropdownMenu();
        refresh();
      }
    }
  };

  const handleActiveAll = async () => {
    if (user?.id) {
      const response = await BranchOfficeService.getAll();
      await ProductComponentService.activeAllByBrand(user?.brand_id);
      await ProductService.activeAllByBrand(user?.brand_id);

      if (response?.code === 200) {
        await response?.data?.map(async (branchOffice) => {
          if (branchOffice?.id) {
            await StockService?.activeAllByBranchOfficeId(branchOffice?.id);
          }
          return true;
        });

        toggleDropdownMenu();
        refresh();
      }
    }
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        style={{
          gap: 15
        }}
      >
        <Link to="/app/productos/crear">
          <Button
            color="primary"
            variant="contained"
            className={classes?.categoryButton}
            size="small"
          >
            <Add
              size="small"
              style={{
                fontSize: 22
              }}
            />
            Agregar Producto
          </Button>
        </Link>
        <Link to="/app/productos/inventario">
          <Button
            color="primary"
            variant="contained"
            className={classes?.categoryButton}
            size="small"
          >
            <FaBoxes
              style={{
                fontSize: 18,
                marginRight: 5
              }}
            />
            Gestionar inventario
          </Button>
        </Link>
        <Link to="/app/productos/toppings">
          <Button
            color="primary"
            variant="contained"
            className={classes?.categoryButton}
            size="small"
          >
            <Category
              size="small"
              style={{
                fontSize: 18,
                marginRight: 5
              }}
            />
            Gestionar toppings
          </Button>
        </Link>
        <Link to="/app/productos/colores">
          <Button
            color="primary"
            variant="contained"
            className={classes?.categoryButton}
            size="small"
          >
            <Category
              size="small"
              style={{
                fontSize: 18,
                marginRight: 5
              }}
            />
            Gestionar colores
          </Button>
        </Link>
        {
          /*
            <Link to="/app/productos/titulos">
              <Button
                color="primary"
                variant="contained"
                className={classes?.categoryButton}
              >
                Gestionar titulos
              </Button>
            </Link>
          */
        }
        <Link to="/app/productos/crear/categoria">
          <Button
            color="primary"
            variant="contained"
            className={classes?.categoryButton}
            size="small"
          >
            <Apps
              size="small"
              style={{
                fontSize: 20,
                marginRight: 5
              }}
            />
            Gestionar Categorías
          </Button>
        </Link>
        <Link to="/app/productos/tallas">
          <Button
            color="primary"
            variant="contained"
            className={classes?.categoryButton}
            size="small"
          >
            <FaRuler
              style={{
                fontSize: 20,
                marginRight: 5
              }}
            />
            Gestionar Tallas
          </Button>
        </Link>
        <Link to="/app/productos/marcas">
          <Button
            color="primary"
            variant="contained"
            className={classes?.categoryButton}
            size="small"
          >
            <AiFillTag
              style={{
                fontSize: 20,
                marginRight: 5
              }}
            />
            Gestionar Marcas
          </Button>
        </Link>
        <div>
          <MoreVertIcon className={classes?.moreIcon} onClick={openDropdownMenu} />
          <Menu
            id="simple-menu"
            anchorEl={anchorElement}
            keepMounted
            open={Boolean(dropdownMenuIsOpen)}
            onClose={toggleDropdownMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={handleActiveOnlyProducts}>
              <Typography variant="body2">
                Activar sólo productos
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleActiveAll}>
              <Typography variant="body2">
                Activar productos y componentes
              </Typography>
            </MenuItem>
          </Menu>
        </div>
        <Box
          width={350}
        >
          <TextField
            className={classes.input}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            InputLabelProps={{
              className: classes.label,
            }}
            placeholder="Buscar Producto"
            variant="filled"
          />
        </Box>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  refresh: PropTypes.func,
};

Toolbar.defaultProps = {
  refresh: () => {}
};

export default Toolbar;
