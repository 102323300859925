import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// MUI components
import { Box, Button, makeStyles } from '@material-ui/core';

// Icons
import { Add, Check, Close, Remove } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  field: {
    '& > input': {
      border: 'none',
      outline: 'none',
      MozAppearance: 'textfield'
    }
  },
  input: {
    backgroundColor: '#f3f7fa',
    fontSize: 13,
    fontWeight: 500,
    fontFamily: '"Poppins", sans-serif',
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
    color: '#000',
    height: '33px',
    width: '33px',
    textAlign: 'center',
    borderRadius: 50
  },
  btn: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    padding: '3px',
    width: 22,
    height: 22,
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#2e7d32'
    },
    '&:active': {
      backgroundColor: '#3e8e41',
      transform: 'scale(0.96)'
    },
    transition: 'background 0.2s ease 0s'
  },
  buttonIcon: {
    borderRadius: '50%',
    minWidth: 10,
    padding: 3,
    margin: '0 3px'
  },
  buttonCancel: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white'
    }
  },
  scale: {
    animation: '$scale 0.7s ease both'
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(0)'
    },
    '10%': {
      transform: 'scale(0)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

const AddStock = ({ toggleAddStock, quantity, setQuantity, onChangeStock }) => {
  const classes = useStyles();

  const increase = () => {
    setQuantity(quantity + 1);
  };

  const decrease = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };
  return (
    <Box
      className={classes.scale}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        className={classes.field}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          onClick={() => decrease()}
          className={classes.btn}
          display={quantity > 0 ? 'flex' : 'none'}
          alignItems="center"
          justifyContent="center"
        >
          <Remove style={{ fontSize: '15px' }} />
        </Box>
        <Box mx={1}>
          <input
            className={classes.input}
            type="text"
            name="quantity"
            onChange={(e) => setQuantity(Number(e.target.value))}
            value={quantity}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Box>
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() => increase()}
          className={classes.btn}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Add style={{ fontSize: '15px' }} />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mt={1}>
        <Button
          className={classes.buttonIcon}
          disabled={quantity === 0}
          color="primary"
          variant="contained"
          size="small"
          onClick={() => onChangeStock()}
        >
          <Check size="small" style={{ fontSize: '13px' }} />
        </Button>
        <Button
          className={clsx(classes.buttonIcon, classes.buttonCancel)}
          color="primary"
          variant="contained"
          size="small"
          onClick={() => {
            setQuantity(0);
            toggleAddStock();
          }}
        >
          <Close size="small" style={{ fontSize: '13px' }} />
        </Button>
      </Box>
    </Box>
  );
};

AddStock.propTypes = {
  toggleAddStock: PropTypes.func,
  quantity: PropTypes.number,
  setQuantity: PropTypes.func,
  onChangeStock: PropTypes.func
};

export default AddStock;
