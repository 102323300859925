import axios from 'axios';

export default class GoogleMapsApiService {
  static async getAddressByPosition(latitude, longitude) {
    try {
      const uri = `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&latlng=${latitude},${longitude}`;
      const response = await axios.get(uri);
      return response;
    } catch (e) {
      console.error(e);
      return {
        data: null,
        code: 500,
        message: '',
        errors: [e.message]
      };
    }
  }
}
