import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { getDate, getHour } from 'src/utils/formatDate';
import cargandoGif from 'src/assets/image/cargando.gif';
import { Box, Grid, Card, Typography, makeStyles } from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';
import { Block, Check, MonetizationOn } from '@material-ui/icons';
import { TbRoad } from 'react-icons/tb';
import { IoIosPerson } from 'react-icons/io';
import { MdDeliveryDining } from 'react-icons/md';

const useStyles = makeStyles((theme) => ({
  service: {
    overflow: 'unset',
    position: 'relative',
    width: '100%',
    borderRadius: 20,
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: 30,
    height: 30,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  avatarProduct: {
    width: '100%',
    height: '100%'
  },
  iconStatus: {
    fontSize: 28,
    color: theme.palette.primary.main
  },
  gif: {
    maxWidth: '100%',
    minWidth: '100%',
    maxHeight: '100%',
    minHeight: '100%'
  },
  text: {
    fontSize: 12,
    fontWeight: 500,
    color: '#fff'
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 13,
    fontWeight: 500,
    color: '#fff'
  },
  iconSwap: {
    fontSize: 15,
    color: '#fff'
  },
  arrowIcon: {
    color: theme.palette.primary.main,
    fontSize: 25
  },
  pointer: {
    animation: '$point 1.25s infinite alternate'
  },
  containerIcon: {
    transform: 'translateY(-40%)'
  },
  bgIcon: {
    backgroundColor: '#d4edda',
    borderRadius: '50%',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
  },
  cardOrder: {
    boxShadow: 'none'
  },
  scroll: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  },
  scale: {
    animation: '$scale 0.7s ease both'
  },
  subTotal: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginRight: '5px',
    fontSize: 15
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(0)'
    },
    '30%': {
      transform: 'scale(0)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

const ServiceItem = ({ item }) => {
  const classes = useStyles();
  const statusTranslate = (status) => {
    switch (status) {
      case 'finished':
        return 'Finalizado';
      case 'rejected':
        return 'Rechazado';
      case 'cancelled':
        return 'Cancelado';
      default:
        return status;
    }
  };

  const formatPaymentMethod = (method) => {
    switch (method) {
      case 'cash':
        return 'Efectivo';
      case 'card':
        return 'Tarjeta';
      case 'datafono':
        return 'Datafono';
      default:
        return '';
    }
  };

  return (
    <Card className={clsx(classes.service, classes.scale)}>
      <Box px={2} pt={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box ml={0.5}>
              <Typography
                color="primary"
                style={{ fontWeight: 600, fontSize: '22px' }}
                variant="h4"
              >
                Orden #{item?.reference}
              </Typography>
              <Box display="flex" mt={0.5}>
                <Typography
                  style={{
                    color: '#A5A5A5',
                    fontWeight: 700,
                    marginRight: '5px'
                  }}
                  variant="body2"
                >
                  {getDate(item?.created_at)}
                </Typography>
                <Typography
                  style={{ color: '#A5A5A5', fontWeight: 700 }}
                  variant="body2"
                >
                  {getHour(item?.created_at)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            pr={0.8}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={item?.status === 'pending_to_be_sent' ? 1 : 0.3}
              width={item?.status === 'pending_to_be_sent' ? 50 : 'fit-content'}
              height={
                item?.status === 'pending_to_be_sent' ? 50 : 'fit-content'
              }
            >
              {item?.status === 'pending_to_be_sent' && (
                <>
                  <Box position="absolute" top={0}>
                    <img
                      className={classes.gif}
                      src={cargandoGif}
                      alt="cargando"
                    />
                  </Box>
                  <IoIosPerson className={classes.iconStatus} />
                </>
              )}
              {item?.status === 'sent' && (
                <MdDeliveryDining className={classes.iconStatus} />
              )}
              {item?.status === 'finished' && (
                <Check className={classes.iconStatus} />
              )}
              {(item?.status === 'cancelled' ||
                item?.status === 'rejected') && (
                <Block className={classes.iconStatus} />
              )}
            </Box>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, fontSize: '13px', textAlign: 'center' }}
            >
              {statusTranslate(item?.status)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box px={2} pt={1} pb={2}>
        <Box
          ml={0.3}
          mt={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <TbRoad
              style={{ fontSize: '22px', color: '#009739' }}
              color="primary"
            />
            <Typography
              style={{
                color: '#A5A5A5',
                fontWeight: 600,
                marginRight: '5px',
                marginLeft: '4px'
              }}
              variant="body2"
            >
              Distancia
            </Typography>
          </Box>
          <Typography className={classes.subTotal} variant="body2">
            <CurrencyFormat
              value={item?.distance || 0}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
            />
            km
          </Typography>
          {/* <Box py={1} pr={1} display="flex">
          <OrderCost
            className={classes.cardOrder}
            distance={item?.distance}
            deliveryCost={item?.delivery_cost}
            column
          />
          </Box> */}
        </Box>
        <Box
          ml={0.3}
          mt={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <MonetizationOn style={{ fontSize: '22px' }} color="primary" />
            <Typography
              style={{
                color: '#A5A5A5',
                fontWeight: 600,
                marginRight: '5px',
                marginLeft: '4px'
              }}
              variant="body2"
            >
              {formatPaymentMethod(item?.payment_method)}
            </Typography>
          </Box>
          <Typography className={classes.subTotal} variant="body2">
            $
            <CurrencyFormat
              value={item?.sub_total}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
            />
          </Typography>
        </Box>
        <Box
          ml={0.5}
          mt={0.7}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            style={{
              color: '#A5A5A5',
              fontWeight: 600,
              marginRight: '5px',
              fontSize: 12
            }}
            variant="body2"
          >
            {item?.status === 'finished' && (
              <>
                H. Salida:{' '}
                {item?.order_status?.filter((s) => s?.status === 'sent')
                  ?.length > 0 ? (
                  <>
                    {getHour(
                      item?.order_status
                        ?.filter((s) => s?.status === 'sent')
                        .slice(-1)[0]?.created_at
                    )}
                  </>
                ) : (
                  <>-</>
                )}
              </>
            )}
            {item?.status === 'cancelled' && (
              <>
                H. Cancelación:{' '}
                {getHour(
                  item?.order_status
                    ?.filter((s) => s?.status === 'cancelled')
                    .slice(-1)[0]?.created_at
                ) || '-'}
              </>
            )}
            {item?.status === 'rejected' && (
              <>
                H. Rechazado:{' '}
                {getHour(
                  item?.order_status
                    ?.filter((s) => s?.status === 'rejected')
                    .slice(-1)[0]?.created_at
                ) || '-'}
              </>
            )}
          </Typography>
          <Typography
            style={{
              color: '#A5A5A5',
              fontWeight: 600,
              fontSize: 12
            }}
            variant="body2"
          >
            H. Entrega:{' '}
            {getHour(
              item?.order_status
                ?.filter(
                  (s) =>
                    (s?.status === 'finished' && !item?.roundtrip) ||
                    s?.status === 'returning'
                )
                .slice(-1)[0]?.created_at
            ) || '-'}
          </Typography>
        </Box>
        {item?.roundtrip && (
          <Box
            ml={0.5}
            mt={0.7}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              style={{
                color: '#A5A5A5',
                fontWeight: 600,
                marginRight: '5px',
                fontSize: 12
              }}
              variant="body2"
            >
              H. Regreso:{' '}
              {getHour(
                item?.order_status
                  ?.filter((s) => s?.status === 'returning')
                  .slice(-1)[0]?.created_at
              ) || '-'}
            </Typography>
            <Typography
              style={{
                color: '#A5A5A5',
                fontWeight: 600,
                fontSize: 12
              }}
              variant="body2"
            >
              H. Finalizado:{' '}
              {getHour(
                item?.order_status
                  ?.filter(
                    (s) =>
                      s?.status === 'finished' ||
                      s?.status === 'driver_delivered'
                  )
                  .slice(-1)[0]?.created_at
              ) || '-'}
            </Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
};

const Results = ({ services }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box height="100%" className={classes.scroll} display="flex" my={2}>
      <Box px={2.5} py={0.5} width="100%">
        <Grid container spacing={2}>
          {services.map((item) => (
            <Grid item xs={12} md={6}>
              <Box
                onClick={() => navigate(`/app/paqueteria/${item?.id}`)}
                className={classes.hoverTransition}
              >
                <ServiceItem item={item} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

ServiceItem.propTypes = {
  item: PropTypes.object
};

Results.propTypes = {
  services: PropTypes.array
};

export default Results;
