import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import useErrors from 'src/hooks/useErrors';
import SizesService from 'src/services/SizesService';
import { Box, Typography, Button, TextField , makeStyles, FormControl, FormHelperText } from '@material-ui/core';
import Modal from 'src/components/Modal';
import useModal from 'src/hooks/useModal';
import { selector as UserSelector } from 'src/redux/ducks/user';

const usStyles = makeStyles(() => ({
  head: {
    background: 'white',
    padding: '1rem',
    borderRadius: '5px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: '1rem'
  }
}));
const Toolbar = ({refresh}) => {
  const [ isOpen, toggle ] = useModal();
  const classes = usStyles();
  const { user } = useSelector(UserSelector);
  const { setErrors } = useErrors();
  const [localError, setLocalError] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { nameAdd } = e.target;
    const errors = [];
    const data = {
      name: nameAdd.value,
      size: [],
      brand_id: user.brand_id
    };
    if (nameAdd.value.trim().length === 0) {
      errors.push({
        field: 'nameAdd',
        error: 'El nombre de las tallas es obligatorio'
      });
    }
    if (errors.length > 0) {
      setErrors(errors.map((err) => err.error));
      setLocalError(errors);
      return;
    }
    await SizesService.create(data);
    refresh();
    setErrors('');
    toggle();
  };
  const  handledModal = () => {
    toggle();
  }
  return (
    <div>
      <Box className={classes.head} display="flex" justifyContent='space-between'>
        <Typography variant="h2">Gestionar Tallas</Typography>
        <Button variant="contained" color="primary" onClick={() => handledModal()}>
          Agregar talla
        </Button>
      </Box>
      <Modal isOpen={isOpen} toggle={toggle} title='Agregar una nueva talla'>
        <form onSubmit={(e) => handleSubmit(e)}>
          <FormControl
            required
            variant="outlined"
            error={localError.some((e) => e.field === 'nameAdd')}
          >
            <Box>
              <TextField
                fullWidth
                label="Nombre de las tallas"
                margin="normal"
                name="nameAdd"
                variant="outlined"
              />
              <Button variant="contained" color="primary" type='submit'>
                Agregar
              </Button>
            </Box>
            {localError.some((e) => e.field === 'nameAdd') && (
              <FormHelperText error>
                {localError?.find((e) => e.field === 'nameAdd')?.error || ''}
              </FormHelperText>
            )}
          </FormControl>
          
        </form>
      </Modal>
    </div>
  );
}
Toolbar.propTypes = {
  refresh: PropTypes.func
};

export default Toolbar;