import HttpRequest from './HttpRequest';

export default class customBrandsService extends HttpRequest {
  static endpoint = 'custom-brands';

  static async getAllByPartnerId(partnerId) {
    this.endpoint = 'custom-brands';
    const response = await this.get(`/by-partner/${partnerId}`);
    return response;
  }

  static async getById(id) {
    this.endpoint = 'custom-brands';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.endpoint = 'custom-brands';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'custom-brands';
    const response = await this.put(id, data);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'custom-brands';
    const response = await this.delete(id);
    return response;
  }

  static async uploadLogo(data) {
    this.endpoint = 'custom-brands/upload-logo';
    const response = await this.post(data);
    return response;
  }

  static async deleteLogo(id) {
    this.endpoint = 'custom-brands/delete-logo';
    const response = await this.delete(id);
    return response;
  }
}