import HttpRequest from './HttpRequest';

export default class BrandService extends HttpRequest {
  static endpoint = 'brands';

  static async getAll() {
    this.endpoint = 'brands/branch-offices';
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'brands';
    const response = await this.get(id);
    return response;
  }

  static async getCategories(id) {
    this.endpoint = `brands/${id}/categories?products=false`;
    const response = await this.get();
    return response;
  }

  static async getProducts(id) {
    this.endpoint = `brands/${id}/categories?products=true`;
    const response = await this.get();
    return response;
  }

  static async create(data) {
    this.endpoint = 'brands';
    const response = await this.post(data);
    return response;
  }

  static async createPublic(data) {
    this.endpoint = 'brands/public-create';
    const response = await this.post(data, true);
    return response;
  }

  static async uploadImagePublic(data) {
    this.endpoint = 'brands/public-create-logo';
    const response = await this.post(data, true);
    return response;
  }

  static async updateCustomize(id, data) {
    this.endpoint = 'brands';
    const response = await this.put(`${id}/customize`, data);
    return response;
  }
}
