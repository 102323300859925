import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// MUI components
import {
  Card,
  Box,
  TextField,
  IconButton,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
  Typography,
  makeStyles,
  Collapse,
  useTheme
} from '@material-ui/core';

// Icons
import { KeyboardArrowDownRounded, MoreHoriz } from '@material-ui/icons';

import useConfirmation from 'src/hooks/useConfirmation';

// // Services
import StockService from 'src/services/StockService';

// Components
import ConfirmationModal from 'src/components/ConfirmationModal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RowSize from './components/RowSize';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '15px',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none'
  },
  input: {
    '& .MuiInputBase-root': {
      boxSizing: 'border-box',
      borderRadius: 6,
      backgroundColor: '#F5F7FB',
      border: '1px solid rgb(216, 216, 216)',
      '&.Mui-focused': {
        border: '0.13em solid #009739'
      },
      '&.Mui-error': {
        border: '0.13em solid #f44336'
      }
    },
    '& .MuiInputBase-root::after, & .MuiInputBase-root::before': {
      display: 'none'
    },
    '& .MuiFilledInput-input': {
      paddingTop: theme.spacing(1.1),
      paddingBottom: theme.spacing(1.1),
      fontFamily: '"Poppins", sans-serif',
      fontSize: 13,
      color: 'rgba(0, 0, 0, 0.8)',
      fontWeight: 500
    },
    '& .MuiFilledInput-input.Mui-disabled': {
      color: '#a8aab4'
    },
    '& .MuiFilledInput-input:-webkit-autofill': {
      borderRadius: 7
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
      marginTop: 0
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiInputAdornment-root': {
        transition: 'color 0.3s ease',
        color: '#8c9baf',
        '& .MuiIconButton-label': {
          transition: 'color 0.3s ease',
          color: '#8c9baf'
        }
      },
      '&.Mui-focused .MuiInputAdornment-root': {
        color: '#009739',
        '& .MuiIconButton-label': {
          color: '#009739'
        }
      }
    }
  },
  label: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 13,
    fontWeight: 500,
    color: '#a8aab4'
  },
  containerAvatar: {
    objectFit: 'cover',
    marginRight: theme.spacing(2),
    width: 60,
    height: 60,
    borderRadius: 10,
    overflow: 'hidden'
  },
  avatar: {
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '100%',
    minHeight: '100%'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd'
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  },
  table: {
    border: 'none',
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
    '& th,td': {
      border: 'none'
    },
    '& th': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5)
    },
    '& th p': {
      color: '#b2b3b8',
      fontWeight: 500,
      fontSize: 14
    }
  },
  head: {
    border: 'none'
  },
  nameProduct: {
    fontSize: 15,
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  descripProduct: {
    color: '#A5A5A5',
    fontSize: 12,
    fontWeight: 500
  },
  row: {
    background: '#fff',
    '& td:first-child': {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10
    },
    '& td:last-child': {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10
    }
  },
  icon: {
    fontSize: 17,
    marginRight: theme.spacing(0.5)
  },
  button: {
    textTransform: 'capitalize',
    width: 150,
    fontSize: 11,
    borderRadius: 7
  },
  buttonIcon: {
    borderRadius: '50%',
    minWidth: 10,
    padding: 3,
    margin: '0 3px'
  },
  buttonCancel: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white'
    }
  },
  tableSizes: {
    '& tr': {
      borderBottom: '1px solid rgb(216, 216, 216)'
    },
    '& tr:last-child': {
      border: 'none'
    }
  },
  dropdown: {
    color: theme.palette.primary.main,
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s ease 0s'
  },
  dropdownActive: {
    transform: 'rotate(180deg)'
  },
  scale: {
    animation: '$scale 0.7s ease both'
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(0)'
    },
    '10%': {
      transform: 'scale(0)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

const Results = ({
  className,
  idBranchOffice,
  addQuantity,
  updateSizes,
  toggleShowSizes,
  data,
  page,
  setPage,
  ...rest
}) => {
  const classes = useStyles();
  const { toggleModal } = useConfirmation();
  const [limit, setLimit] = useState(10);
  const [stock, setStock] = useState([]);
  const [dataEditStock, setDataEditStock] = useState(null);
  const [action, setAction] = useState('');
  const theme = useTheme();

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Actualiza el stock sumando la cantidad seleccionado el selector de cantidad
  const addStock = async () => {
    const { indexStock, indexSize } = dataEditStock;
    delete dataEditStock.indexStock;
    delete dataEditStock.indexSize;

    const quantity = dataEditStock?.products[0]?.quantity;

    const response = await StockService.updateQuantities(dataEditStock);
    if (response?.code === 200) {
      addQuantity(indexStock, indexSize, quantity);
    }
  };

  // Actualiza el stock con la cantidad seleccionada en el input
  const updateStock = async () => {
    const { indexStock } = dataEditStock;
    delete dataEditStock.indexStock;

    const response = await StockService.update(
      dataEditStock?.product_id,
      dataEditStock
    );
    if (response?.code === 200) {
      updateSizes(indexStock, dataEditStock.size_quantities);
    }
  };

  const handleDataStock = (dataStock, actionStock) => {
    setDataEditStock(dataStock);
    setAction(actionStock);
    toggleModal();
  };

  /*
    Una vez aceptada el modal de confirmación se elige el tipo de edición, si fue de cantidad
    o de forma directo atravez del TextField
  */
  const onAccept = () => {
    if (action === 'add') {
      addStock();
      return;
    }

    if (action === 'edit') {
      updateStock();
    }
  };

  /**
   * filterPerPage, me filtra un limite de productos, lo cuales van a ser los que se van
   * a renderizar por cada pagina de la tabla, por ejemplo si se van a mostrar 10 productos
   * por pagina, entonces este metodo me va a obtener solo los 10 productos que se encuentren
   * en esa pagina.
   */
  const filterPerPage = () => {
    // Filtro los productos que ya tengan tallas definidas
    const stockPerPage = data.slice(page * limit, page * limit + limit);
    setStock(stockPerPage);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getTotalQuantity = (itemStock) => {
    return itemStock.size_quantities.reduce((prevValue, currentItem) => {
      return prevValue + currentItem.quantity;
    }, 0);
  };

  useEffect(() => {
    filterPerPage();
  }, [page]);

  useEffect(() => {
    filterPerPage();
  }, [data]);

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1020}>
            <Table className={classes.table}>
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell style={{ width: 10 }} />
                  <TableCell style={{ width: 270 }}>
                    <Typography variant="body2">Nombre</Typography>
                  </TableCell>
                  <TableCell style={{ width: 190 }}>
                    <Typography variant="body2">Variante</Typography>
                  </TableCell>
                  <TableCell style={{ width: 150 }}>
                    <Typography variant="body2">Cantidad</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody lockAxis="y">
                {stock?.map((item, index) => (
                  <>
                    <TableRow className={classes.row} index={index}>
                      <TableCell style={{ width: 10 }}>
                        <Box width="inherit">
                          <IconButton
                            onClick={() => toggleShowSizes(index)}
                            size="small"
                            aria-label="drop-down"
                          >
                            <KeyboardArrowDownRounded
                              className={clsx(classes.dropdown, {
                                [classes.dropdownActive]: item?.showSizes
                              })}
                            />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell
                        style={{
                          width: 270
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <Box className={classes.containerAvatar}>
                            <img
                              src={item?.product?.primary_image}
                              className={classes.avatar}
                              alt="product_image"
                            />
                          </Box>
                          <Box width={270}>
                            <Typography
                              variant="body2"
                              className={classes.nameProduct}
                            >
                              {item?.product?.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={classes.descripProduct}
                            >
                              {item?.product?.description}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell style={{ width: 190 }}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          width="inherit"
                        >
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="body2"
                              className={classes.descripProduct}
                              style={{
                                color: theme.palette.primary.main
                              }}
                            >
                              Tallas:
                            </Typography>
                            <Box display="flex" ml={0.5}>
                              {item?.size_quantities
                                ?.slice(0, 3)
                                .map((size) => (
                                  <Box
                                    bgcolor="#d4edda"
                                    borderRadius="50%"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    width={26}
                                    height={26}
                                    p={0.7}
                                    mx={0.5}
                                  >
                                    <Typography
                                      className={classes.descripProduct}
                                      style={{
                                        color: '#009739',
                                        fontSize: 11,
                                        fontWeight: 600
                                      }}
                                      variant="body2"
                                    >
                                      {size?.name}
                                    </Typography>
                                  </Box>
                                ))}
                              {item?.size_quantities?.length > 3 && (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  bgcolor="#e9e9f0"
                                  borderRadius="50%"
                                  mx={0.3}
                                  width={26}
                                  height={26}
                                >
                                  <MoreHoriz
                                    color="primary"
                                    style={{ fontSize: '19px' }}
                                  />
                                </Box>
                              )}
                            </Box>
                          </Box>
                          <Box display="flex" alignItems="center" mt={1}>
                            <Typography
                              variant="body2"
                              className={classes.descripProduct}
                              style={{
                                color: theme.palette.primary.main
                              }}
                            >
                              Color:
                            </Typography>
                            <Box display="flex" alignItems="center" ml={1}>
                              {item?.product?.color_id && (
                                <Box
                                  mx={0.2}
                                  bgcolor={`#${item?.product?.color?.hexadecimal}`}
                                  width={16}
                                  height={16}
                                  borderRadius="50%"
                                  border="1px solid rgba(0, 0, 0, 0.1)"
                                />
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <TextField
                          className={classes.input}
                          fullWidth
                          disabled
                          InputLabelProps={{
                            className: classes.label
                          }}
                          value={getTotalQuantity(item)}
                          placeholder="Cantidad"
                          size="small"
                          variant="filled"
                        />
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                    <TableRow className={classes.row}>
                      <TableCell style={{ padding: 0 }} colSpan={10}>
                        <Collapse
                          in={item?.showSizes}
                          timeout={400}
                          mountOnEnter
                          unmountOnExit
                        >
                          <Box minWidth={1020}>
                            <Table className={classes.tableSizes}>
                              <TableBody lockAxis="y">
                                {item?.size_quantities?.map(
                                  (size, indexSize) => (
                                    <RowSize
                                      idBranchOffice={idBranchOffice}
                                      item={item}
                                      dataSize={size}
                                      sizes={item?.size_quantities}
                                      index={indexSize}
                                      indexStock={page * limit + index}
                                      onChange={handleDataStock}
                                    />
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={stock.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <ConfirmationModal
        onAccept={onAccept}
        text={
          action === 'add'
            ? `¿Está seguro que desea ingresar ${dataEditStock?.products[0]?.quantity} cantidad(es) más de este producto al stock?`
            : '¿Está seguro que desea editar la cantidad del producto?'
        }
      />
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  idBranchOffice: PropTypes.string,
  addQuantity: PropTypes.func,
  updateSizes: PropTypes.func,
  toggleShowSizes: PropTypes.func,
  data: PropTypes.array,
  page: PropTypes.number,
  setPage: PropTypes.func
};

export default Results;
