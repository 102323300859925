import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import ListOrders from './components/ListOrders';
import Report from './components/Report';

const Results = ({
  numberStatus,
  orders,
  finishedOrders,
  handleFilter,
  getOrders,
  filter,
}) => {
  const getTotalMethod = (method) => {
    const ordersByMethod = finishedOrders?.filter((item) => item?.payment_method === method);
    return ordersByMethod?.reduce((amount, order) => (
      amount + order?.sub_total
    ), 0);
  };

  const getTotal = () => {
    return finishedOrders?.reduce((amount, order) => (
      amount + order?.sub_total
    ), 0);
  };

  return (
    <Box display="flex" p={3} height="100%" width="100%">
      <Box display="flex" flexDirection="column" height="100%" width="100%">
        <Box display="flex" alignItems="center" height="fit-content">
          <h1>Reporte de ventas</h1>
        </Box>
        <Box py={2} display="flex" height="95%" width="100%">
          <ListOrders
            numberStatus={numberStatus}
            orders={orders}
            handleFilter={handleFilter}
            getOrders={getOrders}
            filter={filter}
            totalCash={getTotalMethod('cash')}
            totalCard={getTotalMethod('card')}
            total={getTotal()}
          />
          <Report
            totalCash={getTotalMethod('cash')}
            totalCard={getTotalMethod('card')}
            total={getTotal()}
          />
        </Box>
      </Box>
    </Box>
  );
};

Results.propTypes = {
  numberStatus: PropTypes.object,
  orders: PropTypes.array,
  finishedOrders: PropTypes.array,
  handleFilter: PropTypes.func,
  getOrders: PropTypes.func,
  filter: PropTypes.string
};

export default Results;
