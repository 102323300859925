import HttpRequest from './HttpRequest';

export default class ComponentBrandService extends HttpRequest {
  static endpoint = 'component-brands';

  static async getAll() {
    this.endpoint = 'component-brands';
    const response = await this.get();
    return response;
  }
}
