import React, { useEffect, useState } from 'react';
import useSearch from 'src/hooks/useSearch';
import {
  Container,
  Box,
  makeStyles,
  Grid,
} from '@material-ui/core';
import Page from 'src/components/Page';
import ConfirmationModal from 'src/components/ConfirmationModal';
import ToppingService from 'src/services/ToppingService';
import Toolbar from './Toolbar';
import Topping from '../../../components/Topping/Topping';
import CreateToppingModal from '../../../components/CreateToppingModal';
import CreateItemModal from './modals/CreateItemModal';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ToppingsManagmentView = () => {
  const classes = useStyles();
  const [showCreateTopping, setShowCreateTopping] = useState(false);
  const [showCreateItem, setShowCreateItem] = useState(false);
  const [toppings, setToppings] = useState([]);
  const [selectedTopping, setSelectedTopping] = useState({
    title: '',
    description: '',
    type: '',
    is_required: false,
    max_items: 0,
    order: 0,
    items: [],
  });
  const [selectedItem, setSelectedItem] = useState({
    name: '',
    price: 0,
    min: 0,
    max: 0,
    value: false,
    component_id: [],
    options: [],
    id: ''
  });
  const [indexItem, setIndexItem] = useState(0);
  const [edit, setEdit] = useState(false);
  const { dataFilterd, handleSearch, handleInitialData } = useSearch({ keys: ['title', 'description'] });

  const toggleCreateTopping = (isEdit) => {
    setShowCreateTopping(!showCreateTopping);
    setEdit(isEdit);
  };

  const toggleCreateItem = (isEdit) => {
    setShowCreateItem(!showCreateItem);
    setEdit(isEdit);
  };

  const handleTopping = (topping) => {
    setSelectedTopping(topping);
  };

  const handleItem = (item, index) => {
    setSelectedItem(item);
    setIndexItem(index);
    toggleCreateItem(true);
  };

  const getToppings = async () => {
    try {
      const response = await ToppingService.getAll();
      if (response?.code === 200) {
        setToppings(response?.data);
        handleInitialData(response?.data);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  const refresh = () => {
    getToppings();
  };

  const deleteTopping = async () => {
    try {
      await ToppingService.remove(selectedTopping?.id);
      refresh();
      toggleCreateTopping(false);
    } catch (e) {
      console.error(e.message);
    }
  };

  const deleteItem = async () => {
    try {
      const items = selectedTopping.items.slice();
      items.splice(indexItem, 1);
      const response = await ToppingService.update(selectedTopping.id, {
        ...selectedTopping,
        items
      });
      if (response?.code === 200 || response?.code === 201) {
        refresh();
        toggleCreateItem(false);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  const onAccept = async () => {
    if (showCreateTopping) deleteTopping();
    else if (showCreateItem) deleteItem();
  };

  useEffect(() => {
    getToppings();
  }, []);

  useEffect(() => {
    if (Array.isArray(dataFilterd)) {
      setToppings(dataFilterd);
    }
  }, [dataFilterd]);

  return (
    <Page
      className={classes.root}
      title="Gestión de personalización"
    >
      <Container maxWidth={false}>
        <Toolbar onSearch={handleSearch} toggleModal={toggleCreateTopping} />
        <Box display="flex" mt={3}>
          <Grid container spacing={2}>
            {toppings.map((topping) => (
              <Grid key={topping?.id} item md={6} xs={12}>
                <Topping
                  onRefresh={refresh}
                  toggleCreateTopping={toggleCreateTopping}
                  toggleCreateItem={toggleCreateItem}
                  setTopping={handleTopping}
                  setItem={handleItem}
                  topping={topping}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      {showCreateTopping && (
        <CreateToppingModal
          edit={edit}
          topping={selectedTopping}
          toggleCreateTopping={toggleCreateTopping}
          onRefresh={refresh}
        />
      )}
      {showCreateItem && (
        <CreateItemModal
          edit={edit}
          topping={selectedTopping}
          item={selectedItem}
          indexItem={indexItem}
          toggleCreateItem={toggleCreateItem}
          onRefresh={refresh}
        />
      )}
      <ConfirmationModal onAccept={onAccept} />
    </Page>
  );
};

export default ToppingsManagmentView;
