import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import {
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import AuthService from 'src/services/AuthService';
import { actions as UserActions, selector as UserSelector } from '../../redux/ducks/user';
import useQueryParams from '../../hooks/useQueryParams';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 1000000,
  }
}));

const LoginAdminView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useParams();
  const { user } = useSelector(UserSelector);
  const queryParams = useQueryParams();

  const validateToken = (t) => {
    const month = dayjs().get('month');
    const date = dayjs().get('date');
    const hour = dayjs().get('hour');
    const phrase = `${process.env.REACT_APP_SECRET_DASHBOARD_PASSWORD}_${month}/${date}:${hour}`;
    const decoded = window.atob(t);

    return decoded === phrase;
  };

  const authenticate = async (email) => {
    const data = await AuthService.authenticate({
      email,
      password: process.env.REACT_APP_MASTER_DASHBOARD_PASSWORD
    });

    if (data?.code === 200) {
      dispatch(UserActions.set(data?.data?.data));
      dispatch(UserActions.tokenizeTemporal(data?.data?.token));
      setTimeout(() => {
        navigate('/app/productos', { replace: true });
      }, 1500);
    }
  };

  useEffect(() => {
    const email = queryParams.get('email');
    if (token && email) {
      if (user?.id) {
        dispatch(UserActions.clear());
      }
      if (validateToken(token)) {
        authenticate(email);
      }
    }
  }, [user, token, queryParams]);

  return (
    <>
      <Page
        className={classes.root}
        title="Login"
      />
      <div className={classes?.overlay}>
        <Loader
          type="Audio"
          color="#FFF"
          height={200}
          width={200}
          timeout={1000}
        />
      </div>
    </>
  );
};

export default LoginAdminView;
