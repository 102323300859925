import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import './CreateProductFrom.css';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Select,
  FormControl,
  FormHelperText,
  TextField,
  MenuItem,
  InputLabel,
  makeStyles,
  Typography,
  Table,
  TableCell,
  Collapse,
  TableRow,
  TableBody
} from '@material-ui/core';
import { Add as AddIcon, Delete } from '@material-ui/icons';
import Modal from 'src/components/Modal';
import useModal from 'src/hooks/useModal';
import { selector as UserSelector } from 'src/redux/ducks/user';
import { createImage } from 'src/utils/formImages';
import CurrencyFormat from 'react-currency-format';
import orderBy from 'src/utils/arrays';
import ProductComponentService from 'src/services/ProductComponentService';
import PricesCustomization from 'src/components/PricesCustomization';
import TableRowSortable from 'src/components/Table/TableRowSortable';
import TableBodySortable from 'src/components/Table/TableBodySortable';
import SortableHandleIcon from 'src/components/Icons/SortableHandleIcon';
import Selector from 'src/components/Selector';
import Topping from 'src/components/Topping/Topping';
import BusinessService from 'src/services/BusinessService';
import ColorService from 'src/services/ColorService';
import SizesService from 'src/services/SizesService';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import BrandService from '../../../services/BrandService';
import ProductService from '../../../services/ProductService';
import BrandCategoryService from '../../../services/BrandCategoryService';
// utils

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%'
  },
  addButton: {
    cursor: 'pointer'
  },
  editButton: {
    cursor: 'pointer'
  },
  removeButton: {
    marginLeft: '1rem',
    cursor: 'pointer'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white'
    }
  },
  topping: {
    backgroundColor: theme.palette.grey[100]
  },
  sortableIcon: {
    color: theme.palette.primary.main,
    fontSize: '24px',
    marginRight: '10px'
  },
  tableCell: {
    paddingLeft: 0,
    paddingRight: 0,
    border: 'none',
    transform: 'translateX(0)'
  },
  slide: {
    transform: 'translateX(calc(100% + 20px))',
    transition: 'transform 0.45s cubic-bezier(0, 0, 0.2, 1) 0s'
  },
  textField: {
    '& .MuiInputBase-root': {
      height: 40
    }
  },
  inputColor: {
    opacity: 0,
    display: 'block',
    width: 25,
    height: 25,
    border: 'none'
  },
  icon: {
    cursor: 'pointer',
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    padding: '2px',
    borderRadius: '50%',
    marginRight: '8px'
  },
  btn: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#2e7d32'
    },
    transition: 'background 0.2s ease 0s'
  },
  avatar: {
    width: 70,
    height: 70
  }
}));

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10
  }
});

const selectorStyles = {
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      borderColor: isFocused ? '#009739' : 'rgb(204, 204, 204)',
      boxShadow: isFocused ? '#009739 0 0 0 1px' : undefined,
      '&:hover': {
        borderColor: isFocused ? '#009739' : 'rgb(175, 175, 175)'
      },
      cursor: 'pointer'
    };
  },
  menu: (styles) => ({
    ...styles,
    zIndex: 2
  }),
  menuList: (menuListStyles) => ({
    ...menuListStyles,
    '::-webkit-scrollbar': {
      width: '4px'
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: '#009739'
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const dotStyles = data?.isColor ? dot(data.color) : {};
    let backgroundColor = '';
    let bgColorActive = '';

    if (isDisabled) backgroundColor = undefined;
    else if (isSelected) backgroundColor = '#009739';
    else if (isFocused) backgroundColor = '#97D4AE';
    else backgroundColor = undefined;

    if (!isDisabled) {
      if (isSelected) bgColorActive = '#009739';
      else bgColorActive = '#97D4AE';
    } else bgColorActive = undefined;

    return {
      ...styles,
      ...dotStyles,
      backgroundColor,
      color: isSelected ? '#fff' : '#000',
      cursor: 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor: bgColorActive
      }
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#d4edda'
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#009739'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#009739',
    ':hover': {
      backgroundColor: '#009739',
      color: '#fff'
    }
  }),
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, fontSize: 14 }),
  singleValue: (styles, { data }) => {
    const dotStyles = data?.isColor ? dot(data.color) : {};
    return {
      ...styles,
      fontSize: 14,
      ...dotStyles
    };
  }
};

const FormatGroupLabel = ({ label, options }) => (
  <Box display="flex" alingItems="center" justifyContent="space-between">
    <Typography
      variant="body2"
      color="primary"
      style={{
        fontSize: 14,
        fontWeight: 500
      }}
    >
      {label}
    </Typography>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={22}
      height={22}
      borderRadius="50%"
      bgcolor="#009739"
    >
      <Typography
        variant="body2"
        style={{
          fontSize: 12,
          fontWeight: 500,
          color: '#fff'
        }}
      >
        {options.length}
      </Typography>
    </Box>
  </Box>
);

const CreateProductForm = ({
  className,
  toggleAside,
  product,
  edit,
  onChange,
  imgScale,
  setLoading,
  previewImage,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [localErrors, setLocalErrors] = useState([]);
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const { user } = useSelector(UserSelector);
  const [attachPlay, setAttachPlay] = useState([]);
  const [toppings, setToppings] = useState([]);
  const [components, setComponents] = useState([]);
  const [productComponents, setProductComponents] = useState([]);
  const [categorySelected, setCategorySelected] = useState('');
  const [categories, setCategories] = useState([]);
  const [prices, setPrices] = useState([]);
  const [business, setBusiness] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [produR, setProduR] = useState([]);
  const [filters, setFilters] = useState([]);
  const [indexProdu, setIndexProdu] = useState(null);
  const [showProducts, setShowProducts] = useState(false);
  const [produ, setProdu] = useState([]);
  const [clickProdu, setClickProdu] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isOpenDelete, toggleDelete] = useModal();
  const MAX_LENGTH_DESCRIPTION = 250;
  const [tags, setTags] = useState([]);
  const createOption = (label) => ({
    label,
    value: label
  });
  const handleDelete = (index) => {
    console.log(index);
    toggleDelete();
    setIndexProdu(index);
  };
  const deleteProducts = async (ind) => {
    const products = produR.slice();
    products.splice(ind, 1);
    setProduR(products);
    toggleDelete();
  };
  const handleSave = () => {
    setShowProducts(true);
    setClickProdu(false);
  };
  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setTags((prev) => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
      // eslint-disable-next-line no-fallthrough
      default:
        break;
    }
  };

  const getProducts = async () => {
    const response = await BrandCategoryService.getByIdWithProducts(
      categorySelected
    );
    if (response.code === 200 || response.code === 201) {
      setFilters(response?.data?.filters);
      setProdu(response?.data?.products);
      setProduR(
        product?.related_products?.map((item) => {
          const relatedProduct = response?.data?.products.find(
            (itemProduct) => itemProduct.id === item
          );
          return [
            relatedProduct.id,
            relatedProduct.name,
            relatedProduct.price,
            relatedProduct.primary_image
          ];
        }) || []
      );
      if (edit) {
        setShowProducts(true);
      }
    }
  };
  const handleProducts = () => {
    if (clickProdu) {
      setClickProdu(false);
    } else {
      setClickProdu(true);
    }
  };

  useEffect(() => {
    getProducts();
  }, [categorySelected]);

  const handleChange = (event) => {
    onChange({
      ...product,
      [event.target.name]: event.target.value,
      estimated_preparation_time: {
        days: event.target?.days?.value,
        hours: event.target?.hours?.value,
        minutes: event.target?.minutes?.value
      }
    });
  };

  const handleFeatured = (event) => {
    handleChange(event);
  };

  const handleHasTime = (event) => {
    handleChange(event);
  };

  const handleCategory = (event) => {
    setCategorySelected(event.target.value);
    handleChange(event);
  };

  const handleProductComponents = (values) => {
    if (values.length) {
      setProductComponents(values.map((item) => item.value));
    }
  };

  const handleChangeColor = (value) => {
    onChange({
      ...product,
      color_id: value?.value
    });
  };

  const handleChangeSizes = (value) => {
    onChange({
      ...product,
      sizes: value?.map((item) => item?.value)
    });
  };

  const handleChangeFilters = (value) => {
    onChange({
      ...product,
      filters: value?.map((item) => item?.value)
    });
  };

  const handleChangeProducts = (value) => {
    console.log(value);
    setProduR(
      value?.map((item) => [item?.value, item?.name, item?.price, item?.image])
    );
  };

  const disAttachTopping = (index) => {
    attachPlay[index] = true;
    setAttachPlay(attachPlay.slice());
    setTimeout(() => {
      toppings.splice(index, 1);
      onChange({
        ...product,
        new_customize: {
          sections: toppings.slice()
        }
      });
    }, 450);
  };

  // Creación y edición de la primary_image del producto
  const uploadImage = async (id, file, primaryImage) => {
    if (file && primaryImage) {
      await ProductService.deleteImage(id);
    }
    const formData = createImage(id, file, imgScale);
    if (formData) {
      const response = await ProductService.uploadImage(formData);
      if (response?.code === 200 || response?.code === 201) {
        navigate('/app/productos', { replace: true });
      }
    }
    setLoading(false);
    navigate('/app/productos', { replace: true });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const idToppings = toppings.map((item) => item.id);
    const file = previewImage;

    const data = {
      name: product?.name,
      // eslint-disable-next-line max-len
      description: e.target?.description?.value,
      price: Number(prices?.find((price) => price?.is_default)?.price || 0),
      brand_category_id: e.target?.sub_brand_category_id?.value
        ? e.target?.sub_brand_category_id?.value
        : null,
      reference: e.target?.reference?.value,
      sku: e.target?.sku?.value,
      components: productComponents,
      is_featured: e.target?.is_featured?.value === 'true',
      has_time: e.target?.has_time?.value === 'true',
      on_sale: e.target?.on_sale?.value === 'true',
      max_quantity: e.target?.max_quantity?.value,
      show_by_schedule: e.target?.show_by_schedule?.value,
      estimated_preparation_time: {
        days: Number(e.target?.days?.value),
        hours: Number(e.target?.hours?.value),
        minutes: Number(e.target?.minutes?.value)
      },
      customize: '{}',
      toppings: idToppings,
      prices,
      related_products: produR.map((item) => item[0]),
      business: product?.business || '',
      color_id: product?.color_id || null,
      sizes: product?.business === 'Ropa/Calzado' ? product?.sizes || [] : null,
      group: product?.business === 'Ropa/Calzado' ? product?.group || '' : null,
      is_combo: e.target?.is_combo?.value === 'true',
      seo: {
        tags: tags.map(
          (item) => item?.label
        ) /** esto lo que hace es mapear el tags.label ya que en la funcion handleKeyDown se necesita como objeto */,
        description: e.target?.seoDescription?.value,
        slug: e.target?.seoSlug?.value
      },
      filters: product?.filters,
      slug: e.target?.seoSlug?.value
    };
    if (data?.brand_category_id && data?.brand_category_id?.length === 1) {
      errors.push({
        field: 'brand_category_id',
        error: 'El campo categoría es obligatorio'
      });
    }
    if (data.name.length === 0) {
      errors.push({
        field: 'name',
        error: 'El nombre del producto la categoría es obligatorio'
      });
    }
    if (data.business.length === 0) {
      errors.push({
        field: 'business',
        error: 'Debe seleccionar el tipo de producto'
      });
    }
    if (data.description.length === 0) {
      errors.push({
        field: 'description',
        error: 'La descripción es obligatoria'
      });
    }
    if (data.description.length > MAX_LENGTH_DESCRIPTION) {
      errors.push({
        field: 'description',
        error: 'La descripción es muy larga'
      });
    }
    if (product?.business === 'Ropa/Calzado') {
      if (data?.sizes?.length === 0) {
        errors.push({
          field: 'sizes',
          error: 'Debe seleccionar al menos una talla'
        });
      }

      if (data?.color_id && data?.color_id?.length === 0) {
        errors.push({
          field: 'color',
          error: 'Debe seleccionar el color'
        });
      }
    }
    if (
      data.price === 0 ||
      data?.prices?.length < 1 ||
      !data?.prices.some((p) => p?.is_default) ||
      data?.prices.some((p) => Number(p?.price) === 0)
    ) {
      errors.push({ field: 'price', error: 'El precio es obligatorio' });
    }

    if (errors.length > 0) {
      setErrors(errors.map((item) => item?.error));
      setLocalErrors(errors);
      return;
    }

    setLoading(true);

    const response = edit
      ? await ProductService.update(product.id, data)
      : await ProductService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      uploadImage(response?.data?.id, file, response?.data?.primary_image);
    } else {
      setLoading(false);
      setErrors(response?.errors || []);
    }
  };

  const deleteProduct = async () => {
    if (product?.id) {
      if (product?.primary_image) {
        await ProductService.deleteImage(product.id);
      }
      const response = await ProductService.remove(product.id);
      if (!response) {
        navigate('/app/productos', { replace: true });
      }
    }
  };

  const getBrandCategories = async () => {
    const response = await BrandService.getCategories(user.brand_id);

    if (response?.code !== 200) {
      setErrors(['Ocurrió un error al intentar mostrar las categorías']);
      return;
    }

    const categoriesMain = response?.data?.brand_categories
      .filter((item) => item?.is_main)
      .map((item) => {
        return item;
      });

    const categoriesResult = categoriesMain.map((category) => {
      const subcategories = category.sub_categories.slice();
      if (subcategories.length !== 0) {
        return subcategories.map((subCategory) => {
          return {
            id: subCategory?.id,
            name: `${category.name} - ${subCategory?.name}`
          };
        });
      }

      return {
        id: category?.id,
        name: category.name
      };
    });

    setCategories(categoriesResult.flat());
  };

  const getBusiness = async () => {
    const response = await BusinessService.getAll();
    if (response?.code === 200) {
      setBusiness(response?.data);
    }
  };

  const getColors = async () => {
    const response = await ColorService.getByBrand(user.brand_id);
    if (response?.code === 200) {
      setColors(response?.data);
    }
  };

  const getSizes = async () => {
    const response = await SizesService.getByBrand(user.brand_id);
    if (response?.code === 200) {
      setSizes(response?.data);
    }
  };

  const getTags = () => {
    const tagss = product?.seo?.tags || [];
    setTags(tagss.map((item) => ({ label: item, value: item })));
  };

  // const getDescripion = () => {
  //   const description = product?.seo?.description || '';
  //   setDescription(description);
  // }

  const getBrandProductComponents = async () => {
    const response = await ProductComponentService.getByBrandId(user.brand_id);

    if (response?.code !== 200) {
      setErrors(['Ocurrió un error al intentar mostrar los componentes']);
      return;
    }

    setComponents(response?.data ?? []);
  };

  const removeProduct = () => {
    toggleModal();
  };

  const handlePrices = (data) => {
    // eslint-disable-next-line consistent-return,array-callback-return
    setPrices(
      data.map((d) => {
        let r = {};
        if (d.price) {
          r = {
            ...d,
            price: Number(d.price.toString().replace('.', ''))
          };
        }
        if (d.value) {
          r = {
            ...d,
            price: Number(d.value.toString().replace('.', ''))
          };
        }

        delete r.value;
        return r;
      })
    );
  };

  const getSelectedColor = () => {
    const selectedColor = colors?.find(
      (item) => item?.id === product?.color_id
    );
    return !product?.color_id
      ? ''
      : {
          value: product?.color_id,
          label: selectedColor?.name,
          color: `#${selectedColor?.hexadecimal}`,
          isColor: true
        };
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const payload = arrayMove(
      toppings.map((s, order) => {
        if (order === oldIndex) {
          return { ...s, order: newIndex };
        }
        if (order < oldIndex) {
          return { ...s, order: order + 1 };
        }
        return { ...s, order };
      }),
      oldIndex,
      newIndex
    );
    onChange({
      ...product,
      new_customize: {
        sections: orderBy(payload, 'order')
      }
    });
  };

  const componentesOptions = components.map((c) => ({
    value: c?.id,
    label: c?.name
  }));
  // eslint-disable-next-line max-len
  const defaultComponents = product?.components.map((el) =>
    componentesOptions.find((obj) => obj.value === el)
  );

  useEffect(() => {
    if (user?.brand_id) {
      getBrandCategories();
      getBrandProductComponents();
      getBusiness();
      getColors();
      getSizes();
    }
  }, [user]);

  useEffect(() => {
    getTags();
  }, [product?.seo?.tags]);

  useEffect(() => {
    if (edit) {
      setCategorySelected(product?.sub_brand_category_id);
      setProductComponents(product?.components);
    }
  }, [edit, product, components]);

  useEffect(() => {
    if (Array.isArray(product?.new_customize?.sections)) {
      setToppings(
        product?.new_customize?.sections?.map((item) => {
          return item;
        })
      );
      setAttachPlay(
        product?.new_customize?.sections?.map(() => {
          return false;
        })
      );
    }
  }, [product?.new_customize]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Producto"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <FormControl
                required
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="category">Selecciona una categoría</InputLabel>
                <Select
                  required
                  name="sub_brand_category_id"
                  labelId="category"
                  label="Selecciona una categoría"
                  value={categorySelected}
                  onChange={handleCategory}
                >
                  <MenuItem value="0">Selecciona una categoría</MenuItem>
                  {categories.map((subCategory) => (
                    <MenuItem key={subCategory?.id} value={subCategory?.id}>
                      {subCategory?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                required
                variant="outlined"
                className={classes.formControl}
                error={localErrors?.some((err) => err?.field === 'business')}
              >
                <InputLabel id="business">
                  Selecciona el tipo de producto
                </InputLabel>
                <Select
                  required
                  name="business"
                  labelId="business"
                  label="Selecciona el tipo de producto"
                  value={product?.business || ''}
                  onChange={handleChange}
                >
                  <MenuItem value="0">Selecciona el tipo de producto</MenuItem>
                  {business.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {localErrors?.find((err) => err?.field === 'business')?.error}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Nombre"
                name="name"
                onChange={handleChange}
                required
                value={product.name}
                variant="outlined"
              />
            </Grid>
            <Grid item container justify="flex-end" xs={12}>
              <TextField
                fullWidth
                label="Descripción"
                name="description"
                error={product?.description?.length > MAX_LENGTH_DESCRIPTION}
                onChange={handleChange}
                required
                value={product?.description}
                variant="outlined"
                multiline
                rows={3}
              />
              <Typography
                color={
                  product?.description?.length > MAX_LENGTH_DESCRIPTION
                    ? 'error'
                    : ''
                }
                variant="caption"
              >
                {product?.description?.length}/{MAX_LENGTH_DESCRIPTION}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="SKU"
                name="sku"
                onChange={handleChange}
                value={product?.sku}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Box display="flex" height="100%" alignItems="end">
                <FormControl
                  required
                  className={classes.formControl}
                  error={localErrors?.some((err) => err?.field === 'sizes')}
                >
                  { console.log(filters)}
                  <Selector
                    styles={selectorStyles}
                    options={
                      filters?.map((item) => ({
                      label: item?.name,
                      options: item?.options?.map((option) => ({
                        value: option.name,
                        label: option.name,
                      }))
                    }))}
                    value={
                      Object.keys(filters).length === 0
                        ? []
                        : filters?.options?.map((item) => ({
                            value: item.name,
                            label: item.name
                          }))
                    }
                    isMulti
                    closeMenuOnSelect={false}
                    formatGroupLabel={FormatGroupLabel}
                    onChange={handleChangeFilters}
                    name="filters"
                    labelId="filters"
                    label="filtros"
                    placeholder="Seleccione los filtros"
                  />
                  <FormHelperText error>
                    {localErrors?.find((err) => err?.field === 'sizes')?.error}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Grid>

            {product?.business === 'Ropa/Calzado' && (
              <>
                <Grid item md={6} xs={12}>
                  <Box display="flex" height="100%" alignItems="end">
                    <FormControl
                      required
                      className={classes.formControl}
                      error={localErrors?.some((err) => err?.field === 'color')}
                    >
                      <Selector
                        styles={selectorStyles}
                        options={colors?.map((item) => ({
                          label: item?.name,
                          value: item?.id,
                          color: `#${item?.hexadecimal}`,
                          isColor: true
                        }))}
                        value={getSelectedColor()}
                        onChange={handleChangeColor}
                        name="color"
                        labelId="color"
                        label="Color"
                        placeholder="Seleccione el color"
                      />
                      <FormHelperText error>
                        {
                          localErrors?.find((err) => err?.field === 'color')
                            ?.error
                        }
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box display="flex" height="100%" alignItems="end">
                    <FormControl
                      required
                      className={classes.formControl}
                      error={localErrors?.some((err) => err?.field === 'sizes')}
                    >
                      {/* <InputLabel id="size">y</InputLabel> */}
                      <Selector
                        styles={selectorStyles}
                        options={sizes?.map((item) => ({
                          label: item?.name,
                          options: item?.size?.map((size) => ({
                            value: size,
                            label: size,
                            isColor: false
                          }))
                        }))}
                        value={
                          Object.keys(product).length === 0
                            ? []
                            : product?.sizes?.map((item) => ({
                                value: item,
                                label: item,
                                isColor: false
                              }))
                        }
                        isMulti
                        closeMenuOnSelect={false}
                        formatGroupLabel={FormatGroupLabel}
                        onChange={handleChangeSizes}
                        name="sizes"
                        labelId="sizes"
                        label="Tallas"
                        placeholder="Seleccione las tallas"
                      />
                      <FormHelperText error>
                        {
                          localErrors?.find((err) => err?.field === 'sizes')
                            ?.error
                        }
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Grid>
              </>
            )}
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Referencia"
                name="reference"
                onChange={handleChange}
                value={product?.reference}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                label="Cantidad maxima"
                name="max_quantity"
                onChange={handleChange}
                required
                value={product?.max_quantity || 15}
                type="number"
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                required
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="onSale">Disponible</InputLabel>
                <Select
                  required
                  name="on_sale"
                  labelId="onSale"
                  label="En venta"
                  value={product?.on_sale}
                  onChange={handleChange}
                >
                  {[true, false].map((answer) => (
                    <MenuItem key={answer.toString()} value={answer}>
                      {answer.toString() === 'true' ? 'Si' : 'No'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                required
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="featured">Recomendado</InputLabel>
                <Select
                  required
                  name="is_featured"
                  labelId="featured"
                  label="Recomendado"
                  value={product?.is_featured}
                  onChange={handleFeatured}
                >
                  {[true, false].map((answer) => (
                    <MenuItem key={answer.toString()} value={answer}>
                      {answer.toString() === 'true' ? 'Si' : 'No'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                required
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="is_combo">Combo</InputLabel>
                <Select
                  required
                  name="is_combo"
                  labelId="combo"
                  label="combo"
                  value={product?.is_combo}
                  onChange={handleChange}
                >
                  {[true, false].map((answer) => (
                    <MenuItem key={answer.toString()} value={answer}>
                      {answer.toString() === 'true' ? 'Si' : 'No'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* aqui empieza el cambio seo  */}
            <Grid item md={6} xs={12}>
              <Box display="flex" height="100%" alignItems="end">
                <FormControl
                  required
                  className={classes.formControl}
                  // error={localErrors?.some((err) => err?.field === 'color')}
                >
                  <Selector
                    styles={selectorStyles}
                    // isClearable
                    isMulti
                    inputValue={inputValue}
                    menuIsOpen={false}
                    value={tags}
                    onChange={(newValue) => {
                      setTags(newValue);
                    }}
                    onInputChange={(newValue) => setInputValue(newValue)}
                    onKeyDown={handleKeyDown}
                    name="tags"
                    labelId="tags"
                    label="tags"
                    placeholder="Escriba las tags"
                  />
                  <FormHelperText error>
                    {localErrors?.find((err) => err?.field === 'color')?.error}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Descripcion"
                placeholder={product?.seo?.seoDescription}
                name="seoDescription"
                onChange={handleChange}
                // defaultValue={product?.seo?.description}
                value={product?.seo?.seoDescription}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Slug"
                name="seoSlug"
                // placeholder={product?.seo?.slug}
                onChange={handleChange}
                // defaultValue={product?.seo?.slug}
                value={product?.seo?.seoSlug}
                variant="outlined"
              />
            </Grid>
            {/* aqui termina el cambio seo  */}
            <Grid item md={6} xs={12}>
              <FormControl
                required
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="has_time">
                  ¿Tiene tiempo de preparación?
                </InputLabel>
                <Select
                  required
                  name="has_time"
                  labelId="has_time"
                  label="¿Tiene tiempo de preparación?"
                  value={product?.has_time}
                  onChange={handleHasTime}
                >
                  {[true, false].map((answer) => (
                    <MenuItem key={answer} value={answer}>
                      {answer.toString() === 'true' ? 'Si' : 'No'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                label="Días"
                name="days"
                onChange={handleChange}
                required={product?.has_time}
                disabled={!product?.has_time}
                value={product?.estimated_preparation_time?.days}
                type="number"
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                label="Horas"
                name="hours"
                onChange={handleChange}
                required={product?.has_time}
                disabled={!product?.has_time}
                value={product?.estimated_preparation_time?.hours}
                type="number"
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                label="Minutos"
                name="minutes"
                onChange={handleChange}
                required={product?.has_time}
                disabled={!product?.has_time}
                value={product?.estimated_preparation_time?.minutes}
                type="number"
                variant="outlined"
              />
            </Grid>
            {product?.components && components && defaultComponents[0] && (
              <Grid item xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="product_components">
                    Selecciona los componentes esenciales
                  </InputLabel>
                  <Selector
                    isMulti
                    closeMenuOnSelect={false}
                    name="product_components"
                    labelId="product_components"
                    label="Selecciona los componentes esenciales"
                    options={components.map((c) => ({
                      value: c?.id,
                      label: c?.name
                    }))}
                    defaultValue={defaultComponents}
                    onChange={handleProductComponents}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
          <PricesCustomization
            onChange={handlePrices}
            data={product?.prices}
            edit={edit}
          />

          <Box
            maxheight={
              (clickProdu ? '250px' : '100px') ||
              (showProducts ? '550px' : '100px')
            }
            mt={3}
            mb={4}
          >
            <Modal
              isOpen={isOpenDelete}
              toggle={toggleDelete}
              title="Eliminar producto"
            >
              <Box
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h4">
                  ¿Estas seguro de eliminar este producto?
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt={2}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: '0.3rem' }}
                    onClick={() => deleteProducts(indexProdu)}
                  >
                    Eliminar
                  </Button>
                </Box>
              </Box>
            </Modal>
            <Box>
              <Typography variant="h5">Productos relacionados</Typography>
            </Box>
            <Collapse in={showProducts}>
              <Box mt={2}>
                <Table>
                  <TableBody>
                    {produR?.map((item, index) => (
                      <TableRow index={index} key={item[0]}>
                        <TableCell
                          width={200}
                          padding="none"
                          style={{ border: 'none' }}
                        >
                          <Box
                            py={1.5}
                            pt={0}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box display="flex" justifyContent="flex-start">
                              <Box>
                                <img
                                  src={item[3]}
                                  style={{ padding: '1px' }}
                                  alt="img"
                                  width="25px"
                                  height="25px"
                                />
                              </Box>
                              <Box display="flex" alignItems="center" ml={2}>
                                <Box justifyContent="flex-start" mr={2}>
                                  <Typography variant="h5">
                                    {item[1]}
                                  </Typography>
                                </Box>
                                <Box justifyContent="flex-start">
                                  $
                                  <CurrencyFormat
                                    value={item[2]}
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <Box ml={1}>
                              <Box display="flex" justifyContent="flex-end">
                                <Box
                                  className={clsx(classes.icon, classes.btn)}
                                  onClick={() => handleDelete(index)}
                                >
                                  <Delete
                                    p={2}
                                    style={{ color: '#fff' }}
                                    fontSize="small"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
            <Box mb={2} mt={3}>
              <Button
                type="button"
                variant="contained"
                onClick={() => handleProducts()}
              >
                <AddIcon className={classes?.addButton} />
                Añadir Productos
              </Button>
            </Box>
            <Box
              display={
                (clickProdu ? 'flex' : 'none') ||
                (showProducts ? 'flex' : 'none')
              }
              maxHeight="300px"
            >
              <FormControl required className={classes.formControl}>
                <Selector
                  styles={selectorStyles}
                  options={produ?.map((item) => ({
                    label: (
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          src={item.primary_image}
                          style={{ padding: '1px' }}
                          height="30px"
                          width="30px"
                          alt="img"
                        />
                        <Box
                          style={{ marginLeft: '15px', marginRight: '15px' }}
                        >
                          {item.name}
                        </Box>
                        <Box>
                          $
                          <CurrencyFormat
                            value={item.price}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                          />
                        </Box>
                      </Box>
                    ),
                    value: item.id,
                    name: item.name,
                    price: item.price,
                    image: item.primary_image
                  }))}
                  value={produR?.map((item) => {
                    return {
                      label: (
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}
                        >
                          <img
                            src={item[3]}
                            style={{ padding: '1px' }}
                            height="30px"
                            width="30px"
                            alt="img"
                          />
                          <Box
                            style={{ marginLeft: '15px', marginRight: '15px' }}
                          >
                            {item[1]}
                          </Box>
                          <Box>
                            $
                            <CurrencyFormat
                              value={item[2]}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                            />
                          </Box>
                        </Box>
                      ),
                      value: item[0],
                      name: item[1],
                      price: item[2],
                      image: item[3]
                    };
                  })}
                  isMulti
                  closeMenuOnSelect={false}
                  formatGroupLabel={FormatGroupLabel}
                  onChange={handleChangeProducts}
                  name="products"
                  labelId="products"
                  label="Productos"
                  placeholder="Seleccione los productos"
                />
                <FormHelperText error>
                  {
                    localErrors?.find((err) => err?.field === 'produdcts')
                      ?.error
                  }
                </FormHelperText>
                <Box onClick={() => handleSave()} mt={2}>
                  <Button type="button" color="primary" variant="contained">
                    Guardar
                  </Button>
                </Box>
              </FormControl>
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={9} spacing={2}>
              <Typography variant="h5">Personalización</Typography>
            </Grid>
            <Grid container item xs={3} justify="flex-end">
              <AddIcon
                className={classes?.addButton}
                onClick={() => toggleAside()}
              />
            </Grid>
            {toppings.length > 0 && (
              <Grid item xs={12}>
                <Table>
                  <TableBodySortable
                    onSortEnd={onSortEnd}
                    lockAxis="y"
                    useDragHandle
                  >
                    {toppings.map((item, index) => (
                      <TableRowSortable hover index={index} key={item?.id}>
                        <TableCell
                          width={878}
                          className={clsx(
                            {
                              [classes.slide]: attachPlay[index]
                            },
                            classes.tableCell
                          )}
                        >
                          <Box display="flex" alignItems="center">
                            <SortableHandleIcon
                              className={classes.sortableIcon}
                            />
                            <Topping
                              view
                              className={classes.topping}
                              topping={item}
                              disAttachTopping={disAttachTopping}
                              indexTopping={index}
                            />
                          </Box>
                        </TableCell>
                      </TableRowSortable>
                    ))}
                  </TableBodySortable>
                </Table>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          {edit && (
            <Box mr={2}>
              <Link to={`/app/productos/${product?.id}/horario`}>
                <Button type="button" variant="contained">
                  Horario
                </Button>
              </Link>
            </Box>
          )}
          {edit && (
            <Box mr={2}>
              <Button
                type="button"
                onClick={removeProduct}
                className={classes.error}
                variant="contained"
              >
                Eliminar
              </Button>
            </Box>
          )}
          <Button type="submit" color="primary" variant="contained">
            {edit ? 'Editar' : 'Guardar'}
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteProduct} />
    </form>
  );
};

FormatGroupLabel.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object
};

CreateProductForm.propTypes = {
  className: PropTypes.string,
  toggleAside: PropTypes.func,
  imgScale: PropTypes.string,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  setLoading: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    primary_image: PropTypes.string,
    reference: PropTypes.string,
    sku: PropTypes.string,
    description: PropTypes.string,
    brand_category_id: PropTypes.string,
    sub_brand_category_id: PropTypes.string,
    price: PropTypes.number,
    components: PropTypes.array,
    prices: PropTypes.array,
    is_featured: PropTypes.bool,
    max_quantity: PropTypes.number,
    customize: PropTypes.shape({
      comment: '',
      sections: []
    }),
    new_customize: PropTypes.shape({
      sections: PropTypes.array
    }),
    has_time: PropTypes.bool,
    on_sale: PropTypes.bool,
    estimated_time: PropTypes.string,
    show_by_schedule: PropTypes.bool,
    estimated_preparation_time: PropTypes.object,
    is_combo: PropTypes.bool,
    business: PropTypes.string,
    sizes: PropTypes.array,
    color_id: PropTypes.string,
    group: PropTypes.string,
    related_products: PropTypes.array,
    seo: PropTypes.shape({
      tags: PropTypes.array,
      seoDescription: PropTypes.string,
      seoSlug: PropTypes.string
    }),
    filters: PropTypes.array,
  }),
  previewImage: PropTypes.object
};

CreateProductForm.defaultProps = {
  className: '',
  toggleAside: () => {},
  edit: false,
  onChange: () => {},
  setLoading: () => {},
  product: {
    id: null,
    name: '',
    reference: '',
    primary_image: '',
    description: '',
    brand_category_id: null,
    sub_brand_category_id: null,
    price: 0,
    components: [],
    prices: [],
    is_featured: false,
    customize: {
      comment: '',
      sections: []
    },
    new_customize: {
      sections: []
    },
    has_time: false,
    on_sale: true,
    estimated_time: '10-20 min',
    show_by_schedule: false,
    estimated_preparation_time: {
      days: 0,
      hours: 0,
      minutes: 0
    },
    is_combo: false,
    business: '',
    // sizes: [],
    color_id: null,
    group: '',
    seo: {
      tags: [],
      seoDescription: '',
      seoSlug: ''
    },
    sku: null,
    related_products: []
  },
  previewImage: null
};

export default CreateProductForm;
