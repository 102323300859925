import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { selector as UserSelector } from 'src/redux/ducks/user';
import TitleListView from './TitleListView';
import CreateTitleForm from './CreateTitleForm';
import BrandService from '../../../services/BrandService';
import useErrors from '../../../hooks/useErrors';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateTitleView = () => {
  const classes = useStyles();
  const { setErrors } = useErrors();
  const { user } = useSelector(UserSelector);
  const [sectionSelected, setSectionSelected] = useState('');
  const [edit, setEdit] = useState(false);
  const [sections, setSections] = useState([]);

  const fetchBrandData = async () => {
    const fetchData = await BrandService.getById(user?.brand_id);

    if (fetchData?.code === 200) {
      setSections(fetchData?.data?.titles_customize?.filter((i) => i !== ''));
    }
  };

  const handleReload = () => {
    if (user?.id) {
      window.location.reload();
    }
  };

  const handleEdit = (item) => {
    setEdit(true);
    setSectionSelected(item);
  };

  const handleCancel = () => {
    setEdit(false);
    setSectionSelected('');
  };

  const handleSubmit = async (e, remove = null) => {
    if (!remove) e?.preventDefault();
    let value = '';
    const errors = [];

    if (!remove) {
      value = e?.target?.name?.value;

      if (value?.length < 1) {
        errors.push('El nombre de la sección es obligatorio');
        setErrors(errors);
        return;
      }
    }

    const payload = {
      value,
      is_edit: edit,
      keyword: edit ? sectionSelected : value,
      titles_customize: [...sections, value].filter((i) => i !== '' && i !== remove)
    };

    const response = await BrandService.updateCustomize(user?.brand_id, payload);

    if (response?.code === 200) {
      setErrors([]);
      if (!remove) e?.target?.reset();
      setErrors([]);
      handleReload();
    } else {
      setErrors(response.errors);
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchBrandData();
    }
  }, [user]);

  return (
    <Page
      className={classes.root}
      title="Crear Sección de personalización"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <CreateTitleForm
              edit={edit}
              onCancel={handleCancel}
              onSave={handleSubmit}
              data={sectionSelected}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TitleListView
              data={sections}
              onEdit={(itemToEdit) => handleEdit(itemToEdit)}
              onRemove={(itemToRemove) => handleSubmit(null, itemToRemove)}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CreateTitleView;
