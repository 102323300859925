import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  // Chip,
  Button,
  // Table,
  // TableRow,
  // TableCell,
  // TableBody,
  // TableHead,
  Typography,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import { CancelRounded } from '@material-ui/icons';
import ImgFoodEndOrder from '../../assets/image/end_order.png';

const StatusModalPendingToBeSent = ({
  onClose,
  updateStatus,
  refresh,
  order,
}) => {
  const matches = useMediaQuery('screen and (min-width: 769px)');

  const useStyles = makeStyles((theme) => ({
    buttons: {
      display: 'inline-block',
    },
    overlay: {
      backgroundColor: 'rgba(46, 44, 54, 0.4)',
      position: 'fixed',
      top: 0,
      right: 0,
      height: '100%',
      width: '100%',
      zIndex: '3',
      display: matches ? 'flex' : null,
      justifyContent: matches ? 'center' : null,
      alignItems: matches ? 'center' : null,
    },
    modal: {
      border: '1px solid #e6ebf1',
      borderRadius: '8px',
      width: !matches ? '100%' : '450px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      bottom: '0',
      animation: 'showPopUp 0.5s',
      position: !matches ? 'absolute' : 'relative',
    },
    pane: {
      height: '100%',
      width: '100%',
      position: 'fixed'
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '8px 10px',
    },
    imgContainer: {
      width: '200px',
      height: '200px',
      '& > img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }
    },
    title: {
      marginTop: theme.spacing(2.5),
      backgroundColor: '#ffe205',
      borderRadius: '10px',
      padding: '7px 12px'
    },
    text: {
      marginTop: theme.spacing(2.5),
      textAlign: 'center',
      color: '#999',
      fontSize: '14px',
      fontWeight: 500
    },
    btnConfirm: {
      textTransform: 'capitalize',
      borderRadius: 6,
      width: 125,
      fontWeight: 700,
      fontSize: '14px'
    },
  }));
  const classes = useStyles();

  const onPush = async () => {
    updateStatus('pending_to_be_sent', order?.id);
    refresh();
    onClose('pending_to_be_sent', false);
  };

  return (
    <div className={classes?.overlay}>
      <div className={classes.pane} onClick={() => onClose('pending_to_be_sent', false)} />
      <div className={classes?.modal}>
        <div className={classes.header}>
          <CancelRounded style={{ cursor: 'pointer' }} color="primary" onClick={() => onClose('pending_to_be_sent', false)} />
        </div>
        <Box>
          <Box p={3} pt={1} display="flex" flexDirection="column" alignItems="center">
            <div className={classes?.imgContainer}>
              <img src={ImgFoodEndOrder} alt="" />
            </div>
            <div className={classes.title}>
              <h2 style={{ fontSize: '22px' }}>Terminar orden</h2>
            </div>
            <Typography variant="body2" className={classes?.text}>
              ¿Desea terminar la orden e iniciar la
              búsqueda de un domiciliario?
            </Typography>
            <Box mt={2}>
              <Button className={classes.btnConfirm} size="small" variant="contained" color="primary" onClick={onPush}>Confirmar</Button>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

StatusModalPendingToBeSent.propTypes = {
  onClose: PropTypes.func,
  refresh: PropTypes.func,
  updateStatus: PropTypes.func,
  order: PropTypes.object
};

export default StatusModalPendingToBeSent;
