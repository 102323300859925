import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  makeStyles
} from '@material-ui/core';
// import { Add } from '@material-ui/icons';
import { selector as UserSelector } from 'src/redux/ducks/user';
import Modal from 'src/components/Modal';
// import { v4 } from 'uuid';
import useModal from 'src/hooks/useModal';
import Errors from '../../../components/Errors';
import useErrors from '../../../hooks/useErrors';
import BrandCategoryService from '../../../services/BrandCategoryService';
import CategoryCardPreview from './CategoryCardPreview';
import FilterCard from './FilterCard';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white'
    }
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    minWidth: '20px',
    minHeight: '20px',
    padding: '0px',
    margin: '0px'
  }
}));

const CreateBrandCategoryForm = ({
  className,
  category,
  categories,
  edit,
  onCancel,
  onChange,
  onRefresh,
  ...rest
}) => {
  const inputRef = useRef(null);
  const inputImg = useRef(null);
  const classes = useStyles();
  const { setErrors } = useErrors();
  const { user } = useSelector(UserSelector);
  const [file, setFile] = useState();
  const [trigger, setTrigger] = useState(false);
  const [categorySelected, setCategorySelected] = useState('');
  const [filters, setFilters] = useState([]);
  const [isOpen, toggle] = useModal();
  const [nameFilter, setNameFilter] = useState('');

  const handleCancel = () => {
    onCancel();
    setCategorySelected('');
    setFilters([]);
  };

  const handleAddFilter = () => {
    // validar si viene vacio
    if (nameFilter === '') {
      setErrors({ nameFilter: 'El nombre del filtro es requerido' });
      toggle();
      return;
    }
    const newFilter = {
      name: nameFilter,
      options: []
    };
    setFilters([...filters, newFilter]);
    setNameFilter('');
    toggle();
  };

  const handleDeleteFilter = (index) => {
    const newFilters = filters.filter((filter, i) => i !== index);
    setFilters(newFilters);
  };

  const handleEditarFiltro = (index, value) => {
    const newFilters = filters.map((filter, i) => {
      if (i === index) {
        return {
          ...filter,
          name: value
        };
      }
      return filter;
    });
    setFilters(newFilters);
  };

  const sendOptions = (value, index) => {
    const newFilter = {
      name: filters[index]?.name,
      options: value
    };
    filters[index] = newFilter;
    setFilters([...filters]);
  };

  const handleChange = (e) => {
    onChange({
      ...category,
      [e?.target?.name]: e?.target?.value
    });
  };

  const handleIsMain = (e) => {
    onChange({
      ...category,
      is_main: e?.target?.value === 'true'
    });
  };

  const handleCategory = (e) => {
    setCategorySelected(e?.target?.value);
    handleChange(e);
  };

  const resetForm = () => {
    if (inputImg?.current) {
      inputImg.current.value = '';
      setFile(null);
    }
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };

  const handleSuccess = () => {
    setErrors([]);
    handleCancel();
    onRefresh();
    resetForm();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      name: e.target?.name?.value,
      brand_id: user?.brand_id,
      slug: e?.target?.slug.value,
      is_main: e?.target?.is_main?.value === 'true',
      parent_id:
        e?.target?.is_main?.value === 'true'
          ? null
          : e?.target?.parent_id?.value,
      filters
    };

    if (data.name.length === 0) {
      errors.push('El nombre de la categoría es obligatoria');
      setErrors(errors);
      return;
    }

    if (data.parent_id === null && !data.is_main) {
      errors.push('Se debe seleccionar la categoria principal');
      setErrors(errors);
      return;
    }

    if (data.slug.length === 0) {
      errors.push('El slug de la categoría es obligatoria');
      setErrors(errors);
      return;
    }

    setTrigger(true);

    const response = edit
      ? await BrandCategoryService.update(category?.id, data)
      : await BrandCategoryService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      if (
        response?.data?.image !== null &&
        file !== undefined &&
        file !== null
      ) {
        await BrandCategoryService.deleteImage(response?.data?.id);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await BrandCategoryService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            handleSuccess();
          }, 500);
        }
      } else if (
        response?.data?.image === null &&
        file !== undefined &&
        file !== null
      ) {
        const formData = new FormData();

        // The third parameter is required for server
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await BrandCategoryService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            handleSuccess();
          }, 500);
        }
      } else {
        handleSuccess();
      }
    } else {
      setErrors(response.errors);
    }
  };

  const handleUploadImage = () => {
    if (inputImg.current) {
      inputImg.current.click();
    }
  };

  useEffect(() => {
    if (edit) setCategorySelected(category.parent_id);
    if (edit) {
      setFilters(category?.filters);
    }
  }, [category]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={(e) => {
        setTrigger(true);
        handleSubmit(e);
      }}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Categoría"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <CategoryCardPreview
                // ref={inputImg}
                category={category}
                hasImage={false}
                trigger={trigger}
                setTrigger={setTrigger}
                onUpload={handleUploadImage}
                setFile={setFile}
              />
            </Grid>
            <Grid
              style={{
                height: 'fit-content',
                padding: '12px 0',
                paddingLeft: '10px'
              }}
              container
              spacing={3}
              xs={10}
            >
              <Grid style={{ height: 'fit-content' }} item xs={10}>
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  label="Nombre"
                  name="name"
                  required
                  value={category?.name ? category?.name : ''}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid style={{ height: 'fit-content' }} item xs={10}>
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  label="Slug"
                  name="slug"
                  required
                  value={category?.slug ?? ''}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid style={{ height: 'fit-content' }} item xs={10} md={4}>
                <FormControl disabled={edit} component="fieldset">
                  <FormLabel component="legend">
                    ¿Es una categoria principal?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="is_main_option"
                    name="is_main"
                    value={`${category?.is_main}`}
                    onChange={handleIsMain}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio size="small" color="primary" />}
                      label={
                        <Typography style={{ fontSize: '15px' }}>Si</Typography>
                      }
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio size="small" color="primary" />}
                      label={
                        <Typography style={{ fontSize: '15px' }}>No</Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {!category?.is_main && (
                <Grid style={{ height: 'fit-content' }} item xs={10} md={6}>
                  <FormControl
                    disabled={edit}
                    required
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="grouped-select" id="adomiCategory">
                      Seleccione una categoría de Adomi
                    </InputLabel>
                    <Select
                      // native
                      required
                      name="parent_id"
                      labelId="mainCategory"
                      label="Selecciona la categoria principal a la que pertenece"
                      value={categorySelected}
                      onChange={handleCategory}
                    >
                      {categories.map((item) => (
                        <MenuItem value={item?.id}>{item?.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Box display="flex" flexDirection="column" width="100%">
              <Box margin={2}>
                <Button
                  color="primary"
                  variant="contained"
                  margin={2}
                  onClick={toggle}
                >
                  Agregar Filtros
                </Button>
              </Box>
              <Grid container spacing={2}>
                {filters.map((item, index) => (
                  <Grid item md={4} xs={12}>
                    <FilterCard
                      filter={item}
                      sendOptions={sendOptions}
                      indexFilter={index}
                      handleDeleteFilter={handleDeleteFilter}
                      handleEditarFiltro={handleEditarFiltro}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          {edit && (
            <Box mr={2}>
              <Button type="button" onClick={handleCancel} variant="contained">
                Cancelar
              </Button>
            </Box>
          )}
          <Button type="submit" color="primary" variant="contained">
            {edit ? 'Editar' : 'Guardar'}
          </Button>
        </Box>
        <Modal isOpen={isOpen} toggle={toggle} title="Agregar filtro">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <TextField
              autoFocus
              autoComplete="off"
              variant="outlined"
              label="Nombre del filtro"
              name="FilterName"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
            <Box margin={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddFilter()}
              >
                <Typography>Agregar</Typography>
              </Button>
            </Box>
          </Box>
        </Modal>
      </Card>
    </form>
  );
};

CreateBrandCategoryForm.propTypes = {
  className: PropTypes.func,
  edit: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onRefresh: PropTypes.func,
  category: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    is_main: PropTypes.bool,
    parent_id: PropTypes.string,
    filters: PropTypes.array,
    slug: PropTypes.string
  }),
  categories: PropTypes.array
};

CreateBrandCategoryForm.defaultProps = {
  className: () => null,
  edit: false,
  onCancel: () => null,
  onChange: () => null,
  onRefresh: () => null,
  category: {
    id: null,
    name: '',
    image: '',
    is_main: true,
    parent_id: null,
    slug: ''
  },
  categories: []
};

export default CreateBrandCategoryForm;
