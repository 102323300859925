import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import {
  Box,
  Card,
  Container,
  makeStyles,
  Typography,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import Page from 'src/components/Page';
import BrandService from '../../../services/BrandService';
import ReportService from '../../../services/ReportService';

import { selector as UserSelector } from '../../../redux/ducks/user';

import chart from '../../../utils/chart';
import isArray from '../../../utils/is_array';

const useStyles = makeStyles((theme) => ({
  categoryTitle: {
    display: 'inline-block',
    marginRight: '1rem'
  },
  titleEditable: {
    fontSize: '2.18rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.167,
    letterSpacing: '-0.24px',
    color: '#888',
    marginBottom: '.5rem'
  },
  saveIcon: {
    color: 'white'
  },
  editButton: {
    cursor: 'pointer'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ProductReportView = () => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [showData, setShowData] = useState([]);
  const [products, setProducts] = useState([]);
  const [productId, setProductId] = useState(null);

  const handleProductId = (e) => {
    setProductId(e.target.value);
  };

  const getProduct = async () => {
    const response = await ReportService.getProductSaleById(`${user?.id}?productId=${productId}`);
    if (!response?.data) return;
    const { labels, datasets } = response.data;
    setShowData(chart(isArray(labels), isArray(datasets)));
  };

  const getProducts = async () => {
    const response = await BrandService.getProducts(user?.brand_id);
    if (response?.code === 200) {
      setProducts(
        response?.data?.brand_categories.reduce((productsItems, categorie) => {
          return productsItems.concat(
            categorie?.products.reduce((newProductVal, product) => {
              const value = {
                id: product?.id,
                name: product?.name
              };
              return newProductVal.concat(value);
            }, [])
          );
        }, [])
      );
    } else {
      setProducts([]);
    }
  };

  useEffect(() => {
    if (user?.id) {
      getProducts();
    }
  }, [user]);

  useEffect(() => {
    if (productId) {
      getProduct();
    }
  }, [productId]);

  return (
    <Page className={classes.root} title="Reporte por componente">
      <Container maxWidth={false}>
        <Card>
          <Box mt={3} ml={3}>
            <Typography variant="h2">Reporte por producto</Typography>
            <Box my={3}>
              <InputLabel htmlFor="product-select">Producto</InputLabel>
              <Select
                value="Producto"
                onChange={handleProductId}
                inputProps={{
                  name: 'Productos',
                  id: 'product-select'
                }}
              >
                {products.map((item) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </Box>
            <div className="chart">
              <Line data={showData} options={{ responsive: true }} />
            </div>
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default ProductReportView;
