import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Errors from '../../../components/Errors';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  cancelButton: {
    marginRight: '1rem'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateTitleForm = ({
  className, data, onSave, onCancel, edit, ...rest
}) => {
  const classes = useStyles();
  const [text, setText] = useState('');

  const handleInput = (e) => {
    setText(e.target.value);
  };

  const handleCancel = () => {
    setText('');
    onCancel();
  };

  useEffect(() => {
    setText(data);
  }, [data]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={onSave}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Sección"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                label="Nombre"
                name="name"
                required
                value={text}
                onChange={handleInput}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {
            edit && (
              <Button
                className={classes?.cancelButton}
                variant="contained"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            { edit ? 'Editar' : 'Crear nuevo' }
          </Button>
        </Box>
      </Card>
    </form>
  );
};

CreateTitleForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  data: PropTypes.string,
};

CreateTitleForm.defaultProps = {
  className: '',
  edit: false,
  hasImage: false,
  onCancel: () => {},
  onChange: () => {},
  onSave: () => {},
  data: '',
};

export default CreateTitleForm;
