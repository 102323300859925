import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  // Chip,
  Button,
  // Table,
  // TableRow,
  // TableCell,
  // TableBody,
  // TableHead,
  // Typography,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import { CancelRounded } from '@material-ui/icons';
import ImgRedTarget from '../../assets/image/red-target.png';

const StatusModalFinished = ({
  onClose,
  updateStatus,
  refresh,
  order
}) => {
  const matches = useMediaQuery('screen and (min-width: 769px)');

  const useStyles = makeStyles(() => ({
    buttons: {
      display: 'inline-block',
    },
    overlay: {
      backgroundColor: 'rgba(46, 44, 54, 0.4)',
      position: 'fixed',
      top: 0,
      right: 0,
      height: '100%',
      width: '100%',
      zIndex: '3',
      display: matches ? 'flex' : null,
      justifyContent: matches ? 'center' : null,
      alignItems: matches ? 'center' : null,
    },
    modal: {
      border: '1px solid #e6ebf1',
      borderRadius: '8px',
      width: !matches ? '100%' : '340px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      bottom: '0',
      animation: 'showPopUp 0.5s',
      position: !matches ? 'absolute' : 'relative',
    },
    pane: {
      height: '100%',
      width: '100%',
      position: 'fixed'
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '8px 10px',
    },
    text: {
      color: '#999',
      fontSize: '13px',
      fontWeight: 500
    },
    imgContainer: {
      width: 120,
      height: 120,
      position: 'absolute',
      top: -68,
      left: 4,
      backgroundColor: '#fff',
      borderRadius: '50%',
      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      '& img': {
        width: '100%',
        height: '100%'
      }
    },
    btnConfirm: {
      textTransform: 'capitalize',
      borderRadius: 6,
      width: 150,
      fontWeight: 700,
      fontSize: '14px'
    },
  }));
  const classes = useStyles();

  const onPush = async () => {
    updateStatus('finished', order?.id);
    refresh();
    onClose('finished', false);
  };

  return (
    <div className={classes?.overlay}>
      <div className={classes.pane} onClick={() => onClose('finished', false)} />
      <div className={classes?.modal}>
        <div className={classes.header}>
          <CancelRounded style={{ cursor: 'pointer' }} color="primary" onClick={() => onClose('finished', false)} />
        </div>
        <div className={classes?.imgContainer}>
          <img src={ImgRedTarget} alt="" />
        </div>
        <Box>
          <Box p={3} pb={2.3} pt={4}>
            <h2 className={classes?.title}>Orden Entregada</h2>
            <p className={classes?.text}>
              La orden fue entregada exitosamente
            </p>
            <Box mt={1.5} textAlign="center">
              <Button className={classes.btnConfirm} size="small" variant="contained" color="primary" onClick={onPush}>Confirmar</Button>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

StatusModalFinished.propTypes = {
  onClose: PropTypes.func,
  refresh: PropTypes.func,
  updateStatus: PropTypes.func,
  order: PropTypes.object
};

export default StatusModalFinished;
