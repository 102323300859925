import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  Button,
  TextField,
  FormControl,
  FormHelperText
} from '@material-ui/core';
import Modal from 'src/components/Modal';
import useModal from 'src/hooks/useModal';
import ColorService from 'src/services/ColorService';
import { SketchPicker } from 'react-color';

import { PropTypes } from 'prop-types';

const Cards = ({ data, refresh }) => {
  const [isDelete, setIsDelete] = useState(null);
  const [isOpen, toggle] = useModal();
  const [color, setColor] = useState('#000000');
  const [errors, setErrors] = useState([]);

  const handleChangeColor = (value) => {
    setColor(value?.hex);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name } = e.target;
    const error = [];
    const update = {
      name: name.value,
      hexadecimal: color.slice(1)
    };

    if (!update.name) {
      error.push('El nombre es requerido');
    }

    if (!update.hexadecimal) {
      error.push('El color es requerido');
    }

    if (error.length > 0) {
      setErrors(error);
      return;
    }

    await ColorService.update(data.id, update);
    toggle();
    refresh();
  };

  const deletes = async () => {
    await ColorService.remove(data.id);
    setIsDelete(true);
    refresh();
    toggle();
  };

  const handleDelete = () => {
    setIsDelete(true);
    toggle();
  };

  const handleEditar = () => {
    setIsDelete(false);
    toggle();
  };

  return (
    <div>
      <Card
        style={{
          margin: '1rem'
        }}
        raised
      >
        <Box
          width="250px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={3}
        >
          <Box
            padding={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              bgcolor={`#${data.hexadecimal}`}
              width="100px"
              height="100px"
              borderRadius="12px"
              p={3}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h4">{data.name}</Typography>
              <Typography variant="subtitle1" style={{ opacity: '.4' }}>
                #{data.hexadecimal}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              color="primary"
              style={{ margin: '0.3rem' }}
              onClick={() => handleEditar()}
            >
              Editar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDelete()}
            >
              eliminar
            </Button>
          </Box>
        </Box>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title={isDelete ? 'Borrar' : 'Editar'}
        >
          {isDelete ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h4">
                ¿Estas seguro de eliminar este color?
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: '0.3rem' }}
                  onClick={() => deletes()}
                >
                  Eliminar
                </Button>
              </Box>
            </Box>
          ) : (
            <form onSubmit={(e) => handleSubmit(e)}>
              <FormControl
                required
                variant="outlined"
                // className={classes.formControl}
                error={errors?.map((error) => error.includes('nombre'))}
              >
                <Box>
                  <SketchPicker
                    color={color}
                    onChange={handleChangeColor}
                    disableAlpha
                  />
                  <TextField
                    error={errors && errors.length > 0}
                    helperText={errors}
                    fullWidth
                    label="Nombre del color"
                    margin="normal"
                    name="name"
                    variant="outlined"
                  />
                  <Button variant="contained" color="primary" type="submit">
                    Agregar
                  </Button>
                </Box>
                <FormHelperText error>
                  {errors?.map((error) => error.includes('nombre') && error)}
                </FormHelperText>
              </FormControl>
            </form>
          )}
        </Modal>
      </Card>
    </div>
  );
};

Cards.propTypes = {
  data: PropTypes.array,
  refresh: PropTypes.func
};

export default Cards;
