import React, { useState, useRef } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import LoadingBox from 'src/components/LoadingBox';
import DriverCardPreview from './DriverCardPreview';
import CreateDriverForm from './CreateDriverForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateDriverView = () => {
  const classes = useStyles();
  const [hasImage] = useState(false);
  const [loading, setLoading] = useState();
  const [driver, setDriver] = useState({
    id: null,
    first_name: '',
    last_name: '',
    branch_office_id: '',
    avatar: '',
    email: '',
    phone: '',
    password: '',
    city_id: '',
  });

  const inputRef = useRef();

  const handleDriverAttributes = (values) => {
    setDriver({
      ...driver,
      ...values
    });
  };

  const handleUploadImage = () => inputRef.current.click();

  return (
    <LoadingBox loading={loading}>
      <Page
        className={classes.root}
        title="Crear repartidor propio"
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
            >
              <DriverCardPreview
                ref={inputRef}
                driver={driver}
                hasImage={hasImage}
                onUpload={handleUploadImage}
              />
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xs={12}
            >
              <CreateDriverForm
                ref={inputRef}
                hasImage={hasImage}
                driver={driver}
                setLoading={setLoading}
                onChange={handleDriverAttributes}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </LoadingBox>
  );
};

export default CreateDriverView;
