import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  getFirestore, onSnapshot, doc,
} from 'firebase/firestore';
import FirebaseApp from 'src/utils/firebase';
import {
  makeStyles,
  Container,
  Card,
  Box,
  Typography,
} from '@material-ui/core';
import {
  ArrowBackIosRounded,
  Block,
  Check,
  SwapHoriz
} from '@material-ui/icons';
import { IoIosPerson } from 'react-icons/io';
import { MdDeliveryDining } from 'react-icons/md';
import pusher from 'src/utils/pusher';
import EnvironmentService from 'src/services/EnviromentService';
import OrderService from 'src/services/OrderService';
import Page from 'src/components/Page';
import LoadingBox from 'src/components/LoadingBox';
import GoogleMapsParcel from 'src/components/GoogleMapsParcel';
import OrderCost from '../../../components/OrderCost';
import AssignDriver from './AssignDriver';
import Address from '../../../components/Address';
import ProgressTracking from './components/ProgressTracking';

const Firestore = getFirestore(FirebaseApp);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f6f6fa',
    height: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  title: {
    marginLeft: theme.spacing(1),
    fontWeight: 600,
  },
  card: {
    position: 'relative',
    overflow: 'unset',
    height: '100%',
    borderRadius: 20,
    backgroundColor: '#f4f7fa',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
  },
  container: {
    paddingLeft: 20,
    paddingRight: 20,
    height: '100%'
  },
  cardCost: {
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
  },
  scaleAnimation: {
    animation: '$scale 0.7s ease both'
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  mt3: {
    marginTop: theme.spacing(3),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  scroll: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  },
  driver: {
    padding: '20px',
    marginTop: '10px',
    width: '100%',
    heigth: '150px',
    border: '1px solid #009739',
    borderRadius: '15px',
    backgroundColor: '#fff'
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(0)'
    },
    '30%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

const items = [
  {
    key: 0,
    icon: IoIosPerson,
    title: 'Asignando driver',
    status: 'pending_to_be_sent'
  },
  {
    key: 1,
    icon: MdDeliveryDining,
    title: 'En camino',
    status: 'sent'
  },
  {
    key: 2,
    icon: Check,
    title: 'Completado',
    status: 'finished'
  }
];

const roundtripItems = [
  {
    key: 0,
    icon: IoIosPerson,
    title: 'Asignando driver',
    status: 'pending_to_be_sent'
  },
  {
    key: 1,
    icon: MdDeliveryDining,
    title: 'En camino',
    status: 'sent'
  },
  {
    key: 2,
    icon: SwapHoriz,
    title: 'Regresando (Paquete entregado)',
    status: 'returning'
  },
  {
    key: 3,
    icon: Check,
    title: 'Completado',
    status: 'finished'
  }
];

const mapStyles = {
  width: '100%',
  minWidth: '100%',
  height: '100%',
  minHeight: '100%',
};

const TrackingView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [googleMapStyles, setGoogleMapStyles] = useState(null);
  const [centerDirections, setCenterDirections] = useState(true);
  const [step, setStep] = useState(1);
  const [driver, setDriver] = useState({});
  const [origin, setOrigin] = useState({});
  const [destination, setDestination] = useState({});
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({});
  const snapshotRef = useRef(null);

  const getGoogleMapStyles = async () => {
    const response = await EnvironmentService.getByKey('monitor_google_maps_style');
    if (response?.code === 200) {
      setGoogleMapStyles(JSON.parse(response?.data?.value));
    }
  };

  const getOrder = async () => {
    const response = await OrderService.getById(id);
    if (!response?.success) {
      navigate('/app/paqueteria');
    }
    setOrder(response?.data);
    setLoading(false);
  };

  const fetchDriver = async (driverId) => {
    try {
      snapshotRef.current = onSnapshot(doc(Firestore, 'driverslocation', driverId), (snapshot) => {
        if (snapshot?.data()) {
          setDriver(snapshot?.data());
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  const setRoute = () => {
    const posBranchOffice = {
      lat: order?.data_branch_office?.latitude,
      lng: order?.data_branch_office?.longitude
    };
    const posClient = {
      lat: order?.address?.latitude,
      lng: order?.address?.longitude
    };

    if (order?.status === 'sent') {
      fetchDriver(order?.data_driver?.id);
      if (order?.delivery_status === 'delivering') {
        setDestination(posClient);
        return;
      }
      setDestination(posBranchOffice);
      return;
    }
    setDriver({});
    setOrigin(posBranchOffice);
    setDestination(posClient);
  };

  useEffect(() => {
    getGoogleMapStyles();
  }, []);

  useEffect(() => {
    setCenterDirections(true);
  }, [destination]);

  useEffect(() => {
    if (order?.id) {
      const steps = order?.roundtrip ? roundtripItems : items;
      const deliveryStatus = order?.delivery_status;
      const status = deliveryStatus === 'returning' && order?.status !== 'finished' ? 'returning' : order?.status;
      const itemStep = steps.find((item) => item.status === status);
      setStep(itemStep?.key);
      setRoute();
    }
    return () => {
      if (snapshotRef?.current) {
        snapshotRef.current();
      }
    };
  }, [order]);

  useEffect(() => {
    if (driver?.driver_id) {
      setOrigin({
        lat: driver?.position?.latitude,
        lng: driver?.position?.longitude
      });
    }
  }, [driver?.position]);

  useEffect(() => {
    getOrder();
    let channel = null;
    if (order?.id) {
      channel = pusher.subscribe(`ORDER_${order.id}`);
      ['update']
        .map((item) => {
          channel.bind(item, () => {
            if (['update'].some((event) => item === event)) {
              getOrder();
            }
          });
          return item;
        });
    }
    return () => {
      pusher.unsubscribe(channel?.name);
      if (snapshotRef?.current) {
        snapshotRef.current();
      }
    };
  }, [order?.id]);

  return (
    <Page
      title="Seguimiento"
      className={classes.root}
    >
      <Container className={classes.container}>
        <LoadingBox loading={loading}>
          <Card className={classes.card}>
            <Box
              display="flex"
              p={1}
              height="100%"
            >
              <Box
                display="flex"
                flexDirection="column"
                width="50%"
                height="100%"
                pb={2}
              >
                <Box
                  px={1.5}
                  py={2}
                  display="flex"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                  >
                    <ArrowBackIosRounded
                      color="primary"
                      style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        marginRight: 5
                      }}
                      onClick={() => navigate('/app/paqueteria', { replace: true })}
                    />
                    <Typography color="primary" variant="h3">
                      Paquetería
                      {' '}
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400
                        }}
                      >
                        / Seguimiento
                      </span>
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  width="100%"
                  height="92%"
                >
                  {order?.status === 'pending_to_be_sent' ? (
                    <Box
                      display="flex"
                      width="100%"
                      height="100%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <AssignDriver />
                    </Box>
                  ) : (
                    <Box
                      my={2}
                      mt={3}
                      px={1.5}
                      display="flex"
                      flexDirection="column"
                      width="100%"
                      maxHeight="100%"
                      className={classes.scroll}
                    >
                      <Address
                        className={classes.mb3}
                        showInfo
                        isBranchOffice
                        branchOffice={order?.data_branch_office}
                        addressB={order?.address}
                      />
                      {(order?.status === 'cancelled' || order?.status === 'rejected') ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <Block
                              color="primary"
                              style={{
                                fontSize: 34
                              }}
                            />
                            <Typography
                              variant="body2"
                              color="primary"
                              style={{
                                fontSize: 17,
                                fontWeight: 500
                              }}
                            >
                              Orden
                              {order?.status === 'cancelled' ? ' cancelada' : ' rechazada'}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <ProgressTracking
                          destination={step || 1}
                          items={order?.roundtrip ? roundtripItems : items}
                        />
                      )}
                      <Address
                        className={classes.mt3}
                        showInfo
                        addressB={order?.address}
                      />
                      {order?.roundtrip && (
                        <Address
                          className={classes.mt2}
                          showInfo
                          isBranchOffice
                          isRoundtrip
                          branchOffice={order?.data_branch_office}
                          addressB={order?.address}
                        />
                      )}
                      <Box
                        color="#009739"
                        className={classes.driver}
                      >
                        <Box
                          fontSize={30}
                        >
                          <MdDeliveryDining />
                        </Box>
                        <Typography
                          variant="h5"
                        >
                          Datos del Repartidor
                        </Typography>
                        <Typography
                          variant="body2"
                        >
                          Nombre:
                          {' '}
                          {order?.data_driver?.first_name}
                          {' '}
                          {order?.data_driver?.last_name}
                          <br />
                          Telefono:
                          {' '}
                          {order?.data_driver?.phone}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box
                width="50%"
                position="relative"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  pb={1}
                >
                  <OrderCost
                    className={classes.cardCost}
                    distance={order?.distance}
                    deliveryCost={order?.delivery_cost}
                  />
                </Box>
                <GoogleMapsParcel
                  addressA={order?.data_branch_office}
                  addressB={order?.address}
                  driver={driver}
                  origin={origin}
                  destination={destination}
                  centerDirections={centerDirections}
                  setCenterDirections={setCenterDirections}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                  styles={googleMapStyles}
                  loadingElement={<div style={mapStyles} />}
                  containerElement={<div style={{ width: '100%', height: '100%' }} />}
                  mapElement={<div style={mapStyles} />}
                />
              </Box>
            </Box>
          </Card>
        </LoadingBox>
      </Container>
    </Page>
  );
};

export default TrackingView;
