import HttpRequest from './HttpRequest';

export default class FavoriteBranchOfficeService extends HttpRequest {
  static endpoint = 'favorite-branch-offices';

  static async getCountById(id) {
    this.endpoint = 'favorite-branch-offices/branch-office';
    const response = await this.get(`${id}/count`);
    return response;
  }
}
