import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  makeStyles,
  Box,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const LoadingBox = ({
  children,
  loading = false,
  bgcolor,
  title
}) => {
  const classes = useStyles();

  return (
    <>
      {loading ? (
        <Box
          className={classes.container}
          bgcolor={bgcolor}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <CircularProgress />
            <Typography
              variant="body2"
              color="primary"
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginTop: 12
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      ) : children}
    </>
  );
};

LoadingBox.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  bgcolor: PropTypes.string,
  title: PropTypes.string
};

LoadingBox.defaultProps = {
  bgcolor: '#f6fbf4',
  title: ''
};

export default LoadingBox;
