import axios from 'axios';
import cookie from 'js-cookie';

export default class HttpRequest {
  static endpoint = '';

  static API_URI = process.env.REACT_APP_API_URI;

  static setMicroservice(microservice) {
    switch (microservice) {
      case 'reports':
        this.API_URI = process.env.REACT_APP_API_REPORTS;
        return;
      case 'polygons':
        this.API_URI = process.env.REACT_APP_API_POLYGONS;
        return;
      case 'orders':
        this.API_URI = process.env.REACT_APP_API_ORDERS;
        return;
      case 'environment':
        this.API_URI = process.env.REACT_APP_API_ENV;
        return;
      default:
        this.API_URI = process.env.REACT_APP_API_URI;
    }
  }

  static validateSession(code = 200) {
    if (code === 401) {
      window.location.reload();
    }
  }

  static async get(id = null) {
    try {
      let response = null;
      if (id) {
        response = await axios.get(`${this.API_URI}/${this.endpoint}/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookie.get('token')}`
          },
        });
      } else {
        response = await axios.get(`${this.API_URI}/${this.endpoint}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookie.get('token')}`
          },
        });
      }

      return response.data;
    } catch (err) {
      this.validateSession(err?.response?.status);
      console.error(err.response ? err.response?.data?.errors : err.message);

      return err?.response?.data;
    }
  }

  static async post(data, isPublic = false) {
    try {
      let headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookie.get('token')}`
      };

      if (isPublic) {
        headers = {
          'Content-Type': 'application/json',
          'api-key': `${process.env.REACT_APP_FRONT_API_KEY}`
        };
      }
      const response = await axios.post(`${this.API_URI}/${this.endpoint}`, data, {
        headers
      });

      return response.data;
    } catch (err) {
      console.error(err.response ? err.response?.data?.errors : err.message);
      return err?.response?.data;
    }
  }

  static async put(id, data) {
    try {
      const response = await axios.put(`${this.API_URI}/${this.endpoint}/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookie.get('token')}`
        },
      });

      return response.data;
    } catch (err) {
      console.error(err.response ? err.response?.data?.errors : err.message);
      return err?.response?.data;
    }
  }

  static async putNew(data) {
    try {
      const response = await axios.put(`${this.API_URI}/${this.endpoint}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookie.get('token')}`
        },
      });

      return response.data;
    } catch (err) {
      console.error(err.response ? err.response?.data?.errors : err.message);
      return err?.response?.data;
    }
  }

  static async delete(id) {
    try {
      const response = await axios.delete(`${this.API_URI}/${this.endpoint}/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookie.get('token')}`
        },
      });

      return response.data;
    } catch (err) {
      console.error(err.response ? err.response?.data?.errors : err.message);
      return err?.response?.data;
    }
  }
}
