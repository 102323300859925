import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import arrayMove from 'array-move';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Switch,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  Typography,
  makeStyles,
  Avatar
} from '@material-ui/core';
import TableBodySortable from 'src/components/Table/TableBodySortable';
import TableRowSortable from 'src/components/Table/TableRowSortable';
import SortableHandleIcon from 'src/components/Icons/SortableHandleIcon';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility,
  ArrowBackIosRounded,
} from '@material-ui/icons';

// services
import BrandCategoryService from 'src/services/BrandCategoryService';

// utils
import getInitials from 'src/utils/getInitials';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer',
    color: '#263238',
  },
  removeIcon: {
    marginLeft: '1rem'
  },
  handleIcon: {
    cursor: 'move',
    marginLeft: '1rem'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  avatarHeader: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(0.2)
  }
}));

const AvatarCard = ({
  showSubCategories,
  mainCategory,
  handleMainCategories
}) => {
  const classes = useStyles();
  return (
    <>
      {showSubCategories && (
        <>
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => handleMainCategories()} aria-label="back" size="small">
              <ArrowBackIosRounded style={{ padding: '1px' }} fontSize="small" className={classes.icon} />
            </IconButton>
            <Avatar
              className={classes.avatarHeader}
              src={mainCategory?.image}
            >
              {getInitials(mainCategory?.name)}
            </Avatar>
          </Box>
        </>
      )}
    </>
  );
};

const Results = ({
  className, data, onEdit, onRemove, user, onRefresh, ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [brandCategories, setBrandCategories] = useState([]);
  const [showSubCategories, setShowSubCategories] = useState(false);
  const [mainCategory, setMainCategory] = useState({});

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSubCategories = (category) => {
    setMainCategory(category);
    setShowSubCategories(true);
    setBrandCategories(category.sub_categories);
  };

  const handleMainCategories = () => {
    setMainCategory({});
    setShowSubCategories(false);
    setBrandCategories(data);
  };

  const toggleAvailableBrandCategory = async (brandCategoryId) => {
    await BrandCategoryService.toggle(brandCategoryId);
    onRefresh();
  };

  const editItem = (item) => {
    onEdit(item);
  };

  const removeItem = (item) => {
    onRemove(item);
  };

  const onUpdate = (items) => {
    try {
      items?.map(async (brandCategory) => {
        const payload = {
          ...brandCategory,
          brand_id: user?.brand_id,
        };
        await BrandCategoryService?.update(brandCategory?.id, payload);
      });
    } catch (e) {
      console.log(e?.message);
    }
  };

  const onUpdateSubCategories = async (items) => {
    try {
      const payload = {
        ...mainCategory,
        brand_id: user?.brand_id,
        sub_categories: items
      };
      await BrandCategoryService.update(mainCategory?.id, payload);
    } catch (err) {
      console.log(err?.message);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const payload = arrayMove(
      brandCategories.map((brandCategory, order) => {
        if (order === oldIndex) {
          return ({ ...brandCategory, order: newIndex });
        }
        if (order < oldIndex) {
          return ({ ...brandCategory, order: order + 1 });
        }
        return ({ ...brandCategory, order });
      }),
      oldIndex,
      newIndex
    );
    setBrandCategories(payload);
    if (!showSubCategories) onUpdate(payload);
    else onUpdateSubCategories(payload);
  };

  useEffect(() => {
    if (Array.isArray(data)) {
      setBrandCategories(data);
      setMainCategory({});
      setShowSubCategories(false);
    }
  }, [data]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        subheader={showSubCategories ? 'Subcategorías' : 'Categorías principales'}
        title={<Typography variant="h5">{showSubCategories ? mainCategory?.name : 'Categorías'}</Typography>}
        avatar={(
          <AvatarCard
            showSubCategories={showSubCategories}
            mainCategory={mainCategory}
            handleMainCategories={handleMainCategories}
          />
        )}
      />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell>
                  Nombre
                </TableCell>
                <TableCell width={!showSubCategories ? 120 : 0}>
                  Disponible
                </TableCell>
                {!showSubCategories && (
                  <TableCell style={{ textAlign: 'center' }}>
                    Ver subcategorías
                  </TableCell>
                )}
                <TableCell width={120} />
              </TableRow>
            </TableHead>
            <TableBodySortable onSortEnd={onSortEnd} lockAxis="y" useDragHandle>
              {
              brandCategories.filter((item) => item.is_main || showSubCategories).slice(0, limit)
                .map((customer, index) => (
                  <TableRowSortable
                    hover
                    index={index}
                    key={customer?.id}
                  >
                    <TableCell padding="checkbox">
                      <SortableHandleIcon className={classes?.handleIcon} />
                    </TableCell>
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                      >
                        <Avatar
                          className={classes.avatar}
                          src={customer?.image}
                        >
                          {getInitials(customer?.name)}
                        </Avatar>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          {customer?.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Switch
                        color="primary"
                        checked={customer?.is_available}
                        onChange={() => customer && toggleAvailableBrandCategory(customer?.id)}
                        name="product_available"
                      />
                    </TableCell>
                    {!showSubCategories && (
                      <TableCell style={{ textAlign: 'center' }}>
                        <IconButton onClick={() => handleSubCategories(customer)} aria-label="visibility">
                          <Visibility
                            className={classes?.icon}
                          />
                        </IconButton>
                      </TableCell>
                    )}
                    <TableCell>
                      <EditIcon
                        className={clsx(classes.icon, classes?.removeIcon)}
                        onClick={() => editItem(customer)}
                      />
                      {
                        (customer?.products?.length === 0 || showSubCategories) && (
                          <DeleteIcon
                            className={clsx(classes.icon, classes?.removeIcon)}
                            onClick={() => removeItem(customer)}
                          />
                        )
                      }
                    </TableCell>
                  </TableRowSortable>
                ))
              }
            </TableBodySortable>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={brandCategories?.length}
        onPageChange={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.string,
    brand_id: PropTypes.string,
  }),
  onRefresh: PropTypes.func
};

Results.defaultProps = {
  className: '',
  onRemove: () => null,
  onEdit: () => null,
  user: {
    id: '',
    brand_id: ''
  },
  onRefresh: () => { }
};

AvatarCard.propTypes = {
  showSubCategories: PropTypes.bool,
  mainCategory: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    is_main: PropTypes.bool,
    parent_id: PropTypes.string
  }),
  handleMainCategories: PropTypes.func,
};

export default Results;
