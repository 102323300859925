import { useSelector, useDispatch } from 'react-redux';
import { actions as ErrorActions, selector as ErrorSelector } from '../redux/ducks/errors';

const useErrors = () => {
  const dispatch = useDispatch();
  const { errors } = useSelector(ErrorSelector);

  const setErrors = (input) => {
    dispatch(ErrorActions.set(input));
  };

  const clearErrors = () => dispatch(ErrorActions.clear());

  return {
    errors,
    setErrors,
    clearErrors,
  };
};

export default useErrors;
