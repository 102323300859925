import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Avatar, Button, makeStyles } from '@material-ui/core';
import { ArrowForwardRounded, SwapHoriz } from '@material-ui/icons';
import { MdDeliveryDining } from 'react-icons/md';
import Address from '../../../components/Address';
import Tip from './components/Tip';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    width: 37,
    height: 37
  },
  mb: {
    marginBottom: theme.spacing(1)
  },
  mt: {
    marginTop: theme.spacing(1)
  },
  scroll: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  },
  containerIcon: {
    transform: 'translateY(-45%)'
  },
  showPointer: {
    animationName: '$showPointer',
    animationDuration: '0.6s',
    animationFillMode: 'both',
    animationDelay: '3s',
    animationTimingFunction: 'ease'
  },
  pointer: {
    animation: '$point 1.25s infinite alternate'
  },
  arrowIcon: {
    color: '#009739',
    fontSize: 28
  },
  circle: {
    animationName: '$fade',
    animationDuration: '0.6s',
    animationFillMode: 'both',
    animationDelay: '3s',
    animationTimingFunction: 'ease'
  },
  showConnect: {
    animationName: '$showConnect',
    animationDuration: '2s',
    animationFillMode: 'both',
    animationTimingFunction: 'ease'
  },
  btnRoundtrip: {
    marginTop: theme.spacing(2),
    borderRadius: 50
  },
  iconSwap: {
    fontSize: 15,
    color: '#fff'
  },
  '@keyframes point': {
    '0%': {
      transform: 'translateX(0)'
    },
    '100%': {
      transform: 'translateX(.125em)'
    }
  },
  '@keyframes fade': {
    '0%': {
      opacity: 0,
      transform: 'scale(0)'
    },
    '50%': {
      transform: 'scale(1.3)'
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1)'
    }
  },
  '@keyframes showPointer': {
    '0%': {
      opacity: 0,
      transform: 'translateX(-20px)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)'
    }
  },
  '@keyframes showConnect': {
    '0%': {
      height: 0
    },
    '100%': {
      height: '100%'
    }
  }
}));

const ConnectAddress = ({ roundtrip }) => {
  const classes = useStyles();
  const [pointerAnimation, setPointerAnimation] = useState(false);
  const [finishAnimation, setFinishAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setFinishAnimation(true);
    }, 2400);
    setTimeout(() => {
      setPointerAnimation(true);
    }, 3600);
  }, []);

  return (
    <Box
      className={classes.showConnect}
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        {[0, 1, 2].map((item) => (
          <Box
            style={{
              animationDelay: `${0.6 + 0.6 * item}s`
            }}
            className={classes.circle}
            my={0.5}
            width={8}
            height={8}
            borderRadius="50%"
            bgcolor="#97D4AE"
          />
        ))}
      </Box>
      <Box position="relative" display="flex" alignItems="center">
        {roundtrip && finishAnimation && (
          <Box
            style={{
              animationDelay: '0s'
            }}
            className={classes.circle}
            position="absolute"
            top={-8}
            left={-25}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="50%"
            border="3px solid #97D4AE"
            width={26}
            height={26}
            bgcolor="#009739"
          >
            <SwapHoriz className={classes.iconSwap} />
          </Box>
        )}
        <Avatar
          className={clsx(classes.avatar, classes.circle)}
          style={{
            animationDelay: `${0.6 + 0.6 * 3}s`
          }}
        >
          <MdDeliveryDining size={23} />
        </Avatar>
        <Box
          className={classes.containerIcon}
          position="absolute"
          top="50%"
          left={50}
        >
          <ArrowForwardRounded
            className={clsx(classes.arrowIcon, classes.showPointer, {
              [classes.pointer]: pointerAnimation
            })}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column-reverse" alignItems="center">
        {[0, 1, 2].map((item) => (
          <Box
            style={{
              animationDelay: `${0.6 + 0.6 * item}s`
            }}
            className={classes.circle}
            my={0.5}
            width={8}
            height={8}
            borderRadius="50%"
            bgcolor="#ed88aa"
          />
        ))}
      </Box>
    </Box>
  );
};

const AddressManagment = ({
  toggleWarning,
  addressA,
  addressB,
  handleAddressA,
  handleAddressB,
  checkAddressContain,
  roundtrip,
  setRoundtrip,
  setTip,
  tip
}) => {
  const classes = useStyles();
  const [editAddressA, setEditAddressA] = useState(true);
  const [editAddressB, setEditAddressB] = useState(true);
  const addressARef = useRef();
  const addressBRef = useRef();
  const stateAddressARef = useRef(addressA);
  const stateAddressBRef = useRef(addressB);

  const toggleEdit = (address) => {
    if (address === 'A') {
      setEditAddressA((prev) => !prev);
    } else if (address === 'B') {
      setEditAddressB((prev) => !prev);
    }
  };

  const closeEditAddressA = (e) => {
    if (
      !e?.path?.includes(addressARef.current) &&
      Object.keys(stateAddressARef.current).length !== 0
    ) {
      setEditAddressA(false);
    }
  };

  const closeEditAddressB = (e) => {
    if (
      !e?.path?.includes(addressBRef.current) &&
      stateAddressBRef.current?.address1.length !== 0
    ) {
      setEditAddressB(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', closeEditAddressA);
    document.body.addEventListener('click', closeEditAddressB);

    return () => {
      document.body.removeEventListener('click', closeEditAddressA);
      document.body.removeEventListener('click', closeEditAddressB);
    };
  }, []);

  useEffect(() => {
    stateAddressARef.current = addressA;
  }, [addressA]);

  useEffect(() => {
    stateAddressBRef.current = addressB;
  }, [addressB]);

  return (
    <Box display="flex" flexDirection="column" width="100%" height="74%">
      <Box
        className={classes.scroll}
        mb={2}
        mt={4}
        px={1.5}
        display="flex"
        flexDirection="column"
        width="100%"
        maxHeight="100%"
      >
        <Tip setTip={setTip} tip={tip} />
        <Address
          ref={addressARef}
          edit={editAddressA}
          toggleEdit={toggleEdit}
          className={classes.mb}
          isBranchOffice
          setAddressA={handleAddressA}
          branchOffice={addressA}
          setAddressB={handleAddressB}
          addressB={addressB}
          checkAddressContain={checkAddressContain}
          toggleWarning={toggleWarning}
        />
        {Object.keys(addressA).length !== 0 &&
          addressB?.address1?.length !== 0 && (
            <ConnectAddress roundtrip={roundtrip} />
          )}
        <Address
          ref={addressBRef}
          edit={editAddressB}
          toggleEdit={toggleEdit}
          className={clsx(classes.mt, classes.mb)}
          waitAddress={Object.keys(addressA).length === 0}
          setAddressB={handleAddressB}
          addressB={addressB}
          checkAddressContain={checkAddressContain}
          toggleWarning={toggleWarning}
        />
        {roundtrip ? (
          <Address
            className={classes.mt}
            edit={false}
            isRoundtrip
            isBranchOffice
            addressB={addressB}
            branchOffice={addressA}
            setRoundtrip={setRoundtrip}
          />
        ) : (
          <Button
            onClick={() => setRoundtrip(true)}
            variant="contained"
            disabled={
              Object.keys(addressA).length === 0 ||
              addressB?.address1?.length === 0
            }
            color="primary"
            className={classes.btnRoundtrip}
            startIcon={<SwapHoriz />}
          >
            Agregar ida y vuelta
          </Button>
        )}
      </Box>
    </Box>
  );
};

AddressManagment.propTypes = {
  toggleWarning: PropTypes.func,
  addressA: PropTypes.object,
  addressB: PropTypes.object,
  handleAddressA: PropTypes.func,
  handleAddressB: PropTypes.func,
  checkAddressContain: PropTypes.func,
  roundtrip: PropTypes.bool,
  setRoundtrip: PropTypes.func,
  setTip: PropTypes.number,
  tip: PropTypes.number
};

ConnectAddress.propTypes = {
  roundtrip: PropTypes.bool
};

export default AddressManagment;
