import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Cached, Check, Stop } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  progressWrapper: {
    borderRadius: 8,
    backgroundColor: '#D9E8F1',
    transform: 'translateY(-50%)'
  },
  progressLine: {
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
    transition: 'width 0.5s'
  },
  stepVisited: {
    backgroundColor: theme.palette.primary.main,
  },
  stepUnvisited: {
    backgroundColor: '#D9E8F1',
  },
  step: {
    borderRadius: '50%',
    transition: 'background 0.5s ease'
  },
  iconVisited: {
    color: '#fff'
  },
  iconUnvisited: {
    color: theme.palette.primary.main
  },
  tooltip: {
    position: 'relative',
    display: 'flex',
    height: 'fit-content',
    textDecoration: 'none',
    color: '#1D1D1D'
  },
  tooltipBox: {
    transition: 'background 0.5s ease',
    padding: theme.spacing(1),
    width: 50,
    position: 'relative',
    color: '#F2F2F2',
    fontSize: 25,
    borderRadius: 7,
    '&::after': {
      content: '""',
      transition: 'border 0.5s ease',
      display: 'block',
      borderTop: '7px solid #009739',
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      position: 'absolute',
      bottom: -7,
      left: 'calc(50% - 7px)'
    }
  },
  unVisitedToolTip: {
    '&::after': {
      borderTop: '7px solid #D9E8F1',
    }
  },
  tooltipAnim: {
    animation: '$tooltip 1.5s ease infinite'
  },
  rotate: {
    animation: '$rotate 1.5s linear infinite'
  },
  load: {
    animation: '$load 1.5s linear infinite'
  },
  scale: {
    animation: '$scale 0.7s ease both'
  },
  '@keyframes tooltip': {
    '0%': {
      transform: 'translateY(0px)',
    },
    '50%': {
      transform: 'translateY(-4px)',
    },
    '100%': {
      transform: 'translateY(0px)'
    }
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
  '@keyframes load': {
    '0%': {
      width: 23,
      transform: 'translateX(0)'
    },
    '50%': {
      width: 56,
    },
    '75%': {
      width: -56,
    },
    '100%': {
      width: 23,
      transform: 'translateX(90px)'
    }
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(0)'
    },
    '30%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

const StepTitle = ({
  title,
  icon: Icon
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.scale}
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="#d4edda"
      borderRadius={50}
      width={300}
      p={1}
    >
      <Icon
        style={{
          color: '#009739',
          fontSize: 22
        }}
      />
      <Typography
        variant="body2"
        color="primary"
        style={{
          marginLeft: 7,
          fontWeight: 600,
          fontSize: 13
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const ProgressTracking = ({
  items,
  destination,
}) => {
  const isVisited = (item) => item <= destination;
  const actuallyVisit = (item) => {
    return item === destination && destination !== items.length - 1 ? Cached : Check;
  };
  const classes = useStyles();
  return (
    <Box
      className={classes.scale}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {items.map((item, index) => {
          const Icon = item?.icon;
          const IconState = !isVisited(index) ? Stop : actuallyVisit(index);
          return (
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              width={index === items?.length - 1 ? 'fit-content' : 90}
            >
              <Box
                display="flex"
              >
                <Box
                  key={item.key}
                  className={classes.tooltip}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={clsx(
                      classes.tooltipBox,
                      {
                        [classes.tooltipAnim]: index === destination
                        && destination !== items.length - 1,
                        [classes.stepVisited]: isVisited(index),
                        [classes.unVisitedToolTip]: !isVisited(index),
                        [classes.stepUnvisited]: !isVisited(index),
                      }
                    )}
                  >
                    {Icon && (
                      <Icon
                        className={clsx(
                          {
                            [classes.iconVisited]: isVisited(index),
                            [classes.iconUnvisited]: !isVisited(index),
                          }
                        )}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                position="relative"
                display="flex"
                height="10%"
                my={3}
              >
                <Box
                  position="absolute"
                  style={{
                    transform: 'translateY(-50%)',
                  }}
                  left={13}
                  top="50%"
                  zIndex={1}
                  key={item?.key}
                  display="flex"
                >
                  <Box
                    position="relative"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={clsx(
                      classes.step,
                      {
                        [classes.stepVisited]: isVisited(index),
                        [classes.stepUnvisited]: !isVisited(index),
                      }
                    )}
                    width={23}
                    height={23}
                  >
                    {IconState && (
                      <IconState
                        style={{
                          fontSize: 15
                        }}
                        className={clsx(
                          {
                            [classes.rotate]: index === destination
                            && destination !== items.length - 1,
                            [classes.iconVisited]: isVisited(index),
                            [classes.iconUnvisited]: !isVisited(index),
                          }
                        )}
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  className={classes.progressWrapper}
                  position="absolute"
                  zIndex={0}
                  display="flex"
                  top="50%"
                  left={13}
                  height={8}
                  width={index === items?.length - 1 ? 0 : 113}
                >
                  <Box
                    className={clsx(
                      classes.progressLine,
                      {
                        [classes.load]: index === destination && destination !== items.length - 1,
                      }
                    )}
                    width={index < destination ? 113 : 23}
                    height="100%"
                  />
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      <StepTitle
        title={items[destination]?.title}
        icon={items[destination]?.icon}
      />
    </Box>
  );
};

StepTitle.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node
};

ProgressTracking.propTypes = {
  items: PropTypes.array,
  destination: PropTypes.number,
};

export default ProgressTracking;
