import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  makeStyles,
  Box,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import {
  CancelRounded,
  Room
} from '@material-ui/icons';
import GoogleMapsAddress from './GoogleMapsAddress';

const useStyles = makeStyles(() => ({
  modal: {
    border: '1px solid #e6ebf1',
    borderRadius: '8px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    bottom: '0',
    animation: 'showPopUp 0.5s',
    position: 'absolute',
  },
  modalMd: {
    width: 600,
    height: 'fit-content',
    position: 'relative'
  },
  CreateCustomizationModal: {
    backgroundColor: 'rgba(46, 44, 54, 0.4)',
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
    zIndex: '3',
  },
  CreateCustomizationModalMd: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: 0,
  },
  pane: {
    height: '100%',
    width: '100%',
    position: 'fixed'
  },
  header: {
    padding: '15px 10px',
    borderBottom: '1px solid #e6ebf1',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h3': {
      fontWeight: 600,
      fontSize: 22,
    }
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0',
    height: '100%',
  },
  bodySm: {
    padding: '20px 0',
  },
  iconTitle: {
    fontSize: 27,
    marginRight: 5
  }
}));

const mapStyles = {
  width: '100%',
  minWidth: '100%',
  height: '350px',
  minHeight: '350px',
};

const searchStyles = {
  width: '0',
  minWidth: '0',
  height: '0',
  minHeight: '0',
};

const AddressModal = ({
  className,
  toggleAddressModal,
  confirmAddress,
  address,
  positionAddress,
  handleAddress,
  confirmation
}) => {
  const classes = useStyles();
  const matchesSm = useMediaQuery('screen and (min-width: 600px)', { noSsr: true });
  const matches = useMediaQuery('screen and (min-width: 769px)', { noSsr: true });
  const [elementMap, setElementMap] = useState(address.length !== 0 && confirmation
    ? mapStyles : searchStyles);

  const changeElement = (step) => {
    if (step === 0) setElementMap(searchStyles);
    else if (step === 1) setElementMap(mapStyles);
  };

  return (
    <div
      className={clsx(
        className,
        classes.CreateCustomizationModal,
        {
          [classes.CreateCustomizationModalMd]: matches
        }
      )}
    >
      <div
        className={classes.pane}
        onClick={() => toggleAddressModal()}
      />
      <div
        className={clsx(
          classes.modal,
          {
            [classes.modalMd]: matches
          }
        )}
      >
        <div className={classes.header}>
          <div className={classes.title}>
            <Box
              display="flex"
              alignItems="center"
            >
              <Room
                color="primary"
                className={classes.iconTitle}
              />
              <Typography
                variant="h3"
              >
                {JSON.stringify(elementMap) !== JSON.stringify(mapStyles) ? 'Seleccionar una dirección' : 'Verificar ubicación'}
              </Typography>
            </Box>
            <CancelRounded
              style={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => toggleAddressModal()}
            />
          </div>
        </div>
        <div
          className={clsx(
            classes.body,
            {
              [classes.bodySm]: matchesSm
            }
          )}
          style={{
            paddingRight: JSON.stringify(elementMap) === JSON.stringify(mapStyles) ? 20 : 0,
            paddingLeft: JSON.stringify(elementMap) === JSON.stringify(mapStyles) ? 20 : 0
          }}
        >
          <GoogleMapsAddress
            changeElement={changeElement}
            confirmAddress={confirmAddress}
            address={address}
            positionAddress={positionAddress}
            handleAddress={handleAddress}
            confirmation={confirmation}
            draggable
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={elementMap} />}
            containerElement={<div style={{ width: '100%', height: 'fit-content' }} />}
            mapElement={<div style={elementMap} />}
          />
        </div>
      </div>
    </div>
  );
};

AddressModal.propTypes = {
  className: PropTypes.string,
  toggleAddressModal: PropTypes.func,
  confirmAddress: PropTypes.func,
  address: PropTypes.string,
  positionAddress: PropTypes.object,
  handleAddress: PropTypes.func,
  confirmation: PropTypes.bool
};

AddressModal.defaultProps = {
  className: '',
  confirmation: false,
  handleAddress: () => {}
};

export default AddressModal;
