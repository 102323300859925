import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import getInitials from 'src/utils/getInitials';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Drawer,
  Hidden,
  List,
  Typography,
  useMediaQuery,
  makeStyles
} from '@material-ui/core';
import {
  ExitToApp as ExitToAppIcon,
  // LocalShipping as LocalShippingIcon,
  // BarChart,
  Dashboard
} from '@material-ui/icons';
import { partners, partnersLogistic } from 'src/utils/nav_items';
import NavItem from './NavItem';
import {
  selector as UserSelector,
  actions as UserActions
} from '../../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    zIndex: 2
  },
  drawer: {
    width: 256,
    flexShrink: 0,
    border: 'none',
    '& .MuiDrawer-paperAnchorDockedLeft': {
      border: 'none'
    },
    '& .MuiDrawer-paper': {
      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
    }
  },
  avatar: {
    cursor: 'pointer',
    width: 45,
    height: 45,
    marginBottom: theme.spacing(0.5),
    backgroundColor: '#009739'
  },
  scroll: {
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const matchesLg = useMediaQuery('screen and (min-width: 1280px)', {
    noSsr: true
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(UserSelector);
  const [tabs, setTabs] = useState([]);

  const navigateToTab = (tabActive, href) => {
    if (href !== '/auth') {
      const actTabActive = tabs.findIndex((item) => item?.active);
      tabs[actTabActive].active = false;
      tabs[tabActive].active = true;
      setTabs(tabs.slice());
    } else dispatch(UserActions.clear());
    navigate(href, { replace: true });
  };

  useEffect(() => {
    if (user?.id) {
      const items = user?.logistics ? partnersLogistic : partners;
      setTabs(
        items.map((tab) => {
          return {
            ...tab,
            active: location.pathname?.includes(tab?.href)
          };
        })
      );
    }
  }, [user]);

  useEffect(() => {
    if (openMobile && onMobileClose && !matchesLg) {
      onMobileClose((prev) => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    // Se activa el tab que coincida con la url actual
  }, []);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      bgcolor="#fffefe"
      border="none"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pt={3}
        px={3}
      >
        <Avatar className={classes.avatar} src={user?.avatar}>
          {getInitials(user?.first_name)}
        </Avatar>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            color="textPrimary"
            variant="h6"
            style={{
              fontSize: 15,
              textAlign: 'center'
            }}
          >
            {` ${user?.first_name} ${user?.last_name}`}
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontSize: 13,
              fontWeight: 500,
              color: '#bdbccf'
            }}
          >
            Aliado
          </Typography>
        </Box>
      </Box>
      <Box overflow="auto" mt={3.5} className={classes.scroll}>
        <Box px={1.5} display="flex" alignItems="center">
          <Dashboard
            style={{
              color: '#009739',
              fontSize: 21
            }}
          />
          <Typography
            color="primary"
            variant="body2"
            style={{
              fontSize: 14,
              fontWeight: 500,
              marginLeft: 5
            }}
          >
            Dashboard
          </Typography>
        </Box>
        <Box>
          <List>
            {tabs.map((item, index) => {
              return (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  active={item?.active}
                  index={index}
                  navigateToTab={navigateToTab}
                />
              );
            })}
            <NavItem
              href="/auth"
              title="Cerrar sesión"
              icon={ExitToAppIcon}
              navigateToTab={navigateToTab}
            />
          </List>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={() => onMobileClose((prev) => !prev)}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          elevation={0}
          anchor="left"
          className={classes.drawer}
          classes={{ paper: classes.desktopDrawer }}
          open={!openMobile}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
