import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  TextField,
  TableCell,
  TableRow,
  Typography,
  Button,
  useTheme,
  makeStyles
} from '@material-ui/core';
import { Add, Edit, Check, Close } from '@material-ui/icons';
import { FaRuler } from 'react-icons/fa';

import AddStock from './AddStock';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '15px',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none'
  },
  input: {
    '& .MuiInputBase-root': {
      boxSizing: 'border-box',
      borderRadius: 6,
      backgroundColor: '#F5F7FB',
      border: '1px solid rgb(216, 216, 216)',
      '&.Mui-focused': {
        border: '0.13em solid #009739'
      },
      '&.Mui-error': {
        border: '0.13em solid #f44336'
      }
    },
    '& .MuiInputBase-root::after, & .MuiInputBase-root::before': {
      display: 'none'
    },
    '& .MuiFilledInput-input': {
      paddingTop: theme.spacing(1.1),
      paddingBottom: theme.spacing(1.1),
      fontFamily: '"Poppins", sans-serif',
      fontSize: 13,
      color: 'rgba(0, 0, 0, 0.8)',
      fontWeight: 500
    },
    '& .MuiFilledInput-input.Mui-disabled': {
      color: '#a8aab4'
    },
    '& .MuiFilledInput-input:-webkit-autofill': {
      borderRadius: 7
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
      marginTop: 0
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiInputAdornment-root': {
        transition: 'color 0.3s ease',
        color: '#8c9baf',
        '& .MuiIconButton-label': {
          transition: 'color 0.3s ease',
          color: '#8c9baf'
        }
      },
      '&.Mui-focused .MuiInputAdornment-root': {
        color: '#009739',
        '& .MuiIconButton-label': {
          color: '#009739'
        }
      }
    }
  },
  label: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 13,
    fontWeight: 500,
    color: '#a8aab4'
  },
  containerAvatar: {
    objectFit: 'cover',
    marginRight: theme.spacing(2),
    width: 60,
    height: 60,
    borderRadius: 10,
    overflow: 'hidden'
  },
  avatar: {
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '100%',
    minHeight: '100%'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd'
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  },
  head: {
    border: 'none'
  },
  nameProduct: {
    fontSize: 15,
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  descripProduct: {
    color: '#A5A5A5',
    fontSize: 13,
    fontWeight: 500
  },
  icon: {
    fontSize: 17,
    marginRight: theme.spacing(0.5)
  },
  button: {
    textTransform: 'capitalize',
    width: 150,
    fontSize: 11,
    borderRadius: 7
  },
  buttonIcon: {
    borderRadius: '50%',
    minWidth: 10,
    padding: 3,
    margin: '0 3px'
  },
  buttonCancel: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white'
    }
  },
  iconSize: {
    color: theme.palette.primary.main,
    fontSize: 20
  },
  scale: {
    animation: '$scale 0.7s ease both'
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(0)'
    },
    '10%': {
      transform: 'scale(0)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

const RowSize = ({
  idBranchOffice,
  item,
  dataSize,
  sizes,
  index,
  indexStock,
  onChange
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [showAddStock, setAddStock] = useState(false);
  const [showEditStock, setEditStock] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [size, setSize] = useState(dataSize);

  // Se resetea el TextField con la cantidad anterior del producto.
  const resetQuantity = () => {
    const prevQuantity = dataSize?.quantity;
    setSize({
      ...size,
      quantity: prevQuantity
    });
  };

  const toggleEditStock = () => {
    if (showEditStock) resetQuantity();
    setEditStock(!showEditStock);
  };

  const handleChangeQuantity = (e) => {
    setSize({
      ...size,
      quantity: Number(e?.target?.value)
    });
  };

  const onChangeStock = () => {
    let dataStock = null;
    let action = '';
    if (showAddStock) {
      dataStock = {
        products: [
          {
            id: item?.product_id,
            size: size?.name,
            quantity
          }
        ],
        branch_office_id: idBranchOffice,
        indexStock,
        indexSize: index,
        action: 'add'
      };
      action = 'add';
    } else {
      dataStock = {
        product_id: item?.product_id,
        branch_office_id: idBranchOffice,
        brand_category_id: item?.brand_category_id,
        size_quantities: item?.size_quantities.map((itemSize, i) => {
          if (index === i) {
            return {
              name: itemSize?.name,
              quantity: size?.quantity
            };
          }
          return itemSize;
        }),
        indexStock
      };
      action = 'edit';
    }

    onChange(dataStock, action);
  };

  useEffect(() => {
    if (showAddStock) {
      setAddStock(false);
      setQuantity(0);
    } else if (showEditStock) {
      setEditStock(false);
    }
  }, [sizes[index].quantity]);

  return (
    <>
      <TableRow className={classes.row} index={index}>
        <TableCell style={{ width: 10 }}>
          <Box width="inherit">
            <FaRuler className={classes.iconSize} />
          </Box>
        </TableCell>
        <TableCell style={{ width: 120 }}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <Typography
                variant="body2"
                className={classes.descripProduct}
                style={{
                  color: theme.palette.primary.main
                }}
              >
                Talla:
              </Typography>
              <Box
                bgcolor="#d4edda"
                borderRadius="50%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={26}
                height={26}
                p={0.7}
                ml={1.3}
              >
                <Typography
                  className={classes.descripProduct}
                  style={{
                    color: '#009739',
                    fontSize: 11,
                    fontWeight: 600
                  }}
                  variant="body2"
                >
                  {size?.name}
                </Typography>
              </Box>
            </Box>
          </Box>
        </TableCell>
        <TableCell style={{ width: 150 }}>
          <Box display="flex" flexDirection="column">
            <TextField
              className={classes.input}
              fullWidth
              disabled={!showEditStock}
              focused={showEditStock}
              InputLabelProps={{
                className: classes.label
              }}
              value={size?.quantity}
              onChange={handleChangeQuantity}
              placeholder="Cantidad"
              size="small"
              variant="filled"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            {showEditStock && (
              <Box
                display="flex"
                alignItems="center"
                className={classes.scale}
                mt={1}
              >
                <Button
                  className={classes.buttonIcon}
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => onChangeStock()}
                >
                  <Check size="small" style={{ fontSize: '13px' }} />
                </Button>
                <Button
                  className={clsx(classes.buttonIcon, classes.buttonCancel)}
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => toggleEditStock()}
                >
                  <Close size="small" style={{ fontSize: '13px' }} />
                </Button>
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            {!showAddStock ? (
              <Button
                className={classes.button}
                disabled={showEditStock}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setAddStock((prev) => !prev)}
              >
                <Add className={classes.icon} />
                Entrada inventario
              </Button>
            ) : (
              <AddStock
                toggleAddStock={() => setAddStock((prev) => !prev)}
                onChangeStock={onChangeStock}
                quantity={quantity}
                setQuantity={setQuantity}
              />
            )}
            <Box ml={3}>
              <Button
                className={classes.button}
                disabled={showAddStock}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => toggleEditStock()}
              >
                <Edit className={classes.icon} />
                Ajustar inventario
              </Button>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

RowSize.propTypes = {
  idBranchOffice: PropTypes.string,
  item: PropTypes.object,
  dataSize: PropTypes.object,
  index: PropTypes.number,
  indexStock: PropTypes.number,
  sizes: PropTypes.array,
  onChange: PropTypes.func
};

export default RowSize;
