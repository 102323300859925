import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Check } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '10px',
    padding: '15px',
    paddingBottom: 0,
    width: '98%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '15px',
  },
  customRadio2: {
    '& input[type="radio"]': {
      WebkitAppearance: 'none',
      appearance: 'none'
    },
    display: 'flex',
    width: '70%',
    height: 'fit-content',
    '& input[type="radio"]:checked + $radioButton2': {
      border: '2px solid #009739',
      '& $checked2': {
        backgroundColor: '#009739',
        border: 'none',
      },
      '& $icon2': {
        color: '#fff'
      },
      '& $title2': {
        color: 'blue'
      }
    }
  },
  radioButton2: {
    animation: '$scale 0.7s ease both',
    width: '73%',
    height: '40px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    cursor: 'pointer',
    border: '2px solid #ccc',
    borderRadius: 10,
    transition: 'border 0.5s ease',
    '&:hover': {
      border: '2px solid #009739',
    }
  },
  checked2: {
    backgroundColor: 'transparent',
    borderRadius: '50%',
    border: '2px solid #ccc',
  },
  icon2: {
    color: 'transparent',
    fontSize: 16,
  },
  switch: {
    position: 'aboslute',
    display: 'inline-block',
    width: '50px',
    height: '25px',
  },
  switchInput: {
    position: 'absolute',
    left: '-9999px',
    top: '-9999px',
    '&:checked + $slider': {
      backgroundColor: '#009739',
      '&::before': {
        left: '18px'
      }
    }
  },
  slider: {
    display: 'flex',
    cursor: 'pointer',
    width: '38px',
    height: '20px',
    backgroundColor: '#bfbfbf',
    position: 'relative',
    borderRadius: '100px',
    transition: 'backgroundColor 0.2s',
    '&::before': {
      position: 'absolute',
      content: '""',
      top: '2px',
      left: '4px',
      height: '15px',
      width: '15px',
      borderRadius: '45px',
      transition: '0.2s',
      backgroundColor: '#fff',
      boxShadow: '0 2px 4px 0 rgba(0, 35, 11, 0.2)',
    },
    '&:active::before': {
      width: '20px'
    }
  },
  title: {
    color: '#009739',
  },
  inputGroup: {
    margin: '15px',
    marginlef: '-50px',
    width: '80%',
    height: '20px',
    diplay: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  }
}));

const OptionPay = ({
  setInsurance,
  setCollect,
  collect,
  selectProduct,
  insurance
}) => {
  const classes = useStyles();
  const handleChange = (e) => {
    setCollect(e.target.value);
  };
  return (
    <>
      <Box
        className={classes.container}
      >
        <Typography
          className={classes.title}
        >
          El servicio se cobra en:
        </Typography>
        <Box
          className={classes.inputGroup}
          width="100%"
          height="70px"
          justifyContent="start"
          display="flex"
          alignItems="start"
          flexDirection="row"
          ml={9}
        >
          {['pick_up_point', 'delivery_point'].map((item) => (
            <>
              <label
                className={classes.customRadio2}
                htmlFor={item}
              >
                <input
                  type="radio"
                  name="pay"
                  id={item}
                  value={item}
                  onChange={handleChange}
                  checked={collect === item}
                />
                <Box
                  bgColor="blue"
                  className={classes.radioButton2}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  width="30%"
                  height={50}
                  pt={1}
                  px={1}
                  mt={2}
                  m={1}
                >
                  <Box
                    className={classes.checked2}
                    width={18}
                    height={18}
                    display="flex"
                    alignSelf="start"
                  >
                    <Check
                      className={classes.icon2}
                    />
                  </Box>
                  <Box
                    mt={-2.4}
                    display="flex"
                    flexDirection="row"
                    alignSelf="center"
                  >
                    <Typography
                      className={classes.title2}
                      variant="body2"
                    >
                      {item === 'pick_up_point' ? 'Recogida' : 'Entrega'}
                    </Typography>
                  </Box>
                </Box>
              </label>
            </>
          ))}
        </Box>
        <Box
          className={classes.title}
          display="flex"
          flexDirection="row"
          mt={3}
          fontWeight="00"
        >
          <Typography>
            Asegurar Producto (1%)
          </Typography>
          <Box
            ml={2}
          >
            <label
              className={classes.switch}
            >
              <input
                className={classes.switchInput}
                onChange={(e) => setInsurance(e.target.checked)}
                type="checkbox"
                disabled={selectProduct > 200000}
                checked={insurance && selectProduct <= 200000}
              />
              <span className={classes.slider} />
            </label>
          </Box>
        </Box>
      </Box>
    </>
  );
};

OptionPay.propTypes = {
  setInsurance: PropTypes.func,
  insurance: PropTypes.bool,
  setCollect: PropTypes.func,
  collect: PropTypes.string,
  selectProduct: PropTypes.number,
};

export default OptionPay;
