import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import LoadingBox from 'src/components/LoadingBox';
import useQueryParams from 'src/hooks/useQueryParams';
import ManagerCardPreview from './ManagerCardPreview';
import CreateManagerForm from './CreateManagerForm';
import ManagerService from '../../../services/ManagerService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateManagerView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [hasImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [manager, setManager] = useState({
    id: null,
    first_name: '',
    last_name: '',
    avatar: '',
    email: '',
    phone: '',
    password: '',
    is_order_taker: '',
    is_partner: '',
    is_marketing: '',
    is_photographer: ''
  });
  const queryParams = useQueryParams();
  const typeForm = queryParams.get('type');

  const inputRef = useRef();

  const handleManagerAttributes = (values) => {
    setManager({
      ...manager,
      ...values
    });
  };

  const handleUploadImage = () => inputRef.current.click();

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await ManagerService.getById(id);
        if (response?.code === 200) {
          setManager({
            ...response.data,
            id: response.data.id,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            avatar: response.data.avatar,
            password: response.data.password
          });
        }
      })();
    }
  }, [id]);

  return (
    <LoadingBox loading={loading}>
      <Page className={classes.root} title="Crear Gerente">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            {typeForm !== 'password' && (
              <Grid item lg={4} md={6} xs={12}>
                <ManagerCardPreview
                  ref={inputRef}
                  manager={manager}
                  hasImage={hasImage}
                  onUpload={handleUploadImage}
                />
              </Grid>
            )}
            <Grid item lg={8} md={6} xs={12}>
              <CreateManagerForm
                ref={inputRef}
                edit={edit}
                hasImage={hasImage}
                manager={manager}
                typeForm={typeForm}
                onChange={handleManagerAttributes}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </LoadingBox>
  );
};

CreateManagerView.propTypes = {
  edit: PropTypes.bool
};

CreateManagerView.defaultProps = {
  edit: false
};

export default CreateManagerView;
