import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Menu,
  MenuItem,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

// Services
import ProductComponentService from 'src/services/ProductComponentService';
import ProductService from 'src/services/ProductService';
import BranchOfficeService from 'src/services/BranchOfficeService';
import StockService from 'src/services/StockService';

// Redux
import { selector as UserSelector } from 'src/redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  root: {},
  moreIcon: {
    cursor: 'pointer',
    marginLeft: '1rem'
  },
  categoryButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, refresh, ...rest }) => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [anchorElement, setAnchorElement] = useState(null);
  const [dropdownMenuIsOpen, setDropdownMenuIsOpen] = useState(false);

  const toggleDropdownMenu = () => {
    setDropdownMenuIsOpen(!dropdownMenuIsOpen);
  };

  const openDropdownMenu = (event) => {
    setAnchorElement(event.currentTarget);
    toggleDropdownMenu();
  };

  const handleActiveOnlyComponents = async () => {
    if (user?.id) {
      await ProductComponentService.activeAllByBrand(user?.brand_id);
      toggleDropdownMenu();
      refresh();
    }
  };

  const handleActiveAll = async () => {
    if (user?.id) {
      const response = await BranchOfficeService.getAll();
      await ProductComponentService.activeAllByBrand(user?.brand_id);
      await ProductService.activeAllByBrand(user?.brand_id);

      if (response?.code === 200) {
        await response?.data?.map(async (branchOffice) => {
          if (branchOffice?.id) {
            await StockService?.activeAllByBranchOfficeId(branchOffice?.id);
          }
          return true;
        });

        toggleDropdownMenu();
        refresh();
      }
    }
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Link to="/app/componentes/crear">
          <Button
            color="primary"
            variant="contained"
          >
            Agregar componente
          </Button>
        </Link>
        <div>
          <MoreVertIcon className={classes?.moreIcon} onClick={openDropdownMenu} />
          <Menu
            id="simple-menu"
            anchorEl={anchorElement}
            keepMounted
            open={Boolean(dropdownMenuIsOpen)}
            onClose={toggleDropdownMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={handleActiveOnlyComponents}>Activar sólo componentes</MenuItem>
            <MenuItem onClick={handleActiveAll}>Activar productos y componentes</MenuItem>
          </Menu>
        </div>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Buscar componente"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  refresh: PropTypes.func,
};

Toolbar.defaultProps = {
  refresh: () => {}
};

export default Toolbar;
