import cookie from 'js-cookie';
import { createDuck } from 'redux-duck';
import { createStructuredSelector } from 'reselect';

const duck = createDuck('app/app');

// Define initial state
const initialState = {
  city: cookie.get('city')
};

// Define constants
export const GET_CITY = duck.defineType('GET_CITY');
export const SET_CITY = duck.defineType('SET_CITY');

// Define actions
export const actions = ({
  getCity: duck.createAction(GET_CITY),
  setCity: duck.createAction(SET_CITY),
});

// Define selector
export const selector = createStructuredSelector({
  globalCitySelected: (state) => state.app.city,
});

// Define reducer
export default duck.createReducer({
  [SET_CITY]: (state, { payload }) => {
    cookie.set('city', payload);
    return {
      ...state,
      city: payload
    };
  },
  [GET_CITY]: (state) => {
    return {
      ...state,
      city: cookie.get('city')
    };
  },
}, initialState);
