import React, { useState, useRef } from 'react'
import CustomBrandService from 'src/services/CustomBrandService';
import { PropTypes } from 'prop-types';
import Modal from 'src/components/Modal';
import useModal from 'src/hooks/useModal';
import useErrors from 'src/hooks/useErrors';
import clsx from 'clsx';
import {
    Box,
    Button,
    Typography,
    makeStyles,
    Card,
    TextField,
    FormControl,
    FormHelperText,
    Avatar,
    CircularProgress,
} from '@material-ui/core';
import {
  Edit,
  Delete,
} from '@material-ui/icons';
import BrandLogo from './BrandILogo';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
  },
  card: {
    width: '100%',
    padding: '20px',
    display: 'flex',
  },
  avatar: {
    borderRadius: '10px',
    width: 'auto',
    height: 'auto'
  },
  icon: {
    cursor: 'pointer',
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    padding: '2px',
    borderRadius: '50%',
    marginRight: '8px'
  },
  btn: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#2e7d32'
    },
    transition: 'background 0.2s ease 0s'
  }
}));

const Brand = ({data, refresh, ...rest}) => {
  const classes = useStyles();
  const { setErrors } = useErrors();
  const [isOpen, toggle] = useModal();
  const [localError, setLocalError] = useState([])
  const [isDelete, setIsDelete] = useState(false);
  const inputLogo = useRef(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  
  const handleSuccess = () => {
    setErrors([]);
    setLocalError([]);
    refresh();
    toggle();
    setLoading(false);
  };
  
  const handleEdit = () => {
    setIsDelete(false);
    toggle();
  };

  const handleDelete = () => {
    setIsDelete(true);
    toggle();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name } = e.target;
    const errors = [];
    const update = {
      name: name.value
    };
    if (name.value.trim().length === 0) {
      errors.push({
        field: 'name',
        error: 'El nombre de la marca es obligatoria'
      });
    }
    if (errors.length > 0) {
      setErrors(errors.map((err) => err.error));
      setLocalError(errors);
    } else {
      setLoading(true);
    }
    const response = await CustomBrandService.update(data?.id, update);
    if (response?.code === 201 || response?.code === 200) {
      if (response?.data?.logo !== null && file !== undefined && file !== null) {
        await CustomBrandService.deleteLogo(response?.data[0]?.id);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response?.data[0]?.id);
       
        const responsee= await CustomBrandService.uploadLogo(formData);

        if (responsee?.code === 200 || responsee?.code === 201) {
          setTimeout(() => {
            handleSuccess();
          }, 2000);
        }
      } else if (response?.data?.logo === null && file !== undefined && file !== null) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response?.data?.id);
        
        const responsee = await CustomBrandService.uploadLogo(formData);

        if (responsee?.code === 200 || responsee?.code === 201) {
          setTimeout(() => {
            handleSuccess();
          }, 2000);
        }
      } else {
        handleSuccess();
      }
    } else {
      setErrors(response?.errors);
    }
  };
  
  const handleUploadLogo = () => {
    if (inputLogo.current) {
      inputLogo.current.click();
    }
    
  };
  const deleteBrand = async () => {
    const response = await CustomBrandService.remove(data?.id);
    if (response.code === 200) {
      toggle();
      refresh();
    }
  };
 
  return (
    <Card className={classes.card} {...rest}>
      <Box className={classes.container}>
        <Box display="flex" flexDirection="row" justifyContent="start" alignItems="center">
          <Box width="90px" height="90px" className={classes.logo} mr={3}>
            <Avatar
              className={classes.avatar}
              alt="product"
              src={data?.logo}
              variant="square"
            />
          </Box>
              
          <Box>
            <Typography variant="h4">
              {data?.name}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" flexDirection="row">
        <Box
          className={clsx(classes.icon, classes.btn)}
          onClick={() => handleEdit()}
        >
          <Edit
            style={{ color: '#fff', padding: '2px' }}
            fontSize="small"
          />
        </Box>
        <Box
          className={clsx(classes.icon, classes.btn)}
          onClick={() => handleDelete()}
        >
          <Delete p={2} style={{ color: '#fff' }} fontSize="small" />
        </Box>
      </Box>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        title={isDelete ? 'Eliminar' : 'Editar'}
      >
        {isDelete ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h4">
              ¿Esta seguro de eliminar esta marca?
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '0.3rem' }}
                onClick={() => deleteBrand()}
              >
                Eliminar
              </Button>
            </Box>
          </Box>
        ) : (
          <form onSubmit={(e) => handleSubmit(e)}>
            <FormControl
              required
              variant="outlined"
              error={localError.some((e) => e.field === 'name')}
            >
              <Box>
                <Box maxWidth="250px" margin="auto" mb={2}>
                  <BrandLogo
                    ref={inputLogo}
                    brand={data}
                    hasImage={false}
                    onUpload={handleUploadLogo}
                    setFile={setFile}
                  />
                </Box>
                <TextField
                  fullWidth
                  label="Nombre de la marca"
                  name="name"
                  variant="outlined"
                  defaultValue={data.name}
                />
                <Box mt={2}>
                  <Button disabled={loading} variant="contained" color="primary" type="submit" startIcon={loading ? <CircularProgress size={20} /> : ''}>
                    Editar
                  </Button>
                </Box>
                  
              </Box>
              {localError.some((e) => e.field === 'name') && (
                <FormHelperText error>
                  {localError?.find((e) => e.field === 'name')?.error || ''}
                </FormHelperText>
              )}
            </FormControl>
          </form>
        )}

      </Modal>
    </Card>
  )
}
Brand.propTypes = {
    data: PropTypes.array,
    refresh: PropTypes.func,
  };

export default Brand