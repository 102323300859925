import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';
import BranchOfficeService from 'src/services/BranchOfficeService';
import LoadingBox from 'src/components/LoadingBox';
import FormBranchOffice from '../../components/FormBranchOffice';
import NavBar from './NavBar';
import { selector as UserSelector } from '../../redux/ducks/user';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  content: {
    flexGrow: 1,
    width: '100%',
    marginTop: 64,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('lg')]: {
      marginLeft: '-256px'
    },
    overflow: 'auto'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}));

const DashboardLayout = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [createBranchOffice, setCreateBranchOffice] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user, token } = useSelector(UserSelector);

  const haveBranchOffice = async () => {
    const response = await BranchOfficeService.getByPartner(user?.id);
    setCreateBranchOffice(response.code === 404);
    setLoading(false);
    if (
      response.code !== 404 &&
      response.code !== 200 &&
      response.code !== 201
    ) {
      console.error('Hubo un error al cargar la sucursal', response.errors);
      navigate('/auth/login-dashboard', { replace: true });
    }
  };

  const toggleCreateBranchOffice = () => {
    setCreateBranchOffice((prev) => !prev);
  };

  useEffect(() => {
    if (!token) navigate('/auth', { replace: true });
  }, []);

  useEffect(() => {
    if (user?.id) {
      if (user?.logistics) haveBranchOffice();
      setLoading(false);
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <LoadingBox loading={loading} bgcolor="transparent">
        {createBranchOffice ? (
          <FormBranchOffice
            isFirst
            toggleCreateBranchOffice={toggleCreateBranchOffice}
          />
        ) : (
          <Box display="flex" width="100%">
            <TopBar onMobileNavOpen={setMobileNavOpen} open={isMobileNavOpen} />
            <NavBar
              onMobileClose={setMobileNavOpen}
              openMobile={isMobileNavOpen}
            />
            <Box
              className={clsx(classes.content, {
                [classes.contentShift]: !isMobileNavOpen
              })}
              bgcolor="#fff"
            >
              <Outlet />
            </Box>
          </Box>
        )}
      </LoadingBox>
    </div>
  );
};

export default DashboardLayout;
