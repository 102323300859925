import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';
import {
  TextField,
  Grid,
  Box,
  Button,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { Info, CancelRounded } from '@material-ui/icons';
import useErrors from 'src/hooks/useErrors';
import './AnimationModal.css';
import useConfirmation from 'src/hooks/useConfirmation';
import ToppingService from 'src/services/ToppingService';
import CurrencyFormat from 'react-currency-format';

const CreateItemModal = ({
  topping,
  toggleCreateItem,
  edit,
  item,
  indexItem,
  onRefresh
}) => {
  const { toggleModal } = useConfirmation();
  const matchesSm = useMediaQuery('screen and (min-width: 600px)');
  const matches = useMediaQuery('screen and (min-width: 769px)');
  const [newItem, setNewItem] = useState({
    name: '',
    price: 0,
    min: 0,
    max: 0,
    value: false,
    component_id: [],
    options: [],
    id: ''
  });
  const { setErrors } = useErrors();
  const [localErrors, setLocalErrors] = useState([]);

  const useStyles = makeStyles(() => ({
    modal: {
      border: '1px solid #e6ebf1',
      borderRadius: '8px',
      width: !matches ? '100%' : '600px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      bottom: '0',
      animation: 'showPopUp 0.5s',
      position: !matches ? 'absolute' : 'relative',
    },
    CreateCustomizationModal: {
      backgroundColor: 'rgba(46, 44, 54, 0.2)',
      position: 'fixed',
      top: 0,
      right: 0,
      height: '100%',
      bottom: '0',
      width: '100%',
      zIndex: '3',
      display: matches ? 'flex' : null,
      justifyContent: matches ? 'center' : null,
      alignItems: matches ? 'center' : null,
    },
    pane: {
      height: '100%',
      width: '100%',
      position: 'fixed'
    },
    header: {
      padding: '10px 15px',
      borderBottom: '1px solid #e6ebf1',
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > h3': {
        fontFamily: '"Roboto", Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        fontSize: '22px'
      }
    },
    body: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: matchesSm ? '30px' : '15px',
      overflow: 'auto',
      height: '100%',
    },
    btn: {
      width: '125px',
      fontSize: '13px',
    },
    description: {
      marginLeft: '6px',
      fontSize: '13px',
      color: '#43a047'
    },
  }));
  const classes = useStyles();

  const handleItem = (e) => {
    setNewItem({
      ...newItem,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      name: e.target.name.value,
      price: e.target.price.value ? Number((e.target.price.value).replace('.', '')) : 0,
      min: 0,
      max: e.target?.max === undefined ? 0 : Number(e.target?.max?.value),
      value: edit ? newItem.value : false,
      component_id: [],
      options: [],
      id: edit ? newItem.id : uuid4()
    };

    if (data?.name.length === 0) {
      errors.push({ field: 'name', error: 'El nombre es obligatorio' });
    }

    if (topping.type === 'quantity' && data?.max === 0) {
      errors.push({ field: 'max', error: 'La cantidad maxima es obligatoria' });
    }

    if (data?.max > topping.max_items && topping.max_items !== 0) {
      errors.push({ field: 'max', error: 'La cantidad maxima no puede ser mayor al maximo de opciones seleccionables' });
    }

    if (errors.length > 0) {
      setErrors(errors.map((err) => err.error));
      setLocalErrors(errors);
      return;
    }

    if (topping?.type === 'quantity') data.value = 0;

    try {
      const items = topping.items.slice();
      if (edit) {
        items[indexItem] = data;
      } else {
        items.push(data);
      }

      const response = await ToppingService.update(topping.id, { ...topping, items });
      if (response?.code === 200 || response?.code === 201) {
        setErrors([]);
        onRefresh();
        toggleCreateItem(false);
      } else {
        setErrors(Array.isArray(response.errors) ? response.errors : []);
      }
    } catch (err) {
      setErrors([err.message]);
    }
  };

  useEffect(() => {
    if (edit) setNewItem(item);
  }, []);

  return (
    <div className={classes.CreateCustomizationModal}>
      <div className={classes.pane} onClick={() => toggleCreateItem(false)} />
      <div className={classes.modal}>
        <div className={classes.header}>
          <div className={classes.title}>
            <h3>
              {!edit ? 'Agregar ' : 'Editar '}
              opción
            </h3>
            <CancelRounded style={{ cursor: 'pointer' }} color="primary" onClick={() => toggleCreateItem(false)} />
          </div>
        </div>
        <div className={classes.body}>
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            style={{ width: '100%' }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  value={newItem.name}
                  onChange={handleItem}
                  error={localErrors.some((e) => e.field === 'name')}
                  helperText={localErrors.find((e) => e.field === 'name')?.error || ''}
                  placeholder="Escriba el nombre"
                  label="Nombre"
                  name="name"
                  variant="outlined"
                  type="text"
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <CurrencyFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  customInput={TextField}
                  fullWidth
                  placeholder="Escriba el precio adicional"
                  label="Precio adicional"
                  name="price"
                  variant="outlined"
                  type="text"
                  value={`${newItem?.price}`}
                  onChange={handleItem}
                  required
                />
              </Grid>
              {topping.type === 'quantity' && (
                <Grid
                  item
                  xs={12}
                >
                  <Box mb={2.6} display="flex" alignItems="center">
                    <Info style={{ fontSize: '22px' }} color="primary" />
                    <Typography className={classes.description}>
                      El maximo de opciones seleccionables del tooping es de
                      {` ${topping.max_items}`}
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    error={localErrors.some((e) => e.field === 'max')}
                    helperText={localErrors.find((e) => e.field === 'max')?.error || ''}
                    placeholder="Escriba la cantidad maxima del item"
                    label="Cantidad maxima"
                    name="max"
                    variant="outlined"
                    type="number"
                    value={`${newItem.max}`}
                    onChange={handleItem}
                    required
                  />
                </Grid>
              )}
            </Grid>
            <Box
              display="flex"
              justifyContent="center"
              paddingTop={3}
            >
              {edit && (
                <Box mr={2}>
                  <Button
                    className={classes.btn}
                    variant="contained"
                    onClick={() => toggleModal()}
                  >
                    Eliminar
                  </Button>
                </Box>
              )}
              <Button
                className={classes.btn}
                type="submit"
                color="primary"
                variant="contained"
              >
                {!edit ? 'Guardar' : 'Editar'}
              </Button>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
};

CreateItemModal.propTypes = {
  topping: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    is_required: PropTypes.bool,
    max_items: PropTypes.number,
    order: PropTypes.number,
    items: PropTypes.array,
  }),
  edit: PropTypes.bool,
  item: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.bool,
    component_id: PropTypes.array,
    options: PropTypes.array,
    id: PropTypes.string
  }),
  indexItem: PropTypes.number,
  toggleCreateItem: PropTypes.func,
  onRefresh: PropTypes.func
};

export default CreateItemModal;
