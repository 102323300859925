import { useState } from 'react';

const useSearch = ({ keys }) => {
  const [dataFilterd, setDataFiltered] = useState();
  const [allData, setAllData] = useState();

  const handleInitialData = (initialData) => {
    setDataFiltered(initialData || []);
    setAllData(initialData || []);
  };

  const handleSearch = (search) => {
    if (search === '') {
      setDataFiltered(allData);
      return;
    }
    const expr = new RegExp(search, 'gi');
    const keysArr = keys.map((val) => val.split('.'));
    const filtered = allData.filter((item) => {
      const exprArr = keysArr.map((keysItem) => {
        if (keysItem.length === 1) {
          return ((expr.test(item?.[keysItem[0]])));
        }
        const [key1, key2] = keysItem;
        return ((expr.test(item?.[key1][key2])));
      });
      return (exprArr.some((val) => val === true));
    });
    setDataFiltered([...filtered]);
  };

  return {
    dataFilterd,
    handleSearch,
    handleInitialData,
  };
};

export default useSearch;
