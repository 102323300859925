import React, { useEffect, useState } from 'react';
import orderBy from 'src/utils/arrays';
import { Box } from '@material-ui/core';
import OrderService from 'src/services/OrderService';
import Results from './Results';

const ManagerReportView = () => {
  const [finishedOrders, setFinishedOrders] = useState(null);
  const [cancelledOrders, setCancelledOrders] = useState(null);
  const [rejectedOrders, setRejectedOrders] = useState(null);
  const [filter, setFilter] = useState('finished');
  const [orders, setOrders] = useState(null);

  const getOrderStatus = async (status, date) => {
    const response = await OrderService.getOrdersReport(status, date);
    if (response.success) {
      switch (status) {
        case 'finished':
          setFinishedOrders(orderBy(response?.data?.orders, 'reference', 'high'));
          break;
        case 'cancelled':
          setCancelledOrders(orderBy(response?.data?.orders, 'reference', 'high'));
          break;
        case 'rejected':
          setRejectedOrders(orderBy(response?.data?.orders, 'reference', 'high'));
          break;
        default:
          break;
      }
    }
  };

  const getOrders = (date) => {
    getOrderStatus('finished', date);
    getOrderStatus('cancelled', date);
    getOrderStatus('rejected', date);
  };

  const handleFilter = (status) => {
    setFilter(status);
    switch (status) {
      case 'finished':
        setOrders(finishedOrders);
        break;
      case 'cancelled':
        setOrders(cancelledOrders);
        break;
      case 'rejected':
        setOrders(rejectedOrders);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleFilter(filter);
  }, [finishedOrders, cancelledOrders, rejectedOrders]);

  return (
    <Box display="flex" height="100%" width="100%">
      <Results
        numberStatus={{
          finished: finishedOrders?.length || 0,
          cancelled: cancelledOrders?.length || 0,
          rejected: rejectedOrders?.length || 0,
        }}
        getOrders={getOrders}
        orders={orders}
        finishedOrders={finishedOrders}
        handleFilter={handleFilter}
        filter={filter}
      />
    </Box>

  );
};

export default ManagerReportView;
