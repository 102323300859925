/**
 * Esta función crea y retorna el formato necesario para poder subir una imagen a base de datos.
 * En caso de que la imagen no tenga una escala, entonces solo enviar por parametros id y file.
 * @param {String} id: id del modelo que se le desea asociar la imagen (brand, product, branch_office, etc.).
 * @param {File} File file: objeto de la imagen a subir.
 * @param {String} scale: (Opcional) escala de la imagen (1:1, 3:4 o 5:3).
 * @return {FormData} Me retorna el formato necesario para poder subir una imagen a base de datos.
 */
const createImage = (id, file, scale = '') => {
  if (!file) return null;
  const formData = new FormData();
  formData.append('id', id);
  formData.append('file', file);
  if (scale.length > 0) formData.append('img_scale', scale);
  return formData;
};

/**
 *Esta función hace y recibe exactamente lo mismo que la función anterior, con la diferencia
 *de que esta me permite subir más de una imagen.
 * @param {String} id: id del modelo que se le desea asociar la imagen (brand, product, branch_office, etc.).
 * @param {Array<File>} files: array de objetos de las imagenes a subir de tipo File.
 * @param {Array<String>} scales: (Opcional) array de strings con las escalas de las imagenes (1:1, 3:4 o 5:3).
 * @return {FormData} Me retorna el formato necesario para poder subir una imagen a base de datos.
 */

const createImages = (id, files, scales = []) => {
  if (!files || files?.length === 0) return null;
  const formData = new FormData();
  formData.append('id', id);
  files.forEach((file) => {
    formData.append('file', file);
  });
  if (scales.length > 0) {
    scales.forEach((scale, index) => {
      formData.append(`img_scales[${index}]`, scale);
    });
  }
  return formData;
};

export { createImage, createImages };
