import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: 'adomi-dev.firebaseapp.com',
  databaseURL: 'https://adomi-dev-default-rtdb.firebaseio.com',
  projectId: 'adomi-dev',
  storageBucket: 'adomi-dev.appspot.com',
  messagingSenderId: '279402643897',
  appId: '1:279402643897:web:491abe66ef16c080853668',
  measurementId: 'G-53C2S6NYGY'
};

// Initialize Firebase
const FirebaseApp = initializeApp(firebaseConfig);

export default FirebaseApp;
