import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles, Button
} from '@material-ui/core';
import {
  CheckCircle as ActiveIcon,
  HighlightOff as InactiveIcon,
} from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2';
import DriverService from '../../../services/DriverService';

const useStyles = makeStyles((theme) => ({
  root: {},
  moreVert: {
    cursor: 'pointer'
  },
  active: {
    color: 'green'
  },
  inactive: {
    color: 'red'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main,
  },
  false: {
    fill: '#aaa'
  }
}));

const Results = ({
  className, data, onRefresh, onUnlinkFromBranchOffice, ...rest
}) => {
  const classes = useStyles();
  const [drivers, setDrivers] = useState([]);

  const alertToActivate = async (id, firstName, lastName) => {
    await DriverService.alertToActivate(id);
    await Swal.fire({
      title: 'MENSAJE ENVIADO',
      text: `La alerta fue enviada al WhatsApp del repartidor ${firstName} ${lastName}`,
      icon: 'success',
      button: 'Aceptar'
    });
  };

  useEffect(() => {
    if (Array.isArray(data)) {
      setDrivers(data);
    }
  }, [data]);

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Nombre
                  </TableCell>
                  <TableCell>
                    Teléfono
                  </TableCell>
                  <TableCell>
                    WhatsApp
                  </TableCell>
                  <TableCell>
                    Correo
                  </TableCell>
                  <TableCell>
                    Ciudad
                  </TableCell>
                  <TableCell>
                    Activo
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {drivers.map((driver) => (
                  <TableRow
                    hover
                    key={driver?.id}
                  >
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                      >
                        <Avatar
                          className={classes.avatar}
                          src={driver?.avatar}
                        />
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          {driver?.first_name}
                          <br />
                          {driver?.last_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {driver?.phone || 'Sin registrar'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {driver?.whatsapp || 'Sin registrar'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {driver?.email || 'Sin registrar'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {driver?.city?.name || 'Ninguna'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {
                        driver?.is_free
                          ? (
                            <ActiveIcon className={classes.active} />
                          ) : (
                            <InactiveIcon className={classes.inactive} />
                          )
                      }
                    </TableCell>
                    <TableCell>
                      <Button
                        disabled={driver?.is_free}
                        size="small"
                        color="default"
                        variant="contained"
                        onClick={
                          () => alertToActivate(driver?.id, driver?.first_name, driver?.last_name)
                        }
                      >
                        Alertar
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        color="default"
                        variant="contained"
                        onClick={
                          () => onUnlinkFromBranchOffice(driver?.id,
                            driver?.first_name, driver?.last_name)
                        }
                      >
                        Desvincular
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  onRefresh: PropTypes.func,
  onUnlinkFromBranchOffice: PropTypes.func
};

export default Results;
