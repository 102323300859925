import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Typography,
  makeStyles
} from '@material-ui/core';
import { MonetizationOn } from '@material-ui/icons';
import { TbRoad } from 'react-icons/tb';
import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles(() => ({
  root: {
    width: '95%',
    display: 'flex',
    alignItems: 'center',
  },
  column: {
    width: 'fit-content',
    flexDirection: 'column'
  },
  card: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  bgIcon: {
    backgroundColor: 'rgba(67, 160, 71, 0.3)',
    borderRadius: '50%'
  },
  totalCard: {
    width: '100%',
    padding: '35px 0',
    display: 'flex',
    justifyContent: 'center'
  }
}));

const OrderCost = ({
  distance,
  deliveryCost,
  className,
  column
}) => {
  const classes = useStyles();
  return (
    <Card
      className={clsx(
        classes.root,
        className,
        {
          [classes.column]: column
        }
      )}
    >
      <Box
        className={classes.card}
        py={2}
        px={column ? 1.5 : 2}
      >
        <Box display="flex" flexDirection={column ? 'column' : 'row'} alignItems="center">
          <Box width="35px" height="35px" display="flex" justifyContent="center" alignItems="center" className={classes.bgIcon}>
            <TbRoad style={{ fontSize: 25, color: '#009379' }} />
          </Box>
          <Box
            ml={column ? 0 : 1.5}
            mt={column ? 0.7 : 0}
            textAlign="center"
          >
            <Typography style={{ fontSize: 16, fontWeight: 700, }} color="primary" variant="body2">
              <CurrencyFormat
                value={distance || 0}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
              />
              km
            </Typography>
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 400,
                color: '#959595',
                textAlign: 'center'
              }}
              variant="body2"
            >
              Distancia del servicio
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        width={column ? 37 : 3}
        height={column ? 3 : 37}
        bgcolor="#B5B5B5"
      />
      <Box
        py={2}
        px={column ? 1 : 2}
        className={classes.card}
      >
        <Box display="flex" flexDirection={column ? 'column' : 'row'} alignItems="center">
          <Box width="35px" height="35px" display="flex" justifyContent="center" alignItems="center" className={classes.bgIcon}>
            <MonetizationOn style={{ fontSize: 25 }} color="primary" />
          </Box>
          <Box
            ml={column ? 0 : 1.5}
            mt={column ? 0.7 : 0}
            textAlign="center"
          >
            <Typography style={{ fontSize: 16, fontWeight: 700, }} color="primary" variant="body2">
              $
              <CurrencyFormat
                value={deliveryCost || 0}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
              />
            </Typography>
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 400,
                color: '#959595',
                textAlign: 'center'
              }}
              variant="body2"
            >
              Valor del servicio
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

OrderCost.propTypes = {
  distance: PropTypes.number,
  deliveryCost: PropTypes.number,
  className: PropTypes.string,
  column: PropTypes.bool
};

OrderCost.defaultProps = {
  distance: 0,
  deliveryCost: 0,
  className: '',
  column: false
};

export default OrderCost;
