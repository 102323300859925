import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  circle: {
    width: 60,
    height: 60,
    borderRadius: '50%',
    zIndex: 1,
    position: 'relative',
    background: '#FFF',
    transform: 'scale(1)',
    animation: '$success-anim 0.7s ease',
  },
  circleBorder: {
    width: 60,
    height: 60,
    borderRadius: '50%',
    zIndex: 0,
    position: 'absolute',
    transform: 'scale(1.1)',
    animation: '$circle-anim 0.4s ease',
    backgroundColor: theme.palette.error.main
  },
  error: {
    '&::before,&::after': {
      content: '""',
      display: 'block',
      height: 4,
      backgroundColor: theme.palette.error.main,
      position: 'absolute',
    },
    '&::before': {
      width: 40,
      top: '48%',
      left: '16%',
      transform: 'rotateZ(50deg)'
    },
    '&::after': {
      width: 40,
      top: '48%',
      left: '16%',
      transform: 'rotateZ(-50deg)'
    }
  },
  '@keyframes success-anim': {
    '0%': {
      transform: 'scale(0)'
    },
    '30%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(1)'
    }
  },
  '@keyframes circle-anim': {
    '0%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(1.1)'
    }
  }
}));

const Error = ({
  text
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box className={classes.circleBorder} />
      <Box className={classes.circle}>
        <Box className={classes.error} />
      </Box>
      <Typography
        variant="body2"
        color="error"
        style={{
          fontSize: 17,
          fontWeight: 500,
          marginTop: 10
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

Error.propTypes = {
  text: PropTypes.string
};

export default Error;
