import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Button,
  Card,
  Avatar,
  TextField,
  makeStyles,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
  TextareaAutosize
} from '@material-ui/core';
import { Room } from '@material-ui/icons';
import BranchOfficeService from 'src/services/BranchOfficeService';
import CityService from 'src/services/CityService';
import Page from 'src/components/Page';
import BrandService from 'src/services/BrandService';
import AddressModal from 'src/components/AddressModal';
import useErrors from 'src/hooks/useErrors';
import { selector as UserSelector } from '../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f6fbf4',
  },
  form: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    width: '100%',
  },
  cardForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;',
  },
  avatar: {
    width: 70,
    height: 70
  },
  buttonAvatar: {
    borderRadius: '50%',
    minWidth: 30,
    padding: 7,
  },
  title: {
    marginTop: 5,
    fontSize: 15,
    fontWeight: 500,
    textAlign: 'center'
  },
  textAddr: {
    fontWeight: 500,
    fontSize: 14,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  textArea: {
    width: '100%',
    borderRadius: 5,
    border: '1px solid #ced4da',
    resize: 'none',
    padding: 10,
    '&:focus': {
      outline: 'solid 1px #000',
    }
  }
}));

const FormBranchOffice = ({
  toggleCreateBranchOffice,
  isFirst
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [brand, setBrand] = useState(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [branchOffice, setBranchOffice] = useState({
    name: '',
    phone1: '',
    city_id: '',
    instructions: ''
  });
  const { setErrors } = useErrors();
  const [address, setAddress] = useState('');
  const [localErrors, setLocalErrors] = useState([]);
  const [positionAddress, setPositionAddress] = useState({ lat: 0, lng: 0 });
  const [cities, setCities] = useState([]);

  const goToEdit = () => {
    navigate('/app/sucursales');
  };

  const toggleAddressModal = () => {
    setShowAddressModal((prev) => !prev);
  };
  const confirmAddress = (newAddress, position) => {
    setAddress(newAddress);
    setPositionAddress(position);
    toggleAddressModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      name: branchOffice?.name,
      phone1: branchOffice?.phone1,
      city_id: branchOffice?.city_id,
      address,
      latitude: positionAddress?.lat,
      longitude: positionAddress?.lng,
      currency_id: 'cf6fe1d0-08ff-4c6b-9315-e49f56a88911',
      email: user?.email,
      whatsapp1: branchOffice?.phone1,
      assistance_cost: 0,
      transaction_cost: 0,
      base_km: 0,
      base_cost: 0,
      cost_additional_km: 0,
      max_sell_amount: 0,
      min_sell_amount: 0,
      min_sell_free_delivery: 0,
      tip_driver_adomi: 0,
      tip_driver_partner: 0,
      tip_driver_friend: 0,
      maximum_orders: 0,
      order: 0,
      logistics: true,
      instructions: branchOffice.instructions
    };

    if (data.name.length === 0) {
      errors.push({ field: 'name', error: 'El nombre de la sucursal es obligatorio' });
    }
    if (data.phone1.length === 0) {
      errors.push({ field: 'phone1', error: 'El telefono de la sucursal es obligatorio' });
    }
    if (data.city_id.length === 0) {
      errors.push({ field: 'city_id', error: 'Debe seleccionar una ciudad' });
    }
    if (data.address.length === 0) {
      errors.push({ field: 'address', error: 'La dirección de la sucursal es obligatoria' });
    }

    if (errors.length > 0) {
      setErrors(errors.map((err) => err.error));
      setLocalErrors(errors);
      return;
    }
    setIsSubmitting(true);
    const response = await BranchOfficeService.createPublic(data);
    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      if (isFirst) {
        toggleCreateBranchOffice();
      } else {
        goToEdit();
      }
    } else {
      setErrors(Array.isArray(response.errors) ? response.errors : []);
    }
    setIsSubmitting(false);
  };

  const handleChange = (e) => {
    setBranchOffice({
      ...branchOffice,
      [e.target.name]: e.target.value,
    });
  };

  const getBrandImage = async () => {
    const response = await BrandService.getById(user?.brand_id);
    if (response.code === 200) {
      setBrand(response?.data);
    }
  };

  const getCities = async () => {
    const response = await CityService.getAll();
    if (response?.code !== 200) {
      console.error('Ocurrió un error al intentar mostrar las ciudades');
      return;
    }
    setCities(response.data);
  };

  useEffect(() => {
    getBrandImage();
    getCities();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Crear sucursal"
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        height="fit-content"
        py={3}
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Box
            mb={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {
              isFirst ? (
                <>
                  <Typography
                    color="primary"
                    variant="h1"
                    style={{ textAlign: 'center' }}
                  >
                    ¡Te damos la bienvenida aliado!
                  </Typography>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="body2"
                    style={{
                      marginTop: 15,
                      textAlign: 'center',
                      fontSize: 15
                    }}
                  >
                    Antes de empezar a usar el dashboard, crea tu primera sucursal
                  </Typography>
                </>
              ) : (
                <Typography
                  color="primary"
                  variant="h1"
                  style={{ textAlign: 'center' }}
                >
                  Crea una nueva sucursal
                </Typography>
              )
            }
            <form
              className={classes.form}
              onSubmit={handleSubmit}
            >
              <Card
                className={classes.cardForm}
              >
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    mr={3}
                  >
                    <Avatar
                      className={classes.avatar}
                      alt="brand"
                      src={brand?.logo || ''}
                    />
                    <Typography
                      variant="body2"
                      className={classes.title}
                    >
                      {brand?.name}
                    </Typography>
                  </Box>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                    >
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={localErrors.some((e) => e.field === 'name')}
                        helperText={localErrors?.find((e) => e.field === 'name')?.error || ''}
                        fullWidth
                        label="Nombre"
                        name="name"
                        onChange={handleChange}
                        value={branchOffice.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                    >
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={localErrors.some((e) => e.field === 'phone1')}
                        helperText={localErrors?.find((e) => e.field === 'phone1')?.error || ''}
                        fullWidth
                        label="Teléfono"
                        name="phone1"
                        onChange={handleChange}
                        value={branchOffice.phone1}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Box
                        display="flex"
                        justifyContent="end"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{ cursor: 'pointer' }}
                          onClick={() => toggleAddressModal()}
                        >
                          <Room color="primary" />
                          <Typography
                            color="primary"
                            variant="body2"
                            className={classes.textAddr}
                          >
                            Seleccionar una dirección
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                    >
                      <FormControl
                        error={localErrors.some((e) => e.field === 'city_id')}
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="city">Seleccione una ciudad</InputLabel>
                        <Select
                          name="city_id"
                          labelId="ciudad"
                          label="Selecciona una ciudad"
                          value={branchOffice.city_id}
                          onChange={handleChange}
                        >
                          {
                            cities.map((city) => (
                              <MenuItem key={city.id} value={city.id}>
                                {city.name}
                              </MenuItem>
                            ))
                          }
                        </Select>
                        {localErrors.some((e) => e.field === 'city_id') && (
                          <FormHelperText error>
                            {localErrors?.find((e) => e.field === 'city_id')?.error || ''}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                    >
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled
                        error={localErrors.some((e) => e.field === 'address')}
                        helperText={localErrors?.find((e) => e.field === 'address')?.error || ''}
                        fullWidth
                        label="Dirección"
                        name="address"
                        onChange={handleChange}
                        value={address}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                    >
                      <TextareaAutosize
                        className={classes.textArea}
                        minRows={2}
                        aria-label="minimum height"
                        placeholder="¿Alguna instrucción o detalle de llegada?"
                        // value={}
                        name="instructions"
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Card>
              <Box
                display="flex"
                justifyContent="center"
              >
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ width: 300 }}
                  startIcon={isSubmitting ? <CircularProgress size={25} /> : ''}
                >
                  Crear sucursal
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
      {showAddressModal && (
        <AddressModal
          toggleAddressModal={toggleAddressModal}
          confirmAddress={confirmAddress}
          address={address}
          positionAddress={positionAddress}
          confirmation
        />
      )}
    </Page>
  );
};

FormBranchOffice.propTypes = {
  toggleCreateBranchOffice: PropTypes.func,
  isFirst: PropTypes.bool,
};

FormBranchOffice.defaultProps = {
  toggleCreateBranchOffice: () => {},
  isFirst: false,
};

export default FormBranchOffice;
