import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  makeStyles,
  Typography
} from '@material-ui/core';
import { BlockRounded, CheckRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'capitalize',
    borderRadius: 8,
    width: 85,
    fontWeight: 700,
    fontSize: '12px'
  },
  buttonReject: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  },
  buttonLocal: {
    backgroundColor: '#fec816',
    '&:hover': {
      backgroundColor: '#cfa313',
      color: 'white',
    }
  },
  buttonFinished: {
    borderRadius: 5,
    fontSize: '13px',
    width: '100%',
  },
  buttonProgressRed: {
    backgroundColor: 'transparent',
    color: '#f44336',
    border: '2px solid #f44336',
    transition: 'all 0.5s ease'
  },
  buttonProgressGreen: {
    backgroundColor: 'transparent',
    color: '#43a047',
    border: '2px solid #43a047',
    transition: 'all 0.5s ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    }
  }
}));

const ButtonStatus = ({
  className,
  stylesButtons,
  setIsHoverPane,
  status,
  setOrder,
  setStatus,
  updateStatus,
  idOrder,
  deliveryType,
}) => {
  const classes = useStyles();

  const changeStatus = () => {
    if (status === 'created') setStatus('in_progress', true);
    else if (status === 'in_progress') setStatus('pending_to_be_sent', true);
    else if (status === 'pending_to_be_sent') {
      if (deliveryType === 'adomi') setStatus('sent', true);
      else if (deliveryType === 'local') setStatus('sent_local', true);
    } else if (status === 'sent' || status === 'sent_local') setStatus('finished', true);
  };

  const eventsFirstBtn = () => {
    updateStatus('rejected', idOrder);
  };

  const eventsSecondBtn = () => {
    setOrder();
    changeStatus();
  };

  return (
    <Box className={className}>
      {(status === 'created' || status === 'in_progress') && (
        <Button
          onMouseEnter={() => setIsHoverPane(false)}
          onMouseLeave={() => setIsHoverPane(true)}
          onClick={() => eventsFirstBtn()}
          variant="contained"
          className={clsx(
            classes.button,
            stylesButtons, {
              [classes.buttonReject]: status !== 'pending_to_be_sent',
              [classes.buttonProgressRed]: status === 'in_progress'
            }
          )}
        >
          <Box>
            <BlockRounded style={{ fontSize: 23 }} color="#fff" />
            <Typography style={{ fontSize: 13, fontWeight: 600 }} variant="body2">Rechazar</Typography>
          </Box>
        </Button>
      )}
      {(status === 'created' || status === 'in_progress' || (status === 'pending_to_be_sent' && deliveryType === 'local') || status === 'sent' || status === 'sent_local') && (
        <Button
          onMouseEnter={() => setIsHoverPane(false)}
          onMouseLeave={() => setIsHoverPane(true)}
          onClick={() => eventsSecondBtn()}
          variant="contained"
          style={{ marginLeft: '12px' }}
          className={clsx(
            classes.button,
            stylesButtons,
            {
              [classes.buttonFinished]: status === 'sent' || status === 'sent_local',
              [classes.buttonLocal]: status === 'pending_to_be_sent' && deliveryType === 'local',
              [classes.buttonProgressGreen]: status === 'in_progress'
            },
          )}
          color="primary"
        >
          {status === 'created' ? (
            <Box>
              <CheckRounded style={{ fontSize: 23 }} color="#fff" />
              <Typography style={{ fontSize: 13, fontWeight: 600 }} variant="body2">Aceptar</Typography>
            </Box>
          ) : ''}
          {status === 'in_progress' ? (
            <Box>
              <CheckRounded style={{ fontSize: 23 }} color="#fff" />
              <Typography style={{ fontSize: 13, fontWeight: 600 }} variant="body2">Terminar</Typography>
            </Box>
          ) : ''}
          {status === 'pending_to_be_sent' && deliveryType === 'local' ? 'Envío local' : ''}
          {status === 'sent' || status === 'sent_local' ? 'Orden entregada' : ''}
        </Button>
      )}
    </Box>
  );
};

ButtonStatus.propTypes = {
  className: PropTypes.string,
  stylesButtons: PropTypes.string,
  setIsHoverPane: PropTypes.func,
  status: PropTypes.string,
  setOrder: PropTypes.func,
  setStatus: PropTypes.func,
  updateStatus: PropTypes.func,
  idOrder: PropTypes.string,
  deliveryType: PropTypes.string,
};

ButtonStatus.defaultProps = {
  className: '',
  stylesButtons: '',
  setIsHoverPane: () => { },
  status: '',
  idOrder: '',
  deliveryType: ''
};

export default ButtonStatus;
