import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  Box,
  Typography
} from '@material-ui/core';
import { MdDeliveryDining } from 'react-icons/md';

const useStyles = makeStyles(() => ({
  gif: {
    width: '100%',
    height: '100%'
  },
  iconStatus: {
    fontSize: 70,
    color: '#009739',
    marginLeft: 26,
    animation: '$driverMove 0.3s linear infinite',
  },
  wind: {
    position: 'absolute',
    zIndex: 1,
    right: 90,
    height: 50,
  },
  p: {
    position: 'absolute',
    background: '#009379',
    borderRadius: '0.625em',
    height: 5
  },
  p1: {
    width: 10,
    position: 'absolute',
    right: 0,
    top: 10,
    animation: '$wind 1s -2s linear infinite',
  },
  p2: {
    width: 20,
    right: 10,
    top: 20,
    animation: '$wind 1s -1s linear infinite'
  },
  p3: {
    width: 30,
    right: 0,
    top: 30,
    animation: '$wind 1s 1s linear infinite',
  },
  p4: {
    width: 40,
    right: 20,
    top: 40,
    animation: '$wind 1s -1.5s linear infinite'
  },
  title: {
    fontSize: 20,
    fontWeight: 600
  },
  '@keyframes wind': {
    '0%': {
      transform: 'translateX(100%)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateX(-100%)',
      opacity: '0'
    }
  },
  '@keyframes driverMove': {
    '0%': {
      transform: 'translateY(0px)',
    },
    '50%': {
      transform: 'translateY(-2px)',
    },
    '100%': {
      transform: 'translateY(0px)'
    }
  }
}));

const AssignDriver = () => {
  const classes = useStyles();
  return (
    <Box pr={1.5} display="flex" flexDirection="column" alignItems="center">
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={1}
      >
        <Box
          position="relative"
          display="flex"
          alignItems="center"
        >
          <MdDeliveryDining className={classes.iconStatus} />
          <Box className={classes.wind}>
            <Box className={clsx(classes.p, classes.p1)} />
            <Box className={clsx(classes.p, classes.p2)} />
            <Box className={clsx(classes.p, classes.p3)} />
            <Box className={clsx(classes.p, classes.p4)} />
            <Box className={clsx(classes.p, classes.p5)} />
          </Box>
        </Box>
      </Box>
      <Typography
        variant="body2"
        color="primary"
        className={classes.title}
      >
        Asignando un driver...
      </Typography>
    </Box>
  );
};

export default AssignDriver;
