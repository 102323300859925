import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Card,
  Grid,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Divider,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Group as GroupIcon,
  MoreVert as MoreVertIcon,
  Schedule as ScheduleIcon,
  FavoriteBorder as FavoriteBorderIcon
} from '@material-ui/icons';
import FavoriteBranchOfficeService from 'src/services/FavoriteBranchOfficeService';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  avatar: {
    width: '4.4rem',
    height: '4.4rem',
    overflow: 'hidden',
  },
  managers: {
    marginTop: '.25rem',
  },
  description: {
    height: '3rem',
    minHeight: '3rem',
    maxHeight: '3rem',
    overflow: 'hidden',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkitLineClamp': 2,
    '-webkitBoxOrient': 'vertical',
  }
}));

const BranchOfficeCard = ({
  className, branchOffice, openSchedule, ...rest
}) => {
  const classes = useStyles();
  const [favorites, setFavorites] = useState(0);
  const [menuIsOpen, setMenuIsOpen] = useState(null);

  const toggleMenu = (e) => setMenuIsOpen(!menuIsOpen ? e.currentTarget : null);

  const handleOpenSchedule = () => {
    openSchedule(branchOffice?.id);
    toggleMenu();
  };

  const getFavoriteCount = async (branchOfficeId) => {
    const response = await FavoriteBranchOfficeService.getCountById(branchOfficeId);

    if (response?.code === 200) {
      setFavorites(response?.data?.count);
    }
  };

  useEffect(() => {
    getFavoriteCount(branchOffice?.id);
  }, [branchOffice]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={3} direction="column" justify="center" alignItems="center">
            <Avatar
              className={classes.avatar}
              alt="branchOffice"
              src={branchOffice?.brand?.logo}
              variant="square"
            />
          </Grid>
          <Grid container item xs={9}>
            <Grid container item xs={10} alignItems="center">
              <Typography
                align="left"
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                { branchOffice?.brand?.name }
                {' '}
                { branchOffice?.name }
              </Typography>
            </Grid>
            <Grid container item xs={2} alignItems="flex-end">
              <IconButton onClick={toggleMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={menuIsOpen}
                open={Boolean(menuIsOpen)}
                onClose={toggleMenu}
              >
                <MenuItem onClick={handleOpenSchedule}>Ver Horarios</MenuItem>
              </Menu>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.description}
                align="left"
                color="textPrimary"
                variant="body1"
              >
                {branchOffice?.address}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box p={2}>
        <Grid item container justify="space-between" alignItems="center">
          <Grid container item xs={12} justify="flex-end" alignItem="center">
            <Box mr={1}>
              <ScheduleIcon color={branchOffice?.isOpen ? 'primary' : 'textSecondary'} />
            </Box>
            {' '}
            <Typography
              color={branchOffice?.isOpen ? 'primary' : 'textSecondary'}
              display="inline"
              variant="body2"
              className={classes?.managers}
            >
              {branchOffice?.message}
            </Typography>
          </Grid>
          <Grid item container xs={12} justify="flex-end" alignItem="center">
            <Box mr={1}>
              <GroupIcon color="action" />
            </Box>
            <Typography color="textSecondary" display="inline" variant="body2" className={classes?.managers}>
              {branchOffice?.managers?.length || 0}
              {' '}
              {branchOffice?.managers?.length === 1 ? 'Gerente' : 'Gerentes'}
            </Typography>
          </Grid>
          <Grid item container xs={12} justify="flex-end" alignItem="center">
            <Box mr={1}>
              <FavoriteBorderIcon color="action" />
            </Box>
            <Typography color="textSecondary" display="inline" variant="body2" className={classes?.managers}>
              { favorites }
              {' '}
              Favoritos
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

BranchOfficeCard.propTypes = {
  className: PropTypes.string,
  openSchedule: PropTypes.func,
  branchOffice: PropTypes.object.isRequired
};

export default BranchOfficeCard;
