import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Check } from '@material-ui/icons';
import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles(() => ({
  container: {
    padding: '15px',
    paddingBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '40%',
    backgroundColor: '#fff',
    border: '2px solid #009739',
    borderRadius: '10px',
    marginBottom: '10px'
  },
  customRadio3: {
    '& input[type="radio"]': {
      WebkitAppearance: 'none',
      appearance: 'none'
    },
    display: 'flex',
    width: '70%',
    height: 'fit-content',
    '& input[type="radio"]:checked + $radioButton': {
      border: '2px solid #009739',
      '& $checked': {
        backgroundColor: '#009739',
        border: 'none'
      },
      '& $icon': {
        color: '#fff'
      }
    }
  },
  radioButton: {
    marginTop: '10px',
    width: '80%',
    height: '30%',
    animation: '$scale 0.7s ease both',
    cursor: 'pointer',
    border: '2px solid #ccc',
    borderRadius: 10,
    transition: 'border 0.5s ease',
    '&:hover': {
      border: '2px solid #009739',
      '& $img': {
        filter: 'grayscale(0)',
        opacity: 1
      }
    }
  },
  checked: {
    backgroundColor: 'transparent',
    borderRadius: '50%',
    border: '2px solid #ccc'
  },
  icon: {
    color: 'transparent',
    fontSize: 16
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(0)'
    },
    '30%': {
      transform: 'scale(0)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  },
  com: {
    color: '#888'
  },
  titlePro: {
    color: '#009739'
  }
}));

const Tip = ({ setTip, tip }) => {
  const classes = useStyles();
  const handleChange = (e) => {
    setTip(Number(e.target.value));
  };
  return (
    <>
      <Box className={classes.container} p={5}>
        <Box>
          <Typography className={classes.titlePro} variant="h5">
            Propina adicional (opcional)
          </Typography>
          <Typography className={classes.com} variant="body2">
            100% para el domiciliario
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
          >
            {[0, 2000, 5000].map((item) => (
              <>
                <label className={classes.customRadio3} htmlFor={item}>
                  <input
                    type="radio"
                    name="tip"
                    id={item}
                    value={item}
                    onChange={handleChange}
                    checked={tip === item}
                  />
                  <Box
                    className={classes.radioButton}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="40%"
                    height={35}
                    pt={1}
                    px={1}
                    mt={2}
                  >
                    <Box
                      className={classes.checked}
                      width={18}
                      height={18}
                      display="flex"
                      alignSelf="start"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Check className={classes.icon} />
                    </Box>
                    <Box
                      mt={-2}
                      pb={1}
                      height="20px"
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="h5">
                        {' $ '}
                        <CurrencyFormat
                          value={item}
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          onChange={handleChange}
                        />
                      </Typography>
                    </Box>
                  </Box>
                </label>
              </>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

Tip.propTypes = {
  setTip: PropTypes.number,
  tip: PropTypes.number
};

export default Tip;
