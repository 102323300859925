import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Grid,
  Box,
  Button,
  Switch,
  FormControl,
  Radio,
  RadioGroup,
  FormGroup,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  withStyles,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import useErrors from 'src/hooks/useErrors';
import ToppingService from 'src/services/ToppingService';
import useConfirmation from 'src/hooks/useConfirmation';

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 33,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(17px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    transition: 'background .4s ease 0s',
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[400],
  },
  checked: {},
}))(Switch);

const CreateToppingModal = ({
  edit,
  topping,
  toggleCreateTopping,
  onRefresh
}) => {
  const { toggleModal } = useConfirmation();
  const matchesSm = useMediaQuery('screen and (min-width: 600px)');
  const matches = useMediaQuery('screen and (min-width: 769px)');
  const [newTopping, setNewTopping] = useState({
    title: '',
    description: '',
    type: '',
    is_required: false,
    max_items: '',
    order: 0,
    items: [],
  });
  const { setErrors } = useErrors();
  const [localErrors, setLocalErrors] = useState([]);

  const useStyles = makeStyles(() => ({
    modal: {
      border: '1px solid #e6ebf1',
      borderRadius: '8px',
      width: !matches ? '100%' : '600px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      bottom: '0',
      animation: 'showPopUp 0.5s',
      position: !matches ? 'absolute' : 'relative',
    },
    CreateCustomizationModal: {
      backgroundColor: 'rgba(46, 44, 54, 0.2)',
      position: 'fixed',
      top: 0,
      right: 0,
      height: '100%',
      bottom: '0',
      width: '100%',
      zIndex: '3',
      display: matches ? 'flex' : null,
      justifyContent: matches ? 'center' : null,
      alignItems: matches ? 'center' : null,
    },
    pane: {
      height: '100%',
      width: '100%',
      position: 'fixed'
    },
    header: {
      padding: '10px 15px',
      borderBottom: '1px solid #e6ebf1',
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > h3': {
        fontFamily: '"Roboto", Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        fontSize: '22px'
      }
    },
    body: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: matchesSm ? '30px' : '15px',
      overflow: 'auto',
      height: '100%',
    },
    btn: {
      width: '125px',
      fontSize: '13px',
    }
  }));
  const classes = useStyles();

  const handleRequired = (e) => {
    setNewTopping({
      ...newTopping,
      [e.target.name]: e.target.checked
    });
  };

  const handleTopping = (e) => {
    setNewTopping({
      ...newTopping,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      title: newTopping?.title,
      description: newTopping?.description,
      type: newTopping?.type,
      is_required: newTopping?.is_required,
      max_items: (e.target.type.value === 'radio' || e.target.type.value === 'checkbox') ? 0 : Number(newTopping?.max_items),
      order: newTopping.order,
      items: edit ? newTopping.items : [],
      is_hidden: false
    };
    if (data?.title.length === 0) {
      errors.push({ field: 'title', error: 'El nombre es obligatorio' });
    }

    if (data?.type.length === 0) {
      errors.push({ field: 'type', error: 'Debe elegir un tipo de opción' });
    }

    if (e?.target?.max_items?.value?.length === 0 && data?.type === 'quantity') {
      errors.push({ field: 'max_items', error: 'La cantidad maxima es obligatoria' });
    }

    if (errors.length > 0) {
      setErrors(errors.map((err) => err.error));
      setLocalErrors(errors);
      return;
    }

    if (edit && newTopping.type !== topping.type) {
      if (newTopping.type === 'quantity') {
        data.items = newTopping.items.map((item) => {
          return {
            ...item,
            value: 0,
          };
        });
      } else if (newTopping.type === 'checkbox' || newTopping.type === 'radio') {
        data.items = newTopping.items.map((item) => {
          return {
            ...item,
            value: false,
            max: 0,
          };
        });
      }
    }

    if (edit && topping.type === 'quantity' && data.max_items !== topping.max_items && data.max_items !== 0) {
      data.items = newTopping.items.map((item) => {
        let { max } = item;
        if (max > newTopping.max_items) max = data.max_items;
        return {
          ...item,
          max
        };
      });
    }

    try {
      const response = edit
        ? await ToppingService.update(newTopping.id, data)
        : await ToppingService.create(data);

      if (response?.code === 200 || response?.code === 201) {
        setErrors([]);
        onRefresh();
        toggleCreateTopping(false);
      } else {
        setErrors(Array.isArray(response.errors) ? response.errors : []);
      }
    } catch (err) {
      setErrors([err.message]);
    }
  };

  useEffect(() => {
    if (edit) setNewTopping(topping);
  }, []);

  return (
    <div className={classes.CreateCustomizationModal}>
      <div className={classes.pane} onClick={() => toggleCreateTopping(false)} />
      <div className={classes.modal}>
        <div className={classes.header}>
          <div className={classes.title}>
            <h3>
              {!edit ? 'Agregar ' : 'Editar '}
              topping
            </h3>
            <CancelRoundedIcon style={{ cursor: 'pointer' }} color="primary" onClick={() => toggleCreateTopping(false)} />
          </div>
        </div>
        <div className={classes.body}>
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            style={{ width: '100%' }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                style={{ paddingBottom: '3px', paddingTop: '0' }}
                item
                xs={12}
              >
                <FormGroup>
                  <FormControlLabel
                    style={{ paddingLeft: '10px', paddingTop: matchesSm ? '0' : '10px' }}
                    control={
                      <CustomSwitch name="is_required" checked={newTopping.is_required} onChange={handleRequired} />
                    }
                    label={<Typography style={{ marginLeft: '8px', fontSize: '15px' }}>Obligatorio</Typography>}
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  value={newTopping.title}
                  onChange={handleTopping}
                  error={localErrors.some((e) => e.field === 'title')}
                  helperText={localErrors.find((e) => e.field === 'title')?.error || ''}
                  placeholder="Escriba el nombre"
                  label="Nombre"
                  name="title"
                  variant="outlined"
                  type="text"
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  placeholder="Escriba la descripción"
                  label="Descripción"
                  name="description"
                  multiline
                  minRows={2}
                  variant="outlined"
                  type="text"
                  value={newTopping.description}
                  onChange={handleTopping}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingBottom: '0' }}
              >
                <FormControl error={localErrors.some((e) => e.field === 'type')} component="fieldset">
                  <FormLabel component="legend">Elija el tipo de opción</FormLabel>
                  <RadioGroup row aria-label="type_option" name="type" value={newTopping?.type} onChange={handleTopping}>
                    <FormControlLabel
                      value="radio"
                      control={<Radio size="small" color="primary" />}
                      label={<Typography style={{ fontSize: '15px' }}>Unica</Typography>}
                    />
                    <FormControlLabel
                      value="checkbox"
                      control={<Radio size="small" color="primary" />}
                      label={<Typography style={{ fontSize: '15px' }}>Si/No</Typography>}
                    />
                    <FormControlLabel
                      value="quantity"
                      control={<Radio size="small" color="primary" />}
                      label={<Typography style={{ fontSize: '15px' }}>Cantidad</Typography>}
                    />
                  </RadioGroup>
                  <FormHelperText>
                    {localErrors.find((e) => e.field === 'type')?.error || ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {(newTopping?.type === 'quantity') && (
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    fullWidth
                    error={localErrors.some((e) => e.field === 'max_items')}
                    helperText={localErrors.find((e) => e.field === 'max_items')?.error || ''}
                    placeholder="Escriba la cantidad maxima de opciones a seleccionar"
                    label="Cantidad maxima"
                    name="max_items"
                    variant="outlined"
                    type="number"
                    value={newTopping?.max_items ? newTopping.max_items : ''}
                    onChange={handleTopping}
                    required
                  />
                </Grid>
              )}
            </Grid>
            <Box
              display="flex"
              justifyContent="center"
              paddingTop={3}
            >
              {edit && (
                <Box mr={2}>
                  <Button
                    className={classes.btn}
                    variant="contained"
                    onClick={() => toggleModal()}
                  >
                    Eliminar
                  </Button>
                </Box>
              )}
              <Button
                className={classes.btn}
                type="submit"
                color="primary"
                variant="contained"
              >
                {!edit ? 'Guardar' : 'Editar'}
              </Button>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
};

CreateToppingModal.propTypes = {
  edit: PropTypes.bool,
  topping: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    is_required: PropTypes.bool,
    max_items: PropTypes.number,
    order: PropTypes.number,
    items: PropTypes.array,
    isHidden: PropTypes.bool
  }),
  toggleCreateTopping: PropTypes.func,
  onRefresh: PropTypes.func
};

export default CreateToppingModal;
