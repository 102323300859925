import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import clsx from 'clsx';
import {
  Box,
  Button,
  TextField,
  Container,
  InputAdornment,
  Typography,
  IconButton,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import {
  Email,
  Visibility,
  VisibilityOff,
  Phone,
  Error
} from '@material-ui/icons';
import Errors from 'src/components/Errors';
import AuthService from '../../services/AuthService';
import { actions as UserActions } from '../../redux/ducks/user';
import useErrors from '../../hooks/useErrors';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  input: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& .MuiInputBase-root': {
      boxSizing: 'border-box',
      borderRadius: 15,
      backgroundColor: '#f3f7fa',
      '&.Mui-focused': {
        border: '0.13em solid #009739'
      },
      '&.Mui-error': {
        border: '0.13em solid #f44336'
      }
    },
    '& .MuiInputBase-root::after, & .MuiInputBase-root::before': {
      display: 'none'
    },
    '& .MuiFilledInput-input': {
      paddingTop: theme.spacing(3.5),
      paddingBottom: theme.spacing(1.5),
      paddingRight: theme.spacing(3.5),
      paddingLeft: theme.spacing(3.5),
      fontFamily: '"Poppins", sans-serif',
      fontSize: 14,
      fontWeight: 500
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(27px, 12px) scale(0.75)'
    },
    '& .MuiInputLabel-filled': {
      transform: 'translate(27px, 22px) scale(1)'
    },
    '& .MuiFilledInput-input:-webkit-autofill': {
      borderRadius: 15
    },
    '& .MuiInputBase-adornedEnd': {
      paddingRight: theme.spacing(3),
      '& .MuiInputAdornment-root': {
        transition: 'color 0.3s ease',
        color: '#8c9baf',
        '& .MuiIconButton-label': {
          transition: 'color 0.3s ease',
          color: '#8c9baf'
        }
      },
      '&.Mui-focused .MuiInputAdornment-root': {
        color: '#009739',
        '& .MuiIconButton-label': {
          color: '#009739'
        }
      },
      '&.Mui-error .MuiInputAdornment-root': {
        color: '#f44336',
        '& .MuiIconButton-label svg': {
          color: '#f44336'
        }
      }
    }
  },
  label: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 14,
    fontWeight: 500,
    color: '#a8aab4'
  },
  button: {
    borderRadius: 50,
    width: 220,
    fontSize: 14,
    paddingTop: theme.spacing(1.4),
    paddingBottom: theme.spacing(1.4)
  },
  link: {
    color: '#009739',
    fontSize: 20,
    fontWeight: 500,
    textDecoration: 'underline'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  face: {
    transition: '.4s'
  },
  front: {
    transform: 'perspective(600px) rotateY(0deg)'
  },
  back: {
    transform: 'perspective(600px) rotateY(180deg)'
  },
  flipped: {
    transition: '.4s'
  },
  textDanger: {
    color: 'red'
  },
  hide: {
    display: 'none',
    transition: '.4s'
  },
  conatinerAlerts: {
    backgroundColor: '#f8d7da',
    borderRadius: 10
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '19px',
    marginRight: '4px'
  },
  textAlert: {
    fontSize: '14px',
    wordBreak: 'break-all',
    color: theme.palette.error.main
  }
}));
const colombiaId = 'be8cc509-a489-4ec9-99d7-76b7f48258dd';
const peruId = '0a51f475-1fe0-4403-bead-8f59760c9490';
const venezuelaId = '0a2aa4bd-f482-47ca-9e9c-a209f39b9366';

const regexDigit = /[0-9]/;

const LoginDashView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { setErrors } = useErrors();
  const [showPassword, setShowPassword] = useState(false);
  const codeInput2 = useRef(null);
  const codeInput3 = useRef(null);
  const codeInput4 = useRef(null);
  const [codeInputs, setCodeInputs] = useState(null);
  const submitButtonRef = useRef();
  const [loading, setLoading] = useState(false);
  const [flipped, setFlipped] = useState(false);
  const [phone, setPhone] = useState(null);
  const [countryId, setCountryId] = useState(colombiaId);
  const [error, setError] = useState(null);
  const [codeRequested, setCodeRequested] = useState(false);

  const skipNextInput = (e, ref = null) => {
    if (Number(e?.target?.value) > 9) return;

    const value =
      e?.target?.value.trim().length === 0 ? '' : Number(e?.target?.value);

    setCodeInputs({
      ...codeInputs,
      [e?.target?.name]: value
    });

    if (!ref) {
      submitButtonRef?.current?.click();
    } else if (e?.target?.value.trim().length > 0) {
      ref?.current?.focus();
    }
  };

  const handleClick = () => {
    setFlipped(!flipped);
  };

  const authenticate = async (e) => {
    setLoading(true);
    e.preventDefault();
    let c = colombiaId;
    const form = e.target;
    const p = Number(form?.phone?.value);

    switch (p) {
      case 4244344499:
        setCountryId(venezuelaId);
        c = venezuelaId;
        break;
      case 934019008:
        setCountryId(peruId);
        c = peruId;
        break;
      default:
        setCountryId(colombiaId);
        c = colombiaId;
    }
    if (!codeRequested) {
      const payload = {
        country_id: c,
        phone: Number(form.phone.value)
      };
      const response = await AuthService.requestCodePartner(payload);
      if (response?.code === 200) {
        setPhone(Number(form.phone.value));
        setCodeRequested(true);
      } else {
        setError('El usuario no existe');
      }
    } else {
      const payload = {
        country_id: countryId,
        sms_token: `${form.sms_token1.value}${form.sms_token2.value}${form.sms_token3.value}${form.sms_token4.value}`,
        phone
      };

      const response = await AuthService.loginPartner(payload);

      if (response?.code === 200) {
        dispatch(UserActions.set(response?.data?.data));
        dispatch(UserActions.tokenize(response?.data?.token));
        navigate('/app/paqueteria', { replace: true });
      } else {
        setError('Código inválido');
      }
    }
    setLoading(false);
  };

  const handleSubmit2 = async (e) => {
    setError(false);
    await authenticate(e);
  };

  return (
    <Container className={classes.root} maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        alignItems="center"
        justifyContent="center"
        pt={10}
      >
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="fit-content"
          overflow="hidden"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={2}
            textAlign="center"
          >
            <Typography
              variant="h2"
              color="primary"
              style={{
                fontWeight: 500,
                fontSize: 29
              }}
            >
              ¡Bienvenido de nuevo Aliado!
            </Typography>
            <Typography variant="body2" color="primary">
              {!codeRequested
                ? 'Iniciar sesión como Aliado'
                : 'Ingresa tu codigo de verificación que se te envio por WhatssApp'}
            </Typography>
          </Box>
          <Box className={classes.card}>
            <Box
              px={2}
              className={clsx(classes.face, {
                [classes.front]: !flipped,
                [classes.back]: flipped
              })}
            >
              <Box
                className={clsx({
                  [classes.hide]: flipped
                })}
              >
                <Formik
                  initialValues={{
                    email: '',
                    password: ''
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email('Debe ser un correo válido')
                      .max(255)
                      .required('El correo es obligatorio'),
                    password: Yup.string()
                      .max(255)
                      .required('La contraseña es obligatoria')
                  })}
                  onSubmit={async (value) => {
                    setLoading(true);
                    const response = await AuthService.authenticate({
                      email: value?.email,
                      password: value?.password
                    });
                    if (response?.code === 200) {
                      dispatch(UserActions.set(response?.data?.data));
                      dispatch(UserActions.tokenize(response?.data?.token));
                      if (response?.data?.data?.logistics)
                        navigate('/app/paqueteria', { replace: true });
                      else navigate('/app/productos', { replace: true });
                    } else {
                      setErrors(['El correo y la contraseña no coinciden']);
                    }
                    setLoading(false);
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        className={clsx(classes.input, classes.input2)}
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        FormHelperTextProps={{
                          style: {
                            fontWeight: 500,
                            fontFamily: '"Poppins", sans-serif'
                          }
                        }}
                        label="Correo"
                        InputLabelProps={{
                          className: classes.label
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email />
                            </InputAdornment>
                          )
                        }}
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="filled"
                      />
                      <TextField
                        className={clsx(classes.input, classes.input2)}
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        FormHelperTextProps={{
                          style: {
                            fontWeight: 500,
                            fontFamily: '"Poppins", sans-serif'
                          }
                        }}
                        label="Contraseña"
                        InputLabelProps={{
                          className: classes.label
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                style={{
                                  padding: 0
                                }}
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword((prev) => !prev)}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        variant="filled"
                      />
                      <Errors time={0} />
                      <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="row"
                      >
                        <Box
                          mr={2}
                          my={2}
                          display="flex"
                          justifyContent="center"
                          color="primary"
                        >
                          <Button
                            className={clsx(classes.button, classes.button2)}
                            color="primary"
                            variant="contained"
                            onClick={handleClick}
                          >
                            Whatsapp
                          </Button>
                        </Box>
                        <Box my={2} display="flex" justifyContent="center">
                          <Button
                            className={clsx(classes.button, classes.button2)}
                            color="primary"
                            disabled={isSubmitting || loading}
                            startIcon={
                              isSubmitting || loading ? (
                                <CircularProgress size={25} />
                              ) : (
                                ''
                              )
                            }
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            Iniciar Sesión
                          </Button>
                        </Box>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
            <Box
              px={2}
              className={clsx(classes.face, {
                [classes.front]: flipped,
                [classes.back]: !flipped
              })}
            >
              <Box
                className={clsx({
                  [classes.hide]: !flipped
                })}
              >
                <form onSubmit={handleSubmit2}>
                  <Box margin="auto" width="90%">
                    {!codeRequested && (
                      <TextField
                        className={classes.input}
                        fullWidth
                        FormHelperTextProps={{
                          style: {
                            fontWeight: 500,
                            fontFamily: '"Poppins", sans-serif'
                          }
                        }}
                        label="Numero de teléfono"
                        InputLabelProps={{
                          className: classes.label
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Phone />
                            </InputAdornment>
                          )
                        }}
                        margin="normal"
                        name="phone"
                        type="phone"
                        variant="filled"
                        disabled={codeRequested}
                      />
                    )}
                    {codeRequested && (
                      <Box mb={1}>
                        <div className="ed-grid s-grid-4 s-mb-2 container-code">
                          <input
                            name="sms_token1"
                            value={codeInputs?.sms_token1 ?? ''}
                            className="input full input-code"
                            onChange={(e) => skipNextInput(e, codeInput2)}
                            onKeyPress={(event) => {
                              if (!regexDigit.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <input
                            ref={codeInput2}
                            name="sms_token2"
                            value={codeInputs?.sms_token2 ?? ''}
                            className="input full input-code"
                            onChange={(e) => skipNextInput(e, codeInput3)}
                            onKeyPress={(event) => {
                              if (!regexDigit.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <input
                            ref={codeInput3}
                            name="sms_token3"
                            value={codeInputs?.sms_token3 ?? ''}
                            className="input full input-code"
                            onChange={(e) => skipNextInput(e, codeInput4)}
                            onKeyPress={(event) => {
                              if (!regexDigit.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <input
                            ref={codeInput4}
                            name="sms_token4"
                            value={codeInputs?.sms_token4 ?? ''}
                            className="input full input-code"
                            onChange={(e) => skipNextInput(e)}
                            onKeyPress={(event) => {
                              if (!regexDigit.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </Box>
                    )}
                    {error && (
                      <Box display="flex" alignItems="center" mt={0.5}>
                        <Error className={classes.error} size="small" />
                        <Typography
                          className={classes.textAlert}
                          variant="body2"
                        >
                          {error}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box display="flex" flexDiretion="row" margin="auto">
                    <Box my={2} mr={1} display="flex" justifyContent="center">
                      <Button
                        className={classes.button}
                        color="primary"
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={handleClick}
                      >
                        Correo
                      </Button>
                    </Box>
                    <Box my={2} display="flex" justifyContent="center">
                      <Button
                        className={classes.button}
                        color="primary"
                        disabled={loading}
                        startIcon={
                          loading ? <CircularProgress size={25} /> : ''
                        }
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {codeRequested ? 'Validar código' : 'Solicitar código'}
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={0.5}
          width="100%"
        >
          <Box width="45%" height={2} bgcolor="#c7c5c0" />
          <Box px={1.5}>
            <Typography color="primary" variant="h4">
              O
            </Typography>
          </Box>
          <Box width="45%" height={2} bgcolor="#c7c5c0" />
        </Box>
        <Box textAlign="center" mt={1}>
          <Typography variant="body2">
            <Link to="/auth/register" style={{ color: '#009739' }}>
              Registrate
            </Link>{' '}
            como aliado para empezar a usar nuestro nuevo servicio de paquetería{' '}
            <Link to="/auth/register" className={classes.link}>
              ¡Adomi Logistica!
            </Link>{' '}
            💚
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginDashView;
