import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ColorService from 'src/services/ColorService';
import {
  Box,
  Typography,
  Button,
  TextField,
  makeStyles,
  // FormHelperText,
  FormControl
} from '@material-ui/core';
import Modal from 'src/components/Modal';
import useModal from 'src/hooks/useModal';
import { selector as UserSelector } from 'src/redux/ducks/user';
import { SketchPicker } from 'react-color';
import propTypes from 'prop-types';

const usStyles = makeStyles(() => ({
  head: {
    background: 'white',
    padding: '1rem',
    borderRadius: '5px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: '1rem'
  }
}));

const Head = ({ refresh }) => {
  const [isOpen, toggle] = useModal();
  const classes = usStyles();
  const { user } = useSelector(UserSelector);
  const [color, setColor] = useState('#000000');
  const [errors, setErrors] = useState([]);

  const handleChangeColor = (value) => {
    setColor(value?.hex);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name } = e.target;
    const error = [];
    const data = {
      name: name.value,
      hexadecimal: color?.slice(1),
      brand_id: user.brand_id
    };

    if (!data.name) {
      error.push('El nombre es requerido');
    }

    if (!data.hexadecimal) {
      error.push('El color es requerido');
    }

    if (error.length > 0) {
      setErrors(error);
      return;
    }

    await ColorService.create(data);
    toggle();
    refresh();
  };

  const handledModal = () => {
    toggle();
  };
  return (
    <div>
      <Box
        className={classes.head}
        display="flex"
        justifyContent="space-between"
      >
        <Typography variant="h2">Gestion de colores</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handledModal()}
        >
          Agregar color
        </Button>
      </Box>
      <Modal isOpen={isOpen} toggle={toggle} title="Agregar un nuevo color">
        <form onSubmit={(e) => handleSubmit(e)}>
          <FormControl
            variant="outlined"
            // className={classes.formControl}
            error={errors?.map((error) => error?.includes('nombre'))}
          >
            <Box>
              <SketchPicker
                color={color}
                onChange={handleChangeColor}
                disableAlpha
              />
              <TextField
                error={errors && errors?.length > 0}
                helperText={errors}
                fullWidth
                label="Nombre del color"
                margin="normal"
                name="name"
                variant="outlined"
              />
              <Button variant="contained" color="primary" type="submit">
                Agregar
              </Button>
            </Box>
          </FormControl>
        </form>
      </Modal>
    </div>
  );
};

Head.propTypes = {
  refresh: propTypes.func
};

export default Head;
