import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Card,
  Grid,
  Button,
  Divider,
  CardActions,
  CardContent,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    statsItem: {
      alignItems: 'center',
      display: 'flex'
    },
    statsIcon: {
      marginRight: theme.spacing(1)
    },
    avatar: {
      width: 'auto',
      height: 'auto'
    }
  }));
const BrandLogo = forwardRef(({
  className, onUpload, hasImage, setFile, brand, ...rest
}, ref) => {
    const classes = useStyles();
    const [previewImage, setPreviewImage] = useState(null);
  
    useEffect(() => {
      ref?.current?.addEventListener('change', () => {
        setPreviewImage(URL.createObjectURL(ref?.current?.files[0]));
        setFile(ref?.current?.files[0]);
      });
    }, [ref, setPreviewImage]);
    return (
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <CardContent>
          <Grid container>
            <Grid container item xs={12} direction="column" justifyContent="center" alignItems="center">
              <Avatar
                className={classes.avatar}
                alt="product"
                src={previewImage || brand?.logo}
                variant="square"
              />
              <input name="image[]" type="file" ref={ref} style={{ display: 'none' }} />
            </Grid>
          </Grid>
        </CardContent>
        {
          !hasImage && (
            <>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  fullWidth
                  variant="text"
                  onClick={onUpload}
                >
                  Subir logo
                </Button>
              </CardActions>
            </>
          )
        }
      </Card>
  );
});

BrandLogo.propTypes = {
  className: PropTypes.string,
  hasImage: PropTypes.bool,
  // trigger: PropTypes.bool,
  // setTrigger: PropTypes.func,
  onUpload: PropTypes.func,
  setFile: PropTypes.func,
  brand: PropTypes.object,
}

export default BrandLogo;