import HttpRequest from './HttpRequest';

export default class StockService extends HttpRequest {
  static endpoint = 'stocks';

  static async getAll() {
    this.endpoint = 'stocks';
    const response = await this.get();
    return response;
  }

  static async getAllByBranchOffice(id) {
    this.endpoint = `stocks/by-branch-office/${id}`;
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'stocks';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.endpoint = 'stocks';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'stocks';
    const response = await this.put(id, data);
    return response;
  }

  static async updateQuantities(data) {
    this.endpoint = 'stocks/update-quantities';
    const response = await this.putNew(data);
    return response;
  }

  static async toggle(id) {
    this.endpoint = 'stocks';
    const response = await this.get(`${id}/toggle`);
    return response;
  }

  static async activeAllByBranchOfficeId(id) {
    this.endpoint = 'stocks/branch-office';
    const response = await this.put(`${id}/active-all`);
    return response;
  }
}
