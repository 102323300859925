import HttpRequest from './HttpRequest';

export default class ProductComponentService extends HttpRequest {
  static endpoint = 'product-components';

  static async getAll() {
    this.endpoint = 'product-components';
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'product-components';
    const response = await this.get(id);
    return response;
  }

  static async search(id, string) {
    this.endpoint = 'product-components';
    const response = await this.get(`${id}?name=${string}`);
    return response;
  }

  static async create(data) {
    this.endpoint = 'product-components';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'product-components';
    const response = await this.put(id, data);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'product-components';
    const response = await this.delete(id);
    return response;
  }

  static async toggle(id) {
    this.endpoint = 'product-components';
    const response = await this.get(`${id}/toggle`);
    return response;
  }

  static async activeAllByBrand(id) {
    this.endpoint = 'product-components/brand';
    const response = await this.put(`${id}/active-all`);
    return response;
  }

  static async getByBrandId(id) {
    this.endpoint = 'product-components';
    const response = await this.get(`brand/${id}`);
    return response;
  }

  static async uploadImage(data) {
    this.endpoint = 'product-components/create-image';
    const response = await this.post(data);
    return response;
  }

  static async deleteImage(data) {
    this.endpoint = 'product-components/delete-image';
    const response = await this.delete(data);
    return response;
  }
}
