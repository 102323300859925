import React, { forwardRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { getDate, getHour } from 'src/utils/formatDate';
import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles(() => ({
  border: {
    borderBottom: '1px dashed #000'
  },
  font: {
    fontFamily: '"Courier New", Courier, monospace'
  },
  bold: {
    fontWeight: 'bold'
  }
}));
const TicketOrder = forwardRef(({
  order,
  getDetailPrice,
  getTotalProducts,
  getCouponValue,
  getPaymentMethod
}, ref) => {
  const classes = useStyles();

  return (
    <Box px={2} py={1} ref={ref}>
      <Box py={1} display="flex" flexDirection="column" alignItems="items" textAlign="center" className={classes.border}>
        <Typography className={classes.font} style={{ fontWeight: 600 }} variant="h3">Orden</Typography>
        <Typography className={classes.font} variant="body2">
          {'Pedido: '}
          #
          {order?.reference}
        </Typography>
        <Typography className={classes.font} variant="body2">
          {`Fecha: ${getDate(order?.created_at)} ${getHour(order?.created_at)}`}
        </Typography>
        <Typography className={classes.font} variant="body2">
          {order?.data_branch_office?.brand?.name}
        </Typography>
        <Typography className={classes.font} variant="body2">
          {order?.data_branch_office?.address.replace(/#/g, 'N°')}
        </Typography>
        <Typography className={classes.font} variant="body2">
          {order?.data_branch_office?.phone1}
        </Typography>
      </Box>
      <Box className={classes.border}>
        <Box py={0.5} textAlign="center" className={classes.border}>
          <Typography className={clsx(classes.font, classes.bold)} variant="h6">RESUMEN PRODUCTOS</Typography>
        </Box>
        <Box py={1}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={clsx(classes.font, classes.bold)} variant="body2">Descripción</Typography>
            <Typography className={clsx(classes.font, classes.bold)} variant="body2">Precio</Typography>
          </Box>
          {order?.order_products?.map((product) => {
            const detailPrice = getDetailPrice(product?.prices);
            return (
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography style={{ wordBreak: 'break-all' }} className={classes.font} variant="body2">
                    x
                    {product?.amount}
                    {' '}
                    {product?.name}
                  </Typography>
                  <Typography style={{ wordBreak: 'break-all', fontSize: '12px' }} className={classes.font} variant="body2">
                    {detailPrice?.name}
                    {detailPrice?.description?.length > 0 && (
                      <>
                        {' ('}
                        {detailPrice?.description}
                        {') '}
                      </>
                    )}
                  </Typography>
                </Box>
                <Box ml={3.5}>
                  <Typography style={{ display: 'flex' }} variant="body2">
                    <span className={classes.font} style={{ marginRight: '3px' }}>$</span>
                    <CurrencyFormat
                      className={classes.font}
                      value={product?.total}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                    />
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box className={classes.border}>
        <Box py={0.5} textAlign="center" className={classes.border}>
          <Typography className={clsx(classes.font, classes.bold)} variant="h6">RESUMEN ORDEN</Typography>
        </Box>
        <Box py={1}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.font} variant="body2">Metodo de pago</Typography>
            <Typography className={classes.font} variant="body2">{getPaymentMethod(order)}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.font} variant="body2">Total productos</Typography>
            <Typography variant="body2">
              <span className={classes.font} style={{ marginRight: '3px' }}>$</span>
              <CurrencyFormat
                className={classes.font}
                value={getTotalProducts(order?.order_products)}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
              />
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography className={classes.font} variant="body2">Descuento Adomi</Typography>
            <Typography className={classes.font} variant="body2">
              {'- '}
              $
              <CurrencyFormat
                className={classes.font}
                value={order?.adomi_bonus}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
              />
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.font} variant="body2">
              Cupon
              {order?.coupon_data !== null && (
                <>
                  {order?.coupon_data?.type === 'percentage' && (
                    <span className={classes.font} style={{ marginLeft: '5px' }}>{`(${order?.coupon_value}%)`}</span>
                  )}
                </>
              )}
              :
            </Typography>
            <Typography className={classes.font} variant="body2">
              {order?.coupon_data !== null && (
                <>
                  {order?.coupon_data?.type !== 'delivery' && (
                    <span className={classes.font} style={{ marginRight: '2px' }}>-</span>
                  )}
                </>
              )}
              {order?.coupon_data?.type === 'delivery' && (
                <>
                  Domicilio gratis
                </>
              )}
              {order?.coupon_data?.type !== 'delivery' && (
                <>
                  <span className={classes.font} style={{ marginRight: '3px' }}>$</span>
                  <CurrencyFormat
                    className={classes.font}
                    value={
                      getCouponValue(
                        getTotalProducts(order?.order_products), order?.coupon_data
                      )
                    }
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                </>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box py={1} className={classes.border}>
        <Typography className={clsx(classes.font, classes.bold)} variant="h6">
          <span className={classes.font} style={{ marginRight: '3px' }}>Subtotal = $</span>
          <CurrencyFormat
            className={classes.font}
            value={order?.sub_total}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
          />
        </Typography>
      </Box>
      <Box className={classes.border}>
        <Box py={1}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.font} variant="body2">Costo domicilio</Typography>
            <Typography className={classes.font} variant="body2">
              <span className={classes.font} style={{ marginRight: '3px' }}>$</span>
              <CurrencyFormat
                className={classes.font}
                value={order?.coupon_data?.type !== 'delivery' ? order.delivery_cost : 0}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
              />
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.font} variant="body2">Propina</Typography>
            <Typography className={classes.font} variant="body2">
              <span className={classes.font} style={{ marginRight: '3px' }}>$</span>
              <CurrencyFormat
                className={classes.font}
                value={order?.tip}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
              />
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box py={1} className={classes.border}>
        <Typography className={clsx(classes.font, classes.bold)} variant="h5">
          <span className={classes.font} style={{ marginRight: '3px' }}>Total = $</span>
          <CurrencyFormat
            className={classes.font}
            value={order?.coupon_data?.type !== 'delivery' ? order?.total : order?.total - order.delivery_cost}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
          />
        </Typography>
      </Box>
      <Box className={classes.border}>
        <Box py={0.5} textAlign="center" className={classes.border}>
          <Typography className={clsx(classes.font, classes.bold)} variant="h6">DATOS DE ENTREGA</Typography>
        </Box>
        <Box py={1}>
          <Typography className={classes.font} variant="body2">
            {'Cliente: '}
            {order?.data_client?.first_name}
            {' '}
            {order?.data_client?.last_name}
          </Typography>
          <Typography className={classes.font} variant="body2">
            {'Tel: '}
            {order?.data_client?.phone}
          </Typography>
          <Typography className={classes.font} variant="body2">
            {'Dir: '}
            {`${order?.address?.address1} (${order?.address?.neighborhood}, ${order?.address?.reference})`}
          </Typography>
          <Typography className={classes.font} variant="body2">
            {'Cod pedido: '}
            {order?.delivery_token}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
});

TicketOrder.propTypes = {
  order: PropTypes.object,
  getDetailPrice: PropTypes.func,
  getTotalProducts: PropTypes.func,
  getCouponValue: PropTypes.func,
  getPaymentMethod: PropTypes.func
};

export default TicketOrder;
