import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';

const Selector = ({ type, styles, ...rest }) => {
  switch (type) {
    case 'async-creatable':
      return <AsyncCreatableSelect styles={styles} {...rest} />;
    case 'creatable':
      return <CreatableSelect styles={styles} {...rest} />;
    default:
      return <Select styles={styles} {...rest} />;
  }
};

Selector.propTypes = {
  styles: PropTypes.shape({}),
  type: PropTypes.oneOf(['creatable', 'async-creatable', 'default'])
};

Selector.defaultProps = {
  styles: {},
  type: 'default'
};

export default Selector;
