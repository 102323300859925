import HttpRequest from './HttpRequest';

export default class DriverService extends HttpRequest {
  static endpoint = 'drivers';

  static async getAll(branchOfficeId) {
    this.endpoint = `drivers?branch_office=${branchOfficeId}`;
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'drivers';
    const response = await this.get(id);
    return response;
  }

  static async alertToActivate(id) {
    this.endpoint = 'drivers/alert-to-activate';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.endpoint = 'drivers';
    const response = await this.post(data);
    return response;
  }

  static async unlinkFromBranchOffice(driverId, branchOfficeId) {
    this.endpoint = `drivers/${driverId}/unlink-from-branch-office`;
    const response = await this.get(branchOfficeId);
    return response;
  }

  static async getAvailableByCity(cityId, branchOfficeId = null) {
    this.endpoint = `drivers/active-by-city/${cityId}`;
    const response = await this.get(branchOfficeId ? `?branch_office_id=${branchOfficeId}` : '');
    return response;
  }

  static async uploadImage(data) {
    this.endpoint = 'drivers/create-avatar';
    const response = await this.post(data);
    return response;
  }
}
