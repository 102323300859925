import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useNavigate, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import './ToolbarManagers.css';
import {
  Box,
  Typography,
  makeStyles,
  Card,
  List,
  ListItem,
  Grow
} from '@material-ui/core';
import {
  ListAlt,
  LocalMall,
  MeetingRoomSharp,
  Assessment,
} from '@material-ui/icons';
import { selector as UserSelector, actions as UserActions } from 'src/redux/ducks/user';
import BranchOfficeService from 'src/services/BranchOfficeService';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  icon: {
    marginRight: theme.spacing(0.7),
    fontSize: '22px'
  },
  sideBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#009739',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
  },
  input: {
    position: 'absolute',
    left: '-9999px',
    '&:checked + $toggle::after': {
      background: 'linear-gradient(rgba(0,0,0,0.07),rgba(255,255,255,0.1)),#FFD500',
      boxShadow: '0 0.08em 0.15em -0.1em rgba(0,0,0,.5) inset, 0 0.05em 0.08em -0.01em rgba(255,255,255,.7), -4.15em 0 0 -0.25em rgba(0,0,0,.12)'
    },
    '&:checked + $toggle::before': {
      left: '1.4em',
    }
  },
  wrap: {
    position: 'relative',
  },
  toggle: {
    position: 'relative',
    display: 'block',
    width: '40px',
    height: '20px',
    cursor: 'pointer',
    borderRadius: '1.5em',
    transition: '350ms',
    background: 'linear-gradient(rgba(0,0,0, 0.07) 10%, rgba(255,255,255,0)), #ddd',
    boxShadow: '0 0.07em 0.1em -0.1em rgba(0,0,0,.4) inset, 0 0.05em 0.08em -0.01em rgba(255,255,255,.7)',
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      width: '15px',
      height: '15px',
      top: '0.1em',
      left: '0.1em',
      borderRadius: '50%',
      transition: '250ms ease-in-out',
      background: 'linear-gradient(#f5f5f5 10%,#eee)',
      boxShadow: '0 0.1em 0.15em -0.05em rgba(255,255,255,.9) inset, 0 0.5em 0.3em -0.1em rgba(0,0,0,.25)',
    },
    '&::after': {
      content: '""',
      zIndex: 1,
      position: 'absolute',
      width: '15px',
      height: '15px',
      top: '0.1em',
      left: '3em',
      borderRadius: '50%',
      transition: '250ms ease-in',
      background: 'linear-gradient(rgba(0,0,0,0.07),rgba(255,255,255,0.1)),#ddd',
      boxShadow: '0 0.08em 0.15em -0.1em rgba(0,0,0,.5) inset, 0 0.05em 0.08em -0.01em rgba(255,255,255,.7), -4.15em 0 0 -0.25em rgba(0,0,0,.3)'
    },
  },
  profile: {
    position: 'relative',
    padding: '0 5px',
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  thumbail: {
    position: 'relative',
    display: 'flex',
    minWidth: '50px',
    maxWidth: '50px',
    '& > img': {
      objectFit: 'contain',
      width: '100%',
      borderRadius: '50%',
    }
  },
  buttonProfile: {
    cursor: 'pointer',
    borderRadius: '50%',
    '&:hover': {
      boxShadow: '0 0 0 6px rgba(200, 200, 200, .6)'
    },
    transition: 'all 0.3s ease',
  },
  dropdownNav: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    width: 325,
    borderRadius: 8,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
  },
  tabs: {
    paddingTop: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },
  item: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    position: 'relative',
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
    '&:hover p': {
      color: theme.palette.primary.main
    },
    '& p': {
      transition: 'color 0.3s ease'
    }
  },
  totalOrders: {
    borderRadius: '50%',
    backgroundColor: '#e0336d',
    marginRight: theme.spacing(0.8)
  },
  select: {
    position: 'absolute',
    bottom: '-9px',
    width: '50px',
    height: '4px',
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    opacity: 0,
    transition: 'opacity 0.4s ease',
  },
  showSelect: {
    opacity: 1,
  },
  navItemSelect: {
    color: theme.palette.primary.main
  },
  warning: {
    color: theme.palette.error.main
  },
  pulse: {
    animation: '$pulse 1s infinite both'
  },
  pulseProfile: {
    animation: '$pulseProfile 2s infinite both'
  },
  '@keyframes pulse': {
    '0%': {
      boxShadow: '0 0 0 0px rgba(244, 67, 54, 1)',
    },
    '100%': {
      boxShadow: '0 0 0 10px rgba(244, 67, 54, 0)'
    }
  },
  '@keyframes pulseProfile': {
    '0%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 0px rgba(244, 67, 54, 1)',
    },
    '3.3%': {
      transform: 'scale(1.2)'
    },
    '16.5%': {
      transform: 'scale(1)'
    },
    '33%': {
      transform: 'scale(1.2)'
    },
    '100%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 16px rgba(244, 67, 54, 0)'
    }
  },
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
}));

const ToolbarManagers = ({
  branchOffice,
  totalOrders,
  pendingOrders,
  isOpen
}) => {
  const { user } = useSelector(UserSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [showItems, setShowItems] = useState(false);
  const navRef = useRef(null);
  const btnRef = useRef(null);
  const [navItem, setNavItem] = useState({
    pathOrders: false,
    pathReport: false,
    pathProducts: false,
  });

  const toggleOpen = async () => {
    await BranchOfficeService.updateIsWorking({
      phone: user?.phone,
      is_working: !isOpen
    });
  };

  const closeSession = () => {
    dispatch(UserActions.clear());
    navigate('/auth', { replace: true });
  };

  const navigateTo = (path) => {
    navigate(`/gerente/${path}`, { replace: true });
    setShowItems((prev) => !prev);
  };

  useEffect(() => {
    const pathName = location.pathname;
    const selectNavItem = {
      pathOrders: false,
      pathReport: false,
      pathProducts: false,
    };
    if (pathName === '/gerente/ordenes') selectNavItem.pathOrders = true;
    else if (pathName === '/gerente/reporte') selectNavItem.pathReport = true;
    else if (pathName === '/gerente/productos') selectNavItem.pathProducts = true;

    setNavItem(selectNavItem);
  }, [location]);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!e?.path?.includes(btnRef.current) && !e?.path?.includes(navRef.current)) {
        setShowItems(false);
      }
    };
    document.body.addEventListener('click', closeDropdown);

    return () => document.body.removeEventListener('click', closeDropdown);
  }, []);

  return branchOffice && user && (
    <Box py={2.5} px={4} className={classes.sideBar}>
      <Box display="flex" width={45} height={45}>
        <img src="/static/logo_aliados_white.svg" alt="logo_adomi_aliados" />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography
          variant="body2"
          style={{
            fontSize: 13,
            color: isOpen ? '#FFD500' : '#DDD',
            fontWeight: 700
          }}
        >
          {isOpen ? 'ON' : 'OFF'}
        </Typography>
        <Box className={classes.wrap} mt={0.5}>
          <input checked={isOpen} onChange={() => toggleOpen()} className={classes.input} type="checkbox" id="s1" />
          <label className={classes.toggle} htmlFor="s1" />
        </Box>
      </Box>
      <Box>
        <Box className={classes.profile}>
          <Box
            ref={btnRef}
            onClick={() => setShowItems((prev) => !prev)}
            className={clsx(
              classes.thumbail,
              classes.buttonProfile,
              {
                [classes.pulseProfile]: pendingOrders > 0 && location.pathname !== '/gerente/ordenes'
              }
            )}
          >
            <img
              src={branchOffice?.brand?.logo}
              alt={branchOffice?.brand?.name}
            />
          </Box>
          <Grow
            in={showItems}
            style={{
              transformOrigin: '325px 0 0',
              position: 'absolute',
              zIndex: 2,
              top: 60,
              right: 0
            }}
            {...(showItems ? { timeout: 1000 } : {})}
          >
            <Card ref={navRef} className={classes.dropdownNav}>
              <Box display="flex" alignItems="center" borderBottom="1px solid #CCC" px={2} pb={1.5}>
                <Box className={classes.thumbail}>
                  <img
                    src={branchOffice?.brand?.logo}
                    alt={branchOffice?.brand?.name}
                  />
                </Box>
                <Box ml={1.2} flexDirection="column">
                  <Typography style={{ fontSize: '15px' }} variant="h4">
                    {`${user?.first_name} ${user?.last_name}`}
                  </Typography>
                  <Box mt={0.5}>
                    <Typography style={{ fontSize: '13px', fontWeight: 400 }} variant="body2">{user?.phone}</Typography>
                  </Box>
                </Box>
              </Box>
              <List className={classes.tabs}>
                <ListItem className={classes.item} onClick={() => navigateTo('ordenes')}>
                  <Box
                    display="flex"
                    alignItems="center"
                    borderRadius={5}
                    className={clsx(
                      {
                        [classes.warning]: pendingOrders > 0 && location.pathname !== '/gerente/ordenes',
                        [classes.pulse]: pendingOrders > 0 && location.pathname !== '/gerente/ordenes',
                      }
                    )}
                  >
                    <ListAlt
                      color="primary"
                      className={clsx(
                        classes.icon,
                        {
                          [classes.warning]: pendingOrders > 0 && location.pathname !== '/gerente/ordenes'
                        }
                      )}
                    />
                    <Typography
                      className={clsx({ [classes.navItemSelect]: navItem.pathOrders })}
                      style={{ fontSize: '16px', fontWeight: 500 }}
                      variant="body2"
                    >
                      Pedidos
                    </Typography>
                    <Box ml={1.5} display="flex" alignItems="center" justifyContent="center" className={classes.totalOrders} width={25} height={25}>
                      <Typography style={{ fontWeight: 700, color: '#fff', fontSize: '13px' }} variant="h6">{totalOrders}</Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={classes.item} onClick={() => navigateTo('reporte')}>
                  <Box display="flex" alignItems="center">
                    <Assessment color="primary" className={classes.icon} />
                    <Typography
                      className={clsx({ [classes.navItemSelect]: navItem.pathReport })}
                      style={{ fontSize: '16px', fontWeight: 500 }}
                      variant="body2"
                    >
                      Reporte ventas
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem className={classes.item} onClick={() => navigateTo('productos')}>
                  <Box display="flex" alignItems="center">
                    <LocalMall color="primary" className={classes.icon} />
                    <Typography
                      className={clsx({ [classes.navItemSelect]: navItem.pathProducts })}
                      style={{ fontSize: '16px', fontWeight: 500 }}
                      variant="body2"
                    >
                      Productos
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem className={classes.item} onClick={() => closeSession()}>
                  <Box display="flex" alignItems="center">
                    <MeetingRoomSharp color="primary" className={classes.icon} />
                    <Typography style={{ fontSize: '16px', fontWeight: 500 }} variant="body2">Cerrar Sesión</Typography>
                  </Box>
                </ListItem>
              </List>
            </Card>
          </Grow>
        </Box>
      </Box>
    </Box>
  );
};

ToolbarManagers.propTypes = {
  branchOffice: PropTypes.object,
  totalOrders: PropTypes.number,
  pendingOrders: PropTypes.number,
  isOpen: PropTypes.bool,
};

export default ToolbarManagers;
