import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Timer = ({
  timer,
  valueTime,
  setTimer,
  index
  // cancelOrder,
}) => {
  const refInterval = useRef(null);
  let warning = false;
  // Obtiene por donde va el contador en tiempo real
  const getCreatAt = () => {
    // Parsea la fecha de cuando fue creada la orden
    const createdAt = new Date(timer?.createdAt);
    // Establece el limite del contador, en este caso su limite es 5min, que es donde debe empezar
    createdAt.setMinutes(createdAt.getMinutes() + valueTime);
    return createdAt;
  };

  // Obtiene cuanto tiempo ha pasado desde que se creo la orden
  // recibe como parametro la fecha cuando se creo la orden
  const getTimeRemainder = (createdAt) => {
    // Obtiene la diferencia de tiempo desde que se creo la orden y la hora actual
    const total = Date.parse(createdAt) - Date.parse(new Date());
    const sec = Math.floor((total / 1000) % 60); // Obtenemos los segundos
    const min = Math.floor((total / 1000 / 60) % 60); // Obtenemos los minutos
    return { total, sec, min };
  };

  // Me retorna el formato del contador (00:00)
  const getFormatTimer = (time) => {
    const { min, sec } = time;
    return `${min > 9 ? min : `0${min}`}:${sec > 9 ? sec : `0${sec}`}`;
  };

  // Me permite comenzar la cuenta regresiva
  const startTimer = () => {
    const createdAt = getCreatAt();

    // En caso de que llegue un nuevo contador borra el existente, para poder que no haya errores
    if (refInterval.current) clearInterval(refInterval.current);
    const id = setInterval(() => {
      const { total, min, sec } = getTimeRemainder(createdAt);
      // Solo en caso de que aún el contador no sea 00:00, se actualiza el timer cada segundo
      if (total >= 0) {
        if ((min === 2 && sec === 0) || (min < 2 && min >= 0 && sec >= 0)) {
          warning = true;
        }
        const timerData = {
          ...timer,
          warning,
          time: getFormatTimer({ min, sec }),
          idInterval: refInterval.current
        };
        setTimer(timerData, index);
      } else {
        // Por el contrario se borra el intervalo para que deje de comprabar cada segundo
        // cancelOrder(timer?.id);
        clearInterval(refInterval.current);
      }
    }, 1000);
    // Si aun no existe ningun contandor, se referencia para poder borrarlo en caso
    // de que llegue uno nuevo o se reinicie
    if (!refInterval.current) refInterval.current = id;
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(refInterval.current);
      refInterval.current = null;
    };
  }, []);

  return <div />;
};

Timer.propTypes = {
  timer: PropTypes.object,
  valueTime: PropTypes.number,
  setTimer: PropTypes.func,
  index: PropTypes.number
  // cancelOrder: PropTypes.func
};

export default Timer;
