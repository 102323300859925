import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 2px',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: '#d4edda',
      '& $title': {
        color: theme.palette.primary.main
      },
      '& $icon': {
        color: theme.palette.primary.main
      }
    }
  },
  icon: {
    marginRight: theme.spacing(1),
    color: '#bdbccf'
  },
  title: {
    color: '#bdbccf',
    fontWeight: 500,
    textAlign: 'left'
  },
  active: {
    color: theme.palette.primary.main,
  },
  curve: {
    width: 13,
    height: 25,
    clipPath: 'ellipse(67% 40% at 0% 50%)',
    backgroundColor: theme.palette.primary.main
  },
  select: {
    animation: '$select 0.5s ease both'
  },
  '@keyframes select': {
    '0%': {
      transform: 'scale(0)'
    },
    '50%': {
      transform: 'scale(1.2)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  active,
  index,
  navigateToTab,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Button
        className={classes.button}
        onClick={() => navigateToTab(index, href)}
      >
        <Box
          display="flex"
          alignItems="center"
          width={30}
        >
          <Box
            className={clsx(
              classes.curve,
              {
                [classes.select]: active
              }
            )}
            display={active ? 'flex' : 'none'}
          />
          <Box
            className={active ? classes.select : ''}
            style={{
              animationDelay: '0.5s'
            }}
            width={8}
            height={8}
            borderRadius="50%"
            bgcolor="#009739"
            display={active ? 'flex' : 'none'}
          />
        </Box>
        {Icon && (
          <Icon
            className={clsx(
              classes.icon,
              {
                [classes.active]: active
              }
            )}
            size="20"
          />
        )}
        <Typography
          variant="body2"
          className={clsx(
            classes.title,
            {
              [classes.active]: active
            }
          )}
        >
          {title}
        </Typography>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  active: PropTypes.bool,
  index: PropTypes.number,
  navigateToTab: PropTypes.func
};

NavItem.defaultProps = {
  className: '',
  href: '',
  icon: null,
  title: '',
  active: false,
  index: 0,
  navigateToTab: () => {}
};

export default NavItem;
