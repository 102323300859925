import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import { Apps } from '@material-ui/icons';
import Page from 'src/components/Page';
import LoadingBox from 'src/components/LoadingBox';
import Results from './Results';
import Toolbar from './Toolbar';

import { selector as UserSelector } from '../../../redux/ducks/user';

import BrandService from '../../../services/BrandService';

const useStyles = makeStyles((theme) => ({
  categoryTitle: {
    display: 'inline-block',
    marginRight: '1rem',
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main
  },
  titleEditable: {
    fontSize: '2.18rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.167,
    letterSpacing: '-0.24px',
    color: '#888',
    marginBottom: '.5rem'
  },
  saveIcon: {
    color: 'white'
  },
  editButton: {
    cursor: 'pointer',
    marginRight: '1rem'
  },
  root: {
    position: 'relative',
    backgroundColor: '#f6f6fa',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  container: {
    width: '100%',
    height: '100%'
  },
  loading: {
    height: '100%'
  }
}));

const ProductListView = () => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  const getProducts = async () => {
    setLoading(true);
    const products = await BrandService.getProducts(user.brand_id);

    if (products?.code === 200) {
      setCategories(products?.data?.brand_categories);
      setLoading(false);
    }
  };

  const refresh = async () => {
    if (user?.brand_id) {
      getProducts();
    }
  };

  useEffect(() => {
    if (user?.brand_id) {
      getProducts();
    }
  }, [user]);

  return (
    <Page
      className={clsx(classes.root, {
        [classes.loading]: loading
      })}
      title="Productos"
    >
      <Container className={classes.container} maxWidth={false}>
        <Toolbar refresh={refresh} />
        <LoadingBox
          loading={loading}
          bgcolor="transparent"
          title="Cargando Productos..."
        >
          {categories
            ?.filter((item) => item?.is_main)
            ?.map((category) => (
              <Box mt={3} key={category?.id}>
                <Box display="flex" alignItems="center">
                  <Apps
                    color="primary"
                    style={{
                      fontSize: 24
                    }}
                  />
                  <Typography variant="h3" className={classes?.categoryTitle}>
                    {category?.name}
                    {category?.is_available ? '' : ' (No Disponible)'}
                  </Typography>
                </Box>
                <Results
                  data={category?.products}
                  categoryIsAvailable={!category?.is_available}
                  onRefresh={refresh}
                />
              </Box>
            ))}
        </LoadingBox>
      </Container>
    </Page>
  );
};

export default ProductListView;
