import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Chip,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Edit as EditIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
} from '@material-ui/icons';

// utils
import orderBy from 'src/utils/arrays';
import getInitials from 'src/utils/getInitials';
import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles((theme) => ({
  root: {},
  edit: {
    cursor: 'pointer'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main,
  },
  false: {
    fill: '#aaa'
  },
  featured: {
    backgroundColor: '#1565C0',
    color: 'white',
  },
}));

const Results = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [products, setProducts] = useState([]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const goToEdit = (discountId) => {
    navigate(`/app/descuentos/${discountId}`, { replace: true });
  };

  const validateIfDiscountIsActive = (d) => {
    if (dayjs().isAfter(dayjs(d?.start_date)) && dayjs(d?.end_date).isAfter(dayjs())) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (Array.isArray(data)) {
      setProducts(data);
    }
  }, [data]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nombre
                </TableCell>
                <TableCell />
                <TableCell width="120">
                  Precio Inicial
                </TableCell>
                <TableCell width="160">
                  Descuento
                </TableCell>
                <TableCell width="120">
                  Precio Final
                </TableCell>
                <TableCell width="260">
                  Disponible
                </TableCell>
                <TableCell width="60" />
                <TableCell width="60" />
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(products, 'name').slice(0, limit).map((product) => (
                <TableRow
                  hover
                  key={product.product?.id}
                >
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Avatar
                        className={classes.avatar}
                        src={product.product?.primary_image}
                      >
                        {getInitials(product.name)}
                      </Avatar>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {product.product?.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {
                      product.product?.discount?.is_featured && (
                        <Chip
                          label="Destacado"
                          className={classes?.featured}
                        />
                      )
                    }
                  </TableCell>
                  <TableCell>
                    $
                    <CurrencyFormat value={product.product?.price} displayType="text" thousandSeparator="." decimalSeparator="," />
                  </TableCell>
                  <TableCell>
                    $
                    <CurrencyFormat value={product?.product?.price - product.product?.discount?.discount_price} displayType="text" thousandSeparator="." decimalSeparator="," />
                    {' '}
                    (
                    <CurrencyFormat value={product.product?.discount?.discount_percentage} displayType="text" thousandSeparator="." decimalSeparator="," />
                    %)
                  </TableCell>
                  <TableCell>
                    $
                    <CurrencyFormat value={product.product?.discount?.discount_price} displayType="text" thousandSeparator="." decimalSeparator="," />
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      component="p"
                      className={
                        dayjs().isAfter(dayjs(product?.product?.discount?.start_date))
                          ? classes?.dateAfter
                          : {}
                      }
                    >
                      Desde
                      {' '}
                      {dayjs(product?.product?.discount?.start_date).format('DD [de] MMMM, YYYY hh:mm A')}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="p"
                      className={
                        dayjs().isAfter(dayjs(product?.product?.discount?.end_date))
                          ? classes?.dateAfter
                          : {}
                      }
                    >
                      Hasta
                      {' '}
                      {dayjs(product?.product?.discount?.end_date).format('DD [de] MMMM, YYYY hh:mm A')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <RadioButtonCheckedIcon
                      className={
                        validateIfDiscountIsActive(product?.product?.discount)
                          ? classes?.true
                          : classes?.false
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <EditIcon
                      className={classes.edit}
                      onClick={() => goToEdit(product?.product?.discount?.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={products.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired
};

export default Results;
