import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { CancelRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  overlay: {
    backgroundColor: 'rgba(46, 44, 54, 0.4)',
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
    zIndex: 3,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  modal: {
    borderRadius: '8px',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    animation: 'showPopUp 0.5s',
    position: 'fixed',
    bottom: 0,
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      height: 'fit-content',
    },
  },
  pane: {
    height: '100%',
    width: '100%',
    position: 'absolute'
  },
  header: {
    padding: '10px 15px',
    borderBottom: '1px solid #e6ebf1',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > h3': {
      fontWeight: 'bold',
      fontSize: '22px'
    }
  },
}));

/*
Para el buen funcionamiento del componente deben pasarle por las props el estado isOpen
y la función toggle
*/
const Modal = ({
  className, // Estilos del root Modal
  isOpen,
  toggle,
  children,
  customHeader: Header, // Si desea un encabezado personalizado esta prop recibe un componente
  title, // El titulo del encabezado por defecto
  bodyClass, // estilos del cuerpo del Modal
  showHeader, // Si no desea un encabezado en el Modal envie un false en esta prop
  width // Establece el ancho del Modal (Solo aplica apartir de pantallas mayores a 600px)
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return isOpen ? (
    <Box
      className={classes?.overlay}
    >
      <Box
        className={classes.pane}
        onClick={() => toggle()}
      />
      <Box
        className={clsx(
          classes?.modal,
          className
        )}
        width={matches ? width : '100%'}
      >
        {showHeader && (
          <>
            {Header ? (
              <Header />
            ) : (
              <Box className={classes.header}>
                <Box className={classes.title}>
                  <Typography variant="h3">
                    {title}
                  </Typography>
                  <CancelRounded
                    style={{ cursor: 'pointer' }}
                    color="primary"
                    onClick={() => toggle()}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
        <Box
          className={bodyClass}
          p={2.5}
          display="flex"
          flexDirection="column"
          textAlign="center"
          alignItems="center"
        >
          {children}
        </Box>
      </Box>
    </Box>
  ) : null;
};

Modal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.elementType,
  customHeader: PropTypes.elementType,
  title: PropTypes.string,
  bodyClass: PropTypes.string,
  showHeader: PropTypes.bool,
  width: PropTypes.number
};

Modal.defaultProps = {
  className: '',
  isOpen: false,
  toggle: () => {},
  children: '',
  customHeader: null,
  title: '',
  bodyClass: '',
  showHeader: true,
  width: 600
};

export default Modal;
