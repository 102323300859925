import HttpRequest from './HttpRequest';

export default class ComponentCategoryService extends HttpRequest {
  static endpoint = 'component-categories';

  static async getAll() {
    this.endpoint = 'component-categories';
    const response = await this.get();
    return response;
  }
}
