import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Typography,
  makeStyles,
  Button,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 50,
    width: 200,
    fontSize: 14,
    paddingTop: theme.spacing(1.4),
    paddingBottom: theme.spacing(1.4),
    backgroundColor: 'transparent',
    border: '1px solid #f3feff',
    color: '#f3feff',
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      border: '1px solid #009739',
    },
  },
  overlayPanel: {
    transform: 'translateX(0)',
    transition: 'transform 0.6s ease-in-out'
  }
}));

const OverlayPanel = ({
  className,
  title,
  description,
  setForm,
  isLoginDash
}) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(
        className,
        classes.overlayPanel
      )}
      height="100%"
      width="50%"
      display="flex"
      flexDirection="column"
      position="absolute"
      top={0}
      textAlign="center"
    >
      <Box
        width={110}
        height={38}
        m={3}
        display={isLoginDash ? 'block' : 'none'}
      >
        <img src="/static/logo_aliados_white.svg" alt="logo_adomi_aliados" />
      </Box>
      <Box
        display="flex"
        px={8}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        height={isLoginDash ? '70%' : '100%'}
      >
        <Typography
          variant="h2"
          style={{
            color: '#f3feff',
            fontSize: 35
          }}
        >
          {title}
        </Typography>
        <Box my={2.5}>
          <Typography
            variant="body2"
            style={{
              color: '#f3feff'
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mt={1}
        >
          <Button
            className={classes.button}
            onClick={setForm}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Iniciar Sesión
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

OverlayPanel.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  setForm: PropTypes.func,
  isLoginDash: PropTypes.bool
};

OverlayPanel.defaultProps = {
  className: '',
  title: '',
  description: '',
  setForm: () => {},
  isLoginDash: false
};

export default OverlayPanel;
