import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Select,
  FormControl,
  TextField,
  MenuItem,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import { selector as UserSelector } from 'src/redux/ducks/user';
import DiscountService from 'src/services/DiscountService';
import CurrencyFormat from 'react-currency-format';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  addButton: {
    cursor: 'pointer'
  },
  editButton: {
    cursor: 'pointer'
  },
  removeButton: {
    marginLeft: '1rem',
    cursor: 'pointer'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateDiscountForm = ({
  className, discount, products, edit, onChange, setLoading, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const { user } = useSelector(UserSelector);
  const [productSelected, setProductSelected] = useState(null);

  const handleChange = (event) => {
    onChange({
      ...discount,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];

    const data = {
      product_id: e.target?.product_id?.value,
      discount_percentage: Number(e.target?.discount_percentage?.value || 0),
      start_date: e.target?.start_date?.value,
      end_date: e.target?.end_date?.value,
      is_featured: e.target?.is_featured?.value === 'true'
    };

    if (data.product_id.length === 0) {
      errors.push('El producto base es obligatorio');
    }
    if (data.discount_percentage.length === 0) {
      errors.push('El porcentaje es obligatorio');
    }
    if (data?.discount_percentage < 0 || data?.discount_percentage === 0
      || data?.discount_percentage > 100) {
      errors.push('El porcentaje del descuento es inválido');
    }
    if (data.start_date === '') {
      errors.push('La fecha de inicio es obligatoria');
    }
    if (data.end_date === '') {
      errors.push('La fecha de expiración es obligatoria');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    // setLoading(true);

    const response = edit
      ? await DiscountService.update(discount?.id, data)
      : await DiscountService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setLoading(false);
      navigate('/app/descuentos', { replace: true });
    } else {
      setLoading(false);
      setErrors(response?.errors || []);
    }
  };

  const selectProduct = (e) => {
    setProductSelected(products?.find(({ id }) => id === e.target.value));
    onChange({
      ...discount,
      product_id: e.target.value,
      discount_price: '',
      discount_percentage: '',
    });
  };

  const deleteDiscount = async () => {
    if (discount?.id) {
      const response = await DiscountService.remove(discount?.id);

      if (response?.code) {
        setErrors(['No fue posible eliminar el descuento']);
        return;
      }

      navigate('/app/descuentos', { replace: true });
    }
  };

  const removeDiscount = () => {
    toggleModal();
  };

  useEffect(() => {
    if (edit && discount?.id && products?.length > 0) {
      setProductSelected(products?.find(({ id }) => id === discount?.product_id));
    }
  }, [edit, discount, user, products]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Descuento de Producto"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="product">Selecciona un producto</InputLabel>
                <Select
                  required
                  name="product_id"
                  labelId="product"
                  onChange={selectProduct}
                  disabled={edit}
                  label="Selecciona un producto"
                  value={discount?.product_id}
                >
                  <MenuItem value="0">Selecciona un producto</MenuItem>
                  {
                    products
                      ?.filter((product) => (
                        edit ? product?.id === discount?.product_id : !product?.on_discount
                      ))
                      ?.map((product) => (
                        <MenuItem
                          key={product.id}
                          value={product.id}
                        >
                          { product.name }
                        </MenuItem>
                      ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              /*
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  label="Precio"
                  name="price"
                  disabled
                  required
                  variant="outlined"
                  placeholder="Precio"
                  value={productSelected?.price}
                />
              </Grid>
              */
            }
            <Grid
              item
              md={6}
              xs={12}
            >
              <CurrencyFormat
                thousandSeparator="."
                decimalSeparator=","
                customInput={TextField}
                fullWidth
                inputProps={{ min: 0, max: 100 }}
                label="Porcentaje"
                name="discount_percentage"
                onChange={handleChange}
                required
                disabled={productSelected === null}
                value={discount?.discount_percentage}
                variant="outlined"
              />
            </Grid>
            {
              /*
              <Grid
                item
                xs={6}
              >
                <TextField
                  fullWidth
                  type="number"
                  inputProps={{ min: 1, max: productSelected?.price }}
                  label="Precio de descuento"
                  name="discount_price"
                  onChange={handleChange}
                  disabled={productSelected === null}
                  value={discount?.discount_price}
                  variant="outlined"
                />
              </Grid>
              */
            }
            <Grid
              item
              xs={6}
            >
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                type="datetime-local"
                label="Fecha de inicio"
                name="start_date"
                onChange={handleChange}
                required
                disabled={productSelected === null}
                value={discount?.start_date?.replace('Z', '')}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                label="Fecha de expiración"
                name="end_date"
                onChange={handleChange}
                required
                type="datetime-local"
                disabled={productSelected === null}
                value={discount?.end_date?.replace('Z', '')}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="is_featured">Es Destacado</InputLabel>
                <Select
                  required
                  name="is_featured"
                  labelId="Es Destacado"
                  label="Es Destacado?"
                  value={discount?.is_featured === true}
                  onChange={handleChange}
                >
                  {
                    [true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer ? 'Si' : 'No'}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {
            edit && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={removeDiscount}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={productSelected === null}
          >
            { edit ? 'Editar' : 'Guardar' }
          </Button>
        </Box>
      </Card>
      <ConfirmationModal
        onAccept={deleteDiscount}
      />
    </form>
  );
};

CreateDiscountForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  products: PropTypes.array,
  setLoading: PropTypes.func,
  discount: {
    product_id: PropTypes.string,
    discount_percentage: PropTypes.number,
    discount_price: PropTypes.number,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    is_featured: PropTypes.bool
  }
};

CreateDiscountForm.defaultProps = {
  className: '',
  edit: false,
  onChange: () => {},
  products: [],
  setLoading: () => {},
  discount: {
    product_id: '',
    discount_percentage: '',
    discount_price: 0,
    start_date: '',
    end_date: '',
    is_featured: false
  }
};

export default CreateDiscountForm;
