import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import { DragHandle as DragHandleIcon } from '@material-ui/icons';

const useStyles = makeStyles({
  handleIcon: {
    cursor: 'row-resize'
  },
});

const HandleIcon = ({ className }) => {
  const classes = useStyles();

  return (
    <DragHandleIcon className={`${classes?.handleIcon} ${className}`} />
  );
};

HandleIcon.propTypes = {
  className: PropTypes.string
};

HandleIcon.propTypes = {
  className: ''
};

export default SortableHandle(HandleIcon);
