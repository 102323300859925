import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  List,
  ListItem,
  Slide,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import {
  CancelRounded,
  Add,
} from '@material-ui/icons';
import ToppingCard from './ToppingCard';

const ToppingsAside = ({
  toppings,
  toppingsAttachment,
  onChangeCustomize,
  show,
  toggleAside,
  toggleModal
}) => {
  const matchesXs = useMediaQuery('screen and (min-width: 370px)');
  const matchesSm = useMediaQuery('screen and (min-width: 600px)');
  const matches = useMediaQuery('screen and (min-width: 750px)');

  const useStyles = makeStyles((theme) => ({
    aside: {
      position: 'fixed',
      zIndex: 3,
      top: matchesSm ? '64px' : '57px',
      backgroundColor: '#fff',
      width: matches ? '450px' : '100%',
      right: 0,
      height: matchesSm ? 'calc(100% - 64px)' : 'calc(100% - 57px)',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },
    pane: {
      backgroundColor: 'rgba(46, 44, 54, 0.2)',
      bottom: 0,
      height: '100%',
      width: '100%',
      position: 'fixed',
      zIndex: 2,
    },
    header: {
      padding: '10px 15px',
      borderBottom: '1px solid #e6ebf1',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    title: {
      fontWeight: 'bold',
      fontSize: '20px'
    },
    icon: {
      cursor: 'pointer',
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      padding: '2px',
      borderRadius: '50%',
    },
    addTopping: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main
      },
      transition: 'color 0.2s ease 0s',
    },
    scroll: {
      overflowY: 'auto',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '20px',
        backgroundColor: theme.palette.primary.main
      }
    }
  }));

  const classes = useStyles();

  const attachTopping = (topping) => {
    toppingsAttachment.push(topping);
    onChangeCustomize(toppingsAttachment.slice());
  };

  const disAttachTopping = (index) => {
    toppingsAttachment.splice(index, 1);
    onChangeCustomize(toppingsAttachment.slice());
  };

  return (
    <>
      {show && (
        <Box className={classes.pane} onClick={() => toggleAside()} />
      )}
      <Slide timeout={450} direction="left" in={show} mountOnEnter unmountOnExit>
        <Box className={classes.aside}>
          <Box className={classes.header}>
            <Typography className={classes.title} variant="h3">Toppings</Typography>
            <CancelRounded
              style={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => toggleAside()}
            />
          </Box>
          <Box height="100%" py={2.5}>
            <Box onClick={() => toggleModal()} className={classes.addTopping} display="flex" justifyContent="center">
              <Box
                mb={2.5}
                mx={1}
                className={classes.icon}
              >
                <Add style={{ color: '#fff', fontSize: '17px' }} fontSize="small" />
              </Box>
              <Typography variant="h5">Crear Topping</Typography>
            </Box>
            <Box className={classes.scroll} height="calc(100% - 64px)">
              <Box px={matchesXs ? 2.5 : 1}>
                <Typography style={{ color: '#757575' }} variant="body2">
                  Toppings asociados al producto
                </Typography>
                <List>
                  {toppingsAttachment?.map((topping, index) => (
                    <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <ToppingCard
                        topping={topping}
                        index={index}
                        disAttachTopping={disAttachTopping}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box mt={2.5} px={matchesXs ? 2.5 : 1}>
                <Typography style={{ color: '#757575' }} variant="body2">
                  Lista de toppings
                </Typography>
                <List>
                  {toppings.map((topping) => (
                    <>
                      {!toppingsAttachment.some((item) => item.id === topping.id) && (
                        <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                          <ToppingCard
                            association
                            topping={topping}
                            attachTopping={attachTopping}
                          />
                        </ListItem>
                      )}
                    </>
                  ))}
                </List>
              </Box>
            </Box>
          </Box>
        </Box>
      </Slide>
    </>
  );
};

ToppingsAside.propTypes = {
  toppings: PropTypes.array,
  toppingsAttachment: PropTypes.array,
  onChangeCustomize: PropTypes.func,
  show: PropTypes.bool,
  toggleAside: PropTypes.func,
  toggleModal: PropTypes.func
};

export default ToppingsAside;
