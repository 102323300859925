import { useSelector, useDispatch } from 'react-redux';
import { actions as ModalActions, selector as ModalSelector } from '../redux/ducks/modal';

const useConfirmation = () => {
  const dispatch = useDispatch();
  const { modal } = useSelector(ModalSelector);

  const toggleModal = () => {
    dispatch(ModalActions.set(!modal));
  };

  return {
    modalIsOpen: modal,
    toggleModal
  };
};

export default useConfirmation;
