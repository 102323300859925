import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  makeStyles,
  Container,
  Typography,
  Card,
  Box,
  Button,
  CircularProgress
} from '@material-ui/core';
import { Room, ArrowBackIosRounded } from '@material-ui/icons';
import { VscPackage } from 'react-icons/vsc';
import isContainLocation from 'src/utils/isContainLocation';
import EnvironmentService from 'src/services/EnviromentService';
import PolygonService from 'src/services/PolygonService';
import OrderService from 'src/services/OrderService';
import Page from 'src/components/Page';
import GoogleMapsParcel from 'src/components/GoogleMapsParcel';
import WarningModal from 'src/components/WarningModal';
import BranchOfficeService from 'src/services/BranchOfficeService';
import SucessCheck from 'src/components/SucessCheck';
import Error from 'src/components/Error';
import ProgressBar from './components/ProgressBar';
import AddressManagment from './AddressManagment';
import OrderCost from '../../../components/OrderCost';
import CommodityManagment from './CommodityManagment';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f6f6fa',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  title: {
    marginLeft: theme.spacing(1),
    fontWeight: 600,
  },
  card: {
    position: 'relative',
    overflow: 'unset',
    height: '100%',
    borderRadius: 20,
    backgroundColor: '#f4f7fa',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
  },
  container: {
    paddingLeft: 20,
    paddingRight: 20,
    height: '100%'
  },
  cardCost: {
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
  },
  scaleAnimation: {
    animation: '$scale 0.7s ease both'
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(0)'
    },
    '30%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

const items = [
  {
    key: 1,
    title: 'Direcciones',
    icon: Room,
  },
  {
    key: 2,
    title: 'Mercancía',
    icon: VscPackage
  }
];

const mapStyles = {
  width: '100%',
  minWidth: '100%',
  height: '100%',
  minHeight: '100%',
};

const RequestServiceView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [addressA, setAddressA] = useState({});
  const [addressB, setAddressB] = useState({
    id: '',
    address1: '',
    city_id: '',
    latitude: 0,
    longitude: 0,
    alias: null,
    reference: '',
    address_type: '',
    complement: '',
    is_active: true,
    city: {
      id: '',
      name: '',
      longitude: 0,
      latitude: 0,
      is_available: true,
      support_google_maps: true,
      is_coming_soon: false,
      state: null
    },
    neighborhood: '',
    street: '',
    street_number: ''
  });
  const [roundtrip, setRoundtrip] = useState(false);
  const [distance, setDistance] = useState(0);
  const [deliveryCost, setDeliveryCost] = useState(0);
  const [productLogistic, setProductLogistic] = useState({
    product_id: '',
    name: '',
    description: '',
    image: '',
    price: '',
    customize: {},
    total: 0,
    amount: 0,
    comment: '',
    prices: [],
    discount: {},
    has_discount: false
  });
  const [googleMapStyles, setGoogleMapStyles] = useState(null);
  const [destination, setDestination] = useState(0);
  const [polygons, setPolygons] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState(false);
  const [insurance, setInsurance] = useState(false);
  const [tip, setTip] = useState(0);
  const [collect, setCollect] = useState('pick_up_point');
  const getGoogleMapStyles = async () => {
    const response = await EnvironmentService.getByKey('monitor_google_maps_style');
    if (response?.code === 200) {
      setGoogleMapStyles(JSON.parse(response?.data?.value));
    }
  };

  const validateStep = () => {
    if (destination === 0) {
      return Object.keys(addressA).length === 0 || addressB?.address1.length === 0;
    }
    if (destination === 1) {
      return productLogistic?.product_id?.length === 0 || Number(productLogistic?.price) === 0;
    }
    return false;
  };

  const alertError = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 2000);
  };

  const requestService = async () => {
    setLoading(true);
    const price = Number(productLogistic?.price.replace('.', ''));
    const orderProduct = {
      ...productLogistic,
      price,
      total: price,
      prices: [
        {
          is_default: true,
          name: `Paquete ${productLogistic?.name}`,
          price,
          value: price
        }
      ]
    };
    delete orderProduct.image;
    delete orderProduct.description;
    const order = {
      coupon_id: null,
      branch_office_id: addressA?.id,
      branch_office_name: addressA?.name,
      manager_id: null,
      tip,
      tip_partner: 0,
      total: price + deliveryCost,
      cash_paid: 0,
      delivery_cost: deliveryCost,
      address: addressB,
      order_products: [orderProduct],
      data_branch_office: addressA,
      data_client: {
        id: '',
        phone: '',
        email: '',
        avatar: '',
        first_name: '',
        last_name: '',
        birthdate: new Date(),
        email_confirmed: false,
        is_guest: false,
        active_address: addressB,
        gender: '',
        is_develop: false,
      },
      ship_type: 'adomi',
      free_delivery: false,
      comment: '',
      token: null,
      coupon_data: null,
      coupon_value: 0,
      sub_total: price,
      status_rating: 'pending',
      completed_by: null,
      payment_method: 'cash',
      transaction_id: null,
      has_called: null,
      has_android: null,
      transaction_cost: deliveryCost * 0.2,
      payment_date: null,
      transaction_type: null,
      type: 'logistic',
      scheduled_time: null,
      origin: 'web',
      distance,
      adomi_bonus: 0,
      roundtrip,
      where_pays_delivery: collect,
      insurance_value: insurance && price <= 200000 ? price * 0.01 : 0
    };
    let idOrder = '';
    try {
      const response = await OrderService.createOrder(order);
      if (!response.success) {
        setLoading(false);
        alertError();
        return;
      }
      idOrder = response?.data?.id;
    } catch (err) {
      console.error(err);
      alertError();
      return;
    }
    setLoading(false);
    setFinished(true);
    setTimeout(() => {
      navigate(`/app/paqueteria/${idOrder}`, { replace: true });
    }, 2000);
  };

  const nextStep = () => {
    if (destination < items.length - 1) setDestination((prev) => prev + 1);
  };

  const prevStep = () => {
    if (destination === 0) {
      navigate('/app/paqueteria', { replace: true });
      return;
    }
    if (destination > 0) setDestination((prev) => prev - 1);
  };

  const getDeliveryCostAndDistance = async () => {
    const response = await BranchOfficeService.getDeliveryCostAndDistance(addressA?.id, {
      lat: addressB?.latitude,
      lng: addressB?.longitude
    }, addressA?.city_id);
    if (response?.code === 200) {
      setDistance(response?.data?.distance_km);
      setDeliveryCost(response?.data?.delivery_cost);
    }
  };

  const fetchPolygons = async () => {
    const response = await PolygonService.getByCityId(addressA?.city_id);
    setPolygons(
      response?.data?.map((item) => {
        const paths = item?.points?.map((point) => {
          return { lat: point[0], lng: point[1] };
        });

        return {
          ...item,
          paths
        };
      }) || []
    );
  };

  const checkAddressContain = (positionAddress) => {
    let count = 0;
    polygons.forEach((polygon) => {
      if (isContainLocation(
        positionAddress,
        polygon?.paths
      )) count++;
    });
    return count >= 1;
  };

  const handleAddressA = (branchOffice) => {
    setAddressA(branchOffice);
  };

  const handleAddressB = (address) => {
    setAddressB(address);
  };

  const toggleWarning = () => {
    setShowWarning((prev) => !prev);
  };

  useEffect(() => {
    getGoogleMapStyles();
  }, []);

  useEffect(() => {
    if (addressA?.city_id?.length !== 0) fetchPolygons();
  }, [addressA?.city_id]);

  useEffect(() => {
    if (Object.keys(addressA).length !== 0 && addressB?.address1?.length !== 0) {
      getDeliveryCostAndDistance();
    }
  }, [
    addressA?.latitude, addressA?.longitude,
    addressB?.latitude, addressB?.longitude
  ]);

  useEffect(() => {
    if (roundtrip) {
      setDistance((prev) => prev * 2);
      setDeliveryCost((prev) => prev * 2);
    } else {
      setDistance((prev) => prev / 2);
      setDeliveryCost((prev) => prev / 2);
    }
  }, [roundtrip]);

  return (
    <Page
      title="Paquetería"
      className={classes.root}
    >
      <Container className={classes.container}>
        <Card className={classes.card}>
          <Box
            display="flex"
            p={1}
            height="100%"
          >
            <Box
              width="50%"
              height="100%"
              py={2}
            >
              {!finished ? (
                <Box
                  width="100%"
                  height="100%"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    px={1.5}
                    mb={4.5}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <ArrowBackIosRounded
                        color="primary"
                        style={{
                          cursor: 'pointer',
                          fontSize: '20px',
                          marginRight: 5
                        }}
                        onClick={() => prevStep()}
                      />
                      <Typography color="primary" variant="h3">
                        Paquetería
                        {' '}
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 400
                          }}
                        >
                          / Solicitar servicio
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    px={2}
                    mb={2}
                  >
                    <ProgressBar
                      destination={destination}
                      items={items}
                    />
                  </Box>
                  {destination === 0 && (
                    <AddressManagment
                      toggleWarning={toggleWarning}
                      addressA={addressA}
                      addressB={addressB}
                      handleAddressA={handleAddressA}
                      handleAddressB={handleAddressB}
                      checkAddressContain={checkAddressContain}
                      roundtrip={roundtrip}
                      setRoundtrip={setRoundtrip}
                      setTip={setTip}
                      tip={tip}
                    />
                  )}
                  {destination === 1 && (
                    <CommodityManagment
                      productLogistic={productLogistic}
                      setProductLogistic={setProductLogistic}
                      setInsurance={setInsurance}
                      insurance={insurance}
                      setCollect={setCollect}
                      collect={collect}
                    />
                  )}
                  {destination <= items.length - 1 && (
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="center"
                      pr={0.5}
                    >
                      <Button
                        style={{
                          width: '95%',
                        }}
                        disabled={loading || validateStep()}
                        startIcon={loading && destination === 1 ? <CircularProgress size={20} /> : ''}
                        size="medium"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          if (destination === 1) {
                            requestService();
                            return;
                          }
                          nextStep();
                        }}
                      >
                        {destination === 1 ? 'Finalizar' : 'Siguiente'}
                      </Button>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.scaleAnimation}
                >
                  <SucessCheck text="Servicio completado con exito" />
                </Box>
              )}
            </Box>
            <Box
              width="50%"
              position="relative"
            >
              <Box
                display="flex"
                justifyContent="center"
                pb={1}
              >
                <OrderCost
                  className={classes.cardCost}
                  distance={distance}
                  deliveryCost={deliveryCost}
                />
              </Box>
              {googleMapStyles && (
                <GoogleMapsParcel
                  toggleWarning={toggleWarning}
                  polygons={polygons}
                  addressA={addressA}
                  addressB={addressB}
                  origin={{
                    lat: addressA?.latitude || 0,
                    lng: addressA?.longitude || 0
                  }}
                  destination={{
                    lat: addressB?.latitude || 0,
                    lng: addressB?.longitude || 0
                  }}
                  checkAddressContain={checkAddressContain}
                  setAddressB={handleAddressB}
                  draggableB={destination === 0}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                  styles={googleMapStyles}
                  loadingElement={<div style={mapStyles} />}
                  containerElement={<div style={{ width: '100%', height: '100%' }} />}
                  mapElement={<div style={mapStyles} />}
                />
              )}
            </Box>
          </Box>
          {error && (
            <Box
              position="absolute"
              zIndex={1}
              top={0}
              width="50%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor="rgba(255, 255, 255, 0.5)"
              borderRadius={20}
              className={classes.scaleAnimation}
              style={{
                animationDuration: '0.3s',
                backdropFilter: 'blur(4px)'
              }}
            >
              <Error text="Hubo un error en la solicitud del servicio" />
            </Box>
          )}
        </Card>
      </Container>
      {showWarning && (
        <WarningModal
          toggleWarning={toggleWarning}
          text="La dirección seleccionada esta fuera de la zona de cobertura"
        />
      )}
    </Page>
  );
};

export default RequestServiceView;
