import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import pusher from 'src/utils/pusher';
import dayjs from 'dayjs';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import OrderService from '../../../services/OrderService';
import Results from './Results';
import Toolbar from './Toolbar';

import orderBy from '../../../utils/arrays';

import { selector as UserSelector } from '../../../redux/ducks/user';
import { actions as AlertActions } from '../../../redux/ducks/alert';

const useStyles = makeStyles((theme) => ({
  categoryTitle: {
    display: 'inline-block',
    marginRight: '1rem'
  },
  titleEditable: {
    fontSize: '2.18rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.167,
    letterSpacing: '-0.24px',
    color: '#888',
    marginBottom: '.5rem',
  },
  saveIcon: {
    color: 'white',
  },
  editButton: {
    cursor: 'pointer'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const OrderListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(UserSelector);
  const [date, setDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState(null);
  const [search, setSearch] = useState(null);
  const [isDev, setIsDev] = useState(false);
  const [isDevelop, setIsDevelop] = useState(false);
  const [branchOfficesSelected, setBranchOfficesSelected] = useState(user?.branch_office_id);

  const getOrders = async () => {
    const response = await OrderService.getByBranchOfficeId(
      branchOfficesSelected,
      date || null,
      isDevelop
    );

    if (response?.data) {
      const data = response?.data?.orders.reduce((array, item) => {
        let statusOrder = 0;
        switch (item?.status) {
          case 'in_progress':
            statusOrder = 1;
            break;
          case 'finished':
            statusOrder = 2;
            break;
          case 'rejected':
            statusOrder = 3;
            break;
          case 'cancelled':
            statusOrder = 4;
            break;
          default:
            statusOrder = 0;
        }

        return array.concat({ ...item, statusOrder });
      }, []);

      setOrders(orderBy(data, 'statusOrder'));
    }
  };

  const refresh = async () => {
    if (user?.id) {
      getOrders();
    }
  };

  const handleFilter = (value) => setFilter(value);

  const handleSearch = (value) => setSearch(value);

  const handleDev = (e) => {
    setIsDev(e?.target?.checked);
  };

  const filterDev = (ors) => {
    if (isDev) return ors;

    return ors?.filter((order) => (
      !order?.data_branch_office?.is_develop && !order?.data_branch_office?.is_demo
    ));
  };

  const filterData = (ors) => {
    if (!filter) return ors;

    return ors?.filter((order) => (
      order?.status === filter || order?.payment_method === filter
    ));
  };

  const searchData = (ors) => {
    if (!search) return ors;

    return ors?.reduce((array, order) => {
      if (order?.reference?.toString()?.search(search) !== -1) {
        return array.concat(order);
      }
      return array;
    }, []);
  };

  const handleDate = (d) => setDate(d);

  useEffect(() => {
    if (user?.id) {
      getOrders();
      const channel = pusher.subscribe(`BRANCHOFFICE_${branchOfficesSelected}`);

      ['create-order', 'changes-orders', 'new-order', 'create-order-product', 'create-order-status', 'command-order', 'finish-order', 'client-cancel-order', 'manager-cancel-order']
        .map((item) => {
          channel.bind(item, () => {
            refresh();
            if (['create-order', 'changes-orders']?.some((event) => event === item)) {
              dispatch(AlertActions.set());
            }
          });
          return item;
        });
    }
  }, [user, branchOfficesSelected]);

  useEffect(() => {
    refresh();
  }, [date, branchOfficesSelected]);

  useEffect(() => {
    setBranchOfficesSelected(user?.branch_office_id);
  }, []);

  return (
    <Page
      className={classes.root}
      title="Ordenes en progreso"
    >
      <Container maxWidth={false}>
        <Toolbar
          branchOfficesSelected={branchOfficesSelected}
          setBranchOfficesSelected={setBranchOfficesSelected}
          date={date}
          refresh={refresh}
          onSearch={handleSearch}
          onChange={handleFilter}
          onChangeDate={handleDate}
          onDev={handleDev}
          setIsDevelop={setIsDevelop}
        />
        <Box mt={3}>
          <Results
            data={searchData(filterData(filterDev(orders)))}
            onRefresh={refresh}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default OrderListView;
