import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Box,
  Typography,
  Card,
  Collapse,
  Table,
  TableCell,
  IconButton,
  makeStyles,
  useMediaQuery,
  Button,
  FormControl,
  TextField
  // FormHelperText
} from '@material-ui/core';
import {
  Add,
  Delete,
  Edit,
  // IconButton,
  KeyboardArrowDownRounded
} from '@material-ui/icons';
import TableBodySortable from 'src/components/Table/TableBodySortable';
import TableRowSortable from 'src/components/Table/TableRowSortable';
import SortableHandleIcon from 'src/components/Icons/SortableHandleIcon';
import clsx from 'clsx';
import { v4 } from 'uuid';
import Modal from 'src/components/Modal';
import useModal from '../../../hooks/useModal';

function FilterCard({
  filter,
  sendOptions,
  indexFilter,
  handleDeleteFilter,
  handleEditarFiltro
}) {
  const [showSizes, setShowSizes] = useState(false);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      minHeight: '100px',
      width: '100%'
    },
    description: {
      marginTop: '3px',
      fontSize: '12px',
      color: '#757575'
    },
    dropdown: {
      color: '#757575',
      transform: showSizes ? 'rotate(180deg)' : 'rotate(0deg)',
      transition: 'transform 0.3s ease 0s'
    },
    required: {
      width: 'fit-content',
      backgroundColor: '#ff3364',
      borderBottomRightRadius: '5px',
      color: '#fff',
      padding: '5px 8px'
    },
    icon: {
      cursor: 'pointer',
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      padding: '2px',
      borderRadius: '50%',
      marginRight: '8px'
    },
    sortableIcon: {
      color: theme.palette.primary.main,
      fontSize: '22px',
      marginRight: '10px'
    },
    btn: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#2e7d32'
      },
      transition: 'background 0.2s ease 0s'
    }
  }));
  const classes = useStyles();

  const matches = useMediaQuery('screen and (min-width: 465px)');
  const [isOpenAdd, toggleAdd] = useModal();
  const [isOpenEdit, toggleEdit] = useModal();
  const [isOpenDelete, toggleDelete] = useModal();
  const [isOpenEditSize, toggleEditSize] = useModal();
  const [isOpenDeleteSize, toggleDeleteSize] = useModal();
  const [options, setOptions] = useState([]);
  const [editOption, setEditOption] = useState('');
  const [idDelete, setIdDelete] = useState('');
  const [editName, setEditName] = useState('');
  const [deleteFilter, setDeleteFilter] = useState('');
  const [editFilter, setEditFilter] = useState('');

  const handleAddOption = () => {
    if (editOption === '') {
      toggleAdd();
      return;
    }
    const newOption = {
      id: v4(),
      name: editOption
    };
    sendOptions([...options, newOption], indexFilter);
    setEditOption('');
    toggleAdd();
  };

  const handleDelete = (id) => {
    setIdDelete(id);
    toggleDeleteSize();
  };

  const handleEdit = (id, iterator) => {
    setEditName(filter?.options[iterator]?.name);
    setIdDelete(id);
    toggleEditSize();
  };

  const handleDeleteOption = (id) => {
    const newOptions = options.filter((option) => option.id !== id);
    sendOptions(newOptions, indexFilter);
    toggleDeleteSize();
  };

  const handlefilterDelete = () => {
    setDeleteFilter(indexFilter);
    toggleDelete();
  };

  const deleteF = () => {
    handleDeleteFilter(deleteFilter);
    toggleDelete();
  };

  const handleEditFilter = () => {
    setEditFilter();
    toggleAdd();
  };

  const handleEditFilterName = () => {
    setEditFilter(filter?.name);
    toggleEdit();
  };

  const filterEdit = (index) => {
    handleEditarFiltro(index, editFilter);
    toggleEdit();
  };

  const handleEditOption = (id) => {
    const newOptions = options.map((option) => {
      if (option.id === id) {
        return {
          ...option,
          name: editName
        };
      }
      return option;
    });
    sendOptions(newOptions, indexFilter);
    setEditOption('');
    toggleEditSize();
  };

  useEffect(() => {
    setOptions(filter?.options);
  }, [filter?.options]);

  return (
    <>
      <Card className={classes.root}>
        <Box width="100%">
          <Box pt={2.5} pb={2} display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" ml={2}>
              <IconButton
                onClick={() => setShowSizes(!showSizes)}
                size="small"
                aria-label="drop-down"
              >
                <KeyboardArrowDownRounded className={classes.dropdown} />
              </IconButton>
              <Box ml={1} display="flex" flexDirection="column">
                <Typography
                  style={{ fontSize: '16px', wordBreak: 'break-all' }}
                  variant="h5"
                >
                  {filter?.name}
                </Typography>
              </Box>
            </Box>
            <Box pl={5} mr={2.5} display="flex" alignItems="center">
              <Box
                className={clsx(classes.icon, classes.btn)}
                onClick={() => handleEditFilterName()}
              >
                <Edit
                  style={{ color: '#fff', padding: '2px' }}
                  fontSize="small"
                />
              </Box>
              <Box
                className={clsx(classes.icon, classes.btn)}
                onClick={() => handleEditFilter()}
              >
                <Add style={{ color: '#fff' }} fontSize="small" />
              </Box>
              <Box
                className={clsx(classes.icon, classes.btn)}
                onClick={() => handlefilterDelete()}
              >
                <Delete p={2} style={{ color: '#fff' }} fontSize="small" />
              </Box>
            </Box>
          </Box>
          <Collapse in={showSizes}>
            <Box px={matches ? 2.5 : 1} mt={2}>
              <Table>
                <TableBodySortable
                  // onSortEnd={onSortEnd}
                  lockAxis="y"
                  useDragHandle
                >
                  {filter?.options?.map((item, index) => (
                    <TableRowSortable hover index={index} key={item?.id}>
                      <TableCell
                        width={871}
                        padding="none"
                        style={{ border: 'none' }}
                      >
                        <Box
                          py={2.5}
                          pt={0}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <SortableHandleIcon
                              className={classes.sortableIcon}
                            />
                            <Box ml={1.5}>
                              <Typography variant="h5">
                                {filter?.options[index]?.name}
                              </Typography>
                            </Box>
                          </Box>
                          <Box ml={1}>
                            <Box display="flex" justifyContent="flex-end">
                              <Box
                                className={clsx(classes.icon, classes.btn)}
                                // eslint-disable-next-line no-undef
                                onClick={() =>
                                  handleEdit(filter?.options[index]?.id, index)
                                }
                              >
                                <Edit
                                  style={{ color: '#fff', padding: '2px' }}
                                  fontSize="small"
                                />
                              </Box>
                              <Box
                                className={clsx(classes.icon, classes.btn)}
                                // eslint-disable-next-line no-undef
                                onClick={() =>
                                  handleDelete(filter?.options[index]?.id)
                                }
                              >
                                <Delete
                                  p={2}
                                  style={{ color: '#fff' }}
                                  fontSize="small"
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRowSortable>
                  ))}
                </TableBodySortable>
              </Table>
            </Box>
          </Collapse>
        </Box>
      </Card>

      {/* modal eliminar de filtro  */}
      <Modal
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        title="Eliminar filtro"
      >
        <Box flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="h4">
            ¿Estas seguro de eliminar este filtro?
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              style={{ margin: '0.3rem' }}
              onClick={() => deleteF()}
            >
              Eliminar
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* modal adicionar opcion */}
      <Modal isOpen={isOpenAdd} toggle={toggleAdd} title="Agregar opcion">
        <FormControl
          required
          variant="outlined"
        >
          <Box>
            <TextField
              fullWidth
              autoFocus
              label="Agregar opcion"
              margin="normal"
              name="options"
              onChange={(e) => setEditOption(e.target.value)}
              value={editOption}
              variant="outlined"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddOption()}
            >
              agregar
            </Button>
          </Box>
        </FormControl>
      </Modal>

      {/* modal editar nombre filtro */}
      <Modal isOpen={isOpenEdit} toggle={toggleEdit} title="Editar filtro">
        <FormControl
          required
          variant="outlined"
        >
          <Box>
            <TextField
              fullWidth
              autoFocus
              label="Nombre de los filtros"
              margin="normal"
              name="name"
              variant="outlined"
              onChange={(e) => setEditFilter(e.target.value)}
              value={editFilter}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => filterEdit(indexFilter)}
            >
              Editar
            </Button>
          </Box>
        </FormControl>
      </Modal>

      {/* modal eliminar una opción */}
      <Modal
        isOpen={isOpenDeleteSize}
        toggle={toggleDeleteSize}
        title="Eliminar opcion"
      >
        <Box flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="h4">
            ¿Estas seguro de eliminar esta opcion?
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              style={{ margin: '0.3rem' }}
              onClick={() => handleDeleteOption(idDelete)}
            >
              Eliminar
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* modal editar  la opcion */}
      <Modal
        isOpen={isOpenEditSize}
        toggle={toggleEditSize}
        title="Editar opcion"
      >
        <FormControl
          required
          variant="outlined"
        >
          <Box>
            <TextField
              autoFocus
              fullWidth
              label="Editar opcion"
              margin="normal"
              name="sizeE"
              // defaultValue={edit}
              variant="outlined"
              onChange={(e) => setEditName(e.target.value)}
              value={editName}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditOption(idDelete)}
          >
            Editar
          </Button>
        </FormControl>
      </Modal>
    </>
  );
}

FilterCard.propTypes = {
  filter: PropTypes.array,
  sendOptions: PropTypes.func,
  indexFilter: PropTypes.number,
  handleDeleteFilter: PropTypes.func,
  handleEditarFiltro: PropTypes.func
};

export default FilterCard;
