import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { PropTypes } from 'prop-types';
import SizesService from 'src/services/SizesService';
import arrayMove from 'array-move';
import orderBy from 'src/utils/arrays';
import Modal from 'src/components/Modal';
import useModal from 'src/hooks/useModal';
import useErrors from 'src/hooks/useErrors';
import {
  Box,
  Button,
  Typography,
  makeStyles,
  Card,
  IconButton,
  TextField,
  FormHelperText,
  useMediaQuery,
  FormControl,
  Collapse,
  TableCell,
  Table
} from '@material-ui/core';
import {
  Edit,
  Add,
  Delete,
  KeyboardArrowDownRounded
} from '@material-ui/icons';
import SortableHandleIcon from 'src/components/Icons/SortableHandleIcon';
import TableBodySortable from 'src/components/Table/TableBodySortable';
import TableRowSortable from 'src/components/Table/TableRowSortable';

const Size = ({ data, refresh }) => {
  const matches = useMediaQuery('screen and (min-width: 465px)');
  const { setErrors } = useErrors();
  const [isOpenAdd, toggleAdd] = useModal();
  const [isOpenEdit, toggleEdit] = useModal();
  const [isOpenDelete, toggleDelete] = useModal();
  const [isOpenEditSize, toggleEditSize] = useModal();
  const [isOpenDeleteSize, toggleDeleteSize] = useModal();
  const [localError, setLocalError] = useState([]);
  const [showSizes, setShowSizes] = useState(false);
  const [items, setItems] = useState([]);
  const [indexx, setIndexx] = useState(null);
  const [edit, setEdit] = useState('');
  const [indexEdit, setIndexEdit] = useState(null);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      minHeight: '100px',
      width: '100%',
      flexDirection: 'column'
    },
    description: {
      marginTop: '3px',
      fontSize: '12px',
      color: '#757575'
    },
    dropdown: {
      color: '#757575',
      transform: showSizes ? 'rotate(180deg)' : 'rotate(0deg)',
      transition: 'transform 0.3s ease 0s'
    },
    required: {
      width: 'fit-content',
      backgroundColor: '#ff3364',
      borderBottomRightRadius: '5px',
      color: '#fff',
      padding: '5px 8px'
    },
    icon: {
      cursor: 'pointer',
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      padding: '2px',
      borderRadius: '50%',
      marginRight: '8px'
    },
    sortableIcon: {
      color: theme.palette.primary.main,
      fontSize: '22px',
      marginRight: '10px'
    },
    btn: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#2e7d32'
      },
      transition: 'background 0.2s ease 0s'
    }
  }));
  const classes = useStyles();
  const onUpdate = async (newItems) => {
    try {
      const response = await SizesService.update(data.id, { size: newItems });
      if (response?.code === 200 || response?.code === 201) {
        refresh();
      }
    } catch (err) {
      console.error(err.message);
    }
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const payload = arrayMove(
      items.map((item, order) => {
        if (order === oldIndex) {
          return { name: item, newIndex };
        }
        if (order < oldIndex) {
          return { name: item, order: order + 1 };
        }
        return { name: item, order };
      }),
      oldIndex,
      newIndex
    );
    const orderItems = orderBy(payload, 'order');
    const sizes = orderItems.map((item) => item.name);
    setItems(sizes);
    onUpdate(sizes);
  };
  
  const handleSubmitAdd = async (e) => {
    e.preventDefault();
    const { size } = e.target;
    const sizes = data.size.slice();
    const errors = [];
    sizes.push(size.value);
    const update = {
      size: sizes
    };
    if (size.value.trim().length === 0) {
      errors.push({
        field: 'size',
        error: 'La talla es obligatoria'
      });
    }
    if (errors.length > 0) {
      setErrors(errors.map((err) => err.error));
      setLocalError(errors);
      return;
    }
    const response = await SizesService.update(data.id, update);
    if (response.code === 200){
      toggleAdd();
      refresh();
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name } = e.target;
    const errors = [];
    const update = {
      name: name.value
    };
    if (update.name.trim().length === 0) {
      errors.push({
        field: 'name',
        error: 'El nombre de las tallas es obligatorio'
      });
    }
    if (errors.length > 0) {
      setErrors(errors.map((err) => err.error));
      setLocalError(errors);
      return;
    }
    await SizesService.update(data.id, update);
    toggleEdit();
    refresh();
  };

  const handleSubmitEdit = async (e, indexE) => {
    e.preventDefault();
    const { sizeE } = e.target;
    const errors = [];
    const sizes = data.size.slice();
    sizes.splice(indexE, 1, sizeE.value);
    const update = {
      size: sizes
    };
    if (sizeE.value.trim().length === 0) {
      errors.push({
        field: 'size',
        error: 'La talla es obligatoria'
      });
    }
    if (errors.length > 0) {
      setErrors(errors.map((err) => err.error));
      setLocalError(errors);
      return;
    }
    await SizesService.update(data.id, update);
    toggleEditSize();
    refresh();
  };
  const delet = async () => {
    await SizesService.remove(data?.id);
    toggleDelete();
    refresh();
  };
  const handleDelete = (index) => {
    toggleDeleteSize();
    setIndexx(index);
  };
  const handleEdit = (index, item) => {
    toggleEditSize();
    setIndexEdit(index);
    setEdit(item);
  };
  const deletSize = async (ind) => {
    const sizes = data.size.slice();
    sizes.splice(ind, 1);
    const update = {
      size: sizes
    };
    await SizesService.update(data.id, update);
    toggleDeleteSize();
    refresh();
  };
  useEffect (() => {
    setItems(data.size.slice());
  }, [data]);
  return (
    <Card className={classes.root}>
      <Box width="100%">
        <Box pt={2.5} pb={2} display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" ml={2}>
            <IconButton
              onClick={() => setShowSizes(!showSizes)}
              size="small"
              aria-label="drop-down"
            >
              <KeyboardArrowDownRounded className={classes.dropdown} />
            </IconButton>
            <Box ml={1} display="flex" flexDirection="column">
              <Typography
                style={{ fontSize: '16px', wordBreak: 'break-all' }}
                variant="h5"
              >
                {data.name}
              </Typography>
            </Box>
          </Box>
          <Box pl={5} mr={2.5} display="flex" alignItems="center">
            <Box
              className={clsx(classes.icon, classes.btn)}
              onClick={() => toggleEdit()}
            >
              <Edit
                style={{ color: '#fff', padding: '2px' }}
                fontSize="small"
              />
            </Box>
            <Box
              className={clsx(classes.icon, classes.btn)}
              onClick={() => toggleAdd()}
            >
              <Add style={{ color: '#fff' }} fontSize="small" />
            </Box>
            <Box
              className={clsx(classes.icon, classes.btn)}
              onClick={() => toggleDelete()}
            >
              <Delete p={2} style={{ color: '#fff' }} fontSize="small" />
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal isOpen={isOpenDelete} toggle={toggleDelete} title="Eliminar Talla">
        <Box flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="h4">
            ¿Estas seguro de eliminar esta talla?
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              style={{ margin: '0.3rem' }}
              onClick={() => delet()}
            >
              Eliminar
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal isOpen={isOpenAdd} toggle={toggleAdd} title="Agregar talla">
        <form onSubmit={(e) => handleSubmitAdd(e)}>
          <FormControl
            required
            variant="outlined"
            error={localError.some((e) => e.field === 'size')}
          >
            <Box>
              <TextField
                fullWidth
                label="Agregar talla"
                margin="normal"
                name="size"
                variant="outlined"
              />
              <Button variant="contained" color="primary" type="submit">
                Agregar
              </Button>
            </Box>
            {localError.some((e) => e.field === 'size') && (
              <FormHelperText error>
                {localError?.find((e) => e.field === 'size')?.error || ''}
              </FormHelperText>
            )}
          </FormControl>
        </form>
      </Modal>
      <Modal isOpen={isOpenEdit} toggle={toggleEdit} title="Editar talla">
        <form onSubmit={(e) => handleSubmit(e)}>
          <FormControl
            required
            variant="outlined"
            error={localError.some((e) => e.field === 'name')}
          >
            <Box>
              <TextField
                fullWidth 
                defaultValue={data.name}
                label="Nombre de las tallas"
                margin="normal"
                name="name"
                variant="outlined"
              />
              <Button variant="contained" color="primary" type="submit">
                Editar
              </Button>
            </Box>
            {localError.some((e) => e.field === 'name') && (
              <FormHelperText error>
                {localError?.find((e) => e.field === 'name')?.error || ''}
              </FormHelperText>
            )}
          </FormControl>
        </form>
      </Modal>
      <Modal
        isOpen={isOpenDeleteSize}
        toggle={toggleDeleteSize}
        title="Eliminar talla"
      >
        <Box flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="h4">
            ¿Estas seguro de eliminar esta talla?
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              style={{ margin: '0.3rem' }}
              onClick={() => deletSize(indexx)}
            >
              Eliminar
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        isOpen={isOpenEditSize}
        toggle={toggleEditSize}
        title="Editar talla"
      >
        <form onSubmit={(e) => handleSubmitEdit(e, indexEdit)}>
          <FormControl
            required
            variant="outlined"
            error={localError.some((e) => e.field === 'sizeE')}
          >
            <Box>
              <TextField
                fullWidth
                label="Talla"
                margin="normal"
                name="sizeE"
                defaultValue={edit}
                variant="outlined"
              />
            </Box>
            <Button variant="contained" color="primary" type="submit">
              Editar
            </Button>
            {localError.some((e) => e.field === 'sizeE') && (
              <FormHelperText error>
                {localError?.find((e) => e.field === 'sizeE')?.error || ''}
              </FormHelperText>
            )}
          </FormControl>
        </form>
      </Modal>
      <Collapse in={showSizes}>
        <Box px={matches ? 2.5 : 1} mt={2}>
          <Table>
            <TableBodySortable onSortEnd={onSortEnd} lockAxis="y" useDragHandle>
              {items.map((item, index) => (
                <TableRowSortable hover index={index} key={item.id}>
                  <TableCell
                    width={871}
                    padding="none"
                    style={{ border: 'none' }}
                  >
                    <Box
                      py={2.5}
                      pt={0}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center">
                        <SortableHandleIcon className={classes.sortableIcon} />
                        <Box ml={1.5}>
                          <Typography variant="h5">{item}</Typography>
                        </Box>
                      </Box>
                      <Box ml={1}>
                        <Box display="flex" justifyContent="flex-end">
                          <Box
                            className={clsx(classes.icon, classes.btn)}
                            onClick={() => handleEdit(index, item)}
                          >
                            <Edit
                              style={{ color: '#fff', padding: '2px' }}
                              fontSize="small"
                            />
                          </Box>
                          <Box
                            className={clsx(classes.icon, classes.btn)}
                            onClick={() => handleDelete(index)}
                          >
                            <Delete
                              p={2}
                              style={{ color: '#fff' }}
                              fontSize="small"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRowSortable>
              ))}
            </TableBodySortable>
          </Table>
        </Box>
      </Collapse>
    </Card>
  );
};
Size.propTypes = {
  data: PropTypes.array,
  refresh: PropTypes.func
};
export default Size;
