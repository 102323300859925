import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import cargandoGif from 'src/assets/image/cargando.gif';
import { getHour, getDate } from 'src/utils/formatDate';
import {
  Box,
  Card,
  Collapse,
  Divider,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import './OrderItem.css';
import {
  Restaurant,
  Print,
  ZoomOutMapRounded,
  KeyboardArrowDownRounded,
  Timer as TimerIcon,
  Block,
} from '@material-ui/icons';
import { MdDeliveryDining, MdRoomService, MdDinnerDining } from 'react-icons/md';
import { GiCookingPot } from 'react-icons/gi';
import ButtonStatus from 'src/components/ButtonStatus';

const OrderItem = ({
  order,
  toggleOrderModal,
  togglePrintModal,
  handleOrder,
  handleStatus,
  updateStatus,
  statusTranslate,
  haveToppings,
  detailProduct,
  getDetailPrice,
  toggleShow,
  index,
  timer,
}) => {
  const [products, setProducts] = useState([]);
  const useStyles = makeStyles((theme) => ({
    root: {},
    order: {
      position: 'relative',
      width: '100%',
      borderRadius: 20,
      border: timer?.warning && order.status === 'created' ? '4px solid #f44336' : null,
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    },
    moreVert: {
      cursor: 'pointer'
    },
    statusButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '.8rem',
      borderRadius: '10px',
      padding: '.8rem 1rem',
      backgroundColor: 'rgb(243, 243, 243)',
      border: 'none',
      cursor: 'pointer',
      width: '200px',
      '& > strong': {
        backgroundColor: 'rgb(167, 167, 167)',
        padding: '.7rem',
        borderRadius: '100px',
        height: '2.5rem',
        width: '2.5rem',
        color: 'white',
        textAlign: 'center'
      },
      '&:focus': {
        backgroundColor: 'rgb(242, 255, 0)',
        boxShadow: '2px 4px 6px 0px rgba(189,189,189,1)',
        transition: '1s',
        '& > strong': {
          backgroundColor: 'rgb(224, 51, 109)',
        }
      }
    },
    statusButtonAll: {
      display: 'flex',
      alignItems: 'center',
      gap: '.8rem',
      borderRadius: '10px',
      padding: '.8rem 1rem',
      backgroundColor: 'rgb(242, 255, 0)',
      border: 'none',
      cursor: 'pointer',
      width: '200px',
      boxShadow: '2px 4px 6px 0px rgba(189,189,189,1)',
      transition: '1s',
      '& > strong': {
        backgroundColor: 'rgb(224, 51, 109)',
        padding: '.7rem',
        borderRadius: '100px',
        height: '2.5rem',
        width: '2.5rem',
        color: 'white',
        textAlign: 'center'
      },
    },
    iconFood: {
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
      fontSize: '17px'
    },
    dateAfter: {
      color: '#bbb'
    },
    true: {
      fill: theme?.palette?.primary?.main,
    },
    false: {
      fill: '#aaa'
    },
    statusCell: {
      position: 'relative'
    },
    statusPanel: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 120,
      backgroundColor: 'white',
      borderRadius: '.2rem',
      border: '1px solid #ddd',
    },
    statusPanelList: {
      listStyle: 'none'
    },
    statusPanelItem: {
      padding: 0,
      margin: 0,
      '& > a': {
        display: 'block',
        padding: '.75rem 1rem',
        cursor: 'pointer',
        fontFamily: 'sans-serif',
        fontSize: '.9rem',
        '&:hover': {
          backgroundColor: '#eee'
        }
      }
    },
    pending: {
      backgroundColor: '#FBC02D',
      color: 'white',
    },
    rejected: {
      backgroundColor: 'red',
      color: 'white'
    },
    accepted: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    finished: {
      backgroundColor: '#1565C0',
      color: 'white',
    },
    sent: {
      color: 'white',
      backgroundColor: '#7B1FA2',
    },
    dropdown: {
      color: theme.palette.primary.main,
      transition: 'transform 0.3s ease 0s'
    },
    activeDropdown: {
      transform: order?.show ? 'rotate(180deg)' : 'rotate(0deg)',
    },
    buttonIcon: {
      borderRadius: '50%',
      minWidth: 10,
      padding: 5,
      color: '#fff',
      backgroundColor: '#2eb87e',
      '&:hover': {
        backgroundColor: '#249163'
      }
    },
    collapse: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      paddingBottom: theme.spacing(1),
    },
    ButtonStatus: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(1),
      width: order?.status === 'sent' || order?.status === 'sent_local' ? '100%' : 'fit-content'
    },
    textTimer: {
      fontWeight: 700,
      fontSize: '19px',
      color: timer?.warning ? theme.palette.error.main : theme.palette.primary.main,
    },
    iconTimer: {
      marginRight: 8,
      color: timer?.warning ? theme.palette.error.main : theme.palette.primary.main,
    },
    referenceWarning: {
      color: timer?.warning ? theme.palette.error.main : theme.palette.primary.main,
    },
    borderWarning: {
      backgroundColor: timer?.warning ? theme.palette.error.main : theme.palette.primary.main
    },
    gif: {
      width: '100%',
      height: '100%'
    },
    iconStatus: {
      fontSize: 28,
      color: '#009739'
    }
  }));

  const classes = useStyles();

  const setOrder = () => {
    handleOrder(order);
  };

  const getProducts = () => {
    return order?.order_products?.map((product) => {
      let sections = [];
      if (product?.customize?.sections?.length > 0) {
        sections = product?.customize?.sections?.map((section) => {
          let items = [];
          if (section?.type === 'radio') {
            items = section?.items.filter((item) => item.value);
          } else if (section?.type === 'checkbox') {
            items = section?.items.filter((item) => !item.value);
          } else {
            items = section?.items.filter((item) => item.value > 0);
          }

          if (items?.length === 0) return {};
          return {
            ...section,
            items
          };
        });
      }
      return {
        ...product,
        customize: {
          sections
        }
      };
    });
  };

  useEffect(() => {
    setProducts(getProducts());
  }, []);

  return order && (
    <Card
      className={classes.order}
    >
      <Box
        p={1}
        display="flex"
        alignItems="center"
        justifyContent={order?.status === 'created' ? 'space-between' : 'flex-end'}
        width="100%"
        pt={2}
        px={2}
      >
        {order.status === 'created' && (
          <Box display="flex">
            <TimerIcon className={classes.iconTimer} />
            <Typography className={classes.textTimer} variant="h4">{timer?.time}</Typography>
            <Typography className={classes.textTimer} style={{ marginLeft: 5 }} variant="h4">min</Typography>
          </Box>
        )}
        <Box display="flex">
          <Box mr={1}>
            <Button
              onClick={() => {
                handleOrder(order);
                toggleOrderModal();
              }}
              className={classes.buttonIcon}
              variant="contained"
              size="small"
            >
              <ZoomOutMapRounded style={{ fontSize: '16px' }} />
            </Button>
          </Box>

          <Button
            onClick={() => {
              handleOrder(order);
              togglePrintModal();
            }}
            className={classes.buttonIcon}
            variant="contained"
            size="small"
          >
            <Print style={{ fontSize: '16px' }} />
          </Button>
        </Box>
      </Box>
      <Box p={2} pt={order.status === 'created' ? 3 : 2} pl={1}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => toggleShow(index)} size="small" aria-label="drop-down">
              <KeyboardArrowDownRounded
                style={{ fontSize: '29px' }}
                className={clsx(classes.dropdown, {
                  [classes.activeDropdown]: order?.show
                })}
              />
            </IconButton>
            <Box ml={0.5}>
              <Typography className={clsx({ [classes.referenceWarning]: order?.status === 'created' })} style={{ fontWeight: 600, fontSize: '22px' }} variant="h4">
                Pedido #
                {order?.reference}
              </Typography>
              <Box display="flex" mt={0.5}>
                <Typography style={{ color: '#A5A5A5', fontWeight: 700, marginRight: '5px' }} variant="body2">{getDate(order?.created_at)}</Typography>
                <Typography style={{ color: '#A5A5A5', fontWeight: 700 }} variant="body2">{getHour(order?.created_at)}</Typography>
              </Box>
              <Typography
                style={{
                  color: '#999',
                  fontSize: '12px',
                  marginTop: '2px',
                  fontWeight: 700,
                  wordBreak: 'break-all',
                  marginRight: '50px',
                }}
                variant="body2"
              >
                {`${order?.data_client?.first_name} ${order?.data_client?.last_name}`}
              </Typography>
            </Box>
          </Box>
          <Box pr={1.5} display="flex" flexDirection="column" alignItems="center">
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={1}
              width={order?.status === 'created' || order?.status === 'in_progress' || order?.status === 'pending_to_be_sent' ? 50 : 'fit-content'}
              height={order?.status === 'created' || order?.status === 'in_progress' || order?.status === 'pending_to_be_sent' ? 50 : 'fit-content'}
            >
              {(order?.status === 'created' || order?.status === 'in_progress' || order?.status === 'pending_to_be_sent') && (
                <Box position="absolute" top={0}>
                  <img className={classes.gif} src={cargandoGif} alt="cargando" />
                </Box>
              )}
              {order?.status === 'in_progress' && (
                <GiCookingPot className={classes.iconStatus} />
              )}
              {order?.status === 'pending_to_be_sent' && (
                <MdDeliveryDining className={classes.iconStatus} />
              )}
              {(order?.status === 'sent' || order?.status === 'sent_local') && (
                <MdRoomService className={classes.iconStatus} />
              )}
              {order?.status === 'finished' && (
                <MdDinnerDining className={classes.iconStatus} />
              )}
              {(order?.status === 'cancelled' || order?.status === 'rejected') && (
                <Block className={classes.iconStatus} />
              )}
            </Box>
            <Typography variant="body2" style={{ fontWeight: 600, fontSize: '13px', textAlign: 'center' }}>
              {statusTranslate(order?.status?.toString(), order?.ship_type)}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-start" pl={1} pt={1}>
          <ButtonStatus
            className={classes.ButtonStatus}
            status={order?.status}
            stylesButtons={classes.button}
            setOrder={setOrder}
            setStatus={handleStatus}
            updateStatus={updateStatus}
            idOrder={order?.id}
            deliveryType={order?.ship_type}
          />
        </Box>
      </Box>
      <Collapse className={classes.collapse} in={order?.show}>
        <Divider style={{ marginTop: '5px' }} />
        <Box px={1} pt={1.5}>
          <Typography style={{ fontWeight: 600 }} variant="h5">Productos</Typography>
        </Box>
        <Box p={1} pt={1.5}>
          <Box borderBottom="2px dashed #B5B5B5">
            {products.map((product) => {
              const detailPrice = getDetailPrice(product?.prices);
              return (
                <Box borderTop="2px dashed #B5B5B5">
                  <Box display="flex" alignItems="center" justifyContent="space-between" py={1.5}>
                    <Box display="flex">
                      <Restaurant className={classes.iconFood} />
                      <Box display="flex" flexDirection="column">
                        <Typography style={{ fontWeight: 600 }} variant="body2">
                          {product?.amount}
                          {' x '}
                          {haveToppings(product?.customize?.sections) ? '(S) ' : '(M) '}
                          {product?.name}
                        </Typography>
                        <Typography style={{ fontSize: '12px', marginTop: '2px' }} variant="body2">
                          <span style={{ fontWeight: 600 }}>{detailPrice?.name}</span>
                          {detailPrice?.description?.length > 0 && (
                            <>
                              {` (${detailPrice?.description})`}
                            </>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {detailProduct(product) && (
                    <Box borderTop="2px dashed #B5B5B5" pt={1.5}>
                      {!haveToppings(product?.customize?.sections) && (
                        <Box>
                          {product?.customize?.sections?.map((section) => (
                            <>
                              {Object.keys(section)?.length > 0 && (
                                <Box mb={1.5}>
                                  <Typography style={{ fontWeight: 700 }} variant="h6">{section.title}</Typography>
                                  <Box pl={1}>
                                    {section?.items?.map((item) => (
                                      <Typography variant="body2">
                                        {'* '}
                                        {section?.type === 'quantity' && (
                                          <span style={{ fontWeight: 600 }}>
                                            {item?.value}
                                            {' x '}
                                          </span>
                                        )}
                                        {item?.name}
                                        {section?.type === 'checkbox' && (
                                          <span style={{ fontWeight: 600 }}>
                                            {' (NO) '}
                                          </span>
                                        )}
                                      </Typography>
                                    ))}
                                  </Box>
                                </Box>
                              )}
                            </>
                          ))}
                        </Box>
                      )}
                      {product?.comment?.length > 0 && (
                        <Box pb={1.5}>
                          <Typography style={{ fontWeight: 700 }} variant="h6">Comentarios</Typography>
                          <Box pl={1}>
                            <Typography variant="body2">{product?.comment}</Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Collapse>
      {order?.status === 'created' && (
        <Box width="100%" height={55} className={classes.borderWarning} />
      )}
    </Card>
  );
};

OrderItem.propTypes = {
  order: PropTypes.object,
  toggleOrderModal: PropTypes.func,
  togglePrintModal: PropTypes.func,
  handleOrder: PropTypes.func,
  handleStatus: PropTypes.func,
  updateStatus: PropTypes.func,
  statusTranslate: PropTypes.func,
  haveToppings: PropTypes.func,
  detailProduct: PropTypes.func,
  getDetailPrice: PropTypes.func,
  toggleShow: PropTypes.func,
  index: PropTypes.number,
  timer: PropTypes.object,
};

export default OrderItem;
