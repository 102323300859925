import {
  Percent as PercentIcon,
  ShoppingBag as ShoppingBagIcon
} from 'react-feather';
import { VscPackage } from 'react-icons/vsc';
import {
  Group as GroupIcon,
  Store as StoreIcon,
  Category as CategoryIcon,
  // LocalShipping as LocalShippingIcon,
  // BarChart,
  TransferWithinAStation,
  Assessment
} from '@material-ui/icons';

const partners = [
  // {
  //   href: '/app/ordenes/en-progreso',
  //   icon: LocalShippingIcon,
  //   title: 'Ordenes en Progreso',
  //   active: false
  //   roles: [roles.ADMIN],
  // },
  {
    href: '/app/sucursales',
    icon: StoreIcon,
    title: 'Sucursales',
    active: false
  },
  {
    href: '/app/componentes',
    icon: CategoryIcon,
    title: 'Componentes',
    active: false
  },
  {
    href: '/app/productos',
    icon: ShoppingBagIcon,
    title: 'Productos',
    active: false
  },
  {
    href: '/app/descuentos',
    icon: PercentIcon,
    title: 'Descuentos',
    active: false
  },
  {
    href: '/app/gerentes',
    icon: GroupIcon,
    title: 'Gerentes',
    active: false
  },
  {
    href: '/app/repartidores',
    icon: TransferWithinAStation,
    title: 'Repartidores',
    active: false
  }
  // {
  //   href: '/app/reportes/productos',
  //   icon: BarChart,
  //   title: 'Reporte venta de productos',
  //   active: false
  // },
  // {
  //   href: '/app/reportes/ventas',
  //   icon: BarChart,
  //   title: 'Reporte de ventas',
  //   active: false
  // },
];

const partnersLogistic = [
  // {
  //   href: '/app/ordenes/en-progreso',
  //   icon: LocalShippingIcon,
  //   title: 'Ordenes en Progreso',
  //   active: false
  //   roles: [roles.ADMIN],
  // },
  {
    href: '/app/sucursales',
    icon: StoreIcon,
    title: 'Sucursales',
    active: false
  },
  {
    href: '/app/paqueteria',
    icon: VscPackage,
    title: 'Paqueteria',
    active: false
  },
  {
    href: '/app/reportes/servicios',
    icon: Assessment,
    title: 'Reporte de servicios',
    active: false
  }
];

export { partners, partnersLogistic };
