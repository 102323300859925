import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Grow,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Error } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  conatinerAlerts: {
    backgroundColor: '#f8d7da',
    borderRadius: 10,
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '19px',
    marginRight: '4px'
  },
  textAlert: {
    fontSize: '14px',
    wordBreak: 'break-all',
    color: theme.palette.error.main,
  },
  scroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '30px',
      backgroundColor: theme.palette.error.main
    }
  }
}));

const Alerts = ({ warnings }) => {
  const classes = useStyles();

  return warnings && warnings?.length > 0 && (
    <Grow in timeout={1500}>
      <Box
        overflow="auto"
        maxHeight={124}
        className={clsx(classes.conatinerAlerts, classes.scroll)}
        p={2}
        pt={1.5}
        mx={3}
      >
        {warnings?.map((item) => (
          <Box display="flex" alignItems="center" mt={0.5}>
            <Error className={classes.error} size="small" />
            <Typography className={classes.textAlert} variant="body2">
              La orden #
              {item?.reference}
              {' '}
              esta por ser cancelada
            </Typography>
          </Box>
        ))}
      </Box>
    </Grow>
  );
};

Alerts.propTypes = {
  warnings: PropTypes.array,
};

export default Alerts;
