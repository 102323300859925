import HttpRequest from './HttpRequest';

export default class ProductService extends HttpRequest {
  static endpoint = 'products';

  static async getAll() {
    this.endpoint = 'products';
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'products';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.endpoint = 'products';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'products';
    const response = await this.put(id, data);
    return response;
  }

  static async activeAllByBrand(id) {
    this.endpoint = 'products/brand';
    const response = await this.put(`${id}/active-all`);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'products';
    const response = await this.delete(id);
    return response;
  }

  static async toggle(id) {
    this.endpoint = 'products';
    const response = await this.get(`${id}/toggle`);
    return response;
  }

  static async uploadImage(data) {
    this.endpoint = 'products/create-primary-image';
    const response = await this.post(data);
    return response;
  }

  static async uploadImages(data) {
    this.endpoint = 'products/update-images';
    const response = await this.post(data);
    return response;
  }

  static async deleteImage(id) {
    this.endpoint = 'products/delete-primary-image';
    const response = await this.delete(id);
    return response;
  }

  static async createVariant(data) {
    this.endpoint = 'products/duplicate';
    const response = await this.post(data);
    return response;
  }
}
